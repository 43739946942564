import { createRouter, createWebHashHistory } from 'vue-router';
import routes from './routes';
import { store } from '../store';
import { updateStatusBarStyle } from '../vuetify';

const baseUrl = import.meta.env.BASE_URL;
console.log('baseUrl', baseUrl);

const router = createRouter({
    history: createWebHashHistory(baseUrl),
    routes,
    scrollBehavior(to, _from, savedPosition) {
        if (savedPosition && to.meta?.preserveScrollPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, _from, next) => {
    store.setDocumentTitle(null);

    // Cada ruta puede especificar el color del status bar del dispositivo.
    // Si no se especifica, se usa el color del shell para calcular el color.
    updateStatusBarStyle(to.meta?.statusBarStyle);

    // Cada ruta puede especificar si se oculta el scroll del body.
    if (to.meta.pageOverflowHidden === false) {
        document.documentElement.classList.add('overflow-hidden');
    } else {
        document.documentElement.classList.remove('overflow-hidden');
    }

    next();
});

export default router;
