<template>
    <v-bottom-navigation v-model="activeRoute" :grow="true" bg-color="shell" color="shell-primary" elevation="3">
        <v-btn
            v-if="showBackButton"
            icon="mdi-arrow-left"
            :disabled="!canGoBack"
            aria-hidden="true"
            @click="$router.back()" />
        <v-btn
            icon="mdi-home"
            value="/"
            exact
            :aria-label="$t('bottomNavigation.inicio')"
            to="/" />
        <v-btn
            icon="mdi-mortar-pestle-plus"
            value="/farmacia"
            exact
            :aria-label="$t('bottomNavigation.contacto')"
            to="/farmacia" />
        <!-- El buscador se elimina de la barra inferior en mobile. En un futuro se podría sacar el buscador de la barra superior en mobile y dejarlo en la inferior -->
        <!--<v-btn
            icon="mdi-magnify"
            :active="buscadorVisible"
            :aria-label="$t('bottomNavigation.buscador')"
            value="__buscador__" />-->
        <v-btn
            icon="mdi-bell"
            value="/"
            exact
            :aria-label="$t('bottomNavigation.notificaciones')"
            to="/notificaciones">
            <v-badge :model-value="notificacionesBadgeCount > 0" :content="notificacionesBadgeCount" color="secondary">
                <v-icon icon="mdi-bell" />
            </v-badge>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
    props: {
        /**
         * Indica si el buscador está abierto con el popup de sugerencias.
         */
        buscadorVisible: {
            type: Boolean,
            default: false,
        },

        /**
         * El número de notificaciones sin leer.
         */
        notificacionesBadgeCount: {
            type: Number,
            default: 2,
        },
    },
    emits: [
        'update:buscadorVisible',
    ],
    data() {
        return {
            /**
             * Indica la ruta activa que debe estar seleccionada.
             *
             * @type {string|null}
             */
            activeRoute: null,
        };
    },
    computed: {
        showBackButton() {
            // iOS no tiene botón de atrás, por lo que mostramos un botón de atrás de forma manual.
            // En un futuro, sería ideal reestructurar un poco la navegación para que no sea necesario
            // este botón. Por ejemplo, mostrando un boton de atrás en la barra de navegación solo cuando
            // se está en una página de detalle, y el menu hamburguesa en los niveles superiores de navegación.
            // Explore navigation design for iOS: https://developer.apple.com/videos/play/wwdc2022/10001/
            return Capacitor.getPlatform() === 'ios';
        },
        canGoBack() {
            return this.$route.path !== '/';
        },
    },
    watch: {
        activeRoute() {
            if (this.activeRoute === '__buscador__') {
                this.$emit('update:buscadorVisible', true);
                this.activeRoute = null; // Resetea el valor para que no se quede activo
            }
        },
    },
};
</script>
