const colors = {
    'facebook': '#1877f2',
    'linkedin': '#0077b5',
    'twitter': '#1DA1F2',
    'x': '#000000',
    'instagram': '#fb3958',
    'youtube': '#ff0000',
    'reddit': '#FF5700',
    'pinterest': '#E60023',
    'vimeo': '#1ab7ea',
    'tiktok': '#ff0050',
    'whatsapp': '#25d366',
    'telegram': '#0088cc',
    'dailymotion': '#00aaff',
    'twitch': '#9146ff',
    'github': '#333333',
    'wordpress': '#21759b',
    'blogspot': '#f57d00',
    'tumblr': '#35465c',
    'medium': '#00ab6c',
    'snapchat': '#fffc00',
    'discord': '#5865f2',
    'slack': '#6ecadc',
};

/**
 * Obtiene el color de la red social, o null si no existe.
 *
 * @param {string} slug
 * @returns {string|null}
 */
export function colorSocial(slug) {
    return colors[slug] ?? null;
}
