<template>
    <!-- Hack: iOS requiere para que se muestre el boton de "Search" en el teclado virtal, que
        el <input type="search"> esté dentro de un <form> con un atributo "action" definido -->
    <form action="javascript:void(0);">
        <!-- Desktop: Text field completo -->
        <v-text-field
            v-if="$vuetify.display.mdAndUp"
            v-model="query"
            hide-details
            density="compact"
            type="search"
            :label="$t('dialogBuscador.buscaProductosYcategorias')"
            :aria-label="$t('dialogBuscador.buscaProductosYcategorias')"
            class="mr-4 search-input"
            variant="outlined"
            enterkeyhint="search"
            :style="{
                width: searchFocused ? '500px' : '350px', // 350px se eligió para que se vea bien en todos los lenguaje soportados sin cortar el textos
                backgroundColor: searchFocused ? null : 'rgba(var(--v-theme-surface), var(--v-focus-opacity))',
            }"
            :bg-color="searchFocused ? 'surface' : null"
            :color="searchFocused ? 'shell-primary' : 'on-shell'"
            single-line
            :role="searchFocused ? 'search' : 'button'"
            @keyup.enter="buscar"
            @update:focused="searchFocused = $event"
            @input="filtraCaracteresEspeciales">
            <template #append-inner>
                <v-btn
                    icon="mdi-magnify"
                    density="compact"
                    variant="text"
                    :disabled="query.length < minLength"
                    :color="searchFocused ? 'shell-primary' : 'on-shell'"
                    style="margin-top: -2px; margin-right: -4px; margin-left: 4px; margin-bottom: -2px;"
                    :style="searchFocused ? 'opacity: 1;' : 'opacity: 0.5;'"
                    :aria-label="$t('dialogBuscador.buscar')"
                    @click="buscar" />
            </template>
            <v-menu
                :model-value="searchFocused"
                location-strategy="connected"
                :close-on-content-click="false"
                :open-on-click="false"
                activator="parent"
                offset="3px"
                transition="fade-transition"
                location="bottom end"
                origin="top end">
                <the-buscador-sugerencias v-model:query="query" elevation="3" :min-length="minLength" style="width: 500px" />
            </v-menu>
        </v-text-field>

        <!-- Mobile: Botón de busqueda. La busqueda se abre en un overlay. -->
        <v-btn v-if="!$vuetify.display.mdAndUp" icon color="shell-primary" @click.stop="showValue = true">
            <v-icon icon="mdi-magnify" />

            <v-overlay v-model="showValue" class="d-flex flex-column">
                <v-toolbar class="mx-0-100 d-flex align-center" style="width: 100vw;" color="surface" elevation="1">
                    <v-btn
                        icon="mdi-arrow-left"
                        color="primary"
                        :aria-label="$t('dialogBuscador.cerrar')"
                        @click="showValue = false" />
                    <v-btn
                        v-if="muestraCamara"
                        icon="mdi-camera"
                        density="compact"
                        variant="text"
                        :aria-label="$t('dialogBuscador.buscar')"
                        :to="{name:'LectorQrArticu'}" />
                    <v-text-field
                        ref="searchInputMobile"
                        v-model="query"
                        type="search"
                        variant="plain"
                        density="default"
                        color="on-shell"
                        class="search-input-mobile mx-2"
                        hide-details
                        enterkeyhint="search"
                        :placeholder="$t('dialogBuscador.buscaProductosYcategorias')"
                        autofocus
                        @keyup.enter="buscar"
                        @keyup.esc="showValue = false" />
                    <v-btn
                        v-if="query?.length > 0"
                        icon="mdi-close"
                        variant="text"
                        size="small"
                        color="primary"
                        :aria-label="$t('dialogBuscador.borrar')"
                        @click="clearQuery" />
                </v-toolbar>

                <the-buscador-sugerencias v-model:query="query" elevation="5" :min-length="minLength" class="rounded-t-0 rounded-b-0" />
            </v-overlay>
        </v-btn>
    </form>
</template>

<script>
import imagenDefaultUrl from '../../../images/producto-default.jpg';
import TheBuscadorSugerencias from './TheBuscadorSugerencias.vue';
import StringHelper from '../../support/StringHelper';
import { Capacitor } from '@capacitor/core';

export default {
    components: {
        TheBuscadorSugerencias,
    },
    props: {
        /**
         * Indica si el buscador está abierto con el popup de sugerencias.
         */
        show: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:show'],
    data() {
        return {
            /**
             * Longitud mínima de caracteres de la búsqueda.
             *
             * @type {number}
             */
            minLength: 2, // Debe coincidir con el backend

            /**
             * El termino de búsqueda ingresado por el usuario.
             *
             * @type {string}
             */
            query: '',

            /**
             * Indica si el campo de búsqueda tiene el foco.
             *
             * @type {boolean}
             */
            searchFocused: false,
        };
    },
    computed: {
        imagenDefaultUrl() {
            return imagenDefaultUrl;
        },
        showValue: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit('update:show', value);
            },
        },
        muestraCamara() {
            return Capacitor.getPlatform() !== 'web';
        },
    },
    watch: {
        '$route': {
            handler(to, _from) {
                if (to.query?.query) {
                    this.query = to.query.query;
                } else {
                    this.query = '';
                    this.showValue = false;
                }
            },
            immediate: true,
        },
    },

    _timerId: null, // No reactiva

    methods: {
        buscar() {
            if (this.query.length < this.minLength) {
                return;
            }

            this.$router.push({
                path: '/productos',
                query: { query: this.query },
            });
            this.showValue = false;
            this.searchFocused = false;
        },

        clearQuery() {
            this.query = '';
            this.$refs.searchInputMobile?.focus();
        },
        /**
         * Filtra caracteres especiales de la búsqueda.
         */
        filtraCaracteresEspeciales() {
            this.query = StringHelper.filtraCaracteresEspeciales(this.query);
        },
    },
};
</script>

<style scoped>
.search-input {
    transition: width 0.15s ease-in-out;
}

.search-input-mobile {
    --v-input-padding-top: 0;
}
</style>
