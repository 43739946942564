import '@mdi/font/css/materialdesignicons.css';
import { createApp } from 'vue';

import router from './router';
import i18n, { getDefaultLocale, mergeLocaleMessages } from './i18n';
import vuetify from './vuetify';
import vueCookies from 'vue-cookies';

import App from './App.vue';

import 'vuetify/dist/vuetify.min.css';
import { setupApproxClientHeader, setupAxiosInterceptor, setupAxiosEndpoint } from './support/AxiosHelper';

import { store } from './store';
import StorageHelper from './support/StorageHelper';

/**
 * Crea la aplicación Vue de Approx.
 *
 * @returns {import('vue').App}
 */
function createApproxApp() {
    const app = createApp(App);
    app.use(router);
    app.use(i18n);
    app.use(vuetify);
    app.use(vueCookies);

    setupAxiosInterceptor(router);
    return app;
}


/**
 * Restaura la sesión del usuario.
 *
 * @returns {Promise<void>}
 */
async function restoreUserSession() {
    if (store.user !== null) return;

    const data = await StorageHelper.getJson('user');

    if (data === null) return;

    await store.setUser(data.user, data.token);
}

/**
 * Configura el idioma por defecto de la aplicación.
 *
 * @returns {Promise<string>}
 */
async function setupDefaultLocale() {
    const locale = await getDefaultLocale();
    await store.setIdiomaUser(locale);
    return locale;
}

(async() => {
    setupAxiosEndpoint();

    // Primero se intenta restaurar la sesión del usuario. Es necesario que sea antes de obtener la farmacia
    // ya que el modo ecommerce depende de si el usuario está logueado o no.
    await restoreUserSession();

    // Ahora ya podemos crear la aplicación.
    const app = createApproxApp();

    await Promise.all([
        setupDefaultLocale(),
        setupApproxClientHeader(),
    ]);

    // En web se cargan los idiomas desde el objeto global y nos ahorramos una llamada a la API.
    if (window.idiomas !== undefined) {
        mergeLocaleMessages(window.idiomas);
    }

    app.mount('#app');
})();
