<template>
    <v-dialog v-model="abierto" close-on-content-click max-width="600">
        <v-card class="pa-2 pa-sm-4">
            <v-row>
                <v-col cols="12" sm="4">
                    <v-img :width="180" :height="180" cover :src="imagen" :alt="articulo.descripcion" :lazy-src="imagenDefault" class="rounded-lg mx-auto" />
                </v-col>
                <v-col cols="12" sm="8" class="pa-6">
                    <p class="font-weight-bold">
                        {{ $t('dialogAvisoCesta.seHaAnadido', [articulo.cantidad, articulo.descripcion]) }}
                    </p>
                    <p class="my-4">
                        {{ $t('dialogAvisoCesta.veAtuCesta') }}
                    </p>
                </v-col>
            </v-row>
            <v-card-actions class="d-flex flex-row align-end justify-end mt-4">
                <v-row dense :justify="'end'">
                    <v-col cols="12" sm="auto">
                        <v-btn prepend-icon="mdi-cart" variant="flat" to="/cesta" class="px-6" :block="$vuetify.display.smAndDown">
                            {{ $t('dialogAvisoCesta.verCesta') }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="auto" order-sm="first">
                        <v-btn color="primary" variant="tonal" prepend-icon="mdi-cart-plus" class="px-6" :block="$vuetify.display.smAndDown" @click="lastRoute">
                            {{ $t('dialogAvisoCesta.seguirComprando') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import imagenDefault from '@/../images/producto-default.jpg';

export default {
    props: {
        /**
         * El artículo que se ha añadido a la cesta con su cantidad.
         *
         * @type {{ cantidad: number, descripcion: string, imagen: string }}
         */
        articulo: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            abierto: false,
        };
    },
    computed: {
        /**
         * La imagen del artículo que se ha añadido a la cesta.
         *
         * @returns {string}
         */
        imagen() {
            return this.articulo?.imagen ?? imagenDefault;
        },

        /**
         * La imagen por defecto que se muestra cuando no hay imagen.
         *
         * @returns {string}
         */
        imagenDefault() {
            return imagenDefault;
        },

    },

    watch: {
        articulo() {
            if (this.articulo !== null){
                this.abierto = true;
            }
            else{
                this.abierto = false;
            }
        },
    },
    methods: {

        /**
         * La última ruta que ha visitado el usuario, solo si venía de una página en donde la acción primaria que hace el usuario es "Seguir comprando",
         * o la página de todos los productos, en caso contrario.
         *
         * @returns {string}
         */
        async lastRoute() {
            const lastPath = this.$router.options.history.state.back ?? '/productos';
            const route = this.$router.resolve({ path: lastPath });
            //Si tenemos abierta ficha de artículo, el botón seguir navegando hace que se vuelva a la vista de artículos de la que veníamos (si es que veníamos de alguna)
            if(this.$route.name === 'Articulo' && ['Categoria', 'Articulos', 'Promocion'].includes(route.name)){
                await this.$router.push({path: lastPath});
            }
            else{
                this.abierto = false;
            }
        },

    },
};
</script>
