<template>
    <v-form ref="form" @submit.prevent>
        <div class="d-flex align-center mb-5 text-h6">
            <v-icon icon="mdi-map-marker" class="mr-4" />
            {{ $t('compraEnvioFacturacion.datosDeEnvioYfacturacion') }}
        </div>
        <v-card elevation="1">
            <v-card-text class="pa-4">
                <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :model-value="nombre"
                            autocomplete="given-name"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.nombre')"
                            maxlength="20"
                            required
                            @update:model-value="$emit('update:nombre', $event)" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :model-value="apellidos"
                            autocomplete="family-name"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.apellidos')"
                            maxlength="20"
                            required
                            @update:model-value="$emit('update:apellidos', $event)" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <!-- FIXME: Este campo debería ser autocomplete="tel", el problema es que en DB estamos guardando
                        el teléfono como un INT en lugar de un string, por lo que solo entran 9 digitos y no 12 (aprox) como
                        debería ser. Se debería cambiar la columna en BD a string(255) y cambiar autocomplete a "tel" y remover maxlength
                        -->
                        <v-text-field
                            type="tel"
                            :model-value="telefono"
                            autocomplete="tel-local"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.telefono')"
                            maxlength="9"
                            required
                            @update:model-value="$emit('update:telefono', $event)" />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :model-value="direccion1"
                            autocomplete="address-line1"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.calleYnumero')"
                            maxlength="64"
                            required
                            @update:model-value="$emit('update:direccion1', $event)" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :model-value="direccion2"
                            autocomplete="address-line2"
                            :label="$t('compraEnvioFacturacion.otrosPisoPuertaEscalera')"
                            maxlength="20"
                            @update:model-value="$emit('update:direccion2', $event)" />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            :model-value="poblacion"
                            autocomplete="address-level2"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.poblacion')"
                            maxlength="20"
                            required
                            @update:model-value="$emit('update:poblacion', $event)" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-select
                            :model-value="provincia"
                            autocomplete="address-level1"
                            :items="provincias"
                            :rules="[rules.required]"
                            :label="$t('compraEnvioFacturacion.provincia')"
                            maxlength="20"
                            required
                            @update:model-value="$emit('update:provincia', $event)" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>

const provincias = {
    '02': 'Albacete',
    '03': 'Alicante/Alacant',
    '04': 'Almería',
    '01': 'Araba/Álava',
    '33': 'Asturias',
    '05': 'Ávila',
    '06': 'Badajoz',
    '07': 'Balears, Illes',
    '08': 'Barcelona',
    '48': 'Bizkaia',
    '09': 'Burgos',
    '10': 'Cáceres',
    '11': 'Cádiz',
    '39': 'Cantabria',
    '12': 'Castellón/Castelló',
    '13': 'Ciudad Real',
    '14': 'Córdoba',
    '15': 'Coruña, A',
    '16': 'Cuenca',
    '20': 'Gipuzkoa',
    '17': 'Girona',
    '18': 'Granada',
    '19': 'Guadalajara',
    '21': 'Huelva',
    '22': 'Huesca',
    '23': 'Jaén',
    '24': 'León',
    '25': 'Lleida',
    '27': 'Lugo',
    '28': 'Madrid',
    '29': 'Málaga',
    '30': 'Murcia',
    '31': 'Navarra',
    '32': 'Ourense',
    '34': 'Palencia',
    '35': 'Palmas, Las',
    '36': 'Pontevedra',
    '26': 'Rioja, La',
    '37': 'Salamanca',
    '38': 'Santa Cruz de Tenerife',
    '40': 'Segovia',
    '41': 'Sevilla',
    '42': 'Soria',
    '43': 'Tarragona',
    '44': 'Teruel',
    '45': 'Toledo',
    '46': 'Valencia/València',
    '47': 'Valladolid',
    '49': 'Zamora',
    '50': 'Zaragoza',
    '51': 'Ceuta',
    '52': 'Melilla',
};

export default {

    expose: ['validate'],

    props: {
        nombre: {
            type: String,
            default: '',
        },

        apellidos: {
            type: String,
            default: '',
        },

        telefono: {
            type: String,
            default: '',
        },
        direccion1: {
            type: String,
            default: '',
        },
        direccion2: {
            type: String,
            default: '',
        },
        poblacion: {
            type: String,
            default: '',
        },
        provincia: {
            type: String,
            default: '',
        },
        /**
         * Código postal utilizado para auto completar el campo de provincia.
         */
        codpostal: {
            type: String,
            default: '',
        },
    },

    emits: [
        'update:canContinue',
        'update:nombre',
        'update:apellidos',
        'update:telefono',
        'update:direccion1',
        'update:direccion2',
        'update:poblacion',
        'update:provincia',
    ],

    computed: {
        /**
         * Las reglas de validación.
         *
         * @return {object}
         */
        rules() {
            return {
                required: (value) => !!value || this.$t('compraEnvioFacturacion.obligatorio'),
            };
        },

        canContinue() {
            // Queremos que siempre se pueda presionar en continuar en esta pantalla aunque los datos esten sin completar.
            // Al pulsar "Continuar" se validan los datos y si no son correctos se muestra un mensaje de error.
            return true;
        },

        provincias() {
            return Object.entries(provincias).map(([_key, value]) => value);
        },
    },

    watch: {
        canContinue: {
            immediate: true,
            handler(value) { this.$emit('update:canContinue', value); },
        },

        codpostal: {
            immediate: true,
            handler(value) {
                if (value.length === 5) {
                    const provincia = value.substring(0, 2);
                    if (provincia in provincias) {
                        this.$emit('update:provincia', provincias[provincia]);
                    }
                }
            },
        },
    },

    methods: {
        async validate() {
            const { valid } = await this.$refs.form.validate();
            return valid;
        },
    },
};
</script>
