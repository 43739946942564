import { reactive, computed } from 'vue';
import axios from 'axios';
import moment from 'moment';
import i18n from './i18n';
import StorageHelper from './support/StorageHelper';
import { normalizeCestaHB, normalizeFarmacia, normalizeUser } from './support/Normalization';
import FarmaciaEcommerce from './enums/FarmaciaEcommerce';
import CategoriaService from './support/CategoriaService';
import { Capacitor } from '@capacitor/core';
import { setVuetifyThemeColors } from './vuetify';
import HeartbeatService from './support/HeartbeatService';
import PushNotificationsService from './support/PushNotificationsService';
import SeccionesHome from './enums/SeccionesHome';
import SeccionesHelper from './support/SeccionesHelper';
/**
|--------------------------------------------------------------------------------
| Store global de la app
|--------------------------------------------------------------------------------
|
| Este store contiene los datos globales de la app. Se puede acceder a los datos
| desde cualquier componente de la app. Se debe ser cuidadoso de solo guardar
| datos que sean realmente globales y que no pertenezcan a un componente en particular
| para no crear dependencias entre componentes.
|
| Las propiedades solo deben ser leídas y nunca deben ser modificadas directamente.
| Para modificar los datos, se deben usar los métodos del store. Esto facilita
| debuggear la app, seguir el flujo de datos y evitar errores. Además permite
| que sea muy fácil migrar a Pinia u otro store manager en el futuro si fuese necesario.
|
| Si en un futuro este store se vuelve muy grande, se puede dividir en varios
| stores especializados (por ejemplo, un store para el usuario, otro para la farmacia, etc).
|
*/

export const store = reactive({
    /**
     * El usuario logueado, o null en caso de un usuario guest.
     *
     * @type {object?}
     */
    user: null,

    /**
     * El token del usuario logueado, o null en caso de un usuario guest.
     *
     * @type {string?}
     */
    token: null,

    /**
     * La farmacia actualmente vinculada, o null si no hay ninguna farmacia vinculada.
     *
     * @type {object?}
     */
    farmacia: null,

    /**
     * La categoría raíz en el árbol de categorías, o null si no se cargaron las categorías aún.
     *
     * @type {import('./support/CategoriaService').Categoria?}
     */
    categoriaRoot: null,

    /**
     * El idioma del usuario o null si no se inicializó el idioma de la app aún.
     *
     * @type {string?}
     */
    idiomauser: null,

    /**
     * La información de la cesta del usuario. De momento solo se guarda el count del badge,
     * pero en un futuro sería ideal guardar un hash, o timestamp de la cesta desde el server
     * para detectar cambios de forma más precisa.
     *
     * @type {object}
     * @property {number} badge El número de productos en la cesta a mostrar en el badge.
     * @property {object[]} lineas Los productos de la cesta.
     */
    cesta: {
        badge: 0,
        lineas: [],
    },

    /**
     * La información de las notificaciones del usuario. De momento solo se guarda el count del badge,
     * pero en un futuro sería ideal guardar un hash, ids o timestamp de las notificaciones desde el server
     * para detectar cambios de forma más precisa.
     *
     * @type {object}
     * @property {number} badge El número de notificaciones a mostrar en el badge.
     */
    notificaciones: {
        badge: 0,

    },

    /**
     * La información de los mensajes del usuario. De momento solo se guarda el count del badge,
     * pero en un futuro sería ideal guardar un hash, ids o timestamp de los mensajes desde el server
     * para detectar cambios de forma más precisa.
     *
     * @type {object}
     * @property {number} badge El número de mensajes a mostrar en el badge.
     */
    mensajes: {
        badge: 0,
    },

    /**
     * Indica si la instancia tiene acceso a utilidad de programa de puntos
     *
     * @type {boolean}
     */

    tienePuntos: false,

    /**
     * Indica si el usuario está logueado o no.
     *
     * @type {boolean}
     */
    isLogged: computed(() => store.user !== null),

    /**
     * Indica si el ecommerce está habilitado o no.
     *
     * @type {boolean}
     */
    ecommerceHabilitado: computed(() => store.farmacia && store.farmacia.ecommerce === FarmaciaEcommerce.COMPRA_PRECIOS_VISIBLES),

    /**
     * Indica si hay acceso al catálogo de productos
     *
     * @type {boolean}
     */
    catalogoHabilitado: computed(() => store.farmacia && store.farmacia.ecommerce !== FarmaciaEcommerce.DESHABILITADO),

    /**
     * Indica si se deben mostrar precios de artículo
     *
     * @type {boolean}
     */
    mostrarPrecios: computed(() => store.farmacia.ecommerce === FarmaciaEcommerce.CATALOGO_PRECIOS_VISIBLES
        || store.farmacia.ecommerce === FarmaciaEcommerce.COMPRA_PRECIOS_VISIBLES),

    /**
     * Idiomas soportados
     */
    idiomasSoportados:[],

    /**
     * Orden de las secciones de Home
     * Los estilos se aplican en la vista de HomeView.vue
     * @type {Array}
     */
    seccionesHome: [
        { id: SeccionesHome.ESTADO, orden: 0},
        { id: SeccionesHome.IMAGEN, orden: 1},
        { id: SeccionesHome.CAROUSEL, orden: 2},
        { id: SeccionesHome.ACCESOS, orden: 3},
        { id: SeccionesHome.DESTACADOS, orden: 4},
    ],

    /**
     * Secciones que se guardaran en el KeepAlive
     */
    keepAliveSecctions: [
        'HomeView',
        'ArticulosView',
        'PromocionView',
        'PromocionesView',
        'CategoriaView',
        'ConsumosView',
        'ProxDispensacionesView',
        'EncargosView',
    ],
    /**
     * Vincula la farmacia con el subdominio indicado. Se establecen los headers
     * de axios para que todas las peticiones se hagan al subdominio de la farmacia
     * y se cargan los datos de la farmacia actualmente vinculada desde el
     * server y se almacenan en el store. También se descargan los datos de las
     * categorías de la farmacia.
     *
     * Si ya hay una farmacia vinculada, se desvincula antes de vincular la nueva.
     *
     * La vinculación intenta ser atómica. Esto significa que si algo falla (la farmacia no existe, error de
     * red, modo mantenimiento, etc.), se rechaza la promesa con una excepción y no queda ninguna
     * farmacia vinculada en el store.
     *
     * @param {string} subdominio
     * @returns {Promise<void>}
     */
    async vincularFarmacia(subdominio) {
        const { data } = await axios.get('/farmaciainicio', {
            headers: { 'X-Approx-Subdomain': subdominio },
        });

        await this.setFarmacia(data.farmacia);

        // Si todo salio bien, descargamos los datos de las categorias
        await this.fetchCategorias();

        // Por ultimo guardemos los datos de la farmacia y el subdominio en el localStorage
        // para mostrarlo en la lista de farmacias recientes
        await this.pushFarmaciaReciente();
    },

    /**
     * Establece la farmacia en el store y en el localStorage.
     *
     * @param {object} farmacia
     * @returns {Promise<void>}
     */
    async setFarmacia(farmacia) {

        farmacia = normalizeFarmacia(farmacia);

        axios.defaults.headers.common['X-Approx-Subdomain'] = farmacia.subdominio;

        await StorageHelper.setJson('farmacia', farmacia);

        // Si el usuario está logueado con otra farmacia, lo deslogueamos
        if (this.user && this.user.numserie !== farmacia.numserie) {
            await this.logoutUser();
        }

        setVuetifyThemeColors(farmacia.theme);

        this.farmacia = farmacia;
        document.title = this.farmacia.nombre;
    },

    /**
     * Establece el modo ecommerce si ha cambiado
     *
     * @param {object} farmacia
     * @returns {Promise<void>}
     */
    async setModoEcommerce(modoecommerce) {
        this.farmacia.ecommerce = modoecommerce;
        await StorageHelper.setJson('farmacia', this.farmacia);
    },

    /**
     * Desvincula la farmacia. Se eliminan los headers de axios para las peticiones,
     * se eliminan los datos de la farmacia y del user se desloguea si está logueado.
     *
     * @returns {Promise<void>}
     */
    async desvincularFarmacia() {
        if (!Capacitor.isNativePlatform()) return; // En web no se desvincula la farmacia NUNCA
        if (this.farmacia === null) return;

        await this.logoutUser();

        this.farmacia = null;
        axios.defaults.headers.common['X-Approx-Subdomain'] = null;
        await StorageHelper.remove('farmacia');
    },

    /**
     * Guarda la farmacia actualmente vinculada en la lista de farmacias recientes.
     * Si la farmacia ya está en la lista, se elimina y se vuelve a agregar al principio.
     * En web, este método es no-op.
     *
     * @returns {Promise<void>}
     */
    async pushFarmaciaReciente() {
        if (!Capacitor.isNativePlatform()) return;

        const recientes = await this.getFarmaciasRecientes();
        const index = recientes.findIndex(f => f.numserie === this.farmacia.numserie);
        if (index !== -1) {
            recientes.splice(index, 1); // Remueve la farmacia de la lista porque la vamos a agregar al principio
        }

        await StorageHelper.setJson('farmaciasRecientes', [
            {
                nombre: this.farmacia.nombre,
                numserie: this.farmacia.numserie,
                subdominio: this.farmacia.subdominio,
                direccion: this.farmacia.direccion,
                timestamp: (new Date()).toISOString(),
                token: this.token,
                user: this.user,
            },
            ...recientes,
        ]);
    },

    /**
     * Obtiene la lista de farmacias recientes ordenadas por fecha de acceso.
     * En web, este método siempre devuelve una lista vacía.
     *
     * @returns {Promise<void>}
     */
    async getFarmaciasRecientes() {
        if (!Capacitor.isNativePlatform()) return [];

        return (await StorageHelper.getJson('farmaciasRecientes', []))?.sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
        }) ?? [];
    },

    /**
     * Establece el idioma del usuario.
     *
     * @param {string} idiomauser Código ISO del idioma (ej: 'es', 'en', 'fr', etc.)
     * @returns {Promise<void>}
     */
    async setIdiomaUser(idiomauser) {
        this.idiomauser = idiomauser;
        axios.defaults.headers.common['Accept-Language'] = idiomauser;
        moment.locale(idiomauser);
        i18n.global.locale.value = idiomauser;
        await StorageHelper.setString('idiomauser', idiomauser);
    },

    /**
     * Establece el usuario y el token en el store y en el localStorage.
     * Si no se especifica el token, se mantiene el que ya estaba.
     *
     * @param {object} user
     * @param {string|null} token
     * @returns {Promise<void>}
     */
    async setUser(user, token) {
        user = normalizeUser(user);
        this.user = user;
        this.token = token ?? this.token;
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        await StorageHelper.setJson('user', { user, token });
    },

    /**
     * Elimina el usuario y el token del store y del localStorage.
     * Si el usuario está logueado, se hace logout en el server.
     *
     * @returns {Promise<void>}
     */
    async logoutUser() {
        if (this.user === null) return;
        if (this.farmacia !== null) {
            await axios.post('/logout').catch((error) => {
                // Si el logout falla, no hacemos nada, ya que significa que el token ya no es válido
                console.error(error);
            });
        }
        this.user = null;
        this.token = null;
        axios.defaults.headers.common.Authorization = null;
        await StorageHelper.remove('user');

        // Enviamos de nuevo el token de push para que se actualice en el servidor
        // que el usuario ya no está logueado.
        // No esperamos ni capturamos errores ya que no es crítico.
        PushNotificationsService.instance.enviarTokenPush().catch(console.error);

        // Forzamos un heartbeat para refrescar los datos del usuario.
        // Tampoco hace falta esperar a que termine ni chequear el resultado.
        HeartbeatService.instance.heartbeat().catch(console.error);
    },

    /**
     * Loguea al usuario con las credenciales especificadas en el servidor y
     * establece el usuario y el token en el store y en el localStorage.
     *
     * @param {string} email
     * @param {string} password
     * @param {string?} recaptcha TODO: Es necesario? No se usa en el server
     * @returns {Promise<void>}
     */
    async loginUser(email, password, recaptcha) {
        const { data } = await axios.post('/login', {
            email: email,
            password: password,
            idfarmacia: this.farmacia.numserie,
            recaptcha: recaptcha,
        });

        await store.setUser(data.user, data.token);

        // Necesitamos reenviar el token push porque el servidor guarda los datos del usuario
        // en la base de datos y necesita actualizar el token push.
        // No esperamos ni chequeamos el resultado porque no es crítico.
        PushNotificationsService.instance.enviarTokenPush().catch(console.error);

        // Forzamos un heartbeat para refrescar los datos del usuario.
        // Tampoco hace falta esperar a que termine ni chequear el resultado.
        HeartbeatService.instance.heartbeat().catch(console.error);
    },

    /**
     * Actualiza las opciones de RGPD del usuario en el store y en el server.
     * El usuario debe estar logueado para poder llamar a este método.
     * Los datos pasados se combinan con los que ya existen en el store.
     *
     * @param {{notificacionescomerciales: bool?, facturaporemail: bool?, name: string?, apellidos: string?}} opciones
     * @returns {Promise<void>}
     */
    async updateOpcionesRgpd(opciones) {
        if (this.user === null) {
            throw new Error('No hay usuario logueado');
        }

        await axios.post('/opcionesrgpd', opciones);

        this.user = {
            ...this.user,
            ...opciones,
        };
        await StorageHelper.setJson('user', { user: this.user, token: this.token });
        
    },

    /**
     * Actualiza los datos del usuario logueado en el store con lo que trae del server
     * @returns Informacion del usuario logueado actualizada
     */
    async getDatosUsuario(){
        if (this.user === null) {
            throw new Error('No hay usuario logueado');
        }

        const { data } = await axios.get('/usuario');
        this.user = {
            ...this.user,
            ...data,
        };

        this.setUser(this.user, this.token);
        
        await StorageHelper.setJson('user', { user: this.user, token: this.token });

        return this.user;
    },

    /**
     * Carga las categorías de la farmacia y las almacena en el store.
     *
     * @returns {Promise<void>}
     */
    async fetchCategorias() {
        if (this.farmacia === null) {
            throw new Error('No hay farmacia vinculada');
        }
        const { data: categorias } = await axios.get('/categorias');
        this.setCategorias(categorias);
    },

    /**
     * Chequea y establece el valor que determina si el usuario actual tiene puntos en el programa de puntos de la farmacia
     *
     * @returns {Promise<void>}
     */
    async checkPuntos() {
        const { data } = await axios.get('/puntos');
        let puntos = data.puntos ? parseFloat(data.puntos) : false;
        let euros = data.euros ? parseFloat(data.euros) : false;
        let valor = puntos || euros;
        store.setTienePuntos(valor);
    },

    /**
     * Establece las categorías en el store.
     *
     * @param {object[]} categorias
     */
    setCategorias(categorias) {
        this.categoriaRoot = CategoriaService.fromList(categorias);
    },

    /**
     * Establece los datos de la cesta.
     * @param {number} data
     */
    async setCestaData(data) {
        this.cesta.badge = data.badge;
        this.cesta.lineas = data.lineas;
        await StorageHelper.setJson('cesta', normalizeCestaHB(this.cesta));
    },
    /**
     * Establece el badge de las notificaciones.
     * @param {number} badge
     */
    setNotificacionesBadge(badge) {
        this.notificaciones.badge = badge;
    },

    /**
     * Establece el badge de los mensajes.
     *
     * @param {number} badge
     */
    setMensajesBadge(badge) {
        this.mensajes.badge = badge;
    },

    /**
     * Establece el título del documento HTML.
     *
     * @param {string?} title
     */
    setDocumentTitle(title) {
        if (store.farmacia === null) return;
        window.document.title = title === null
            ? store.farmacia.nombre
            : `${title} | ${store.farmacia.nombre}`;
    },

    /**
     * Establece el valor de tienePuntos
     */
    setTienePuntos(valor) {
        this.tienePuntos = valor;
    },

    /**
     * Establece los idiomas habilitados
     */
    setIdiomasSoportados(idiomas) {
        this.idiomasSoportados = idiomas;
    },

    /**
     * Establece las secciones de la Home
     * @param {Object} secciones Conjunto de Secciones y su orden para la Home
     */
    setSeccionesHome(secciones) {
        var seccionesHelper = new SeccionesHelper();
        secciones = secciones.trim();
        secciones = seccionesHelper.getSecciones(secciones);
        //Si hay algun id que no se encuentra en las secciones, se elimina
        this.seccionesHome = this.seccionesHome.filter((seccion) => secciones.some((s) => s.id === seccion.id));
        this.seccionesHome.forEach((seccion) => {
            //Se busca el id correspondiente de la seeccion
            const seccionData = secciones.find((s) => s.id === seccion.id);
            if (seccionData) {
                //Se establece el orden de la seccion
                seccion.orden = seccionData.orden;
            }
        });
    },
    /**
     * Actualiza el KeepAlive de las Secctiones
     */
    ActualizaKeepAliveSecctions() {

        this.keepAliveSecctions.filter(() => {
            return !this.keepAliveSecctions.includes('CategoriaView');
        });

        this.keepAliveSecctions.push('CategoriaView');

    },
});

