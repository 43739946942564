<template>
    <v-container v-if="farmacia" fluid>
        <v-sheet
            v-for="item in seccionesHome"
            :key="item.orden"
            :rounded="item.rounded"
            :class="item.class"
            :style="item.style"
            color="background">
            <component :is="item.componente" v-if="! item.margin" />
            <v-container
                v-else>
                <v-sheet
                    :rounded="item.rounded"
                    :class="item.class"
                    :style="item.style"
                    color="background">
                    <component :is="item.componente" />
                </v-sheet>
            </v-container>
        </v-sheet>
    </v-container>
    <home-pie-social />
</template>

<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import HomeEstadoAtencion from './HomeEstadoAtencion.vue';
import HomeCarousel from './HomeCarousel.vue';
import HomeArticulosDestacados from './HomeArticulosDestacados.vue';
import HomeAccesosCards from './HomeAccesosCards.vue';
import HomePieSocial from './HomePieSocial.vue';
import SeccionesHome from '@/enums/SeccionesHome.js';
import SeccionesHelper from '@/support/SeccionesHelper.js';
import HomeImagen from './HomeImagen.vue';
export default {
    name: 'HomeView', // Usado por <keep-alive>

    components: {
        HomeEstadoAtencion,
        HomeCarousel,
        HomeArticulosDestacados,
        HomeAccesosCards,
        HomePieSocial,
        HomeImagen,
    },

    data: () => ({
        mostrarComparteCard: false,
    }),

    computed: {
        farmacia() {
            return store.farmacia;
        },

        isLogged() {
            return store.isLogged;
        },

        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },

        catalogoHabilitado() {
            return store.catalogoHabilitado;
        },
        esApp() {
            return Capacitor.isNativePlatform();
        },

        platform() {
            return Capacitor.getPlatform();
        },
        tienePuntos(){
            return store.tienePuntos;
        },
        /**
         * Secciones de la vista Home
         * Devuelve un array con las secciones a mostrar en la vista Home
         * Ordenados por el valor de la propiedad orden.
         *      -Si la propiedad margin es true, se muestra el componente envuelto en un v-container.
         *      -Si Orden es menor o igual a 0, no se muestra la sección.
         * @returns {Array}
         */
        seccionesHome(){
            var seccionesHelper = new SeccionesHelper();

            /**
             * Secciones de la vista Home
             * Aqui se definen las secciones que se mostraran en la vista Home
             * Se definen las propiedades:
             *  -class: Clases css que se aplicaran al v-sheet
             *  -rounded: Valor de la propiedad rounded del v-sheet
             *  -style: Estilos css que se aplicaran al v-sheet
             *  -componente: Componente que se mostrara en la sección
             *  -margin: Si es true, se muestra el componente envuelto en un v-container
             * @type {Array}
             */
            var secciones =[
                {
                    id: SeccionesHome.ESTADO,
                    orden: 0,
                    class: this.$vuetify.display.mdAndUp ? '' : 'mx-n4',
                    rounded: this.$vuetify.display.mdAndUp ? 'lg' : 0,
                    style: 'overflow: hidden',
                    componente: HomeEstadoAtencion,
                    margin: this.$vuetify.display.mdAndUp,
                },
                {
                    id: SeccionesHome.IMAGEN,
                    orden: 1,
                    class: this.$vuetify.display.mdAndUp ? '' :'mx-n4',
                    rounded: this.$vuetify.display.mdAndUp ? 'lg' : 0,
                    style: 'overflow: hidden',
                    componente: HomeImagen,
                    margin: this.$vuetify.display.mdAndUp,
                },
                {
                    id: SeccionesHome.CAROUSEL,
                    orden: 2,
                    class: this.$vuetify.display.mdAndUp ? '' :'mx-n4',
                    rounded: this.$vuetify.display.mdAndUp ? 'lg' : 0,
                    style: 'overflow: hidden',
                    componente: HomeCarousel,
                    margin: this.$vuetify.display.mdAndUp,
                },
                {
                    id: SeccionesHome.ACCESOS,
                    orden: 3,
                    class: '',
                    rounded: 0,
                    style: '',
                    componente: HomeAccesosCards,
                    margin: true,
                },
                {
                    id: SeccionesHome.DESTACADOS,
                    orden: 4,
                    class: 'mx-n4',
                    rounded: 0,
                    style: '',
                    componente: HomeArticulosDestacados,
                    margin: false,
                },
            ];
            var seccionesCopy = secciones;
            try{
                secciones = seccionesHelper.procesaSecciones(secciones, this.$vuetify.display.mdAndUp);
            }catch(error)
            {
                secciones = seccionesCopy;
            }


            if(!this.catalogoHabilitado){
                secciones = secciones.filter(seccion => seccion.id !== SeccionesHome.DESTACADOS);
            }
            return secciones;
        },
    },

};
</script>
