<template>
    <v-row>
        <v-slide-y-transition
            appear>
            <!-- Precio Real GRANDE -->
            <v-col
                v-if="showBig"
                class="d-flex font-weight-bold align-center glegoo-bold"
                :style="styleBigNormal"
                :class="preciotachado ? 'text-error' : 'text-primary'"
                :cols="preciotachado ? '4': '12'">
                {{ precio }}
            </v-col>

            <!-- Precio Real NORMAL -->
            <v-col
                v-else
                class="d-flex font-weight-bold glegoo-regular justify-center pb-0"
                :style="styleNormalNormal"
                :class="preciotachado ? 'text-error' : 'text-primary'"
                cols="12">
                {{ precio }}
            </v-col>
        </v-slide-y-transition>
        <v-slide-x-transition appear duration="500" group>
            <!-- Precio tachado GRANDE -->
            <v-col
                v-if="preciotachado && showBig "
                key="0"
                cols="8"
                :style="styleBigTachado"
                class="text-end text-grey-darken-1">
                <span v-if="muestraPrecioTachado()">
                    {{ $t('articuloView.pvpAnterior') }}
                </span>
                <br>
                <span v-if="muestraPrecioTachado()" class="text-decoration-line-through glegoo-bold">
                    {{ preciotachado }}
                </span>
            </v-col>
            <!-- Precio tachado NORMAL -->
            <v-col
                v-else-if="!showBig"
                key="1"
                cols="12"
                :style="styleNormalTachado"
                class="text-grey-darken-1 pt-1 pb-1">
                <v-row class="d-flex" :style="(preciotachado) ? {}: {color:' transparent'}">
                    <v-col cols="7" :style="(muestraPrecioTachado()) ? {}: {color:' transparent'}">
                        {{ $t('articuloView.pvpAnterior') }}
                    </v-col>
                    <v-col cols="5" class="text-decoration-line-through glegoo-regular" :style="(muestraPrecioTachado()) ? {}: {color:' transparent'}">
                        {{ preciotachado }}
                    </v-col>
                </v-row>
            </v-col>
        </v-slide-x-transition>
    </v-row>
</template>

<script>
export default {
    name: 'ThePrecio',
    props: {
        precio: {
            type: String,
            required: true,
        },
        preciotachado: {
            type: String,
            default: null,
        },
        justify: {
            type: String,
            default: 'center',
        },
        showBig: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['porcentaje'],
    computed: {
        precioDisplay() {
            return this.preciotachado
                ? this.preciotachado
                : this.precio;
        },
        //Estilos del precio en ArticuView
        styleBigNormal() {
            return this.$vuetify.display.mdAndUp
                ? {
                    fontSize: '2.8rem',
                }
                : {
                    fontSize: '3rem',
                };
        },
        styleBigTachado() {
            return this.$vuetify.display.mdAndUp
                ? {
                    fontSize: '1.8rem',
                }
                : {
                    fontSize: '1.5rem',
                };
        },
        //Estilos del precio en Listas de Articulos
        styleNormalNormal() {
            switch(this.$vuetify.display.name){
            case 'md':
            case 'sm':
                return {
                    fontSize: '1.7rem',  //R
                };

            case 'xs':
                return {
                    fontSize: '1.6rem',  //R
                };

            default:
                return {
                    fontSize: '1.9rem',  //R
                };
            }
        },

        styleNormalTachado() {
            switch(this.$vuetify.display.name){
            case 'md':
            case 'sm':
                return {
                    fontSize: '1rem',
                };
            case 'xs':
                return {
                    fontSize: '0.8rem',
                };
            default:
                return {
                    fontSize: '1rem',
                };
            }
        },
    },
    created() {
        this.calcularPorcentaje();
    },
    methods: {
        calcularPorcentaje() {
            if (this.preciotachado) {
                //Se quita el Simbolo de € para hacer las operaciones
                // y se cambia la coma por un punto para que se pueda hacer la operacion
                var precio = (this.precio.substring(0, this.precio.length - 2).replace(',', '.'));
                var precioTachado = (this.preciotachado.substring(0, this.preciotachado.length - 2).replace(',', '.'));

                var porcentaje = ((precioTachado - precio) * 100) / precioTachado;
                this.$emit('porcentaje', porcentaje);
            }
        },
        muestraPrecioTachado(){
            if(!this.preciotachado)
                return false;
            var precio = Number(this.precio.substring(0, this.precio.length - 2).replace(',', '.'));
            var preciotachado = Number(this.preciotachado.substring(0, this.preciotachado.length - 2).replace(',', '.'));
            if(preciotachado > precio)
            {
                return true;
            }
            return false;
        },
    },
};
</script>


<style scoped>
.glegoo-regular {
  font-family: "Glegoo", serif;
  font-weight: 400;
  font-style: normal;
  white-space: nowrap;
}

.glegoo-bold {
  font-family: "Glegoo", serif;
  font-weight: 700;
  font-style: normal;
  white-space: nowrap;
}
</style>
