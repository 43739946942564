<template>
    <div>
        <v-img
            :src="src"
            height="40vh"
            class="d-flex align-end text-end"
            @click="dialog = true">
            <template #default>
                <v-icon size="x-large" color="primary">
                    mdi-magnify-plus-outline
                </v-icon>
            </template>
        </v-img>

        <v-dialog v-model="dialog" max-width="500px">
            <!-- V-card es de donde se referencia de base para la imagen, cuando  -->
            <v-card
                ref="image"
                class="align-center"
                style="overflow: hidden;"
                @mousemove="adaptImageHover($event)"
                @touchmove.passive="adaptImageTouch($event)"
                @touchstart.passive="isHovered= true"
                @touchend="isHovered= false"
                @mouseenter="isHovered = true"
                @mouseleave="isHovered = false"
                @click="dialog = false">
                <v-img
                    id="innerImage"
                    :src="src"
                    :style="`transform: scale(${zoom}) translateX(${-zoomX}%) translateY(${-zoomY}%)`"
                    height="60vh"
                    width="500px">
                    <v-chip v-if="zoom === 1 && $vuetify.display.smAndDown" text="Click para ampliar" class="ma-2" prepend-icon="mdi-magnify-plus-outline" />
                </v-img>
                <slot />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        maxZoom:{
            type: Number,
            default: 2,
        },
    },
    data() {
        return {
            dialog: false,
            isHovered: false,
            isTouched: false,
            zoom: 1,
            zoomX: 0,
            zoomY: 0,
        };
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.zoomLevel = 1; // Reseteamos el zoom al cerrar el dialog
            }
        },
        isHovered() {
            if(this.isHovered){
                this.zoom = this.maxZoom; // Aplicamos el zoom que le hemos pasado
            }
            else{
                // Si no estamos encima de la imagen, reseteamos el zoom
                this.zoom = 1;
                this.zoomX= 0;
                this.zoomY= 0;
            }
        },
    },
    methods: {
        adaptImageHover(event) {
            if(this.isHovered){
                let image = this.$refs.image;

                this.zoom = this.maxZoom;
                //El 250 es para centrar el zoom en el centro de la imagen, ya que el offsetX e Y
                //nos dan la posición del ratón en relación a la ventana, no a la imagen
                this.zoomX = (((event.offsetX-250) / image.$el.offsetWidth) * (this.zoom - 1))*50;
                this.zoomY = (((event.offsetY-250) / image.$el.offsetHeight) * (this.zoom - 1))*50;
            }
        },
        adaptImageTouch(event) {
            if(this.isHovered){
                let image = this.$refs.image;
                //El 250 es para centrar el zoom en el centro de la imagen, ya que el offsetX e Y
                //nos dan la posición del ratón en relación a la ventana, no a la imagen
                this.zoom = this.maxZoom;
                this.zoomX = (((event.touches[0].clientX-250) / image.$el.offsetWidth) * (this.zoom - 1))*50;
                this.zoomY = (((event.touches[0].clientY-250) / image.$el.offsetHeight) * (this.zoom - 1))*50;
            }
        },
    },
};
</script>
<style scoped>
#innerImage{
    transition: all 0.2s ease-out;
    cursor: pointer;
}

</style>
