<template>
    <!-- eslint-disable vue/singleline-html-element-content-newline - Necesitamos preservar el formato del texto correctamente en los links -->
    <v-container>
        <div>
            <v-btn prepend-icon="mdi-arrow-left" color="primary" @click="volver">
                {{ $t('volver') }}
            </v-btn>
        </div>

        <v-alert
            v-if="!$i18n.locale.startsWith('es')"
            type="info"
            class="mt-4">
            {{ $t('avisoIdiomaSoloEspañol') }}
        </v-alert>

        <div class="pb-12 pt-6 texto-legal">
            <h1>CONDICIONES GENERALES DE CONTRATACIÓN</h1>
            <h2>Introducción:</h2>
            <p>
                Este documento contractual regirá las Condiciones Generales de contratación
                de productos (en adelante, «Condiciones») a través de la Web App
                {{ hostname }}, propiedad de
                {{ farmacia.rgpddenominacionsocial }} bajo la marca comercial de {{ farmacia.rgpdnombrecomercial }},
                en adelante, PRESTADOR, cuyos datos de
                contacto figuran también en el Aviso Legal de esta Web App.
            </p>
            <p>
                Estas Condiciones permanecerán publicadas en el apartado
                <router-link to="/configuracion">Configuración</router-link>
                de esta Web App a disposición del USUARIO para reproducirlas y guardarlas
                como confirmación del contrato, pudiendo ser modificadas en cualquier
                momento por {{ farmacia.rgpddenominacionsocial }} Es responsabilidad del
                USUARIO leerlas periódicamente, ya que resultarán aplicables aquellas que
                se encuentren vigentes en el momento de realización de pedidos. {{ farmacia.rgpddenominacionsocial }}
                archivará el documento electrónico donde se
                formalice la compra y lo tendrá a disposición del USUARIO por si éste se lo
                solicita.
            </p>
            <p>
                Los contratos no estarán sujetos a formalidad alguna con excepción de los
                supuestos expresamente señalados en los Códigos Civil y de Comercio y en
                esta o en otras leyes especiales.
            </p>
            <p>
                La aceptación de este documento conlleva que el USUARIO:
            </p>
            <ul>
                <li>Ha leído, entiende y comprende lo aquí expuesto.</li>
                <li>Es una persona con capacidad suficiente para contratar.</li>
                <li>Asume todas las obligaciones aquí dispuestas.</li>
            </ul>
            <p>
                Estas condiciones tendrán un período de validez indefinido y serán
                aplicables a todas las contrataciones realizadas a través de la Web App del
                PRESTADOR.
            </p>
            <p>
                El PRESTADOR informa de que el comercio es responsable y conoce la
                legislación vigente de los países a los que envía los productos, y se
                reserva el derecho de modificar unilateralmente las condiciones, sin que
                ello pueda afectar a los bienes o promociones que fueron adquiridos
                previamente a la modificación.
            </p>
            <h3>
                Identidad de las partes contratantes
            </h3>
            <p>
                Por un lado, el PRESTADOR de los productos contratados por el USUARIO es
                {{ farmacia.rgpddenominacionsocial }}, con domicilio social en {{ farmacia.rgpddomiciliosocial }}, NIF
                {{ farmacia.rgpdnif }} y con teléfono de
                atención al cliente {{ farmacia.telefono }}.
            </p>
            <p>
                Y de otro, el USUARIO, registrado en la Web App mediante un nombre de
                usuario y contraseña, sobre los que tiene responsabilidad plena de uso y
                custodia, y es responsable de la veracidad de los datos personales
                facilitados al PRESTADOR.
            </p>
            <h3>Objeto del contrato</h3>
            <p>
                El presente contrato tiene por objeto regular la relación contractual de
                compraventa nacida entre el PRESTADOR y el USUARIO en el momento en que
                este acepta durante el proceso de contratación en línea la casilla
                correspondiente.
            </p>
            <p>
                La relación contractual de compraventa conlleva la entrega, a cambio de un
                precio determinado y públicamente expuesto a través de la Web App, de un
                producto concreto.
            </p>
            <h3>Rectificación de los datos</h3>
            <p>
                Cuando el USUARIO identifique errores en los datos publicados en la Web App
                o en los documentos generados por la relación contractual, podrá
                notificarlo al correo electrónico {{ farmacia.rgpdemail }} para que
                {{ farmacia.rgpddenominacionsocial }} los corrija a la mayor brevedad posible.
            </p>
            <p>
                El USUARIO podrá mantener actualizados sus datos accediendo a su cuenta de
                usuario.
            </p>
            <h3>Procedimiento de contratación</h3>
            <p>
                El USUARIO, para poder acceder a los productos o servicios que ofrece el
                PRESTADOR, deberá ser mayor de edad o menor de edad emancipado y darse de
                alta a través de la Web App mediante la creación de una cuenta de usuario.
                Por ello, el USUARIO deberá proporcionar de manera libre y voluntaria los
                datos personales que se le requerirán, los cuales se tratarán de
                conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril
                de 2016 (GDPR), relativo a la protección de las personas físicas en lo que
                respecta al tratamiento de datos personales y a la libre circulación de
                estos datos y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), relativa
                a la protección de datos de carácter personal y detallada en el Aviso legal
                y en la Política de privacidad de esta Web App.
            </p>
            <p>
                El USUARIO seleccionará un nombre de usuario y una contraseña,
                comprometiéndose a hacer un uso diligente de los mismos y a no ponerlos a
                disposición de terceros, así como a comunicar al PRESTADOR la pérdida o
                robo de los mismos o el posible acceso por un tercero no autorizado, de
                manera que este proceda al bloqueo inmediato.
            </p>
            <p>
                Una vez ha sido creada la cuenta de usuario, se informa de que conforme
                a lo que exige el artículo 27 de la Ley 34/2002, de Servicios de la
                Sociedad de la Información y del Comercio Electrónico (LSSICE), el
                procedimiento de contratación seguirá los siguientes pasos:
            </p>
            <ol class="ml-10 mb-4">
                <li>Cláusulas generales de contratación.</li>
                <li>Envío y entrega de pedidos.</li>
                <li>Derecho de desistimiento.</li>
                <li>Reclamaciones y resolución de litigios en línea.</li>
                <li>Fuerza mayor.</li>
                <li>Competencia.</li>
                <li>Generalidades de la oferta.</li>
                <li>Precio y plazo de validez de la oferta.</li>
                <li>Gastos de transporte.</li>
                <li>Forma de pago.</li>
                <li>Proceso de compra.</li>
                <li>Disociación y suspensión o rescisión del contrato.</li>
                <li>Garantías y devoluciones.</li>
                <li>Ley aplicable y jurisdicción.</li>
            </ol>
            <h3>1. CLÁUSULAS GENERALES DE CONTRATACIÓN</h3>
            <p>
                Salvo estipulación particular por escrito, la realización de un pedido al
                PRESTADOR supondrá la aceptación por parte del USUARIO de estas condiciones
                legales. Ninguna estipulación hecha por el USUARIO podrá diferir de las del
                PRESTADOR si no ha sido expresamente aceptada por adelantado y por escrito
                por el PRESTADOR.
            </p>
            <h3>2. ENVÍO Y ENTREGA DE PEDIDOS</h3>
            <p>
                El PRESTADOR no enviará ningún pedido hasta que haya comprobado que se ha
                realizado el pago, salvo que se establezca el pago contra reembolso.
            </p>
            <p>
                Los envíos de mercancías se harán habitualmente mediante mensajería exprés, según el destino designado
                libremente por el USUARIO.
            </p>
            <p>
                El envío se efectuará una vez se haya confirmado la disponibilidad de la
                mercancía y comprobado el pago del pedido, salvo pago contra reembolso.
            </p>
            <p>
                El plazo de entrega estará comprendido entre 1 y 5 días laborables, según la población de destino y la
                forma de pago elegida. La fecha
                prevista de envío y entrega se suministrarán previamente a la confirmación
                del pedido.
            </p>
            <h3>
                Falta de ejecución del contrato a distancia
            </h3>
            <p>
                En caso de no poder ejecutar el contrato porque el producto o servicio
                contratado no esté disponible en el plazo previsto, se informará al USUARIO
                de la falta de disponibilidad y de que quedará legitimado para cancelar el
                pedido y recibir la devolución del importe total pagado sin ningún coste, y
                sin que por ello se derive ninguna responsabilidad por daños y perjuicios
                imputable al PRESTADOR.
            </p>
            <p>
                En caso de retraso injustificado por parte del PRESTADOR respecto a la
                devolución del importe total, el USUARIO podrá reclamar que se le pague el
                doble del importe adeudado, sin perjuicio a su derecho de ser indemnizado
                por los daños y perjuicios sufridos en lo que excedan de dicha cantidad.
            </p>
            <p>
                El PRESTADOR no asumirá ninguna responsabilidad cuando la entrega del
                producto o servicio no llegue a realizarse, por ser los datos facilitados
                por el USUARIO, falsos, inexactos o incompletos.
            </p>
            <p>
                La entrega se considerará realizada en el momento en que el transportista
                haya puesto los productos a disposición del USUARIO y este, o el delegado
                de este, haya firmado el documento de recepción de la entrega.
            </p>
            <p>
                El PRESTADOR responderá ante el USUARIO de cualquier falta de conformidad
                que exista en el momento de la entrega del pedido, pudiendo el USUARIO,
                mediante una simple declaración, exigirle la subsanación de dicha falta de
                conformidad, la reducción del precio o la resolución del contrato. En
                cualquiera de estos supuestos el USUARIO podrá exigir, además, la
                indemnización de daños y perjuicios, si procede.
            </p>
            <p>
                El USUARIO tendrá derecho a suspender el pago de cualquier parte pendiente
                del precio del producto adquirido hasta que el PRESTADOR cumpla con las
                obligaciones establecidas en este contrato.
            </p>
            <p>
                Corresponde al USUARIO verificar los productos a la recepción y exponer
                todas las salvedades y reclamaciones que puedan estar justificadas en el
                documento de recepción de la entrega.
            </p>
            <h3>3. DERECHO DE DESISTIMIENTO</h3>
            <p>
                En el menú<router-link to="/configuracion">Configuración</router-link>, tendrá disponible el documento Formulario de
                desistimiento que debe
                remitir cumplimentado, fechado y firmado a la Farmacia según las
                indicaciones proporcionadas en el mismo.
            </p>
            <p>
                El USUARIO dispone de un plazo de catorce días naturales, contados a partir
                de la fecha de recepción del producto o desde la celebración del contrato
                de compraventa si fuera una prestación de un servicio, para ejercer el
                derecho de desistimiento, regulado en el
                <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&amp;amp;b=156&amp;amp;tn=1&amp;amp;p=20140328&amp;amp;a102">
                    artículo 102 del Real Decreto Legislativo 1/2007
                </a>
                , de 16 de noviembre, por el que se aprueba el texto refundido de la Ley
                General para la Defensa de los Consumidores y Usuarios y otras leyes
                complementarias, en adelante RDL 1/2007. Si el PRESTADOR no cumple con el
                deber de información y documentación sobre el derecho de desistimiento, el
                plazo para su ejercicio finalizará doce meses después de la fecha de
                expiración del período de desistimiento inicial, conforme al
                <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&amp;amp;p=20140328&amp;amp;tn=1&amp;amp;a105">
                    artículo 105 del RDL 1/2007
                </a>
                .
            </p>
            <p>
                El derecho de desistimiento no será aplicable a los contratos referidos y
                enumerados en el
                <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&amp;amp;p=20140328&amp;amp;tn=1&amp;amp;a103">
                    artículo 103 del RDL 1/2007
                </a>
                , y que se relacionan
                <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&amp;amp;p=20140328&amp;amp;tn=1&amp;amp;a103">
                    aquí
                </a>
            </p>
            <p>
                Toda devolución deberá comunicarse al PRESTADOR, solicitando un número de
                devolución mediante el formulario de contacto de la Web App, o por correo
                electrónico a {{ farmacia.rgpdemail }}, indicando el número de factura o
                pedido correspondiente. En caso de que la devolución no se realizara con el
                embalaje original de entrega, el PRESTADOR podrá cobrar el coste del mismo
                al USUARIO informando previamente de ello a través del mismo canal de
                comunicación utilizado.
            </p>
            <p>
                Una vez el USUARIO haya recibido el número de devolución, hará llegar el
                producto al PRESTADOR, indicando este número en la carta de envío, con los
                gastos de transporte a su cargo, en el domicilio de {{ farmacia.rgpddenominacionsocial }},
                {{ farmacia.rgpddomiciliosocial }}
            </p>
            <h3>4. RECLAMACIONES Y RESOLUCIÓN DE LITIGIOS EN LÍNEA</h3>
            <p>
                Cualquier reclamación que el USUARIO considere oportuna será atendida a la
                mayor brevedad posible, pudiéndose realizar en las siguientes direcciones
                de contacto:
            </p>
            <p>
                Postal: {{ farmacia.rgpddenominacionsocial }}, {{ farmacia.rgpddomiciliosocial }} Teléfono:
                {{ farmacia.telefono }}
            </p>
            <p>
                E-mail: {{ farmacia.rgpdemail }}
            </p>
            <h2>Resolución de litigios en línea (<em>Online Dispute Resolution</em>)</h2>
            <p>
                Conforme al Art. 14.1 del Reglamento (UE) 524/2013, la Comisión Europea
                facilita una plataforma de acceso gratuito para la resolución de conflictos
                online entre el USUARIO y el PRESTADOR, sin necesidad de recurrir a los
                tribunales de justicia, mediante la intervención de un tercero, llamado
                Organismo de resolución de litigios, que actúa de intermediario entre
                ambos. Este organismo es neutral y dialogará con ambas partes para lograr
                un acuerdo, pudiendo finalmente sugerir y/o imponer una solución al
                conflicto.
            </p>
            <p>Enlace a la plataforma ODR: <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a></p>
            <h3>5. FUERZA MAYOR</h3>
            <p>
                Las partes no incurrirán en responsabilidad ante cualquier falta debida a
                causa mayor. El cumplimiento de la obligación se demorará hasta el cese del
                caso de fuerza mayor.
            </p>
            <h3>6. COMPETENCIA</h3>
            <p>
                El USUARIO no podrá ceder, transferir o transmitir los derechos,
                responsabilidades y obligaciones contratados en la venta.
            </p>
            <p>
                Si alguna estipulación de estas condiciones fuera considerada nula o de
                imposible cumplimiento, la validez, legalidad y cumplimiento del resto no
                se verán afectados de ninguna manera, ni sufrirán modificación de ningún
                modo.
            </p>
            <p>
                El USUARIO declara haber leído, conocer y aceptar las presentes Condiciones
                en toda su extensión.
            </p>
            <h3>7. GENERALIDADES DE LA OFERTA</h3>
            <p>
                Todas las ventas y entregas efectuadas por el PRESTADOR se entenderán
                sometidas a las presentes Condiciones.
            </p>
            <p>
                Ninguna modificación, alteración o pacto contrario a la Propuesta Comercial
                de {{ farmacia.rgpddenominacionsocial }} o a lo aquí estipulado, tendrá efecto,
                salvo pacto expreso por escrito firmado por el PRESTADOR, en este caso,
                estos pactos particulares prevalecerán.
            </p>
            <h3>8. PRECIO Y PLAZO DE VALIDEZ DE LA OFERTA</h3>
            <p>
                Los precios que se indican respecto de cada producto o servicio incluyen el
                Impuesto sobre el Valor Añadido (IVA) u otros impuestos que pudieran ser
                aplicables. Estos precios, a menos que se indique expresamente lo
                contrario, no incluyen los gastos de envío o comunicación, manipulación o
                cualesquiera otros servicios adicionales y anexos al producto adquirido.
            </p>
            <p>
                Los precios aplicables a cada producto son los publicados en la Web App y
                se expresarán en la moneda EURO. El USUARIO asume que la valoración
                económica de algunos de los productos podrá variar en tiempo real.
            </p>
            <p>
                Antes de realizar la compra podrá comprobar en línea todos los detalles del
                presupuesto: artículos, cantidades, precio, disponibilidad, gastos de
                transporte, cargos, descuentos, impuestos y el total de la compra. Los
                precios pueden cambiar diariamente mientras no se realice el pedido.
            </p>
            <p>
                Una vez realizado el pedido, los precios se mantendrán tanto si hay
                disponibilidad de productos como si no.
            </p>
            <p>
                Todo pago realizado al PRESTADOR conlleva la emisión de una factura a
                nombre del USUARIO registrado o de la razón social que este haya informado
                en el momento de realizar el pedido. Esta factura se enviará en formato
                papel junto con el producto adquirido. En caso de querer recibirla mediante
                correo electrónico, deberá solicitarlo en el formulario de registro, en el menú
                <router-link to="/configuracion">Configuración</router-link>
                o por cualquiera de los medios que el PRESTADOR pone a su disposición,
                informándole de que en cualquier momento podrá revocar dicha decisión.
            </p>
            <p>
                Para cualquier información sobre el pedido, el USUARIO podrá contactar a
                través del teléfono de atención al cliente del PRESTADOR {{ farmacia.telefono }} o vía
                correo electrónico a la dirección {{ farmacia.domiciliosocial }}
            </p>
            <h3>9. GASTOS DE TRANSPORTE</h3>
            <p>
                Los precios publicados en la tienda no incluyen gastos de envío o
                comunicación, o prestaciones complementarias, salvo pacto expreso por
                escrito en contrario.
            </p>
            <p>
                Los portes se calcularán en el momento de guardar la cesta o
                presupuesto, ya que se calculan en base a la dirección de entrega.
            </p>
            <v-table v-if="reglasEnvio.length > 0">
                <thead>
                    <tr class="text-left">
                        <th>Opción de envío</th>
                        <th class="px-2">Zona</th>
                        <th class="px-1">Importe</th>
                        <th class="px-1">Importe mínimo</th>
                        <th class="px-1">Gratis pedidos superiores a</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="reglasenvio in reglasEnvio" :key="reglasenvio.idregla">
                        <td class="truncate">{{ reglasenvio.descripcion }}</td>
                        <td class="px-2">{{ reglasenvio.zona }}</td>
                        <td class="px-1 text-right">{{ reglasenvio.importe > 0 ? reglasenvio.importe : '0.00' }}</td>
                        <td class="px-1 text-right">{{ reglasenvio.importeminimo > 0 ? reglasenvio.importeminimo : '0.00' }}</td>
                        <td class="px-1 text-right">{{ reglasenvio.importegratis }}</td>
                    </tr>
                </tbody>
            </v-table>
            <h3>10. FORMAS DE PAGO.</h3>
            <div>
                El PRESTADOR es el responsable de las transacciones económicas y posibilita
                las siguientes formas para efectuar el pago de un pedido: pago en Farmacia
                para recogidas en el propio establecimiento y pago mediante:
                <ul>
                    <li v-for="formapago in formasPago" :key="formapago.descripcion">
                        {{ formapago.descripcion }}
                    </li>
                </ul>
            </div>
            <h3>Medidas de seguridad</h3>
            <p>
                Esta Web App utiliza técnicas de seguridad de la información generalmente
                aceptadas en la industria, tales como SSL, datos introducidos en página
                segura, firewalls, procedimientos de control de acceso y mecanismos
                criptográficos, todo ello con el objeto de evitar el acceso no autorizado a
                los datos. Para lograr estos fines, el usuario/cliente acepta que el
                prestador obtenga datos para efecto de la correspondiente autenticación de
                los controles de acceso.
            </p>
            <p>
                El PRESTADOR se compromete a no permitir ninguna transacción que sea
                considerada ilegal por las marcas de tarjetas de crédito o el banco
                adquiriente y que pueda o tenga el potencial de dañar la buena voluntad de
                los mismos o influir de manera negativa en ellos.
            </p>
            <p>
                Está prohibida, en virtud de los programas de las marcas de tarjetas, la
                venta u oferta de un producto o servicio que no cumpla con todas las leyes
                aplicables al Comprador, Banco Emisor, Comerciante o Titular de la tarjeta
                o tarjetas.
                Además, las siguientes actividades también están prohibidas explícitamente: Vender medicamentos.
            </p>
            <h3>11. PROCESO DE COMPRA</h3>
            <p>
                Cualquier producto de nuestro catálogo se puede añadir a la cesta. En esta,
                solo se observarán los artículos, la cantidad, el precio y el importe
                total. Una vez guardada la cesta se procederá a calcular los impuestos,
                cargos y descuentos según los datos de pago y de envío introducidos.
            </p>
            <p>
                Las cestas no tienen ninguna vinculación administrativa, solo es un
                apartado donde se puede simular un presupuesto sin ningún compromiso por
                ambas partes.
            </p>
            <p>
                Desde la cesta se puede hacer un pedido siguiendo los pasos siguientes para
                su correcta formalización:
            </p>
            <p>
                1. - Comprobación de los datos de facturación.
            </p>
            <p>
                2. - Comprobación de la dirección de envío.
            </p>
            <p>
                3. - Selección de la forma de pago.
            </p>
            <p>
                4. - Realizar el pedido (comprar).
            </p>
            <p>
                Una vez procesado el pedido, el sistema envía instantáneamente un
                correo electrónico al USUARIO confirmando la realización del pedido.
            </p>
            <h3>Pedidos (solicitudes de compra)</h3>
            <p>
                Previamente a la confirmación del pedido y con cada modificación del
                estado del pedido, se suministrará información mediante notificaciones
                a través de la Web App y correo electrónico.
            </p>
            <h3>
                12.
                DISOCIACIÓN Y SUSPENSIÓN O RESCISIÓN DEL CONTRATO
            </h3>
            <p>
                Si cualquiera de estos términos y condiciones se considerara ilegal, nula o
                por cualquier razón inaplicable, esta condición se considerará separable y
                no afectará la validez y aplicabilidad de ninguna de las condiciones
                restantes.
            </p>
            <p>
                El PRESTADOR podrá, sin previo aviso, suspender o terminar el acceso del
                USUARIO a sus servicios, en su totalidad o en parte, cuando el USUARIO no
                cumpla las obligaciones establecidas en este contrato o cualquier
                disposición legal, licencia, reglamento, directiva, código de prácticas o
                políticas que le sean aplicables.
            </p>
            <p>
                Cuando El PRESTADOR ejerza cualquiera de sus derechos o facultades bajo
                esta Cláusula, tal ejercicio no perjudicará ni afectará el ejercicio de
                cualquier otro derecho, facultad o recurso que pueda estar a disposición de
                El PRESTADOR.
            </p>
            <h3>13. GARANTÍAS Y DEVOLUCIONES</h3>
            <p>
                Las garantías responderán a lo regulado en el Título referido a "Garantías
                y servicios posventa" del Real Decreto Legislativo 1/2007, de 16 de
                noviembre, por el que se aprueba el texto refundido de la Ley General para
                la Defensa de los Consumidores y Usuarios y otras leyes complementarias, al
                que puede acceder clicando
                <a href="http://www.boe.es/buscar/act.php?id=BOE-A-2007-20555&amp;amp;p=20211103&amp;amp;tn=1&amp;amp;tiv-3">
                    aquí
                </a>
            </p>
            <h3>14. LEY APLICABLE Y JURISDICCIÓN</h3>
            <p>
                Estas condiciones se regirán o interpretarán conforme a la legislación
                española en aquello que no esté expresamente establecido. Cualquier
                controversia que pudiera suscitarse de la prestación de los productos o
                servicios objeto de estas Condiciones se someterá a los juzgados y
                tribunales del domicilio del USUARIO, al lugar del cumplimiento de la
                obligación o aquel en que se encuentre el bien si éste fuera inmueble.
            </p>
        </div>
    </v-container>
</template>
<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';
export default {
    data: () => ({
        /**
         * Las formas de pago disponibles.
         *
         * @type {object[]}
         */
        formasPago: [],

        /**
         * Las reglas de envío de todas las opciones de envío a domicilio, activas, para las condiciones de contratación.
         *
         * @type {object[]}
         */
        reglasEnvio: [],
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        hostname() {
            return Capacitor.isNativePlatform()
                ? 'Approx'
                : window.location.hostname;
        },
    },
    created() {
        this.getFormasPago();
        this.getReglasEnvio();
    },
    methods: {
        volver() {
            this.$router.back();
        },
        async getFormasPago() {
            const { data } = await axios.get('/formaspago');
            this.formasPago = data;
        },
        async getReglasEnvio() {
            const { data } = await axios.get('/reglasenvio');
            this.reglasEnvio = data;
        },
    },
};
</script>
<style scoped>
a {
    color: var(--v-tertiary-base) !important;
}

.truncate {
  /* white-space: nowrap;
  max-width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
