import StorageHelper from '../support/StorageHelper.js';
import { createI18n } from 'vue-i18n';
import offlineMessages from './offline.json';
/*
import ca from './lang/ca.json';
import de from './lang/de.json';
import en from './lang/en.json';
import es from './lang/es.json';
import eu from './lang/eu.json';
import fr from './lang/fr.json';
import gl from './lang/gl.json';
import pt from './lang/pt.json';
*/

// https://github.com/vitejs/vite/issues/5359
import 'moment/dist/locale/ca';
import 'moment/dist/locale/de';
//import 'moment/dist/locale/en'; // Incluido por defecto
import 'moment/dist/locale/es';
import 'moment/dist/locale/eu';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/gl';
import 'moment/dist/locale/pt';

export async function getDefaultLocale() {
    const idiomasSoportados = Object.keys(offlineMessages);
    const locale = await StorageHelper.getString('idiomauser');
    if (locale){
        return locale;
    } else{
        return navigator.languages.find(lang => idiomasSoportados.includes(lang.split('-')[0]))?.split('-')[0] ?? 'es';
    }
}

/**
 * Crea el objeto i18n con los mensajes de idioma.
 *
 */
const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages: offlineMessages,
});

function deepMerge(obj1, obj2) {
    const result = { ...obj1 };
    for (const key of Object.keys(obj2)) {
        if (key in obj1) {
            result[key] = deepMerge(obj1[key], obj2[key]);
        } else {
            result[key] = obj2[key];
        }
    }
    return result;
}

/**
 * Mergea los mensajes de idioma con los ya existentes, sobreescribiendo las claves que ya existan.
 *
 * @param {{[locale: string]: import('vue-i18n').LocaleMessageObject}} messages
 */
export function mergeLocaleMessages(messages) {
    for (const [locale, message] of Object.entries(messages)) {
        const localeMessage = i18n.global.messages[locale] || {};
        i18n.global.mergeLocaleMessage(locale, deepMerge(localeMessage, message));
    }
}


export default i18n;
