<template>
    <v-img
        v-if="imagenHome"
        :src="imagenHome"
        cover
        height="164"
        aria-hidden="true" />
</template>
<script>
import { store } from '@/store';

export default {
    computed: {
        imagenHome() {
            return store.farmacia.rutaimagenhome;
        },

    },


};
</script>

