<template>
    <v-app-bar
        color="shell"
        :class="{'pl-1 pr-0' : compact}"
        density="default"
        elevation="1">
        <v-app-bar-nav-icon
            v-if="$vuetify.display.mdAndDown"
            color="shell-primary"
            :class="{'mx-n1' : compact}"
            :aria-label="$t('appBar.menu')"
            @click.stop="$emit('drawerToggled')">
            <v-badge :model-value="mensajesBadgeCount > 0" dot color="secondary">
                <v-icon icon="mdi-menu" />
            </v-badge>
        </v-app-bar-nav-icon>

        <v-toolbar-title :class="{'ml-1 mr-0' : compact, 'ml-6': !$vuetify.display.mdAndDown}">
            <router-link to="/" class="text-on-shell text-decoration-none" :style="{'font-size': fontSizeTitulo }">
                {{ nombreFarmaciaCorto }}
            </router-link>
        </v-toolbar-title>

        <template #append>
            <template v-if="busquedaHabilitada">
                <the-buscador :show="buscadorVisible" @update:show="$emit('update:buscadorVisible', $event)" />
            </template>

            <v-btn
                to="/cuenta"
                :class="{'mx-n1' : compact}"
                icon="mdi-account-circle"
                color="shell-primary"
                :aria-label="$t('appBar.miCuenta')" />

            <v-btn
                v-if="$vuetify.display.smAndUp"
                icon
                :class="{'mx-n1' : compact}"
                to="/notificaciones"
                color="shell-primary"
                :aria-label="$t('appBar.notificaciones')">
                <v-badge :model-value="notificacionesBadgeCount > 0" :content="notificacionesBadgeCount" color="secondary">
                    <v-icon icon="mdi-bell" />
                </v-badge>
            </v-btn>

            <v-btn
                v-if="cestaHabilitada"
                icon
                :class="{'ml-n1 mr-n2' : compact}"
                to="/cesta"
                color="shell-primary"
                :aria-label="$t('appBar.cesta')">
                <v-badge :model-value="cestaBadgeCount > 0" :content="cestaBadgeCount" color="secondary">
                    <v-icon icon="mdi-cart" />
                </v-badge>
            </v-btn>
        </template>
    </v-app-bar>
</template>

<script>
import { store } from '../../store';
import StringHelper from '../../support/StringHelper';
import TheBuscador from './TheBuscador.vue';

export default {
    components: {
        TheBuscador,
    },
    props: {
        /**
         * Indica si el buscador está abierto con el popup de sugerencias.
         */
        buscadorVisible: {
            type: Boolean,
            default: false,
        },

        /**
         * El número de notificaciones sin leer.
         */
        notificacionesBadgeCount: {
            type: Number,
            default: 0,
        },

        /**
         * El número de productos en la cesta.
         */
        cestaBadgeCount: {
            type: Number,
            default: 0,
        },
    },
    emits: [
        /**
         * Emitido cuando se pulsa el boton de colapsar/expandir el navigation drawer.
         */
        'drawerToggled',

        'update:buscadorVisible',
    ],
    data() {
        return {};
    },
    computed: {
        busquedaHabilitada() {
            return store.catalogoHabilitado;
        },
        cestaHabilitada() {
            return store.ecommerceHabilitado;
        },
        nombreFarmacia() {
            return store.farmacia?.nombre;
        },

        mensajesBadgeCount() {
            return store.mensajes.badge;
        },

        /**
         * Indica si se debe mostrar la barra de navegación en modo compacto.
         *
         * @returns {boolean}
         */
        compact() {
            return !this.$vuetify.display.smAndUp;
        },

        /**
         * Devuelve el nombre corto de la farmacia.
         *
         * Segun análisis de la BD, casi un 59% de los nombres de farmacias en producción empiezan por "Farmacia ".
         * Un pequeño porcentaje empieza por "Farmacia de " y algunas terminan en " farmacia".
         *
         * Lo que hacemos es quitar el prefijo "Farmacia " y el sufijo " farmacia" si existe.
         * Si en cambio el prefijo es "Farmacia de" (incluye "del"), lo dejamos tal cual ya que sino quedaría raro.
         *
         * @returns {string}
         */
        nombreFarmaciaCorto() {
            if (this.$vuetify.display.smAndUp) return this.nombreFarmacia;

            const s = this.nombreFarmacia || '';

            if (s.length < 20) { // Este numero podemos ajustarlo a medida que despejemos espacio en la barra
                return s;
            }

            if (s.startsWith('Farmacia ') && !s.startsWith('Farmacia de')) { // Español
                return StringHelper.sentenceCase(s.substr(9));
            }

            if (s.startsWith('Farmàcia ') && !s.startsWith('Farmàcia de')) { // Catalán
                return StringHelper.sentenceCase(s.substr(9));
            }

            if (s.startsWith('Fcia. ')) { // Solo la 'Fcia. Ana Gutierres'
                return StringHelper.sentenceCase(s.substr(6));
            }

            if (s.endsWith(' farmacia')) {
                return StringHelper.sentenceCase(s.substr(0, s.length - 9));
            }

            return s;
        },

        /**
         * Devuelve el tamaño de fuente del titulo de la barra de navegación. Esto es para que las farmacias con
         * nombres largos quepan en la barra de navegación.
         *
         * @returns {string}
         */
        fontSizeTitulo() {
            if (this.$vuetify.display.smAndUp) return '1em'; // Pantallas grandes no cambiamos el tamaño de fuente

            // A medida que despejemos espacio en la barra, podemos ir subiendo el numero de caracteres
            const s = this.nombreFarmaciaCorto;
            if (s.length < 15) return '1em';
            if (s.length < 19) return '0.95em';
            if (s.length < 22) return '0.9em';
            return '0.85em'; // Nunca menos de 0.85em ya que quedaría muy pequeño y resultaría ilegible
        },
    },
};
</script>
