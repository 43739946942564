
const eurosFormat = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' });

/**
 * Provee utilidades para trabajar con precios en euros.
 */
export default class EurosHelper {
    /**
     * Formatea un precio en euros.
     *
     * @param {number|string} value
     * @returns {string}
     */
    static format(value) {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        return eurosFormat.format(value);
    }

    /**
     * Separa un precio en euros en sus componentes.
     *
     * @param {number|string} value
     * @returns {{ integer: string, fraction: string, currency: string, decimal: string }}
     */
    static parts(value) {
        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        const parts = eurosFormat.formatToParts(value).reduce((acc, part) => {
            acc[part.type] = part;
            return acc;
        }, {});

        return {
            integer: parts.integer.value,
            fraction: parts.fraction.value,
            currency: parts.currency.value,
            decimal: parts.decimal.value,
        };
    }
}
