<template>
    <p class="text-body-2 text-justify text-medium-emphasis">
        {{ textoLegal }}
        <i18n-t scope="global" keypath="compraEnvioFacturacion.laPoliticaDePrivacidad.text">
            <template #url>
                <a href="#/politica-privacidad">{{ $t('compraEnvioFacturacion.laPoliticaDePrivacidad.link') }}</a>
            </template>
        </i18n-t>
    </p>
</template>

<script>
export default {
    props: {
        /**
         * La denominación social del comerciante.
         */
        rgpddenominacionsocial: {
            type: String,
            required: true,
        },
    },

    computed: {
        textoLegal() {
            return (
                this.$t('compraEnvioFacturacion.esteComercianteSeCompromete') +
                this.rgpddenominacionsocial +
                this.$t('compraEnvioFacturacion.finesDelTratamiento')
            );
        },
    },
};
</script>
