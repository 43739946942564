import StringHelper from './StringHelper';

export class Categoria {
    /**
     * @param {number} id
     * @param {string} descripcion
     */
    constructor(id, descripcion, imagen = null) {
        this.id = +id;
        this.descripcion = StringHelper.sentenceCase(descripcion);
        this.hijos = [];
        this.padre = null;
        this.link = `/categorias/${id}`;
        this.imagen = imagen;
    }

    /**
     * Añade un hijo a la categoría.
     *
     * @param {Categoria} hijo
     */
    addHijo(hijo) {
        this.hijos.push(hijo);
        hijo.padre = this;
    }

    /**
     * Busca una categoría por su id. Devuelve la categoría si la encuentra o null si no.
     *
     * @param {number|string} id
     * @returns {Categoria?}
     */
    findById(id) {
        id = +id;
        if (this.id === id) {
            return this;
        }
        for (let i = 0; i < this.hijos.length; i++) {
            const hijo = this.hijos[i];
            const encontrado = hijo.findById(id);
            if (encontrado) {
                return encontrado;
            }
        }
        return null;
    }

    /**
     * Obtiene todas las categorías padre de esta categoría de forma recursiva.
     * Esto puede ser útil para generar breadcrumbs. El primer elemento del array
     * es la categoría raíz y el último es la categoría actual.
     *
     * @returns {Array<Categoria>}
     */
    getBreadcrumbs() {
        const padres = [this];
        let padre = this.padre;
        while (padre !== null) {
            padres.push(padre);
            padre = padre.padre;
        }
        return padres.reverse();
    }
}

// TODO: Mover esta lógica al backend
export default class CategoriaService {
    /**
     * Transforma un array de categorías en un árbol de categorías.
     *
     * @param {Array} categorias
     * @returns {Categoria}
     */
    static fromList(categorias) {
        const root = new Categoria(0, '');
        if (!categorias) {
            return root;
        }

        // Primero populamos un diccionario<id, categoria> para buscar en O(1)
        const categoriasById = {};
        categorias.forEach((cat) => categoriasById[cat.idcategoria] = new Categoria(+cat.idcategoria, cat.descripcion, cat.imagen));

        // Luego creamos el árbol de categorías
        categorias.forEach((cat) => {
            const padre = categoriasById[cat.idcategoriapadre] ?? root;
            const categoria = categoriasById[cat.idcategoria];
            padre.addHijo(categoria);
        });
        return root;
    }
}
