<template>
    <v-carousel
        v-if="items.length > 0"
        v-model:model-value="itemIndex"
        :height="imageHeight"
        :show-arrows="items.length > 1 && esWeb() ? 'hover' : false"
        :hide-delimiters="items.length <= 1"
        hide-delimiter-background
        :cycle="items.length > 1"
        class="bg-surface carousel-dots">
        <router-link v-for="(item, i) in items" :key="i" :to="item.url" class="text-decoration-none" :class="{disabled: !item.urlExterna && item.url === ''}" @click="linkExterno(item)">
            <v-carousel-item :src="item.imagen" cover :height="imageHeight" class="carousel-item">
                <v-sheet v-if="!item.imagen" color="primary-brand" class="d-flex fill-height flex-column align-center justify-center">
                    <v-icon icon="mdi-sale" size="52px" class="mr-2" />
                    <div class="mt-6 text-h6 text-md-h3">
                        {{ item.descripcion }}
                    </div>
                </v-sheet>
            </v-carousel-item>
        </router-link>
    </v-carousel>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import StringHelper from '../../support/StringHelper';
import { Capacitor } from '@capacitor/core';
import { isExternalUrl } from '../../support/Normalization';

export default {
    data() {
        return {
            /**
             * El índice del item actual.
             * Este valor solo se usa ya que Vuetify hace una animacion de acuerdo al index del item.
             * Si no definimos un index, entonces se muestra una animación de transición que no queremos.
             *
             * @type {number}
             */
            itemIndex: 0,

            /**
             * Los items del slider.
             *
             * @type {object[]}
             */
            items: [],
        };
    },
    computed: {
        imagenHome() {
            return store.farmacia.rutaimagenhome;
        },
        imageHeight() {
            return {
                xs: '160px',
                sm: '400px',
                md: '385px',
                lg: '490px',
                xl: '490px',
            }[this.$vuetify.display.name] || '490px';
        },
    },
    watch: {
        '$route'(to){
            to.path === '/' && this.loadData();
        },
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            const { data } = await axios.get('/banners');
            let banners = Object.values(data);
            this.items = banners.map((item) => ({
                ...item,
                descripcion: StringHelper.titleCase(item.descripcion),

                imagen: store.farmacia.mostrarImagenes && item.imagen?.ruta
                    ? `${store.farmacia.endpointUrl}/img/${store.farmacia.numserie}/${item.imagen.ruta}`
                    : null,
                urlExterna: item.url && isExternalUrl(item.url) ? item.url : null,
                url: this.getLink(item),
            }));
        },
        esWeb() {
            return !Capacitor.isNativePlatform();
        },
        getLink(banner) {
            let link;
            switch (banner.tipoentidad) {
            case 'Oferta':
                link = store.ecommerceHabilitado || store.catalogoHabilitado
                    ? `/promociones/${banner.identidad}`
                    : '/promociones';
                break;
            case 'Categoria':
                link = `/categorias/${banner.identidad}`;
                break;
            case 'Producto':
                link = `/productos/${banner.identidad}`;
                break;
            case 'Url':
                link = '';
                break;
            default:
                link = '';
                break;
            }
            return link;
        },
        linkExterno(item){
            if(item.tipoentidad === 'Url'){
                window.open(item.urlExterna, '_blank');
            }
        },
    },
};
</script>

<style>
.carousel-dots .v-carousel__controls .v-btn {
    color: rgb(0,0,0, 0.1) !important;
}
</style>

<style scoped>
.carousel-item {
    cursor: pointer;
}
.disabled {
    pointer-events: none;
}
</style>
