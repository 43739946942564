<template>
    <div>
        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="obtenerPedidos" />

        <div v-else-if="loading" class="text-center">
            <v-card class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
                <v-progress-circular indeterminate color="primary" size="large" />
            </v-card>
        </div>

        <div v-else-if="pedidos.length === 0">
            <v-card class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
                <v-icon size="60" icon="mdi-cart" class="text-medium-emphasis" />
                <div class="text-high-emphasis text-h6 mt-6">
                    {{ $t('cuentaView.noTienesPedidosActualmente') }}
                </div>
                <v-btn color="primary" class="mt-6" to="/productos" variant="elevated">
                    {{ $t('cuentaView.explorarProductos') }}
                </v-btn>
            </v-card>
        </div>

        <div v-else>
            <pedido-card v-for="pedido in pedidos" :key="pedido.idpedido" :pedido="pedido" class="mx-n4 mx-sm-0" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import PedidoCard from '@/components/PedidoCard.vue';
import OopsAlert from '@/components/OopsAlert.vue';

export default {
    components: {
        PedidoCard,
        OopsAlert,
    },

    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Los pedidos del usuario.
         *
         * @type {object[]}
         */
        pedidos: [],
    }),

    created() {
        this.obtenerPedidos();
    },

    methods: {
        async obtenerPedidos() {
            this.loading = true;
            this.error = null;

            try {
                const { data } = await axios.get('/pedidos');
                this.pedidos = data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
