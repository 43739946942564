<template>
    <div>
        <v-sheet v-if="!mensajes.length" color="background" rounded="lg" :border="true" class="text-center pa-8">
            <span class="text-medium-emphasis">
                {{ $t('pedidoCard.noHayMensajesDeSeguimiento') }}
            </span>
        </v-sheet>
        <v-sheet v-else color="background" class="pa-4" rounded="lg" :border="true">
            <div v-for="mensaje in mensajes" :key="mensaje.idmensaje">
                <div v-if="mensaje.autor === 'U'" class="mt-2 text-right">
                    <div class="text-body-2 text-medium-emphasis" :title="formatFecha(mensaje.fechahora)">
                        <b>{{ $t('pedidoCard.tu') }}</b>, el {{ formatFecha(mensaje.fechahora) }}
                    </div>
                    <v-sheet class="py-2 px-4 d-inline-block rounded-xl rounded-te-0" color="secondary">
                        {{ mensaje.mensaje }}
                    </v-sheet>
                </div>
                <div v-else class="mt-2">
                    <div class="text-body-2 text-medium-emphasis" :title="formatFecha(mensaje.fechahora)">
                        <b>{{ farmaciaNombre }}</b>, el {{ formatFecha(mensaje.fechahora) }}
                    </div>
                    <v-sheet class="py-2 px-4 d-inline-block rounded-xl rounded-ts-0" color="surface" :border="true">
                        {{ mensaje.mensaje }}
                    </v-sheet>
                </div>
            </div>
        </v-sheet>
        <v-form v-if="$vuetify.display.mdAndUp" v-click-outside="onClickOutside" class="d-flex justify-space-between mt-3 mb-n2" @submit.prevent="enviarMensaje">
            <v-textarea
                v-model="mensajeTexto"
                counter="255"
                maxlength="255"
                variant="outlined"
                :placeholder="$t('pedidoCard.mensaje')"
                :persistent-hint="false"
                :rows="1"
                auto-grow
                :single-line="true"
                enterkeyhint="send"
                @keydown.enter.prevent="enviarMensaje" />
            <v-btn
                type="submit"
                rounded="lg"
                color="primary"
                append-icon="mdi-send"
                elevation="1"
                height="55"
                class="ml-4"
                :disabled="mensajeTexto.length < 1">
                {{ $t('pedidoCard.enviar') }}
            </v-btn>
        </v-form>
        <v-form v-else v-click-outside="onClickOutside" class="d-flex justify-space-between mt-3 mb-n2" @submit.prevent="enviarMensaje">
            <v-textarea
                v-model="mensajeTexto"
                counter="255"
                maxlength="255"
                variant="outlined"
                density="compact"
                :placeholder="$t('pedidoCard.mensaje')"
                :persistent-hint="false"
                :rows="1"
                auto-grow
                :single-line="true"
                enterkeyhint="send"
                @keydown.enter.prevent="enviarMensaje" />
            <v-btn
                type="submit"
                size="small"
                icon="mdi-send"
                color="primary"
                class="ml-2"
                :aria-label="$t('pedidoCard.enviar')"
                :disabled="mensajeTexto.length < 1" />
        </v-form>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { store } from '@/store';
import { clickOutside } from '@/support/Directives';

export default {
    directives: {
        clickOutside,
    },

    props: {
        /**
         * El pedido del que se mostrarán los mensajes.
         *
         * @type {Object}
         */
        pedido: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            /**
             * El mensaje que está escribiendo el usuario.
             *
             * @type {string}
             */
            mensajeTexto: '',

            /**
             * Los mensajes de seguimiento del pedido.
             *
             * @type {Array}
             */
            mensajes: this.pedido.mensajes,
        };
    },

    computed: {
        farmaciaNombre() {
            return store.farmacia.nombre;
        },
    },

    methods: {
        formatFecha(fechahora) {
            return moment(fechahora).format('LLL');
        },
        validate() {
            return this.mensajeTexto.length > 0 && this.mensajeTexto.length < 255;
        },
        async enviarMensaje() {
            if (!this.validate()) {
                return;
            }

            const response = await axios.post('/pedido/mensaje', {
                idpedido: this.pedido.idpedido,
                mensaje: this.mensajeTexto,
            });

            this.mensajes.push(response.data);
            this.mensajeTexto = '';
        },
        onClickOutside() {
            // En iOS, por alguna extraña razón, el teclado no se oculta cuando se hace click fuera del input.
            // la solución es tener un listener en el body del evento click. Esto hace que iOS permita perder
            // el foco del input. La directiva custom v-click-outside sirve para esto.
            // Este callback puede quedar vacío.
        },
    },
};
</script>
