<template>
    <v-row class="mt-8" dense>
        <v-col cols="12" sm="auto" order="last" order-sm="first">
            <v-btn
                v-if="stepNumber > 1"
                :block="$vuetify.display.smAndDown"
                color="primary"
                variant="plain"
                class="mr-4"
                size="large"
                :disabled="disabled"
                prepend-icon="mdi-chevron-left"
                @click="$emit('previous')">
                {{ $t('compraEnvioFacturacion.atras') }}
            </v-btn>
        </v-col>

        <v-spacer />

        <v-col cols="12" sm="auto">
            <v-btn
                v-if="isLastStep"
                :disabled="disabled || !canContinue"
                :block="$vuetify.display.smAndDown"
                color="primary"
                class="px-10"
                size="large"
                :loading="loading"
                @click="$emit('next')">
                {{ finishButtonLabel }}
            </v-btn>
            <v-btn
                v-else
                :disabled="disabled || !canContinue"
                :block="$vuetify.display.smAndDown"
                color="surface"
                class="px-10"
                size="large"
                :loading="loading"
                append-icon="mdi-chevron-right"
                @click="$emit('next')">
                {{ $t('compraEnvioFacturacion.continuar') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        /**
         * Número de paso actual.
         */
        stepNumber: {
            type: Number,
            required: true,
        },

        /**
         * Número total de pasos.
         */
        stepCount: {
            type: Number,
            required: true,
        },

        /**
         * Indica si se puede continuar al siguiente paso.
         */
        canContinue: {
            type: Boolean,
            required: true,
        },

        /**
         * Texto del botón de finalizar.
         */
        finishButtonLabel: {
            type: String,
            required: true,
        },

        /**
         * Indica si el botón de continuar debe mostrar un spinner de carga.
         */
        loading: {
            type: Boolean,
            default: false,
        },

        /**
         * Indica si el botón de continuar debe estar deshabilitado.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        /**
         * Se emite cuando se hace click en el botón de anterior.
         */
        'previous',

        /**
         * Se emite cuando se hace click en el botón de siguiente.
         */
        'next',
    ],

    computed: {
        isLastStep() {
            return this.stepNumber >= this.stepCount;
        },
    },
};
</script>
