<template>
    <div>
        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarFormasPago" />

        <div v-else-if="loading" class="d-flex align-center justify-center">
            <v-progress-circular indeterminate color="primary" size="64" />
        </div>

        <v-col v-else-if="formasPago.length === 0">
            <v-alert type="error" :border="'start'" :text="$t('compraEnvioFacturacion.noMetodosDePagoDisponibles')" />
        </v-col>

        <div v-else-if="formasPago.length === 1">
            <v-alert
                elevation="0"
                type="info"
                variant="tonal"
                :border="true"
                rounded="lg"
                class="d-flex align-center py-5 px-8 h-100 bg-surface"
                :title="$t('compraEnvioFacturacion.medioDePago')">
                <img
                    v-if="formasPago[0].imagen"
                    :src="formasPago[0].imagen"
                    :alt="formasPago[0].descripcion"
                    height="35">
                <div v-else class="font-weight-bold">
                    {{ formasPago[0].descripcion }}
                </div>
            </v-alert>
        </div>

        <div v-else-if="formasPago.length > 0">
            <div class="d-flex align-center mb-5 text-h6">
                <v-icon icon="mdi-credit-card-outline" class="mr-4" />
                {{ $t('compraEnvioFacturacion.seleccionaUnMedioDePago') }}
            </div>

            <v-item-group v-model:model-value="value" mandatory class="mb-6 compra-formas-pago-selector">
                <v-row class="d-flex align-stretch" dense>
                    <v-col v-for="item in formasPago" :key="item.idformapago" cols="12" lg="6">
                        <v-item v-slot="{ isSelected, toggle }" :value="item">
                            <v-card
                                :color="isSelected ? 'primary' : null"
                                elevation="1"
                                :variant="isSelected ? 'tonal' : 'text'"
                                class="d-flex align-center py-5 px-8 h-100 bg-surface"
                                :class="isSelected ? 'font-weight-bold' : ''"
                                @click="toggle">
                                <v-radio :model-value="isSelected" :aria-label="item.descripcion">
                                    <template #label>
                                        <img
                                            v-if="item.imagen"
                                            :src="item.imagen"
                                            :alt="item.descripcion"
                                            height="35"
                                            class="ml-6">
                                        <div v-else class="font-weight-bold ml-3 d-flex align-center">
                                            {{ item.descripcion }}
                                        </div>
                                    </template>
                                </v-radio>
                            </v-card>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import OopsAlert from '../../components/OopsAlert.vue';
import imageCreditCards from '../../../images/credit-cards.png';
import imageBizum from '../../../images/logo-bizum.png';
import StringHelper from '../../support/StringHelper';

export default {
    components: {
        OopsAlert,
    },
    props: {
        /**
         * El id de la regla de envío seleccionada.
         *
         * @type {number}
         */
        idregla: {
            type: Number,
            required: true,
        },

        /**
         * La forma de pago seleccionada.
         *
         * @type {Object?}
         */
        modelValue: {
            type: Object,
            default: null,
        },
    },

    emits: [
        'update:modelValue',
    ],

    data() {
        return {
            /**
             * Las formas de pago disponibles.
             *
             * @type {Array}
             */
            formasPago: [],

            /**
             * Indica si se está cargando la lista de formas de pago.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * El error que se ha producido al cargar las formas de pago.
             *
             * @type {Error}
             */
            error: null,
        };
    },

    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },

    watch: {
        idregla() {
            this.cargarFormasPago();
        },
    },

    created() {
        this.cargarFormasPago();
    },

    methods: {
        async cargarFormasPago() {
            this.loading = true;
            this.error = null;
            this.formasPago = [];

            try {
                const response = await axios.post('/pago/opciones', {
                    idreglaenvio: this.idregla,
                });
                this.formasPago = response.data.map(forma => ({
                    ...forma,
                    descripcion: StringHelper.sentenceCase(forma.descripcion),
                    imagen: {
                        'C': imageCreditCards,
                        'z': imageBizum,
                    }[forma.paymethod] || null,
                    paymethod: forma.paymethod?.trim() || ' ', // Normaliza el paymethod, ya que algunos son ' ', otros '' y otros null o undefined.
                }));

                if (this.formasPago.length === 1) {
                    this.value = this.formasPago[0];
                } else if (this.formasPago.length > 1) {
                    this.value = null;
                }
            } catch (error) {
                console.error(error);
                this.error = error;
            }
            this.loading = false;
        },
    },
};
</script>

<style>
/* Por defecto, el label de los radio buttons tiene un opacity: var(--v-medium-emphasis); (Aprox. 0.6)
Eso hace que la imagen de las tarjetas de crédito se vea semi-transparente. */
.compra-formas-pago-selector .v-label {
    opacity: 1 !important;
}
</style>
