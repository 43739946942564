import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import axios from 'axios';
import { store } from '../store';
import { Dialog } from '@capacitor/dialog';

/**
 * Configure el encabezado axios X-Approx-Client. los datos son
 * codificados como pares clave=valor separados por punto y coma.
 * Ejemplos:
 * - X-Approx-Client: platform=ios;model=iPhone 11 Pro Max;osVersion=14.4;appVersion=1.0.0;buildNumber=52
 * - X-Approx-Client: platform=android;model=Pixel 4a;osVersion=11;appVersion=1.0.0;buildNumber=2
 * - X-Approx-Client: platform=web;model=MacIntel;osVersion=10_15_7;
 * - X-Approx-Client: platform=web;model=Windows NT 10.0;osVersion=Windows NT 10.0 Win64 x64
 *
 * @returns {Promise<void>}
 */
export async function setupApproxClientHeader() {
    const device = await Device.getInfo();
    const app = Capacitor.isNativePlatform() ? await App.getInfo() : { version: null, build: null };
    const data = {
        platform: device.platform,
        model: device.model,
        osVersion: device.osVersion,
        appVersion: app.version,
        buildNumber: app.build,
    };

    const formatValue = (input) => input.replace(/;/g, '').replace(/=/g, '');

    axios.defaults.headers.common['X-Approx-Client'] = Object.entries(data)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `${key}=${formatValue(value)}`)
        .join(';');
}

/**
 * Indica si el diálogo de actualización está siendo mostrado. Se usa para evitar
 * que se muestre más de una vez.
 *
 * @type {boolean}
 */
let isShowingUpdateDialog = false;

/**
 * Configure el interceptor axios para redirigir a la página de inicio de sesión
 * cuando el estado de respuesta es 401.
 *
 * @param {Router} router
 */
export function setupAxiosInterceptor(router) {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.isAxiosError) {
                const status = error.response?.status;
                if (status === 401 || status === 419) { // 401 Unauthorized, 419 Authentication Timeout
                    if (store.isLogged) {
                        // El usuario está autenticado, pero su sesión expiró.
                        // Esto significa que si bien nosotros tenemos un token guardado, ese token
                        // expiró o fue invalidado por el servidor o no pertenece a esta farmacia.
                        store.logoutUser();
                    } else {
                        // El usuario no está autenticado, redirigir a la página de login.
                        router.push({ name: 'Login' });
                    }
                } else if (status === 426) { // 426 Upgrade Required
                    // La aplicación necesita ser actualizada.
                    // Esto significa que la versión de la app que está usando el usuario es muy vieja y fue deprecada.
                    // El server nos devuelve un objeto con información sobre la actualización y los textos que se deben mostrar al usuario.

                    if (!Capacitor.isNativePlatform()) {
                        // En web, forzar recarga de la página. Esto nunca debería llegar a ocurrir.
                        window.location.reload();
                    } else if (!isShowingUpdateDialog) {
                        isShowingUpdateDialog = true;
                        // En mobile, mostrar un diálogo con la información de la actualización.
                        const data = error.response?.data;
                        Dialog.alert({
                            title: data.title ?? 'Actualización requerida',
                            message: data.message ?? 'Tu versión de la app está desactualizada, por favor actualiza la app para poder seguir usándola.',
                            buttonTitle: data.confirm_text ?? 'Actualizar',
                        }).then(() => {
                            isShowingUpdateDialog = false;
                            if (data.link) {
                                window.location.href = data.link;
                            }
                        });
                    }
                }
            }

            return Promise.reject(error);
        },
    );
}


/**
 * Setup axios endpoint.
 *
 * @returns {void}
 */
export function setupAxiosEndpoint() {
    axios.defaults.withCredentials = true;
    const endpoint = import.meta.env.VITE_ENDPOINT_URL;

    if (!endpoint) {
        throw new Error('VITE_ENDPOINT_URL no definido');
    }

    axios.defaults.baseURL = endpoint;
    console.info('Endpoint URL: ' + endpoint);
}
