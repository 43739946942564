<template>
    <v-container>
        <v-row :dense="!$vuetify.display.smAndUp">
            <v-col cols="12" md="7" lg="6">
                <v-card v-if="contactInfoItems || enlaces" :title="$t('contacto.informacionDeContacto')" class="mx-n4 mx-sm-0 mt-n4 mt-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
                    <v-list role="navigation">
                        <v-list-item
                            v-for="item in contactInfoItems"
                            :key="item.icono"
                            :href="item.link"
                            style="text-"
                            :prepend-icon="item.icono"
                            :target="item.target">
                            {{ item.texto }}
                        </v-list-item>
                        <v-list-item
                            v-for="enlace in enlaces"
                            :key="enlace.url"
                            :href="enlace.url"
                            :prepend-icon="enlace.icon"
                            target="_blank">
                            {{ enlace.titulo }}
                        </v-list-item>
                    </v-list>
                </v-card>
                <contacto-horarios-tabla class="mt-4 mt-sm-6" />
            </v-col>
            <v-col cols="12" md="5" lg="6">
                <v-row v-if="farmacia.rutaimagen" dense>
                    <v-col>
                        <div class="mx-n4 mx-sm-0">
                            <v-img :src="farmacia.rutaimagen" />
                        </div>
                    </v-col>
                </v-row>
                <v-alert v-if="!consentimientoCookies" type="error" icon="mdi-cookie-alert" class="rounded-b-0">
                    {{ $t('contacto.atencionDebesActivar') }}
                    <template #append>
                        <v-btn variant="tonal" color="on-error" @click="aceptarTodas()">
                            {{ $t('registroView.aceptarTodas') }}
                        </v-btn>
                    </template>
                </v-alert>
                <v-row v-if="farmacia.gmaps && consentimientoCookies" dense>
                    <v-col>
                        <div class="mx-n4 mx-sm-0">
                            <iframe
                                :src="farmacia.gmaps"
                                width="100%"
                                height="450"
                                frameborder="0"
                                style="border: 0"
                                aria-hidden="false"
                                :aria-label="$t('contacto.mapa')"
                                tabindex="0" />
                        </div>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>
                        <div class="mx-n4 mx-sm-0">
                            <iframe
                                src=""
                                width="100%"
                                height="450"
                                frameborder="0"
                                style="border: 1px black solid"
                                aria-hidden="false"
                                :aria-label="$t('contacto.mapa')"
                                tabindex="0" />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { store } from '@/store';
import ContactoHorariosTabla from './ContactoHorariosTabla.vue';

export default {
    components: {
        ContactoHorariosTabla,
    },
    data: () => ({
        consentimientoCookies: false,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        enlaces() {
            return store.farmacia.enlaces.map((enlace) => ({
                titulo: enlace.titulo,
                url: enlace.url,
                icon: `mdi-${enlace.iconslug ?? 'link'}`,
            }));
        },
        contactInfoItems() {
            const { movil, telefono, whatsapp, email, direccion, web } = store.farmacia;

            return [
                {
                    icono: 'mdi-cellphone',
                    texto: movil,
                    link: 'tel:' + movil,
                    target: '_self',
                },
                {
                    icono: 'mdi-phone',
                    texto: telefono,
                    link: 'tel:' + telefono,
                    target: '_self',
                },
                {
                    icono: 'mdi-whatsapp',
                    texto: whatsapp,
                    link: `https://api.whatsapp.com/send?phone=${whatsapp}`,
                    target: '_blank',
                },
                {
                    icono: 'mdi-email',
                    texto: email,
                    link: 'mailto:' + email,
                    target: '_blank',
                },
                {
                    icono: 'mdi-map-marker',
                    texto: direccion,
                    link: `https://www.google.com/maps/search/?api=1&query=${direccion}`,
                    target: '_blank',
                },
                {
                    icono: 'mdi-web',
                    texto: web,
                    link:  web,
                    target: '_blank',
                },
            ].filter((item) => item.texto);
        },
    },
    created() {
        let activarAnaliticas = false;
        let activarPublicitarias = false;
        if (this.$cookies.isKey('cookiescfg')) {
            const analiticasPublicitarias = this.$cookies.get('cookiescfg').split(',').map((x) => x === '1');
            activarAnaliticas = analiticasPublicitarias[0];
            activarPublicitarias = analiticasPublicitarias[1];
        }
        if ((activarAnaliticas && activarPublicitarias)) {
            this.consentimientoCookies = true;
        }
    },
    methods: {
        aceptarTodas() {
            this.$cookies.set('cookiescfg', '1,1', '365d');
            this.consentimientoCookies = true;
        },
    },
};
</script>
