<template>
    <v-form ref="form" @submit.prevent>
        <compra-opcion-envio
            v-if="cesta"
            :opcion-envio="opcionEnvio"
            @update:opcion-envio="$emit('update:opcionEnvio', $event)">
            <compra-metodo-envio
                v-if="opcionEnvio"
                :codpostal="codpostal"
                :opcion-envio="opcionEnvio"
                :cesta-total="cesta.total"
                :tienda-direccion="tiendaDireccion"
                :tienda-direccion-entrega="opcionEnvio.direccion"
                :tienda-nombre="tiendaNombre"
                @update:metodo-envio="$emit('update:metodoEnvio', $event)"
                @update:codpostal="$emit('update:codpostal', $event)" />
        </compra-opcion-envio>
    </v-form>
</template>

<script>
import CompraOpcionEnvio from './CompraOpcionEnvio.vue';
import CompraMetodoEnvio from './CompraMetodoEnvio.vue';

export default {
    components: {
        CompraOpcionEnvio,
        CompraMetodoEnvio,
    },

    expose: ['validate'],

    props: {
        /**
         * La cesta de la compra.
         */
        cesta: {
            type: Object,
            required: true,
        },

        /**
         * La dirección de la tienda.
         */
        tiendaDireccion: {
            type: String,
            required: true,
        },

        /**
         * El nombre de la tienda.
         */
        tiendaNombre: {
            type: String,
            required: true,
        },

        /**
         * La opción de envío seleccionada.
         */
        opcionEnvio: {
            type: Object,
            default: null,
        },

        /**
         * El método de envío seleccionado.
         */
        metodoEnvio: {
            type: Object,
            default: null,
        },

        /**
         * El código postal de la dirección de envío.
         */
        codpostal: {
            type: String,
            required: true,
        },
    },

    emits: [
        'update:canContinue',
        'update:metodoEnvio',
        'update:codpostal',
        'update:opcionEnvio',
    ],

    computed: {
        canContinue() {
            return this.opcionEnvio !== null && this.metodoEnvio !== null;
        },
    },

    watch: {
        canContinue: {
            immediate: true,
            handler(value) { this.$emit('update:canContinue', value); },
        },
    },

    methods: {
        async validate() {
            const { valid } = await this.$refs.form.validate();
            return valid;
        },
    },
};
</script>
