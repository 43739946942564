<template>
    <v-card>
        <v-card-title class="d-flex align-center" style="white-space: normal;">
            <div class="flex-shrink">
                {{ $t('descripcionMenuItems.comparte') }}
            </div>
            <v-spacer />
            <v-btn icon="mdi-close" class="ml-4" variant="text" :aria-label="$t('share.cerrar')" @click="$emit('cerrar')" />
        </v-card-title>
        <v-card-text class="pa-4 pa-md-6">
            <div class="text-center ma-4 mt-0 pb-2">
                <qrcode-vue :value="qrUrl" :size="250" level="M" />
            </div>
            <v-text-field
                :model-value="copyLinkUrl"
                variant="outlined"
                color="primary"
                readonly
                :hide-details="true"
                style="--v-input-padding-top: 4px; --v-field-padding-top: 4px;--v-field-padding-bottom: 4px;"
                class="align-center comparte-card-url-input">
                <template #append-inner>
                    <v-btn icon variant="text" class="mr-n2 ml-2" :aria-label="$t('share.copiar')" @click="copyUrl">
                        <v-icon icon="mdi-content-copy" />
                        <v-tooltip activator="parent" location="top" :text="$t('share.copiar')" />
                    </v-btn>
                </template>
            </v-text-field>
        </v-card-text>
        <v-sheet v-if="canShare" class="pa-4 pa-md-6" color="primary">
            <v-btn color="surface" :prepend-icon="shareIcon" block size="large" @click="share">
                {{ $t('share.compartir') }}
            </v-btn>
            <v-btn class="mt-2" color="on-primary" block variant="plain" @click="$emit('cerrar')">
                {{ $t('share.cerrar') }}
            </v-btn>
        </v-sheet>
        <v-snackbar v-model="mostrarMensajeCopiado">
            {{ $t('share.enlaceCopiadoAlPortapapeles') }}
        </v-snackbar>
    </v-card>
</template>

<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
        QrcodeVue,
    },
    props: {
        /**
         * La URL del código QR.
         */
        qrUrl: {
            type: String,
            default: '',
        },

        /**
         * La URL para compartir copiando el enlace.
         */
        copyLinkUrl: {
            type: String,
            default: '',
        },

        /**
         * La URL para compartir por redes sociales.
         */
        socialShareUrl: {
            type: String,
            default: '',
        },
    },
    emits: ['cerrar'],
    data: () => ({
        /**
         * Indica si el dispositivo soporta compartir.
         */
        canShare: false,

        /**
         * Indica si se debe mostrar el mensaje de enlace copiado al portapapeles.
         */
        mostrarMensajeCopiado: false,
    }),
    computed: {
        shareIcon() {
            return Capacitor.getPlatform() === 'ios' ? 'mdi-export-variant' : 'mdi-share-variant';
        },
    },
    async created() {
        this.canShare = (await Share.canShare()).value;
    },
    methods: {
        copyUrl() {
            navigator.clipboard.writeText(this.copyLinkUrl);
            this.mostrarMensajeCopiado = true;
        },
        share() {
            Share.share({
                title: this.$t('share.title', [store.farmacia.nombre]),
                text: this.$t('share.text', [store.farmacia.nombre]),
                url: this.socialShareUrl,
                dialogTitle: this.$t('share.dialogTitle', [store.farmacia.nombre]),
            });
        },
    },
};
</script>

<style>
/* No puede ser scoped porque necesitamos alcanzar los elementos internos de vuetify */

.comparte-card-url-input input {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
</style>
