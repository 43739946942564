import { Preferences } from '@capacitor/preferences';

export default class StorageHelper {

    /**
     * Lee un string desde el local storage del dispositivo. Si el string no existe, devuelve
     * el valor por defecto (null).
     *
     * @param {string} key
     * @param {string?} defaultValue
     * @returns {Promise<string?>}
     */
    static async getString(key, defaultValue = null) {
        try {
            const { value } = await Preferences.get({ key });
            return value ?? defaultValue;
        } catch (e) {  // Prevenir crash en caso de que Storage no esté disponible
            console.error(e);
            return defaultValue;
        }
    }

    /**
     * Guarda un string en el local storage del dispositivo.
     *
     * @param {string} key
     * @param {string} value
     */
    static async setString(key, value) {
        await Preferences.set({ key, value });
    }

    /**
    * Lee un JSON desde el local storage del dispositivo. Si el JSON no existe, devuelve
    * el valor por defecto (null).
    *
    * @param {string} key
    * @param {any} defaultValue
    * @returns {Promise<any>}
    */
    static async getJson(key, defaultValue = null) {
        try {
            const { value } = await Preferences.get({ key });
            if (value === null) return defaultValue;
            return JSON.parse(value);
        } catch (e) {  // Prevenir crash en caso de que Storage no esté disponible, o el JSON por alguna razón sea inválido
            console.error(e);
            return defaultValue;
        }
    }

    /**
     * Guarda un JSON en el local storage del dispositivo.
     *
     * @param {string} key
     * @param {any} value
     * @returns {Promise<void>}
     */
    static async setJson(key, value) {
        await Preferences.set({ key, value: JSON.stringify(value) });
    }

    /**
     * Elimina un JSON del local storage del dispositivo.
     *
     * @param {string} key
     * @returns {Promise<void>}
     */
    static async remove(key) {
        await Preferences.remove({ key });
    }
}
