import { PushNotifications } from '@capacitor/push-notifications';
import { store } from '../store';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import EventEmitter from './EventEmitter';
import HeartbeatService from './HeartbeatService';
import { LocalNotifications } from '@capacitor/local-notifications';
import { isExternalUrl, normalizeNotificationUrl } from './Normalization';

export default class PushNotificationsService extends EventEmitter {

    static _instance = null;

    /**
     * @returns {PushNotificationsService}
     */
    static get instance() {
        return PushNotificationsService._instance ??= new PushNotificationsService();
    }

    _inicializado = false;

    /**
     * @type {string|null}
     */
    tokenpush = null;

    /**
     * @type {import('vue-router').Router|null}
     */
    _router = null; // Se inyecta en el constructor para evitar dependencias circulares

    /**
     * Inicializa el servicio de notificaciones push y pide inmediatamente el permiso al usuario
     * para recibir notificaciones.
     *
     * @param {import('vue-router').Router} router
     * @returns {Promise<void>}
     */
    async initialize(router) {
        if (this._inicializado) return;
        this._inicializado = true;

        this._router = router;

        if (!Capacitor.isNativePlatform()) return;

        await PushNotifications.removeAllListeners();

        // Llamado después de que se registra el token push en los servers de FCM/APNS
        await PushNotifications.addListener('registration', (token) => {
            console.info('Registration token: ', token.value);
            this.tokenpush = token.value;
            this.enviarTokenPush();
        });

        // Llamado cuando se recibe una notificación push mientras la app está en primer plano
        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.info('Push notification received: ', notification);

            // Forzamos un heartbeat para actualizar los badges.
            HeartbeatService.instance.heartbeat();

            // Creamos una notificación nativa para que se muestre en el sistema operativo.
            // Esto es necesario porque si la app está en primer plano, no se muestra la notificación
            // por defecto.
            LocalNotifications.schedule({
                notifications: [
                    {
                        title: notification.title,
                        body: notification.body,
                        extra: notification.data,
                        id: Math.floor(Math.random() * 1_000_000_000),
                    },
                ],
            });
        });

        // Llamado cuando se pulsa sobre una notificación push
        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.info('Push notification action performed');

            this._handleNotificationPressed(notification.notification.data);
        });

        // Llamado cuando se pulsa sobre una notificación local
        await LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
            console.info('Local notification action performed');

            this._handleNotificationPressed(notification.notification.extra);
        });

        await LocalNotifications.requestPermissions();

        await this._registerNotifications();
    }

    /**
     * Maneja el evento de pulsar sobre una notificación.
     *
     * @param {{tipolink: string, url: string}} data
     */
    _handleNotificationPressed(data) {
        console.info('Push notification data', data);
        const url = normalizeNotificationUrl(data.url, store.farmacia.subdominio);
        console.info('Push notification resolved url', url);

        if (!url) {
            this._router.push({ name: 'Home' });
        } else if (isExternalUrl(url)) {
            window.open(url, '_blank'); // Abrir en navegador externo
        } else {
            const route = this._router.resolve(url);

            if (route.name === 'Error404') {
                console.warn(`La ruta de la notificación no existe: ${route}.`);
                return null; // Si la ruta no existe, se devuelve null para evitar mostrar 404 al usuario
            }

            this._router.push(route).catch(console.error);
        }

        // TODO: lo ideal aquí sería poder informarle al backend que la notificación ha sido
        // leída para que se actualice el badge. El problema es que actualmente no existe
        // ninguna API para notificar lectura de a una notificación a la vez.
        // Además al día de la fecha tampoco estamos enviando el ID de la notificación al
        // frontend, por lo que no podemos saber qué notificación se ha leído.
    }

    /**
     * Registra la app para recibir notificaciones push.
     *
     * @returns {Promise<void>}
     */
    async _registerNotifications() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    }

    /**
     * Envía el token push al servidor para que se pueda enviar notificaciones push al usuario.
     * Si el usuario no está logueado, se envía un token de invitado.
     *
     * @returns {Promise<boolean>}
     */
    async enviarTokenPush() {
        if (!this.tokenpush) return false;
        if (!store.farmacia) {
            throw new Error('No se ha vinculado ninguna farmacia');
        }

        const endpoint = store.isLogged ? '/push' : '/pushguest';

        await axios.post(endpoint, {
            idfarmacia: store.farmacia.numserie,
            token: this.tokenpush,
        });

        return true;
    }
}
