<template>
    <v-container>
        <h1 class="mb-4 text-h5 text-primary font-weight-bold">
            {{ $t('pedidoSingleView.detalleDePedido') }}
        </h1>

        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="obtenerPedido" />

        <div v-else-if="loading" class="text-center d-block ma-16">
            <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" size="70" />
        </div>

        <recurso-no-encontrado-card v-else-if="!pedido" />

        <pedido-card v-else :pedido="pedido" />
    </v-container>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import PedidoCard from '@/components/PedidoCard.vue';
import RecursoNoEncontradoCard from '@/components/RecursoNoEncontradoCard.vue';
import OopsAlert from '@/components/OopsAlert.vue';

export default {
    components: {
        RecursoNoEncontradoCard,
        PedidoCard,
        OopsAlert,
    },
    props: {
        idpedido: {
            type: [Number, String],
            required: true,
        },
    },
    data: () => ({
        /**
         * Error al cargar las notificaciones.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si se está cargando contenido.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * El pedido que se está visualizando.
         *
         * @type {Object|null}
         */
        pedido: null,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
    mounted() {
        this.obtenerPedido();
    },
    methods: {
        async obtenerPedido() {
            this.loading = true;
            this.error = null;

            try {
                const { data } = await axios.get(`/pedidos/${+this.idpedido}`);
                this.pedido = data[0];
            } catch (e) {
                this.error = e;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
