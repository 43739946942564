<template>
    <div>
        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />
        <v-card v-else-if="loading" class="mx-n4 mx-sm-0">
            <div class="text-center pa-16">
                <v-progress-circular indeterminate size="large" color="primary" />
            </div>
        </v-card>
        <div v-else>
            <v-card v-if="horarios.length > 0" :class="{'text-body-2': $vuetify.display.smAndDown}" class="mx-n4 mx-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
                <v-card-title>{{ $t('horario.horarioDeApertura') }}</v-card-title>
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                {{ $t('horario.dia') }}
                            </th>
                            <th class="text-left">
                                {{ $t('horario.franjasHorarias') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in horarios" :key="item.dia">
                            <td>{{ item.dia }}</td>
                            <td>{{ item.horario }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
            <v-card v-if="turnos.length > 0" class="mt-6 mt-0 mx-n4 mx-sm-0" :class="{'text-body-2': $vuetify.display.smAndDown}" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
                <v-card-title>{{ $t('horario.proximasGuardias') }}</v-card-title>
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                {{ $t('horario.desde') }}
                            </th>
                            <th class="text-left">
                                {{ $t('horario.hasta') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in turnos" :key="item.desde">
                            <td>{{ item.desde }}</td>
                            <td>{{ item.hasta }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
            <v-card v-if="festivos.length > 0" class="mt-6 mt-0 mx-n4 mx-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
                <v-card-title>{{ $t('horario.proximosFestivos') }}</v-card-title>
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                {{ $t('horario.desde') }}
                            </th>
                            <th class="text-left">
                                {{ $t('horario.hasta') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in festivos" :key="item.desde">
                            <td>{{ item.desde }}</td>
                            <td>{{ item.hasta }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import OopsAlert from '../../components/OopsAlert.vue';

export default {
    components: {
        OopsAlert,
    },
    data() {
        return {
            /**
             * Indica si se está cargando información del servidor.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Error al cargar la información del servidor.
             *
             * @type {Error|null}
             */
            error: null,

            /**
             * Los horarios de apertura.
             *
             * @type {{dia: string, horario: string}[]}
             */
            horarios: [],

            /**
             * Los turnos de guardia.
             *
             * @type {{desde: string, hasta: string}[]}
             */
            turnos: [],

            /**
             * Los festivos.
             *
             * @type {{desde: string, hasta: string}[]}
             */
            festivos: [],
        };
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.error = null;
            try {
                const { data } = await axios.get('/horarios');
                this.horarios = this.arreglarHorarios(data.horarios || []);
                this.turnos = this.arreglarTurnos(data.turnos || []);
                this.festivos = this.arreglarFestivos(data.festivos || []);
            }
            catch (error) {
                console.error(error);
                this.error = error;
            }
            this.loading = false;
        },
        /**
         * Devuelve true si el horario es todo el día.
         *
         * @param {{horadesde: string, horahasta: string}} horario
         * @returns {boolean}
         */
        esTodoElDia(horario) {
            return horario.horadesde === '00:00' && horario.horahasta === '23:59';
        },
        /**
         * Transforma un array de horarios pertenecientes a un único dia
         * de la semana en su representación en texto.
         *
         * @param {{horadesde: string, horahasta: string}[]} horarios
         * @returns {string}
         */
        horariosToString(horarios) {
            if (horarios.length === 0) {
                return this.$t('horario.cerrado');
            }
            const horariosTodoElDia = horarios.filter((horario) => this.esTodoElDia(horario));
            if (horariosTodoElDia.length === horarios.length) {
                return this.$t('horario.todoElDia');
            }
            return horarios.map((horario) => `${horario.horadesde} - ${horario.horahasta}`).join(' / ');
        },
        /**
         * Genera el array de horarios a partir de los datos de horarios del servidor.
         *
         * @param {any} horarios
         * @returns {{dia: string, horario: string}[]}
         */
        arreglarHorarios(horarios) {
            horarios = horarios.map((h) => ({
                key: h.dia.toUpperCase(),
                horadesde: h.horadesde.substring(0, 5),
                horahasta: h.horahasta.substring(0, 5),
            }));
            const dias = [
                { key: 'L', label: this.$t('horario.lunes') },
                { key: 'M', label: this.$t('horario.martes') },
                { key: 'X', label: this.$t('horario.miercoles') },
                { key: 'J', label: this.$t('horario.jueves') },
                { key: 'V', label: this.$t('horario.viernes') },
                { key: 'S', label: this.$t('horario.sabado') },
                { key: 'D', label: this.$t('horario.domingo') },
            ];
            return dias.map((dia) => {
                const horariosDelDia = horarios.filter((h) => h.key === dia.key);
                return {
                    dia: dia.label,
                    horario: this.horariosToString(horariosDelDia),
                };
            });
        },
        /**
         * Genera el array de turnos a partir de los datos de turnos del servidor.
         *
         * @param {any} turnos
         * @returns {{desde: string, hasta: string}[]}
         */
        arreglarTurnos(turnos) {
            return turnos.map((item) => ({
                desde: moment(item.fechahoradesde).format('DD/MM HH:mm'),
                hasta: moment(item.fechahorahasta).format('DD/MM HH:mm'),
            }));
        },
        /**
         * Genera el array de festivos a partir de los datos de festivos del servidor.
         *
         * @param {any} festivos
         * @returns {{desde: string, hasta: string}[]}
         */
        arreglarFestivos(festivos) {
            return festivos.map((item) => ({
                desde: moment(item.fechahoradesde).format('DD/MM HH:mm'),
                hasta: moment(item.fechahorahasta).format('DD/MM HH:mm'),
            }));
        },
    },
};
</script>
