<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-row>
                    <v-col class="text-center mt-4 mb-6">
                        <h1 class="text-primary text-h4 font-weight-bold mb-2">
                            {{ $t('loginView.bienvenido') }}
                        </h1>
                        <p class="text-medium-emphasis mx-auto" style="max-width: 75%;">
                            {{ $t('loginView.iniciaSesionParaObtenerMasBeneficios', { farmacia: farmacia.nombre}) }}
                        </p>
                    </v-col>
                </v-row>

                <v-sheet color="surface" elevation="1" rounded>
                    <oops-alert
                        v-if="error"
                        :error="error"
                        variant="flat"
                        class="rounded-b-0"
                        :titulo="$t('loginView.errorEnElLogin')" />

                    <v-alert v-if="mostrarRecaptcha && !consentimientoCookies" type="error" icon="mdi-cookie-alert" class="rounded-b-0">
                        {{ $t('registroView.atencionDebesActivar') }}
                        <template #append>
                            <v-btn variant="tonal" color="on-error" @click="aceptarTodas()">
                                {{ $t('registroView.aceptarTodas') }}
                            </v-btn>
                        </template>
                    </v-alert>

                    <v-form ref="loginForm" v-model="valid" :disabled="mostrarRecaptcha && !consentimientoCookies" class="pa-6" @submit.prevent="login">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    type="email"
                                    autocomplete="email"
                                    :rules="loginEmailRules"
                                    label="E-mail"
                                    required
                                    autocapitalize="off" />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    autocomplete="current-password"
                                    :append-icon="mostrarPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    :rules="[rules.required, rules.min]"
                                    :type="mostrarPassword ? 'text' : 'password'"
                                    autocapitalize="off"
                                    name="input-10-1"
                                    :label="$t('loginView.contrasena')"
                                    :hint="$t('loginView.alMenos8caracteres')"
                                    @click:append="mostrarPassword = !mostrarPassword" />
                            </v-col>
                            <v-col v-if="mostrarRecaptcha && consentimientoCookies">
                                <vue-recaptcha
                                    sitekey="6Le-IPkZAAAAABTpYJnhCu-Z2gE2jVESYwnhESz7"
                                    :load-recaptcha-script="consentimientoCookies"
                                    :language="$i18n.locale"
                                    style="height: 78px"
                                    @verify="recaptchaOk = true"
                                    @error="console.error($event)" />
                            </v-col>

                            <v-col cols="12">
                                <v-btn
                                    type="submit"
                                    block
                                    :disabled="!canSubmit"
                                    color="primary"
                                    size="large"
                                    :loading="loading">
                                    {{ $t('loginView.acceder') }}
                                </v-btn>
                                <v-btn to="/reestablecer-contrasena" color="primary" block variant="text" class="mt-2">
                                    {{ $t('loginView.olvideMiContrasena') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-divider />
                    <v-sheet color="secondary-surface" class="pa-6">
                        <v-row>
                            <v-col align-self="center">
                                <v-icon icon="mdi-chevron-double-right" />
                                {{ $t('loginView.noTienesUnaCuenta') }}
                            </v-col>
                            <v-col cols="auto">
                                <v-btn to="/registro" variant="outlined" color="on-secondary-surface" append-icon="mdi-chevron-right">
                                    {{ $t('loginView.registrarse') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { Capacitor } from '@capacitor/core';
import { store } from '@/store';
import OopsAlert from '../../components/OopsAlert.vue';

export default {
    components: {
        VueRecaptcha,
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Mensajes del chat.
         *
         * @type {object[]|null}
         */
        mensajes: null,

        /**
         * Indica si el formulario es válido.
         */
        valid: false,

        /**
         * El email del usuario.
         *
         * @type {string}
         */
        email: '',

        /**
         * La contraseña del usuario.
         *
         * @type {string}
         */
        password: '',

        /**
         * Indica si se debe mostrar la contraseña.
         *
         * @type {boolean}
         */
        mostrarPassword: false,

        /**
         * Indica si el usuario ha aceptado las cookies.
         *
         * @type {boolean}
         */
        consentimientoCookies: false,

        /**
         * Indica si el usuario ha pasado el recaptcha.
         *
         * @type {boolean}
         */
        recaptchaOk: false,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        mostrarRecaptcha() {
            return !Capacitor.isNativePlatform();
        },
        canSubmit() {
            return this.valid
                && this.consentimientoCookies
                && (!this.mostrarRecaptcha || this.recaptchaOk);
        },
        loginEmailRules() {
            return [
                (v) => !!v || this.$t('loginView.requerido'),
                (v) => /.+@.+\./.test(v) || this.$t('loginView.introduceUnEmailValido'),
            ];
        },
        rules(){
            return {
                required: (value) => !!value || this.$t('loginView.obligatorio'),
                min: (v) => (v && v.length >= 8) || this.$t('loginView.minimo8caracteres'),
            };
        },
    },
    created() {
        let activarAnaliticas = false;
        let activarPublicitarias = false;
        if (this.$cookies.isKey('cookiescfg')) {
            const analiticasPublicitarias = this.$cookies.get('cookiescfg').split(',').map((x) => x === '1');
            activarAnaliticas = analiticasPublicitarias[0];
            activarPublicitarias = analiticasPublicitarias[1];
        }
        if (!this.mostrarRecaptcha || (activarAnaliticas && activarPublicitarias)) {
            this.consentimientoCookies = true;
        }
    },

    methods: {
        validate() {
            return this.$refs.loginForm.validate()
                && (!this.mostrarRecaptcha || window.grecaptcha.getResponse());
        },
        async login() {
            this.error = null;
            if (!this.validate()) return;

            this.loading = true;

            try {
                await store.loginUser(this.email, this.password, window.grecaptcha?.getResponse());

                this.$router.push({
                    path: this.$route.query.redirect || '/',
                });
                await store.checkPuntos();

            } catch (error) {
                console.error(error);
                this.error = error;
                window.grecaptcha?.reset();
                window.scrollTo(0, 0); // Scrollear al top de la página para que se vea el error
            }

            this.loading = false;
        },
        aceptarTodas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '1,1');
            this.consentimientoCookies = true;
        },
    },
};
</script>
