/**
 * Clase base para implementar un EventEmitter.
 */
export default class EventEmitter {

    /**
     * @type {Map<string, function[]>}
     */
    _listeners = new Map();

    /**
     * Añade un listener para un evento.
     *
     * @param {string} event
     * @param {function} callback
     */
    addListener(event, callback) {
        if (!this._listeners.has(event)) {
            this._listeners.set(event, []);
        }

        this._listeners.get(event).push(callback);
    }

    /**
     * Emite un evento.
     *
     * @param {string} event
     * @param {any} data
     */
    emit(event, data) {
        if (!this._listeners.has(event))
            return;

        const callbacks = this._listeners.get(event);
        callbacks.forEach(callback => callback(data));
    }

    /**
     * Elimina un listener para un evento.
     *
     * @param {string} event
     * @param {function} callback
     */
    removeListener(event, callback) {
        if (!this._listeners.has(event))
            return;

        const listeners = this._listeners.get(event);
        const index = listeners.indexOf(callback);
        if (index > -1) {
            listeners.splice(index, 1);
        }
    }
}
