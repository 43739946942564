<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="d-flex justify-center align-center" style="height: 90vh">
                <v-card width="100%" class="pa-4">
                    <v-card-title class="text-h5 mb-8" style="word-break: normal">
                        <v-icon icon="mdi-card-account-details-star" class="mr-4" size="32" />
                        {{ nombreCompleto }}
                    </v-card-title>
                    <div class="text-center ma-4" @click="holded = true;">
                        <qrcode-vue :value="qrCodeValue" :size="200" level="M" />
                    </div>
                    <v-dialog v-model="holded">
                        <v-card>
                            <v-card-title class="text-primary text-h4">
                                UUID
                            </v-card-title>
                            <v-card-text class="text-secondary">
                                {{ qrCodeValue }}
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { store } from '@/store';
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
        QrcodeVue,
    },
    data: () => ({
        holded: false,
    }),
    computed: {
        nombreCompleto() {
            return `${store.user.name} ${store.user.apellidos}`;
        },
        qrCodeValue() {
            return store.user.uuid;
        },
    },
};
</script>
