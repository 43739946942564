<template>
    <v-text-field
        v-model="value"
        type="number"
        hide-details
        class="quantity-selector"
        :class="{'show-unit': showUnit}"
        :min="minValue"
        :max="maxValue"
        variant="outlined"
        color="primary"
        :suffix="showUnit ? $t('quantitySelector.unidadSufijo') : null"
        width="10em"
        density="compact"
        :single-line="true">
        <template #prepend>
            <v-btn
                icon="mdi-minus"
                :disabled="!canDecrement"
                density="compact"
                variant="tonal"
                color="primary"
                :aria-label="$t('quantitySelector.decrementar')"
                @click="value -= 1" />
        </template>
        <template #append>
            <v-btn
                icon="mdi-plus"
                :disabled="!canIncrement"
                density="compact"
                variant="tonal"
                color="primary"
                :aria-label="$t('quantitySelector.incrementar')"
                @click="value += 1" />
        </template>
    </v-text-field>
</template>

<script>
export default {
    props: {
        /**
         * Valor del campo en número de unidades.
         */
        modelValue: {
            type: [Number, String],
            default: 1,
        },
        /**
         * Mínimo número de unidades.
         */
        min: {
            type: [Number, String],
            default: 1,
        },
        /**
         * Máximo número de unidades. Si es null, no hay máximo.
         */
        max: {
            type: [Number, String],
            default: null,
        },
        /**
         * Indica si se muestra el sufijo de unidad.
         */
        showUnit: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        minValue() {
            return parseInt(this.min);
        },
        maxValue() {
            return this.max === null ? null : parseInt(this.max);
        },
        canDecrement() {
            return this.value > this.minValue;
        },
        canIncrement() {
            return this.maxValue === null || this.value < this.maxValue;
        },
        value: {
            get() {
                return parseInt(this.modelValue);
            },
            set(value) {
                if (value < this.minValue) {
                    value = this.minValue;
                }
                if (this.maxValue && value > this.maxValue) {
                    value = this.maxValue;
                }

                if (value !== this.modelValue) {
                    this.$emit('update:modelValue', value);
                }
            },
        },
    },
};

</script>

<style>
/* No puede ser scoped porque necesitamos alcanzar los elementos internos de Vuetify. Asi que prefijamos con el nombre del componente. */

.quantity-selector {
    /* Width = 2 * button width + text field width */
    max-width: 9em;
}
.quantity-selector.show-unit {
    width: auto;
}

.quantity-selector input {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
}

.quantity-selector.show-unit input {
    text-align: end;
    padding-left: 1em;
    padding-right: .25em;
}

/* Ajusta el margen excesivo entre los botones y el campo de texto. */
.quantity-selector .v-input__prepend {
    margin-inline-end: .5em !important;
}
.quantity-selector .v-input__append {
    margin-inline-start: .5em !important;
}

/* Remover el spinner de los campos de tipo number. */
/* Chrome, Safari, Edge, Opera */
.quantity-selector input::-webkit-outer-spin-button,
.quantity-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-selector input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>

