<template>
    <v-container>
        <v-row class="ma-1">
            <v-btn preprend-icon="mdi-arrow-left" x-large color="primary" @click="volver">
                {{ $t('volver') }}
            </v-btn>
            <v-spacer />
            <v-btn v-if="esWeb()" preprend-icon="mdi-printer" x-large color="primary" @click="imprimir">
                {{ $t('derechoDesistimiento.imprimir') }}
            </v-btn>
        </v-row>
        <div class="pb-12 pt-6 texto-legal">
            <h1>DERECHO DE DESISTIMIENTO</h1>
            <p>
                <strong>A la atención de:</strong>
            </p>
            <p>Nombre: {{ farmacia.rgpddenominacionsocial }}</p>
            <p>NIF: {{ farmacia.rgpdnif }}</p>
            <p>
                {{ farmacia.rgpddomiciliosocial }}
            </p>
            <p>
                {{ farmacia.telefono }}
            </p>
            <p>
                {{ farmacia.rgpdemail }}
            </p>
            <p>
                <strong>Datos del bien/prestación a desistir:</strong>
            </p>
            <p>N.º contrato/pedido/factura:</p>
            <p>Fecha contrato/pedido/factura:</p>
            <p>Fecha de recepción del producto/servicio:</p>
            <p>Descripción del producto/servicio:</p>
            <p>
                <strong>Datos del consumidor/usuario:</strong>
            </p>
            <p>Nombre:</p>
            <p>Domicilio:</p>
            <p>Población:</p>
            <p>* Teléfono:</p>
            <p>* E-mail:</p>
            <p>
                <em>(* datos no obligatorios)</em>
            </p>
            <h2>
                Derecho de desistimiento:
            </h2>
            <p>
                Podrá ejercer el derecho de desistimiento dentro del período de 14 días establecidos por ley a partir
                del día siguiente de la fecha de un contrato de servicios o del día de la recepción de un producto.
            </p>
            <p>
                De conformidad con el artículo 102 y siguientes del Real Decreto Legislativo 1/2007, de 16 de noviembre,
                por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y
                Usuarios y otras leyes complementarias, le comunico que desisto del contrato de venta del
                bien/prestación descrito anteriormente dentro del término establecido, por lo que agradecería se pusiese
                en contacto conmigo mediante los datos de contacto facilitados, para notificarme que se ha procedido a
                esta solicitud.
            </p>
            <p>Fecha de la solicitud:</p>
            <p>Firma del consumidor/usuario:</p>
        </div>
    </v-container>
</template>
<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
export default {
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
    methods: {
        esWeb() {
            return !Capacitor.isNativePlatform();
        },
        volver() {
            this.$router.back();
        },
        imprimir() {
            window.print();
        },
    },
};
</script>
