<template>
    <v-card :loading="loading" class="bg-surface" style="z-index: -1;">
        <div v-if="query?.length < minLength" class="text-center px-8 py-16 text-medium-emphasis">
            <v-icon icon="mdi-cart" size="50" />
            <p class="my-4">
                {{ $t('dialogBuscador.empiezaAEscribir') }}
            </p>
        </div>

        <div v-else-if="items.length === 0" class="text-center px-16 py-16 text-medium-emphasis">
            <v-icon icon="mdi-magnify" size="50" />
            <p class="my-4">
                {{ $t('dialogBuscador.sinResultados') }}
            </p>
        </div>

        <v-list v-else>
            <v-list-item v-for="(item, i) in items" :key="i" class="mx-0" :to="item.to">
                <template #prepend>
                    <v-avatar v-if="item.idcategoria" icon="mdi-shape" color="primary" />
                    <v-avatar v-else rounded="lg" :image="item.imagen" />
                </template>
                <v-list-item-title>{{ item.descripcion }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import axios from 'axios';
import imagenDefaultUrl from '../../../images/producto-default.jpg';

export default {
    props: {
        /**
         * El termino de búsqueda a mostrar sugerencias.
         */
        query: {
            type: String,
            default: '',
        },

        /**
         * El número mínimo de caracteres para mostrar sugerencias.
         */
        minLength: {
            type: Number,
            default: 2,
        },

    },
    emits: [
        'update:query',
        'update:loading',
    ],
    data() {
        return {
            /**
             * Indica si se está cargando sugerencias del servidor.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Las sugerencias de búsqueda.
             *
             * @type {object[]}
             */
            items: [],

            /**
             * Temporizador para disparar sugerencias
             *
             * @type {number}
             */

            temporizador: null,



        };
    },
    computed: {
        imagenDefaultUrl() {
            return imagenDefaultUrl;
        },
        loadingValue: {
            get() {
                return this.loading;
            },
            set(value) {
                this.loading = value;
                this.$emit('update:loading', value);
            },
        },

    },
    watch: {
        query() {
            this.reiniciarTemporizador();
        },
    },
    created() {
        this.fetchSugerenciasBusqueda();
    },
    methods: {
        async fetchSugerenciasBusqueda() {
            const q = this.query === null ? '' : this.query.trim();
            if (q.length < this.minLength) {
                this.items = [];
                return;
            }

            this.loadingValue = true;
            try {
                //se comprueba si el valor es un número y si tiene más de 5 caracteres, si es así se busca por idarticu
                if (q.length > 5 && !isNaN(q)) {
                    const { data } = await axios.post('/articulos', {
                        idarticu: q,
                    });

                    this.items = data.data.map(item => ({
                        ...item,
                        imagen: item.imagen?.ruta || imagenDefaultUrl,
                        to: this.getLinkTo(item),
                    }));

                }else{
                    const { data } = await axios.get(`/sugerenciasbusqueda/${q}`);
                    this.items = data.map(item => ({
                        ...item,
                        imagen: item.imagen?.ruta || imagenDefaultUrl,
                        to: this.getLinkTo(item),
                    }));
                }
            } catch (e) {
                // Si algo falla, nos comemos el error ya que no es algo crítico.
                console.error(e);
            } finally {
                this.loadingValue = false;
            }

            return;

        },
        getLinkTo(item) {
            if (item.idarticu) {
                return `/productos/${item.idarticu}`;
            } else if (item.idcategoria) {
                return `/categorias/${item.idcategoria}`;
            }

            return null;
        },

        reiniciarTemporizador() {
            // Reiniciamos el temporizador cada vez que se realiza una entrada
            if (this.temporizador) {
                clearTimeout(this.temporizador);
            }
            this.temporizador = setTimeout(() => {
                // Después de 2 segundos sin entrada, llamamos a fetchSugerenciasBusqueda()
                this.fetchSugerenciasBusqueda();
            }, 1000);
        },
    },
};
</script>
