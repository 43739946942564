<template>
    <v-select
        v-if="$vuetify.display.mdAndUp"
        v-model="ordenSeleccionado"
        class="align-center w-100"
        color="primary"
        return-object
        :items="ordenes"
        item-title="text"
        item-value="value"
        :hide-details="true"
        density="compact"
        variant="outlined">
        <template #prepend>
            <span class="font-weight-bold mt-n3 mr-2 align-center text-medium-emphasis">
                {{ $t('productosView.ordenarPor') }}
            </span>
        </template>
    </v-select>
    <v-menu v-else location="bottom">
        <template #activator="{props}">
            <v-btn icon="mdi-filter" size="x-small" variant="tonal" v-bind="props" />
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in ordenes"
                :key="index"
                :class="modelValue === item.value ? 'bg-grey-lighten-2 text-primary' : ''">
                <v-list-item-title @click="ordenSeleccionado = item">
                    {{ item.text }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { store } from '@/store';
import ArticuloOrden from '../enums/ArticuloOrden';

/**
 * Selector de ordenación de artículos. Utiliza el query string 'ordenar' para mantener el orden seleccionado en la URL.
 */
export default {

    props: {
        /**
         * El orden seleccionado.
         *
         * @property {ArticuloOrden} value
         */
        modelValue: {
            type: Number,
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data: () => ({}),

    computed: {
        /**
         * Obtiene los criterios de ordenación disponibles en el selector de ordenación.
         *
         * @returns {{text: string, value: number, slug: string}[]}
         */
        ordenes(){
            return [
                {
                    text: this.$t('productosView.novedades'),
                    value: ArticuloOrden.UPDATED_AT_DESC,
                    slug: 'novedades',
                },
                {
                    text: this.$t('productosView.nombreAlfabetico'),
                    value: ArticuloOrden.DESCRIPCION_ASC,
                    slug: 'alfabetico',
                },
                {
                    text: this.$t('productosView.nombreAlfabeticoDesc'),
                    value: ArticuloOrden.DESCRIPCION_DESC,
                    slug: 'alfabetico-desc',
                },
                {
                    text: this.$t('productosView.menorPrecio'),
                    value: ArticuloOrden.PVP_ASC,
                    slug: 'menor-precio',
                },
                {
                    text: this.$t('productosView.mayorPrecio'),
                    value: ArticuloOrden.PVP_DESC,
                    slug: 'mayor-precio',
                },
            ].filter(orden => {
                if (! store.mostrarPrecios) {
                    return orden.value !== ArticuloOrden.PVP_ASC && orden.value !== ArticuloOrden.PVP_DESC;
                }

                return true;
            });
        },

        /**
         * Obtiene el orden seleccionado.
         *
         * @returns {{text: string, value: number, slug: string}}
         */
        ordenSeleccionado: {
            get() {
                return this.ordenes.find(orden => orden.value === this.modelValue) || this.ordenes[0];
            },
            set(value) {
                this.$emit('update:modelValue', value.value);
            },
        },
    },

    watch: {
        ordenSeleccionado() {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    ordenar: this.ordenSeleccionado.slug,
                },
            });
        },
    },

    created() {
        const ordernar = this.$route.query.ordenar;
        this.ordenSeleccionado = ordernar ? this.ordenes.find(orden => orden.slug === ordernar) : this.ordenes[0];
    },
};
</script>
