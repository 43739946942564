<template>
    <v-checkbox color="primary" required :hide-details="true">
        <template #label>
            <div>
                <i18n-t scope="global" keypath="compraEnvioFacturacion.heLeidoYaceptoLosTycdu.text">
                    <template #url>
                        <a href="#/terminos-condiciones-uso" @click.stop>{{ $t('compraEnvioFacturacion.heLeidoYaceptoLosTycdu.link') }}</a>
                    </template>
                </i18n-t>
                <i18n-t scope="global" keypath="compraEnvioFacturacion.laPoliticaDePrivacidad.text">
                    <template #url>
                        <a href="#/politica-privacidad" @click.stop>{{ $t('compraEnvioFacturacion.laPoliticaDePrivacidad.link') }}</a>
                    </template>
                </i18n-t>
                <i18n-t scope="global" keypath="compraEnvioFacturacion.yLasCondicionesGeneralesDeContratacion.text">
                    <template #url>
                        <a href="#/condiciones-contratacion" @click.stop>{{ $t('compraEnvioFacturacion.yLasCondicionesGeneralesDeContratacion.link') }}</a>
                    </template>
                </i18n-t>
            </div>
        </template>
    </v-checkbox>
</template>
