<template>
    <div>
        <v-btn color="primary" class="mb-4 me-4" @click="aceptarTodas()">
            {{ $t('panelCookies.aceptarTodas') }}
        </v-btn>
        <v-btn color="primary" class="mb-4" @click="rechazarTodas()">
            {{ $t('cookiesBanner.rechazarCookies') }}
        </v-btn>
        <v-card class="mx-n4 mx-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
            <v-tabs v-model="tab" grow>
                <v-tab value="tecnicas-funcionales">
                    {{ $t('panelCookies.tecnicasYfuncionales') }}
                </v-tab>

                <v-tab value="analiticas">
                    {{ $t('panelCookies.analiticas') }}
                </v-tab>

                <v-tab value="publicitarias">
                    {{ $t('panelCookies.publicitarias') }}
                </v-tab>
            </v-tabs>
            <v-window v-model="tab" class="pa-4" :touch="false">
                <v-window-item value="tecnicas-funcionales">
                    <h2 class="text-subtitle-1 font-weight-bold">
                        {{ $t('panelCookies.cookiesTecnicasYFuncionalesSiempreActivas') }}
                    </h2>
                    <v-switch :model-value="true" disabled color="primary" />
                    <v-table>
                        <thead>
                            <tr>
                                <th v-for="campo in headers" :key="campo" class="text-left">
                                    {{ campo }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in tecnicasYfuncionales" :key="item.nombre">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.propiedad }}</td>
                                <td>{{ item.finalidad }}</td>
                                <td>{{ item.plazo }}</td>
                                <td>{{ item.observaciones }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-window-item>
                <v-window-item value="analiticas">
                    <h2 id="analiticas" class="text-subtitle-1 font-weight-bold">
                        {{ $t('panelCookies.cookiesAnaliticas') }}
                    </h2>
                    <v-switch v-model="activarAnaliticas" color="primary" />
                    <v-table>
                        <thead>
                            <tr>
                                <th v-for="campo in headers" :key="campo" class="text-left">
                                    {{ campo }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in analiticas" :key="item.nombre">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.propiedad }}</td>
                                <td>{{ item.finalidad }}</td>
                                <td>{{ item.plazo }}</td>
                                <td>{{ item.observaciones }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-alert type="info" color="primary-surface" class="mt-4 mx-n4 mb-n4 rounded-0">
                        <p>{{ $t('panelCookies.obtenerMasInformacion') }}</p>
                        <div class="text-right">
                            <v-btn to="/politica-privacidad" variant="tonal">
                                {{ $t('panelCookies.politicaDePrivacidadDeGoogle.link') }}
                            </v-btn>
                        </div>
                    </v-alert>
                </v-window-item>
                <v-window-item value="publicitarias">
                    <h2 id="publicitarias" class="text-subtitle-1 font-weight-bold">
                        {{ $t('panelCookies.cookiesPublicitarias') }}
                    </h2>
                    <v-switch v-model="activarPublicitarias" color="primary" />
                    <v-table>
                        <thead>
                            <tr>
                                <th v-for="campo in headers" :key="campo" class="text-left">
                                    {{ campo }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in publicitarias" :key="item.nombre">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.propiedad }}</td>
                                <td>{{ item.finalidad }}</td>
                                <td>{{ item.plazo }}</td>
                                <td>{{ item.observaciones }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>
<script>
export default {
    data: () => ({
        /**
         * Indica la pestaña activa.
         *
         * @type {'tecnicas-funcionales'|'analiticas'|'publicitarias'|null}
         */
        tab: null,

        /**
         * Indica si las cookies analíticas están activas.
         *
         * @type {boolean}
         */
        activarAnaliticas: false,

        /**
         * Indica si las cookies publicitarias están activas.
         *
         * @type {boolean}
         */
        activarPublicitarias: false,
    }),
    computed: {
        headers() {
            return ([
                this.$t('panelCookies.nombre'),
                this.$t('panelCookies.tipo'),
                this.$t('panelCookies.propiedad'),
                this.$t('panelCookies.finalidad'),
                this.$t('panelCookies.plazo'),
                this.$t('panelCookies.observaciones'),
            ]);
        },
        tecnicasYfuncionales() {
            return [
                {
                    nombre: 'SEARCH_SAMESITE',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com ',
                    finalidad: this.$t('panelCookies.sameSiteEvita'),
                    plazo: this.$t('panelCookies.xMeses', ['6']),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: 'CONSENT',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.rastreadorDeConsentimiento'),
                    plazo: this.$t('panelCookies.xAnos', ['2']),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: 'OGPC',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.utilizacionDeLasOpciones'),
                    plazo: this.$t('panelCookies.xDias', ['15']),
                    observaciones: this.$t('panelCookies.preferenciasYpersonalizacion'),
                },
                {
                    nombre: 'OGP',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.utilizacionDeLasOpciones'),
                    plazo: this.$t('panelCookies.xDias', ['15']),
                    observaciones: this.$t('panelCookies.preferenciasYpersonalizacion'),
                },
                {
                    nombre: this.$t('panelCookies.elNombreVaCambiandoEnCadaSesion'),
                    tipo: this.$t('panelCookies.propia'),
                    propiedad: '.farmaticapprox.es',
                    finalidad: this.$t('panelCookies.identificacionDeUnUsuario'),
                    plazo: this.$t('panelCookies.sesion'),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: 'approx_session',
                    tipo: this.$t('panelCookies.propia'),
                    propiedad: '.farmaticapprox.es',
                    finalidad: this.$t('panelCookies.identificacionDeUnUsuario'),
                    plazo: this.$t('panelCookies.sesion'),
                    observaciones: ' Técnica',
                },
                {
                    nombre: 'XSRF-TOKEN',
                    tipo: this.$t('panelCookies.propia'),
                    propiedad: '.farmaticapprox.es',
                    finalidad: this.$t('panelCookies.tokenDeSecurizacion'),
                    plazo: this.$t('panelCookies.sesion'),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: '_grecaptcha',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    inalidad: this.$t('panelCookies.proveeProteccionContraSpam'),
                    plazo: this.$t('panelCookies.sesion'),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: this.$t('panelCookies.elNombreVaCambiandoEnCadaSesion'),
                    tipo: this.$t('panelCookies.propia'),
                    propiedad: '.farmaticapprox.es',
                    finalidad: this.$t('panelCookies.identificacionDeUnUsuario'),
                    plazo: this.$t('panelCookies.sesion'),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
                {
                    nombre: 'cookiescfg',
                    tipo: this.$t('panelCookies.propia'),
                    propiedad: '.farmaticapprox.es',
                    finalidad: this.$t('panelCookies.configuracionDeUsuario'),
                    plazo: this.$t('panelCookies.xAnos', [1]),
                    observaciones: this.$t('panelCookies.tecnica'),
                },
            ];
        },
        analiticas() {
            return  [
                {
                    nombre: 'OTC',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.analiticaCookieQueIdentifica'),
                    plazo: this.$t('panelCookies.xDias', ['15']),
                    observaciones: this.$t('panelCookies.analitica'),
                },
            ];
        },
        publicitarias() {
            return [
                {
                    nombre: '__Secure-1PAPISID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', ['2']),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'SAPISID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', ['2']),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'APISID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', ['2']),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'HSID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', ['2']),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '__Secure-1PSID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [2]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'SID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [2]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '__Secure-3PSIDCC',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [1]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '__Secure-3PSID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [2]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'SSID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [2]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'SIDCC',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com ',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [1]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '__Secure-1PSIDCC',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [1]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '__Secure-3PAPISID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [2]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'NID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xMeses', [6]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: '1P_JAR',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xMeses', [1]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
                {
                    nombre: 'ANID',
                    tipo: this.$t('panelCookies.deTerceros'),
                    propiedad: '.google.com',
                    finalidad: this.$t('panelCookies.publicitariaMideElRendimiento'),
                    plazo: this.$t('panelCookies.xAnos', [1]),
                    observaciones: this.$t('panelCookies.publicitaria'),
                },
            ];
        },
    },
    watch: {
        activarAnaliticas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', +this.activarAnaliticas + ',' + +this.activarPublicitarias);
        },
        activarPublicitarias() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', +this.activarAnaliticas + ',' + +this.activarPublicitarias);
        },
    },
    mounted() {
        let analiticasPublicitarias;
        if (this.$cookies.isKey('cookiescfg')) {
            analiticasPublicitarias = this.$cookies.get('cookiescfg').split(',');
            this.activarAnaliticas = analiticasPublicitarias[0] === '1';
            this.activarPublicitarias = analiticasPublicitarias[1] === '1';
        }
    },
    methods: {
        aceptarTodas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '1,1');
            this.$router.back();
        },
        rechazarTodas() {
            this.$cookies.keys().forEach((cookie) => {
                if (cookie !== 'XSRF-TOKEN' && (!cookie.endsWith('_session')))
                {
                    this.$cookies.remove(cookie);
                }
            });
            this.$router.back();
        },
    },
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: var(--v-tertiary-base) !important;
}
</style>
