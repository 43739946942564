<template>
    <v-footer
        v-if="mostrar"
        class="d-flex flex-column py-8 pb-2 w-100 bg-background px-0">
        <div v-if="enlacesSociales.length" class="text-center mb-2">
            <div class="subheading text-subtitle-1 font-weight-bold mb-2">
                {{ $t("pieSocial.siguenosEnRedes") }}
            </div>
            <v-btn
                v-for="enlace in enlacesSociales"
                :key="enlace.url"
                class="mx-2 mx-md-4"
                :href="enlace.url"
                target="_blank"
                variant="elevated"
                color="surface"
                icon
                :aria-label="enlace.title"
                :title="enlace.title">
                <v-icon
                    :icon="enlace.icono"
                    size="24px"
                    :color="enlace.color" />
            </v-btn>
        </div>
        <template v-if="enlacesContacto.length > 0">
            <div
                v-if="$vuetify.display.smAndUp"
                class="mt-6 mb-6 text-md-body-2">
                <v-btn
                    v-for="enlace in enlacesContacto"
                    :key="enlace.texto"
                    variant="text"
                    class="no-uppercase"
                    color="on-background"
                    :href="enlace.link"
                    :target="enlace.target"
                    :aria-label="enlace.title"
                    :prepend-icon="enlace.icono"
                    :title="enlace.title">
                    {{ enlace.texto }}
                </v-btn>
            </div>
            <v-list v-else class="mt-6 mb-6 mx-n10 w-100 bg-transparent">
                <v-list-item
                    v-for="(enlace, i) in enlacesContacto"
                    :key="i"
                    class="py-2"
                    :href="enlace.link"
                    :target="enlace.target"
                    :aria-label="enlace.title"
                    :prepend-icon="enlace.icono"
                    :title="enlace.texto"
                    :subtitle="enlace.title" />
            </v-list>
        </template>
    </v-footer>
</template>

<script>
import { store } from '@/store';
import { colorSocial } from '../../support/ColorSocial';

export default {
    computed: {
        farmacia() {
            return store.farmacia;
        },
        mostrar() {
            return (
                this.enlacesSociales.length > 0 ||
                this.enlacesContacto.length > 0
            );
        },
        enlacesContacto() {
            if (this.farmacia === null) {
                return [];
            }

            const enlacesDefault = [
                {
                    icono: 'mdi-map-marker',
                    texto: this.farmacia.direccion,
                    link: `https://www.google.com/maps/search/?api=1&query=${this.farmacia.direccion}`,
                    target: '_blank',
                },
                {
                    icono: 'mdi-phone',
                    texto: this.farmacia.telefono,
                    link: `tel:${this.farmacia.telefono}`,
                    target: '_self',
                },
                {
                    icono: 'mdi-cellphone',
                    texto: this.farmacia.movil,
                    link: `tel:${this.farmacia.movil}`,
                    target: '_self',
                },
                {
                    icono: 'mdi-email',
                    texto: this.farmacia.email,
                    link: `mailto:${this.farmacia.email}`,
                    target: '_blank',
                },
                {
                    icono: 'mdi-whatsapp',
                    texto: this.farmacia.whatsapp,
                    link: `https://api.whatsapp.com/send?phone=${this.farmacia.whatsapp}`,
                    target: '_blank',
                },
                {
                    icono: 'mdi-web',
                    texto: 'Web',
                    link: this.farmacia.web,
                    target: '_blank',
                },
            ];

            const enlacesExtra = this.farmacia.enlaces
                .filter((enlace) => enlace.iconslug === null)
                .map((enlace) => ({
                    icono: 'mdi-open-in-new',
                    texto: enlace.titulo,
                    link: enlace.url,
                    target: '_blank',
                    title: this.$t('pieSocial.abrir', { red: enlace.titulo }),
                }));

            return enlacesDefault
                .concat(enlacesExtra)
                .filter((enlace) => enlace.texto && enlace.link);
        },
        enlacesSociales() {
            if (this.farmacia === null) {
                return [];
            }

            return this.farmacia.enlaces
                .filter((enlace) => enlace.iconslug)
                .map((enlace) => ({
                    ...enlace,
                    icono: enlace.iconslug
                        ? 'mdi-' + enlace.iconslug
                        : 'mdi-web',
                    color: colorSocial(enlace.iconslug),
                    title: this.$t('pieSocial.abrir', { red: enlace.titulo }),
                }));
        },
    },
};
</script>
<style scoped>
.no-uppercase {
    text-transform: unset !important;
    text-decoration: none;
}
</style>
