<template>
    <v-breadcrumbs density="compact" color="primary" :items="breadcrumbItems" class="font-weight-bold">
        <template #divider>
            <v-icon size="x-small" icon="mdi-chevron-right" style="opacity: 0.6;" />
        </template>
        <template #title="{ item }">
            <v-icon
                v-if="item.icon"
                :icon="item.icon"
                size="1.2em"
                :aria-label="item.iconLabel" />
            <span v-if="item.title" :class="{'ml-2': item.icon}">
                {{ item.title }}
            </span>
        </template>
    </v-breadcrumbs>
</template>

<script>
/**
 * Este componente es un wrapper de Vuetify Breadcrumbs que además
 * permite definir un ícono para cada item.
 * La propiedad `items.*.icon` debe ser un string con el nombre del ícono,
 * y la propiedad `items.*.iconLabel` debe ser un string con el label del ícono
 * usado para la accesibilidad.
 *
 * Comportamiento de la barra con respecto a la cantidad de items:
 * DISPLAY WEB
 * - Si hay 5 o menos items, se muestran todos.
 * - Si hay más de 5 items, se muestra el primer item, luego "..." y los dos últimos items.
 * DISPLAY MÓVIL
 * - Si hay 3 o menos items, se muestran todos.
 * - Si hay más de 3 items, se muestra el primer item, luego "..." y los dos últimos items.
 */
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        //
    }),
    computed: {
        breadcrumbItems() {
            if (this.items.length <= this.maxShownItems) {
                return this.items.map(item => ({
                    ...item,
                    icon: item.icon || null,
                    iconLabel: item.iconLabel || null,
                    to: item.to || null,
                    title: item.title || null,
                }));
            } else {
                const lastItems = this.items.slice(-2); // Obtener los dos últimos elementos
                return [
                    // Item Icono de la casa
                    {
                        ...this.items[0],
                        icon: this.items[0].icon || null,
                        iconLabel: this.items[0].iconLabel || null,
                    },
                    // Item "..."
                    { title: '...', icon: null, iconLabel: null },
                    // Últimos dos elementos del array
                    ...lastItems.map(item => ({
                        ...item,
                        icon: item.icon || null,
                        iconLabel: item.iconLabel || null,
                        to: item.to || null,
                        title: item.title || null,
                    })),
                ];
            }
        },
        maxShownItems() {
            return this.$vuetify.display.mdAndUp ? 5 : 3;
        },
    },
};
</script>
