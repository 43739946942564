
/**
 * Clase que contiene métodos de utilidad para cadenas de texto en Approx.
 * Todos los métodos de convertir a mayúsculas o minúsculas solo convierten
 * la capitalización en caso de que la cadena esté totalmente en mayúsculas.
 *
 * Esto es asi porque los farmaceuticos suelen escribir los strings todo en mayusculas.
 * Sin embargo existen farmacias que capitalizan correctamente los strings, por eso
 * en ese caso se preserva la capitalización original.
 */
export default class StringHelper {

    /**
     * Expresión regular que define los caracteres permitidos en los campos de texto
     */
    static regex = 'A-ZÑÁËÍÓÚÜa-zñáéíóúü0-9;._*$"&!(){}/-';

    /**
     * Determina si una cadena está totalmente en mayúsculas (screaming case).
     *
     * @param {string?} str
     * @returns {boolean}
     */
    static isScreaming(str) {
        return (! str) ? false : str.toLocaleUpperCase() === str;
    }

    /**
     * Determina si una cadena está totalmente en minúsculas.
     *
     * @param {string?} str
     * @returns {boolean}
     */
    static isLowerCase(str) {
        return (!str) ? false : str.toLocaleLowerCase() === str;
    }

    /**
     * Convierte una cadena a "Formato de oración", preservando la capitalización de las palabras
     * cuando sea conveniente.
     *
     * Ejemplo:
     * "hola mundo" => "Hola mundo"
     * "HOLA MUNDO" => "Hola mundo"
     * "Hola MUNdo" => "Hola MUNdo"
     * "HOLA MUNDo" => "HOLA MUNDo"
     *
     * @param {string?} str
     * @returns {string}
     */
    static sentenceCase(str) {
        str = StringHelper.trimToNull(str);

        if (!StringHelper.isScreaming(str) && !StringHelper.isLowerCase(str)) {
            return str;
        }

        return str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase();
    }

    /**
     * Convierte una cadena a "Formato De Título", preservando la capitalización de las palabras
     * cuando sea conveniente.
     *
     * Ejemplo:
     * "hola mundo" => "Hola Mundo"
     * "HOLA MUNDO" => "Hola Mundo"
     * "Hola MUNdo" => "Hola MUNdo"
     * "HOLA MUNDo" => "HOLA MUNDo"
     *
     * @param {string?} str
     * @returns {string}
     */
    static titleCase(str) {
        str = StringHelper.trimToNull(str);

        if (!StringHelper.isScreaming(str) && !StringHelper.isLowerCase(str)) {
            return str;
        }

        return str.split(' ').map(word => {
            return word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase();
        }).join(' ');
    }

    /**
     * Trimea una cadena, dejando null si la cadena resultante es vacía.
     *
     * @param {string|null} str
     * @returns {string|null}
     */
    static trimToNull(str) {
        return str?.trim() || null;
    }

    /**
     * Filtra los caracteres especiales de una cadena definidos por @param regex
     * @param {string} query Datos de entrada que se van a comprobar
     * @returns {string} query Datos corregida
     */
    static filtraCaracteresEspeciales(query) {

        var regexFinal = new RegExp(this.regex);
        if (!regexFinal.test(query)) {
            var regexToClean = new RegExp('[^' + this.regex + '\\s]', 'g');
            query = query.replace(regexToClean, '');
        }
        return query;
    }

}
