<template>
    <div>
        <div class="d-flex align-center mb-4 text-h6">
            <v-icon icon="mdi-note-text" class="mr-4" />
            {{ $t('compraEnvioFacturacion.observacionesDePedido') }}
        </div>

        <v-textarea
            :model-value="modelValue"
            name="observaciones"
            :placeholder="$t('compraEnvioFacturacion.cualquierComentario')"
            rows="2"
            auto-grow
            :counter="modelValue.length > 150"
            variant="solo"
            validate-on="input"
            :single-line="true"
            maxlength="255"
            @update:model-value="$emit('update:modelValue', $event)" />
    </div>
</template>

<script>

export default {
    props: {
        /**
         * Valor del campo.
         *
         * @type {String}
         */
        modelValue: {
            type: String,
            default: '',
        },
    },

    emits: ['update:modelValue'],
};
</script>
