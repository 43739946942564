<template>
    <v-container>
        <v-row>
            <v-col>
                <oops-alert v-if="error" :error="error" />
                <v-card class="pa-4 mt-n4 mx-n4 mx-sm-0 mt-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
                    <v-card-title class="d-flex mb-4">
                        <span>{{ $t('configuracion.opcionesDePrivacidad') }}</span>
                        <v-spacer />
                        <v-progress-circular
                            v-if="loading"
                            indeterminate
                            color="primary"
                            size="24"
                            class="ml-4" />
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-checkbox
                                v-model="notificacionescomerciales"
                                :label="textoNotificaciones"
                                :hide-details="true"
                                class="mt-0 pt-0" />
                        </v-row>
                        <v-row>
                            <v-checkbox
                                v-model="facturaporemail"
                                :label="textoFacturaEmail"
                                :hide-details="true"
                                class="mt-0 pt-0" />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="esWeb" class="mt-4">
            <v-col>
                <div class="text-h5 mb-4">
                    {{ $t('configuracion.cookiesUtilizadasEnEsteSitioWeb') }}
                </div>
                <cookies-panel />
            </v-col>
        </v-row>

        <v-card class="mt-4 px-4 pb-4 mx-n4 mx-sm-0" :rounded="$vuetify.display.smAndUp ? 'lg' : 0">
            <v-card-title class="mb-3">
                {{ $t('configuracion.informacionLegal') }}
            </v-card-title>
            <v-card-text>
                <v-row>
                    <router-link to="/terminos-condiciones-uso">
                        {{ $t('linksLegal.terminosYcondicionesDeUso.link') }}
                    </router-link>
                </v-row>
                <v-row>
                    <router-link to="/politica-privacidad">
                        {{ $t('linksLegal.politicaDePrivacidad.link') }}
                    </router-link>
                </v-row>
                <v-row v-if="esWeb">
                    <router-link to="/politica-cookies">
                        {{ $t('linksLegal.politicaDeCookies.link') }}
                    </router-link>
                </v-row>
                <v-row>
                    <router-link to="/condiciones-contratacion">
                        {{ $t('linksLegal.condicionesGeneralesDeContratacion.link') }}
                    </router-link>
                </v-row>
                <v-row>
                    <router-link to="/derecho-desistimiento">
                        {{ $t('linksLegal.formularioDerechoDesistimiento.link') }}
                    </router-link>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { store } from '@/store';
import CookiesPanel from '../components/CookiesPanel.vue';
import { Capacitor } from '@capacitor/core';
import OopsAlert from '../components/OopsAlert.vue';

export default {
    components: {
        CookiesPanel,
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si se aceptan las notificaciones comerciales.
         *
         * @type {boolean}
         */
        notificacionescomerciales: store.user.notificacionescomerciales,

        /**
         * Indica si se acepta recibir la factura por email.
         *
         * @type {boolean}
         */
        facturaporemail: store.user.facturaporemail,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        linkTextoLegal() {
            return `/${this.farmacia.numserie}#/politica-privacidad`;
        },
        textoNotificaciones() {
            return this.$t('configuracion.aceptoElEnvio');
        },
        textoFacturaEmail() {
            return this.$t('configuracion.quieroRecibirLaFactura');
        },
        esWeb() {
            return !Capacitor.isNativePlatform();
        },
    },
    watch: {

        async notificacionescomerciales(value) {
            if (value === store.user.notificacionescomerciales) return;

            await this.guardarDatos({
                notificacionescomerciales: value,
            });
        },
        async facturaporemail(value) {
            if (value === store.user.facturaporemail) return;

            await this.guardarDatos({
                facturaporemail: value,
            });
        },
    },
    methods: {
        async guardarDatos(datos) {
            this.error = null;
            this.loading = true;
            try {
                await store.updateOpcionesRgpd(datos);
            } catch (error) {
                this.error = error;
            }
            this.loading = false;
        },
    },
};
</script>
