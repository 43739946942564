<template>
    <articulo-agregado-dialog :articulo="articuloAgregado" />
    <v-card
        v-if="mostrarImagen"
        :height="imageHeight"
        :width="imageWidth"
        elevation="10"
        :to="{ name: 'Articulo', params: { idarticu: articulo.idarticu } }">
        <template #image>
            <v-img
                :src="articulo.imagen"
                :alt="articulo.descripcion"
                :height="imageHeight"
                cover
                :width="imageWidth"
                :gradient="`to bottom, rgba(255,255,255,0), ${$vuetify.theme.themes.light.colors['on-primary']}`" />
        </template>
        <template #default>
            <v-card-text class="d-flex h-100">
                <v-row no-gutters>
                    <v-col cols="12" align-self="start" class="pa-0 ma-0">
                        <articulo-card-chips v-if="$vuetify.display.mdAndUp" :articulo="articulo" :stockcesta="Number(stockcesta)" :no-disponible="disponible" :porcentaje-aplicado="porcentaje" />
                    </v-col>
                    <v-col cols="12" align-self="end" style="font-size: 1.2rem;" class="font-weight-bold text-primary">
                        {{ articulo.descripcion }}
                        <the-precio v-if="muestraPrecioFinal" class="mt-2" :precio="precio" :preciotachado="preciotachado" @porcentaje="calculaPorcentaje" />
                    </v-col>
                </v-row>
            </v-card-text>
        </template>
    </v-card>
    <v-card
        v-else
        :width="imageWidth"
        hover
        :height="imageHeight"
        elevation="10"
        :to="{ name: 'Articulo', params: { idarticu: articulo.idarticu } }">
        <articulo-card-chips v-if="$vuetify.display.mdAndUp" :articulo="articulo" :stockcesta="Number(stockcesta)" :no-disponible="disponible" :porcentaje-aplicado="porcentaje" />
        <v-card-text>
            <v-row>
                <v-card-text style="font-size: 1.2rem;" class="font-weight-bold text-primary">
                    {{ articulo.descripcion }}
                </v-card-text>
                <v-col v-if="muestraPrecioFinal" cols="12" class="mb-4">
                    <the-precio :precio="precio" :preciotachado="preciotachado" @porcentaje="calculaPorcentaje" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import ArticuloAgregadoDialog from '../views/articulo/ArticuloAgregadoDialog.vue';
import HeartbeatService from '../support/HeartbeatService';
import ArticuloCardChips from './ArticuloCardChips.vue';
import imagenDefault from '../../images/producto-default.jpg';
import EurosHelper from '../support/EurosHelper';
import ThePrecio from './ThePrecio.vue';
import { store } from '@/store';

export default {
    components: {
        ArticuloCardChips,
        ArticuloAgregadoDialog,
        ThePrecio,
    },
    props: {
        articulo: {
            type: Object,
            required: true,
        },
        mostrarPrecio: {
            type: Boolean,
            default: true,
        },
        mostrarImagen:{
            type: Boolean,
            default: true,
        },
    },
    emits: ['error'],
    data() {
        return {
            /**
               * Indica si se estan enviando los datos al servidor para agregar el articulo a la cesta.
               *
               * @type {boolean}
               */
            agregarACestaLoading: false,

            /**
                * Error al agregar el articulo a la cesta.
                *
                * @type {Error|null}
                */

            agregarACestaError: null,
            /**
                * Error al agregar el articulo a la cesta.
                *
                * @type {boolean}
                */
            disableCardRedirection: false,
            /**
                * El ultimo articulo que se ha agregado a la cesta.
                *
                * @type {{ idarticu: string, cantidad: number, descripcion: string, imagen: string, pvp: number }}
                */
            articuloAgregado: null,
            flipped: false,
            porcentaje: 0,
        };
    },
    computed: {
        imagenDefault() {
            return imagenDefault;
        },
        precio() {
            if (this.articulo.importeOferta /*&& this.articulo.importeOferta < this.articulo.pvp*/)
            {
                return EurosHelper.format(this.articulo.importeOferta);
            }
            return EurosHelper.format(this.articulo.pvp);
        },
        /**
         * Indica si se debe mostrar el cartel de "No hay más articulos".
         * @type {boolean}
         */
        disponible() {
            let res = this.articulo.noDisponible;

            if (this.articulo.dispensasinstock || Number(this.articulo.dispensasinstock) === 1)
            {
                return res;
            }

            if (Number(this.stockcesta) >= Number(this.articulo.stockactual)) {
                res = true;
            }
            return res;

        },
        /**
             * Indica si se debe mostrar el botón de agregar a la cesta
             * segun la configuración del ecommerce.
             * @returns {boolean}
             */
        mostrarBoton() {
            return store.ecommerceHabilitado;
        },
        stockcesta(){
            if (!store.isLogged)
                return 0;
            let stockcesta = 0;
            if (!store.cesta.lineas) {
                return 0;
            }
            let articuCesta = store.cesta.lineas.find((linea) => linea.idarticu === this.articulo.idarticu) ?? false;
            if (articuCesta) {
                stockcesta = articuCesta.cantidad;
            } else {
                stockcesta = 0;
            }
            return stockcesta;
        },
        imageWidth() {
            return {
                xs: '200px',
                sm: '250px',
                md: '300px',
            }[this.$vuetify.display.name] || '300px';
        },
        imageHeight() {
            if(!this.mostrarImagen)
                return {
                    xs: '175px',
                    sm: '175px',
                    md: '175px',
                }[this.$vuetify.display.name] || '175px';
            else
                return {
                    xs: '300px',
                    sm: '300px',
                    md: '300px',
                }[this.$vuetify.display.name] || '300px';
        },
        preciotachado() {
            if (this.articulo.importeOferta && this.articulo.importeOferta < this.articulo.pvp)
            {
                return EurosHelper.format(this.articulo.pvp);
            }
            else
                return null;
        },
        muestraPrecioFinal(){
            if(!this.disponible && this.mostrarPrecio)
                return true;
            else
                return false;
        },
    },
    methods: {
        async agregarACesta(e) {
            //Se evita que reaccione al evento del padre
            e.preventDefault();
            if (!store.isLogged) {
                this.$router.push({
                    name: 'Login',
                    query: { redirect: this.$route.fullPath },
                });
                return;
            }
            this.disableCardRedirection = true;
            this.agregarACestaLoading = true;
            this.agregarACestaError = null;
            this.articuloAgregado = null;

            try {
                await axios.post('/cesta', {
                    idarticu: this.articulo.idarticu,
                    cantidad: 1,
                });

                this.articuloAgregado = {
                    ...this.articulo,
                    cantidad: 1,
                };
            } catch (error) {
                console.error(error);
                this.agregarACestaError = error;
                this.$emit('error', this.agregarACestaError);
            } finally {
                this.agregarACestaLoading = false;
                this.disableCardRedirection = false;
                this.flipCard();
            }

            if (this.agregarACestaError === null) {
                HeartbeatService.instance.heartbeat();
            }
        },
        calculaPorcentaje(porcentaje) {
            this.porcentaje = porcentaje;
        },
    },
};
</script>

<style scoped>
.titulo {
    font-weight: normal;
    font-size: 1.1rem;
    word-break: normal;
    line-height: 1.25em;
    display: block;

    text-overflow: ellipsis;
    /* Multiline ellipsis for webkit (Support: https://caniuse.com/mdn-css_properties_-webkit-line-clamp)*/
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* Multiline ellipsis for Opera */
    text-overflow: -o-ellipsis-lastline;
    /* Standard */
    line-clamp: 3;
    /* Max height back up for non-supporting browsers (3 lines * line-height) */
    max-height: calc(1.25em * 3);
}

</style>
