<template>
    <v-container v-if="!analizando" class="h-100 position-relative">
        <v-row>
            <v-btn
                v-if="isTorchAvailable"
                color="primary"
                icon="mdi-flashlight"
                class="ma-3"
                @click="toggleTorch" />
            <v-col cols="12" class="d-flex justify-center align-center">
                <p class="text-center info-text">
                    {{ !$route.meta.isArticuloFind ? $t('lectorQrView.escaneaElCodigoQr') : $t('lectorQrView.escaneaArticulo') }}
                </p>
            </v-col>
        </v-row>
        <v-btn
            v-if="isTorchAvailable"
            color="primary"
            location="bottom"
            position="fixed"
            class="mb-5"
            icon="mdi-arrow-left"
            @click="$router.back()" />
        <div ref="overlaySquare" class="overlay-square" />
    </v-container>
    <v-container v-else class="h-100 d-flex align-center justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            size="64"
            class="ma-5" />
    </v-container>
</template>

<script>
import { BarcodeScanner, BarcodeFormat} from '@capacitor-mlkit/barcode-scanning';
import { Dialog } from '@capacitor/dialog';
import { store } from '../store';
import axios from 'axios';


export default {
    name: 'BarcodeScanning',
    data() {
        return {
            isTorchAvailable: false,
            scanTimeout: null,
            tiempoEspera: 500, 
            focusedBarcode: null,
            analizando: false,
        };
    },
    async mounted() {
        if (!await this.didUserGrantPermission()) {
            console.info('Permiso de cámara denegado');
            this.$router.back();
        }
        await BarcodeScanner.isTorchAvailable().then((result) => {
            this.isTorchAvailable = result.available;
        });
        await this.startScan();
    },
    beforeUnmount() {
        this.stopScan();
    },
    methods: {
        /**
         * Inicia el escaneo de códigos de barras.
         * 
         * @returns {Promise<void>} Promesa que se resuelve cuando se ha iniciado el escaneo.
         * @throws {Dialog} - Notifica al usuario si no se ha podido iniciar el escaneo.
         */
        async startScan() {
            this.hideBackground();
            this.formats = [BarcodeFormat.QrCode, BarcodeFormat.DataMatrix, BarcodeFormat.Ean13];
            const options = {
                formats: this.formats,
                lensFacing: this.lensFacing,
            };

            await BarcodeScanner.addListener('barcodeScanned', this.barcodeScanned);

            await BarcodeScanner.startScan(options);
        },
        /**
         * Oculta el fondo de la aplicación.
         * 
         * @returns {void}
         */
        hideBackground(){
            document.body.classList.add('barcode-scanning-active');
            const html = document.querySelector('html');
            if(html){
                html.style.background = 'transparent';
            }
            const app = document.querySelector('#app');
            if(app){
                app.style.background = 'transparent';
            }
            const vApp = document.querySelector('.v-application');
            if(vApp){
                vApp.style.background = 'transparent';
            }
        },
        /**
         * Muestra el fondo de la aplicación.
         * 
         * @returns {void}
         */
        showBackground(){
            document.body.classList.remove('barcode-scanning-active');

            const html = document.querySelector('html');
            if(html){
                html.style.background = undefined;
            }
            const app = document.querySelector('#app');
            if(app){
                app.style.background = undefined;
            }
            const vApp = document.querySelector('.v-application');
            if(vApp){
                vApp.style.background = undefined;
            }
        },
        /**
         * Detiene el escaneo de códigos de barras.
         * 
         * @returns {Promise<void>} Promesa que se resuelve cuando se ha detenido el escaneo.
         */
        async stopScan() {
            this.showBackground();
            await BarcodeScanner.stopScan();
        },
        /**
         * Activa o desactiva la linterna.
         * 
         * @returns {Promise<void>} Promesa que se resuelve cuando se ha cambiado el estado de la linterna.
         */
        async toggleTorch() {
            await BarcodeScanner.toggleTorch();
        },
        /**
         * Comprueba si el usuario ha concedido permisos para la cámara.
         *
         * @returns {Promise<boolean>} true si el usuario ha concedido permisos, false en caso contrario.
         */
        async didUserGrantPermission() {
            // se comprueba si el usuario ha concedido permisos
            const status = await BarcodeScanner.checkPermissions();
            
            if (status.camera === 'granted') { // granted=true, por lo que ya está concedido
                return true;
            }
            if (status.camera === 'prompt') { // prompt=true, por lo que se solicitará permiso
                const statusRequest = await BarcodeScanner.requestPermissions();
                return statusRequest.camera === 'granted';
            }
            if (status.camera === 'prompt-with-rationale' || status.camera === 'denied') { // prompt-with-rationale=true, por lo que se solicitará permiso
                // se redirige al usuario a la configuración de la aplicación si desea concederlo de todos modos
                const openConfig = await this.askQuestion('Escanear QR', [
                    this.$t('lectorQrView.necesitamosTuPermiso'),
                    this.$t('lectorQrView.puedesConcederElPermiso'),
                ].join('\n'), this.$t('lectorQrView.abrirConfiguracion'), this.$t('lectorQrView.cancelar'));
                if (!openConfig) {
                    await this.showMessage(this.$t('lectorQrView.siCambiasDeOpinion'));
                    return false;
                }
                await BarcodeScanner.openSettings();
            }

            // unknown status por lo que se solicitará permiso
            const statusRequest = await BarcodeScanner.checkPermissions(); 
            return statusRequest.camera === 'granted';
        },
        /**
         * Analiza el código de barras escaneado.
         * 
         * @param {event} event - Evento capturado por el listener de barcodeScanned.
         * @throws {Dialog} - Notifica al usuario si el código escaneado no es válido.
         */
        async barcodeScanned(event) {
            this.repintaCuadrado(event);
            //Si cambia el codigo de barras leido por el scanner, se reinicia el contador
            if(this.focusedBarcode !== event.barcode.rawValue){
                this.focusedBarcode = event.barcode.rawValue;
                clearTimeout(this.scanTimeout);
                this.scanTimeout = null;
            }
            // Puedes seguir usando la lógica existente para procesar el código de barras
            if (this.scanTimeout === null) {
                this.scanTimeout = setTimeout(async() => {
                    this.analizando = true; 
                    //Quitamos los listeners una vez que se ha procesado el código
                    BarcodeScanner.removeAllListeners();
                    await this.processBarcode(event.barcode.rawValue);
                    this.scanTimeout = null;
                }, this.tiempoEspera);
            }
            
        },
        /**
         * Repinta el cuadrado del overlay encima del codigo que esta escaneando.
         * 
         * @param {event} event - Evento capturado por el listener de barcodeScanned.
         */
        async repintaCuadrado(event) {
            const overlaySquare = this.$refs.overlaySquare;
            const cornerPoints = event.barcode.cornerPoints;

            // Extraer coordenadas x e y de los puntos del código de barras
            const xCoords = cornerPoints.map(point => point[0]);
            const yCoords = cornerPoints.map(point => point[1]);

            // Calcular el rectángulo que envuelve al código de barras
            const minX = Math.min(...xCoords);
            const maxX = Math.max(...xCoords);
            const minY = Math.min(...yCoords);
            const maxY = Math.max(...yCoords);

            // Ajustar el tamaño y posición del overlay
            const barcodeWidth = maxX - minX;
            const barcodeHeight = maxY - minY;

            // Aplicar el tamaño y la posición al overlay, ajustando por el contenedor
            overlaySquare.style.left = `${(xCoords) / window.devicePixelRatio}px`;
            overlaySquare.style.top = `${(yCoords) / window.devicePixelRatio}px`;
            overlaySquare.style.width = `${barcodeWidth / window.devicePixelRatio}px`;
            overlaySquare.style.height = `${barcodeHeight / window.devicePixelRatio}px`;
        },
        /**
         * Procesa el código de barras escaneado. 
         * @param {string} barcode Cadena a analizar, ya sea un código QR o un código de barras.
         */
        async processBarcode(barcode){
            if(this.$route.meta.isArticuloFind === true){
                //Se eliminan los saltos de linea
                if(barcode.includes('\n')){
                    barcode = barcode.replace('\n', '');
                }
                const articuSinonimo = await axios.get('/sinonimos/'+ barcode);
                if (articuSinonimo.data?.idArticu){
                    await this.$router.push({name:'Articulo', params: {idarticu: articuSinonimo.data.idArticu}});
                }
                else{
                    await this.showMessage(this.$t('lectorQrView.articuloNoEncontrado'));
                    this.$router.back();
                }
                BarcodeScanner.removeAllListeners();
            }else{
                const subdomain = this.parseSubdomain(barcode);                
                if (subdomain === null) {
                    await this.showMessage(this.$t('lectorQrView.elCodigoQrNoPerteneceAfarmatic'));
                    this.$router.back();
                } else {
                    try{
                        await store.vincularFarmacia(subdomain);
                        await this.$router.push('/');
                    }catch(e){
                        let mensaje = e.response?.data?.message ?? this.$t('lectorQrView.errorGenerico');
                        await this.showMessage(this.$t('lectorQrView.imposibleConectar'), mensaje);
                        this.$router.back();
                    }

                }  
            }
        },
        /**
         * Muestra un mensaje al usuario.
         *
         * @param {string} title Título del mensaje.
         * @param {string} message Mensaje a mostrar.
         * @param {string} okText Texto del botón de aceptación.
         * @returns {Promise<void>} Promesa que se resuelve cuando el usuario cierra el mensaje.
         */
        async showMessage(title, message = null, okText = this.$t('lectorQrView.entendido')) {
            if (message === null) {
                message = title;
                title = '';
            }

            await Dialog.alert({
                title,
                message,
                okButtonTitle: okText,
            });
        },

        /**
         * Pregunta al usuario una pregunta y devuelve la respuesta.
         *
         * @param {string} title Título de la pregunta.
         * @param {string} message Pregunta a realizar.
         * @param {string} yesText Texto del botón de afirmación.
         * @param {string} noText Texto del botón de negación.
         * @returns {Promise<boolean>} true si la respuesta es afirmativa, false en caso contrario.
         */
        async askQuestion(title, message = null, yesText = this.$t('lectorQrView.si'), noText = this.$t('lectorQrView.no')) {
            if (message === null) {
                message = title;
                title = '';
            }

            return Dialog.confirm({
                title,
                message,
                okButtonTitle: yesText,
                cancelButtonTitle: noText,
            }).then(({ value }) => value);
        },

        /**
         * Parsea el contenido del código QR para obtener el subdominio de la farmacia.
         *
         * @param {string} content Contenido del código QR.
         * @returns {string|null} Subdominio de la farmacia o null si el contenido del código QR no es válido.
         */
        parseSubdomain(content) {
            if (content.endsWith('farmaticapprox.es/#/install') || content.endsWith('farmapp.es/#/install') || content.endsWith('farmaticapprox.net/#/install')) {
                const url = /:\/\/([^/]+)/.exec(content)[1].split('.');                
                if (url.length === 3) {
                    return url[0];
                }
            }
            return null;
        },
    },
};
</script>

<style>
.barcode-scanning-active{
    background-color: transparent;
}
.position-relative {
    position: relative;
}

.overlay-square {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;  /* Puedes ajustar el tamaño según lo necesites */
    height: 200px; /* Puedes ajustar el tamaño según lo necesites */
    transform: translate(-50%, -50%);
    border: 4px solid rgba(255, 255, 255, 0.7); /* Borde blanco semitransparente */
    border-radius: 8px; /* Esquinas redondeadas opcionalmente */
    box-shadow: 0 0 10px rgba(104, 104, 104, 0.5); /* Sombra para darle mejor apariencia */
    pointer-events: none; /* Para que no interfiera con las interacciones */
}

.info-text {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 1em;
    border-radius: 0.8em;
    margin-left: 1em;
    margin-right: 1em;
}
</style>
