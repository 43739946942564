import { Capacitor } from '@capacitor/core';
import { store } from '../store';

const esWeb = ! Capacitor.isNativePlatform();

/**
 * Redirige a la página de vincular farmacia si no hay farmacia seleccionada.
 *
 * @param {object} to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function ensureFarmacia(to, _from) {
    // Para web, deshabilitamos el guard, ya que los datos de la farmacia se proveen en window.farmacia (se cargan en App.vue).
    // El mismo App.vue nunca va a dejar que se muestre una página que requiera una farmacia si no hay una farmacia seleccionada.
    if (esWeb) {
        return true;
    }

    if (!store.farmacia) {
        console.warn('No hay farmacia vinculada. Redirigiendo a VincularFarmacia');
        // Pasamos la ruta a la que queríamos ir para que el login nos redirija ahí una vez autenticados.
        // Esto es necesario porque el guard se ejecuta antes de que se cargue la farmacia en App.vue.
        // Esto es útil cuando RedSys nos redirige a una página de "/error-pago" o "/pago-ok" ya que se carga desde cero la app
        // y como en ese momento no hay farmacia vinculada, se redirige a VincularFarmacia, se carga la farmacia y luego se redirige
        // a la URL original que se pasó en el query string (Ej: ?redirect=/error-pago).
        // Esta redirección se hace en App.vue.
        return { name: 'VincularFarmacia', query: { redirect: to.fullPath } };
    }

    return true;
}

/**
 * Redirige a la página de inicio si hay farmacia seleccionada.
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function withoutFarmacia(_to, _from) {
    // En web SIEMPRE hay una farmacia vinculada, ya que los datos de la farmacia se proveen en window.farmacia
    if (esWeb) {
        return { name: 'Home' };
    }

    // Si ya tenemos una farmacia vinculada, redirigimos al home
    if (store.farmacia) {
        console.warn('Ya hay farmacia vinculada. Redirigiendo a Home');
        return { name: 'Home' };
    }

    return true;
}

/**
 * Redirige a la página de inicio si el usuario está autenticado en la aplicación.
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function redirectIfAutenticated(_to, _from) {
    return store.user ? { name: 'Home' } : true;
}

/**
 * Redirige a la página de login si el usuario no está autenticado en la aplicación.
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function autenticate(to, _from) {
    if (!store.user) {
        return {
            name: 'Login',
            query: { redirect: to.fullPath }, // Pasamos la ruta a la que queríamos ir para que el login nos redirija ahí una vez autenticados
        };
    }

    return true;
}

/**
 * Cancela la navegación si la farmacia no tiene ecommerce habilitado.
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function ensureEcommerce(_to, _from) {
    return store.ecommerceHabilitado;
}

/**
 * Cancela la navegación si la farmacia no tiene acceso al catálogo de productos
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function ensureCatalogo(_to, _from) {
    return store.catalogoHabilitado; // El catálogo se muestra en todos los modos que aplican al cliente excepto cuando está deshabilitado (0)
}

/**
 * Cancela la navegación si no estamos en la versión de app mobile nativa.
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function onlyMobile(_to, _from) {
    return esWeb ? { name: 'Home' } : true;
}

/**
 * Cancela la navegación si no estamos en la versión web.
 *
 * @param {object} _to
 * @param {object} _from
 * @returns {boolean|object}
 */
export function onlyWeb(_to, _from) {
    return !esWeb ? { name: 'Home' } : true;
}
