<template>
    <v-slide-x-transition appear>
        <v-chip
            v-if="porcentajeAplicado > 0"
            class="ma-2 mb-0"
            prepend-icon="mdi-brightness-percent"
            label
            size="small"
            variant="elevated"
            elevation="0"
            color="error"
            text-color="white">
            {{ porcentajeAplicado.toFixed(0) }}%
        </v-chip>
    </v-slide-x-transition>
    <v-slide-x-transition appear>
        <v-chip
            v-if="stockcesta > 0"
            class="ma-2 mb-0"
            prepend-icon="mdi-cart-arrow-down"
            label
            size="small"
            variant="elevated"
            elevation="0"
            color="info"
            text-color="white">
            {{ (stockcesta === 1)
                ? (stockcesta + ' ' + $t('articulosLista.unidad'))
                : (stockcesta + ' ' + $t('articulosLista.unidades')) }}
        </v-chip>
    </v-slide-x-transition>
    <v-slide-x-transition appear>
        <v-chip
            v-if="noDisponible"
            class="ma-2 mb-0"
            variant="elevated"
            elevation="0"
            label
            size="small"
            color="error"
            prepend-icon="mdi-cancel">
            {{ $t('articulosLista.noDisponible') }}
        </v-chip>
    </v-slide-x-transition>
    <v-slide-x-transition appear>
        <v-chip
            v-if="articulo.rotuloarticulos"
            class="ma-2 mb-0"
            prepend-icon="mdi-label"
            label
            size="small"
            variant="elevated"
            elevation="0"
            color="pink"
            text-color="white">
            {{ articulo.rotuloarticulos }}
        </v-chip>
    </v-slide-x-transition>
    <!-- Si solo hay una oferta y esta tiene rotulo -->
    <v-slide-x-transition
        v-if="articulo.ofertas && articulo.ofertas.length === 1 && articulo.ofertas[0].rotuloarticulos !== ''"
        appear>
        <v-chip
            class="ma-2 mb-0"
            label
            size="small"
            color="amber"
            variant="elevated"
            elevation="0"
            prepend-icon="mdi-label"
            text-color="white"
            @click.prevent=" tooltipOppened = !tooltipOppened">
            <v-menu
                :model-value="tooltipOppened"
                activator="parent"
                color="shell-secondary"
                content-class="pa-0 ma-0 bg-shell rounded"
                open-on-hover>
                <v-list
                    class="pa-0 ma-0"
                    :border="'sm'"
                    color="shell-primary"
                    rounded>
                    <v-list-item
                        v-for="oferta, index in articulo.ofertas"
                        :key="index"
                        base-color="primary"
                        variant="tonal"
                        class="ma-0"
                        prepend-icon="mdi-star"
                        :title="oferta.rotuloarticulos? oferta.rotuloarticulos : $t('articulosLista.promo')"
                        :subtitle="oferta.descripcion"
                        @click.prevent="routerGoTo({name:'Promocion', params:{idpromocion : oferta.idoferta}})">
                        <template #append>
                            <v-tooltip :text="$t('articulosLista.promoUnicaXCliente')">
                                <template #activator="{ props }">
                                    <v-icon v-if="oferta.aplicarsolounavezcliente === '1'" v-bind="props">
                                        mdi-repeat-once
                                    </v-icon>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            {{ articulo.ofertas[0].rotuloarticulos }}
        </v-chip>
    </v-slide-x-transition>
    <!-- Si hay mas de una oferta o la unica oferta no tiene rotulo Y qyeremos mostrar todos los rótulos-->
    <v-slide-x-transition
        v-else-if="((mostrarChipPromoDefault === 2)) && (articulo.ofertas && (articulo.ofertas.length > 1 || rotuloOfertas.length > 0))"
        group
        appear>
        <v-chip
            v-for="rotulo, index in rotuloOfertas"
            :key="index"
            class="ma-2 mb-0"
            label
            size="small"
            :color="getRandomColor(index)"
            variant="elevated"
            elevation="0"
            prepend-icon="mdi-star"
            text-color="white"
            @click.prevent="tooltipOppened = !tooltipOppened">
            <v-menu
                :model-value="tooltipOppened"
                activator="parent"
                color="shell-secondary"
                content-class="pa-0 ma-0 bg-shell rounded"
                open-on-hover>
                <v-list
                    class="pa-0 ma-0"
                    :border="'sm'"
                    color="shell-primary"
                    rounded>
                    <v-list-item
                        base-color="primary"
                        variant="tonal"
                        class="ma-0"
                        prepend-icon="mdi-star"
                        :title="rotulo.rotuloarticulos? rotulo.rotuloarticulos : $t('articulosLista.promo')"
                        :subtitle="rotulo.descripcion"
                        @click.prevent="routerGoTo({name:'Promocion', params:{idpromocion : rotulo.idoferta}})">
                        <template #append>
                            <v-tooltip :text="$t('articulosLista.promoUnicaXCliente')">
                                <template #activator="{ props }">
                                    <v-icon v-if="rotulo.aplicarsolounavezcliente === '1'" v-bind="props">
                                        mdi-repeat-once
                                    </v-icon>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            {{ rotulo.rotuloarticulos }}
        </v-chip>
    </v-slide-x-transition>
    <!-- Si mostramos el desplegable con las promos y su descripcion -->
    <v-slide-x-transition
        v-else-if="(mostrarChipPromoDefault === 1) && (articulo.ofertas && (articulo.ofertas.length > 1 || articulo.ofertas[0].rotuloarticulos === ''))"
        appear>
        <v-chip
            class="ma-2 mb-0"
            label
            size="small"
            color="amber"
            variant="elevated"
            elevation="0"
            prepend-icon="mdi-information"
            :ripple="false"
            @click.prevent=" tooltipOppened = !tooltipOppened">
            <v-menu
                :model-value="tooltipOppened"
                activator="parent"
                color="shell-secondary"
                content-class="pa-0 ma-0 bg-shell rounded"
                open-on-hover>
                <v-list
                    class="pa-0 ma-0"
                    :border="'sm'"
                    color="shell-primary"
                    rounded>
                    <v-list-item
                        v-for="oferta, index in articulo.ofertas"
                        :key="index"
                        base-color="primary"
                        variant="tonal"
                        class="ma-0"
                        prepend-icon="mdi-star"
                        :title="oferta.rotuloarticulos? oferta.rotuloarticulos : $t('articulosLista.promo')"
                        :subtitle="oferta.descripcion"
                        @click.prevent="routerGoTo({name:'Promocion', params:{idpromocion : oferta.idoferta}})">
                        <template #append>
                            <v-tooltip :text="$t('articulosLista.promoUnicaXCliente')">
                                <template #activator="{ props }">
                                    <v-icon v-if="oferta.aplicarsolounavezcliente === '1'" v-bind="props">
                                        mdi-repeat-once
                                    </v-icon>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-list-item>
                </v-list>
            </v-menu>
            {{ $t('articulosLista.promo') }}
        </v-chip>
    </v-slide-x-transition>
    <v-slide-x-transition appear>
        <v-chip
            v-if="articulo.tipoarticu === 'L'"
            class="ma-2 mb-0"
            variant="elevated"
            elevation="0"
            label
            size="small"
            prepend-icon="mdi-animation-outline"
            color="yellow">
            {{ $t('articulosLista.lote') }}
        </v-chip>
    </v-slide-x-transition>
    <v-slide-x-transition
        v-if="articulo.portada === '1'"
        appear>
        <v-chip
            class="ma-2 mb-0 text-white"
            color="orange"
            variant="elevated"
            elevation="0"
            size="small"
            append-icon="mdi-star">
            {{ $t('home.destacados') }}
        </v-chip>
    </v-slide-x-transition>
</template>

<script>
import { store } from '@/store';
export default {
    props: {

        /**
         * El articulo a mostrar los chips.
         */
        articulo: {
            type: Object,
            required: true,
        },
        /**
         * Cantidad de articulos en la cesta.
         */
        stockcesta: {
            type: Number,
            default: 0,
        },
        /**
         * Indica si el articulo no esta disponible.
         */
        noDisponible: {
            type: Boolean,
            default: false,
        },
        porcentajeAplicado: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            tooltipOppened: false,
        };
    },
    computed: {
        /**
         * Indica si se debe mostrar el chip de promo.
         */
        mostrarChipPromoDefault() {
            return store.farmacia.etiquetapromo;
        },
        rotuloOfertas() {
            const ofertas = this.articulo.ofertas;
            if (!ofertas) {
                return [];
            }
            console.log(ofertas);
            if (ofertas.length === 1) {
                if (ofertas[0].rotuloarticulos === '') {
                    return [];
                }
                return [ofertas[0]];
            } else {
                return ofertas.filter((oferta) => oferta.rotuloarticulos !== '');
            }
        },

    },
    unmounted() {
        this.tooltipOppened = false;
    },
    methods: {
        getRandomColor(index) {
            //Sacar colores de vuetify
            let colores = this.$vuetify.theme.global.current.colors;
            colores = Object.keys(colores);
            colores = colores.filter(color => color.includes('on') === false && color.includes('background') === false && color.includes('surface') === false);
            return colores[index];
        },
        routerGoTo(route) {
            this.$router.push(route);
        },
    },
};
</script>
<style scoped>
.v-card.on-hover.v-theme--dark{
    background-color: rgba(#FFF, 0.8);
}

</style>
