import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles'; // Importa los estilos globales de Vuetify
import { md3 } from 'vuetify/blueprints';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

/*
|-------------------------------------------------------------------------------------------
| Roles de colores
|-------------------------------------------------------------------------------------------
|
| Los roles de colores estan basados en la especificación de Material Design 3.0
| (https://material.io/archive/guidelines/style/color.html#color-color-palette)
| y adaptados a las necesidades de Approx.
|
| Los roles de colores que comienzan con 'on-' son roles de contraste. Estos roles
| deben usarse para el texto, iconos y elementos que se encuentran directamente
| sobre el color de fondo correspondiente. Por ejemplo, un botón
| que tiene el rol de color 'primary' debe tener el texto con el rol de color 'on-primary'.
|
| Solo deben usarse roles de colores que garanticen el contraste sobre el color de fondo.
| Por ejemplo, si se quiere poner un titulo sobre un fondo 'primary' se debe usar el rol
| de color 'on-primary' y no 'info'. Ya que no podemos garantizar que el color 'info'
| tenga suficiente contraste sobre el color 'primary'. Si en esta lista no se especifica
| explícitamente que un rol de color tiene contraste sobre otro, entonces NO lo tiene y
| no debe usarse.
|
| Nota: Vuetify crea por defecto algunos roles de colores que no están en esta lista.
|       Estos roles de colores no deben usarse (salvo acuerdo previo).
|
| Los colores pueden usarse de las siguientes formas:
| - Mediante la propiedad 'color' de los componentes de Vuetify. Ej: 'color="primary"'.
| - Usando la clase '.text-{color}' para color de texto. Ej: '.text-primary', '.text-on-primary'.
| - Usando la clase '.bg-{color}' para color de fondo. Ej: '.bg-primary', '.bg-on-primary'.
| - Con variables CSS. Ej: 'color: rgba(var(--v-theme-primary), var(--v-medium-emphasis-opacity))', 'background-color: rgb(var(--v-theme-primary))'.
|
| Roles de colores:
|
| Nota: "Garantizar contraste" significa que está asegurado que este color puede utilizarse como texto o
| ícono sobre el color indicado y cumple al menos con el nivel de contraste AA del standard WCAG 2.0.
|
| - 'background': Solo se usa para el fondo de la página o superficies scrollables.
| - 'surface': Superficies que no son scrollables (tarjetas, paneles, botones no destacados, etc.).
|   Si se usa sobre 'background' debe aplicarse elevation o border. Normalmente este color es blanco puro o casi puro. Pero si se requiriera podría ser
|   un color oscuro en caso de implementar un modo oscuro en el futuro.
| - 'surface-variant': Garantiza contraste sobre surface. Superficies no scrolleables que deban diferenciarse de 'surface'. Este color
|   es el inverso a `surface`. Por ejemplo si surface es un color claro, surface-variant es oscuro.
|   Vuetify lo usa para tooltips, popovers, etc. No debería ser necesario utilizarlo manualmente. No debe ser utilizado para texto o iconos,
|   sólo para superficies.
| - 'primary': Garantizado el contraste sobre 'surface' y 'background'.
|   Se utiliza para elementos destacados que requieren alta visibilidad como botones, iconos, headers, etc.
| - 'primary-brand': No garantiza contraste sobre nada. Se utiliza solo para superficies
|   destacadas que requieran alta visibilidad. Garantiza que es el color primario exacto del branding
|   de la aplicación sin ninguna alteración, pero no garantiza contraste sufiente para ser utilizado como texto o icono.
|   Puede ser utilizado para superficies de banners, headers o elementos del branding que no ofrezcan interactividad.
|   Al mostrar texto o iconos sobre este color debe usarse negrita y opacidad alta, ya que sino no siempre se alcanza a leer el texto.
| - 'primary-surface': No garantiza contraste sobre nada. Este color siempre va a ser menos vibrante que 'primary'.
|   No debe usarse para texto o iconos ya que no garantiza contraste. Se puede utilizar para fondos de elementos de soporte o decorativos
|   como fondos de tarjetas, paneles, etc.
| - 'secondary': Garantizado el contraste sobre 'surface' y 'background'.
|   Se utiliza para elementos destacados que requieren menos visibilidad que 'primary' pero deban
|   igualmente estar destacados (botones, iconos, headers, etc).
| - 'secondary-brand': No garantiza contraste sobre nada. Se utiliza solo para superficies
|   destacadas que requieran menos visibilidad que 'primary-brand'. Garantiza que es el color secundario exacto del branding
|   de la aplicación sin ninguna alteración, pero no garantiza contraste sufiente para ser utilizado como texto o icono.
|   Puede ser utilizado para superficies de banners, headers o elementos del branding que no ofrezcan interactividad.
|   Al mostrar texto o iconos sobre este color debe usarse negrita y opacidad alta, ya que sino no siempre se alcanza a leer el texto.
| - 'secondary-surface': No garantiza contraste sobre nada. Este color siempre va a ser menos vibrante que 'secondary'.
|   No debe usarse para texto o iconos ya que no garantiza contraste. Se puede utilizar para fondos de elementos de soporte o decorativos
|   como fondos de tarjetas, paneles, etc.
| - 'error': Garantizado el contraste sobre 'surface' y 'background'.
|   Solo debe usarse para representar errores, descuentos, acciones destructivas o significados negativos para el usuario.
| - 'success': Garantizado el contraste sobre 'surface' y 'background'.
|   Solo debe usarse para representar acciones exitosas, bonificaciones o significados constructivos o gratificantes para el usuario.
| - 'warning': Garantizado el contraste sobre 'surface' y 'background'.
|   Solo debe usarse para representar advertencias o acciones que requieren atención del usuario pero que no son necesariamente negativas.
| - 'info': Garantizado el contraste sobre 'surface' y 'background'.
|   Solo debe usarse para representar información o significados neutrales para el usuario que deben ser comunicados.
| - 'shell': Superficies del shell de la aplicación (top navigation, bottom navigation y drawer).
| - 'shell-primary': Garantizado el contraste sobre 'shell'. Solo debe usarse para elementos interactivos.
| - 'on-background': Garantizado el contraste sobre 'background'.
| - 'on-surface': Garantizado el contraste sobre 'surface'.
| - 'on-surface-variant': Garantizado el contraste sobre 'surface-variant'.
| - 'on-primary': Garantizado el contraste sobre 'primary'.
| - 'on-primary-brand': Garantizado el contraste sobre 'primary-brand'.
| - 'on-primary-surface': Garantizado el contraste sobre 'primary-surface'.
| - 'on-secondary': Garantizado el contraste sobre 'secondary'.
| - 'on-secondary-brand': Garantizado el contraste sobre 'secondary-brand'.
| - 'on-secondary-surface': Garantizado el contraste sobre 'secondary-surface'.
| - 'on-error': Garantizado el contraste sobre 'error'.
| - 'on-success': Garantizado el contraste sobre 'success'.
| - 'on-warning': Garantizado el contraste sobre 'warning'.
| - 'on-info': Garantizado el contraste sobre 'info'.
| - 'on-shell': Garantizado el contraste sobre 'shell'. Solo debe usarse para elementos no interactivos.
| - 'on-shell-primary': Garantizado el contraste sobre 'shell-primary'. Solo debe usarse para elementos interactivos.
|
*/

const coloresDefault = {
    'background': '#f5faff',
    'surface': '#ffffff',
    'surface-variant': '#000000',
    'primary': '#223f5e',
    'secondary': '#007185',
    'primary-brand': '#223f5f',
    'secondary-brand': '#0097ac',
    'primary-surface': '#5b6571',
    'secondary-surface': '#67d2e0',
    'shell': '#223f5e',
    'shell-primary': '#ffffff',
    'error': '#b3261e',
    'success': '#4caf50',
    'warning': '#fb8c00',
    'info': '#2196f3',
};


const vuetify = createVuetify({
    components,
    directives,

    // Los blueprints son simplemente defaults para la configuración de Vuetify:
    // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/blueprints/md3.ts
    blueprint: md3,

    defaults: {
        'VBadge': {
            // Todavía no sé si queda bien, en un futuro se puede cambiar. Pero poner 'surface-variant' en general tiene más
            // contraste sobre el shell que 'secondary'. El problema es si el farmaceutico elije justo un color primary que sea negro
            // entonces no se va a ver. Pero bueno, en un futuro se puede crear un nuevo rol (Algo como 'shell-accent' o algo asi)
            // para usar con los badges. Por ahora no porque no la quise complicar mucho.
            color: 'surface-variant',
        },
    },

    theme: {
        defaultTheme: 'light',
        variations: {
            // Desactiva los colores de variación. Approx usa colores dinámicos por lo que es
            // mejor controlar los colores a mano para evitar que se generen colores no deseados.
            colors: [],
            lighten: 0,
            darken: 0,
        },
        themes: {
            light: {
                dark: false,
                colors: coloresDefault,
            },
        },
    },
});

export default vuetify;

export function setVuetifyThemeColors(colors) {
    vuetify.theme.themes.value.light.colors = {
        ...coloresDefault,
        ...colors,
    };

    updateStatusBarStyle();
}

/**
 * Obtiene el color de un tema de Vuetify.
 *
 * @param {string} colorName
 * @returns {string}
 */
export function getThemeColor(colorName) {
    return vuetify.theme.themes.value.light.colors[colorName];
}

/**
 * Determina si un color es claro.
 *
 * @param {string} color Color en formato hexadecimal.
 * @returns {boolean} True si el color es claro, false si es oscuro.
 */
export function isColorLight(color) {
    const r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const [red, green, blue] = [parseInt(r[1], 16), parseInt(r[2], 16), parseInt(r[3], 16)];
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    return brightness > 125;
}

/**
 * Actualiza el estilo (claro/oscuro) de la barra de estado del dispositivo. Si no
 * se especifica un estilo, se utiliza el color del shell para determinar el estilo.
 *
 * @param {Style?} style Estilo de la barra de estado. Si no se especifica, se utiliza el color del shell para determinar el estilo.
 */
export function updateStatusBarStyle(style) {
    if (!Capacitor.isNativePlatform()) {
        return;
    }

    if (!style) {
        const shellColor = getThemeColor('shell');
        style = isColorLight(shellColor) ? Style.Light : Style.Dark;
    }

    StatusBar.setStyle({ style: style });
}
