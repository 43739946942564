<template>
    <v-container>
        <h1 class="mb-4 text-h5 text-primary font-weight-bold">
            {{ $t('encargosView.misEncargos') }}
        </h1>

        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />

        <div v-else-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" />
        </div>

        <v-card v-else-if="encargos.length === 0" class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
            <v-icon size="60" icon="mdi-gavel" class="text-medium-emphasis " />
            <div class="text-high-emphasis text-h6 mt-6">
                {{ $t('encargosView.noTienesEncargos') }}
            </div>
            <div class="text-medium-emphasis text-body-1 mt-1">
                {{ $t('encargosView.noTienesEncargosDescripcion') }}
            </div>
        </v-card>

        <v-card v-else class="mx-n4 mx-sm-0">
            <v-list>
                <template v-for="(encargo, i) in encargos" :key="encargo.id">
                    <v-list-item class="py-4" :to="encargo.to">
                        <template #prepend>
                            <v-img
                                :src="encargo.imagen"
                                :alt="encargo.descripcion"
                                class="mr-4 mr-sm-6 rounded-lg"
                                :width="imageSize"
                                :height="imageSize" />
                        </template>
                        <div class="d-flex flex-column flex-sm-row justify-sm-space-between">
                            <div>
                                <v-list-item-title class="font-weight-bold text-wrap pb-2 ">
                                    {{ encargo.unidades }} × {{ encargo.descripcion }}
                                </v-list-item-title>
                                <div class="text-body-2 text-medium-emphasis">
                                    <span class="d-inline-block" style="min-width: 7rem;">{{ $t('encargosView.nroEncargo') }}:</span>
                                    <strong>#{{ encargo.id }}</strong>
                                </div>
                                <div class="text-body-2 text-medium-emphasis">
                                    <span class="d-inline-block" style="min-width: 7rem;">{{ $t('encargosView.entrega') }}:</span>
                                    <strong>{{ fechaHora(encargo.fechaentrega) }}</strong>
                                </div>
                                <div class="text-body-2 text-medium-emphasis">
                                    <span class="d-inline-block" style="min-width: 7rem;">{{ $t('encargosView.encargado') }}:</span>
                                    <strong>{{ fechaHora(encargo.fecha) }}</strong>
                                </div>
                            </div>
                            <div class="mt-2 mt-sm-0">
                                <v-chip :color="encargo.estado.color" variant="text" class="ml-n3 font-weight-bold" :rounded="0">
                                    <v-icon v-if="encargo.estado.icon" :icon="encargo.estado.icon" class="mr-2" />
                                    {{ encargo.estado.text }}
                                </v-chip>
                            </div>
                        </div>
                    </v-list-item>
                    <v-divider v-if="i < encargos.length - 1" />
                </template>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import imagenProductoDefault from '../../images/producto-default.jpg';
import OopsAlert from '../components/OopsAlert.vue';
import StringHelper from '../support/StringHelper';
import EstadoEncargo from '../enums/EstadoEncargo';

export default {
    name: 'EncargosView', // Usado por <keep-alive>

    components: {
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Encargos del usuario.
         *
         * @type {Array}
         */
        encargos: [],
    }),
    computed: {
        imageSize() {
            return this.$vuetify.display.smAndUp ? 120 : 80;
        },
        estados() {
            return { // Códigos de estado que envia Farmatic: http://jira.consoft.es:8080/browse/AP-343. Cualquier otro código se mostrará tal cual.
                [EstadoEncargo.ENCARGADO]: {
                    text: this.$t('encargosView.encargado'),
                    icon: 'mdi-calendar',
                    color: 'warning',
                },
                [EstadoEncargo.PEDIDO]: {
                    text: this.$t('encargosView.pedido'),
                    icon: 'mdi-exit-to-app',
                    color: 'warning',
                },
                [EstadoEncargo.RECIBIDO]: {
                    text: this.$t('encargosView.recibido'),
                    icon: 'mdi-exit-to-app',
                    color: 'warning',
                },
                [EstadoEncargo.DISPENSADO]: {
                    text: this.$t('encargosView.dispensado'),
                    icon: 'mdi-logout',
                    color: 'success',
                },
                [EstadoEncargo.ANULADO]: {
                    text: this.$t('encargosView.anulado'),
                    icon: 'mdi-alert-octagon',
                    color: 'error',
                },
                [EstadoEncargo.NO_RECOGIDO]: {
                    text: this.$t('encargosView.noRecogido'),
                    icon: 'mdi-exit-to-app',
                    color: 'warning',
                },
                [EstadoEncargo.NO_ENVIAN]: {
                    text: this.$t('encargosView.noEnvian'),
                    icon: 'mdi-alert-octagon',
                    color: 'error',
                },
                [EstadoEncargo.N_A]: null, // No se muestra
            };
        },
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.error = null;

            try {
                const { data } = await axios.get('/histoencargos');
                this.encargos = data
                    .filter(element => element.descripcion) // Hack: existe un bug de "lineas fantasmas" en modulo de Farmatic. (Corregido en Farmatic Approx v1.0.7)
                    .map((element) => ({
                        id: element.idcontador,
                        to: element.articuexiste ? `/productos/${element.idarticu}` : null,
                        fecha: element.fecha,
                        fechaentrega: element.fechaentrega,
                        descripcion: element.descripcion,
                        unidades: element.unidades,
                        imagen: element.imagen ?? imagenProductoDefault,
                        estado: this.estados[element.estado] || {
                            text: element.estado?.length > 3 // Esto previene que capitalicemos 'N/A' u otras siglas.
                                ? StringHelper.titleCase(element.estado)
                                : element.estado,
                            icon: null, // No hay icono
                            color: 'grey',
                        },
                    }));
            } catch (error) {
                this.error = error;
                console.error(error);
            }

            this.loading = false;
        },
        fechaHora(fechahora){
            //Se separa en 2 componentes la fecha y se comprueban que sean validas.
            var fecha1 = moment(fechahora);
            var fecha2 = moment(fechahora);
            if(!fecha1.isValid() || !fecha2.isValid())
            {
                return '--';
            }
            return fecha1.format('L') + ' ' + fecha2.format('LT');
        },
    },
};
</script>
