<template>
    <v-dialog width="600px" :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)">
        <v-card class="pa-2">
            <v-card-title>
                <span class="headline" style="word-break: normal !important">
                    {{ notificacion.titulo }}
                </span>
            </v-card-title>
            <v-card-text>{{ notificacion.descripcion }}</v-card-text>
            <v-card-actions class="justify-end">
                <v-btn class="px-4" @click="$emit('update:modelValue', false)">
                    {{ $t('dialogNotificacion.cerrar') }}
                </v-btn>
                <v-btn
                    v-if="linkProps.href || linkProps.to"
                    v-bind="linkProps"
                    color="primary"
                    variant="flat"
                    class="px-4"
                    @click="$emit('update:modelValue', false)">
                    {{ $t('dialogNotificacion.verMas') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { store } from '../store';
import { normalizeNotificationUrl, isExternalUrl } from '../support/Normalization';

export default {
    props: {
        /**
         * Indica si el diálogo está abierto.
         */
        modelValue: {
            type: Boolean,
            default: false,
        },

        /**
         * La notificación a mostrar.
         */
        notificacion: {
            type: Object,
            default: null,
        },
    },
    emits: [
        'update:modelValue',
    ],
    computed: {
        /**
         * Devuelve las propiedades para un enlace.
         */
        linkProps() {
            const url = normalizeNotificationUrl(this.notificacion.url, store.farmacia.subdominio);

            if (!url) {
                return {};
            } else if (isExternalUrl(url)) {
                return {
                    href: url,
                    target: '_blank',
                    rel: 'noopener',
                };
            } else {
                const route = this.$router.resolve(url);

                if (route.name === 'Error404') {
                    console.warn(`La ruta de la notificación no existe: ${route}.`);
                    return {}; // Si la ruta no existe, no se muestra el enlace.
                }

                return { to: route };
            }
        },
    },
};
</script>
