<template>
    <v-row no-gutters class="mx-n4 mt-4">
        <v-col v-if="isLogged" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/tarjeta"
                icon="mdi-qrcode"
                :nombre="$t('menuItems.miTarjeta')"
                :descripcion="$t('descripcionMenuItems.miTarjeta')" />
        </v-col>
        <v-col v-if="isLogged && ecommerceHabilitado" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/pedidos"
                icon="mdi-cart-check"
                :nombre="$t('menuItems.misPedidos')"
                :descripcion="$t('descripcionMenuItems.miPedido')" />
        </v-col>
        <v-col v-if="catalogoHabilitado" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/categorias/0"
                icon="mdi-shopping"
                :nombre="$t('menuItems.productos')"
                :descripcion="$t('descripcionMenuItems.productos')" />
        </v-col>

        <v-col v-if="catalogoHabilitado" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/promociones"
                icon="mdi-sale"
                :nombre="$t('menuItems.promociones')"
                :descripcion="$t('descripcionMenuItems.promociones')" />
        </v-col>

        <v-col v-if="farmacia.web" v-bind="accesoCardsProps">
            <home-acceso-card
                :href="farmacia.web"
                target="_blank"
                icon="mdi-web"
                :nombre="$t('menuItems.web')"
                :descripcion="$t('descripcionMenuItems.web')" />
        </v-col>
        <v-col v-if="farmacia.mensajeria" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/mensajes"
                icon="mdi-email-multiple-outline"
                :nombre="$t('menuItems.mensajes')"
                :descripcion="$t('descripcionMenuItems.mensajes')"
                :badge="mensajesBadgeCount" />
        </v-col>

        <v-col v-if="farmacia.whatsapp" v-bind="accesoCardsProps">
            <home-acceso-card
                :href="`https://api.whatsapp.com/send?phone=${farmacia.whatsapp}`"
                target="_blank"
                icon="mdi-whatsapp"
                :nombre="$t('menuItems.whatsapp')"
                :descripcion="$t('descripcionMenuItems.whatsapp')" />
        </v-col>

        <v-col v-bind="accesoCardsProps">
            <home-acceso-card
                to="/consumos"
                icon="mdi-format-list-bulleted-type"
                :nombre="$t('menuItems.misConsumos')"
                :descripcion="$t('descripcionMenuItems.misConsumos')" />
        </v-col>

        <v-col v-if="isLogged && farmacia.programapuntos && tienePuntos" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/puntos"
                icon="mdi-counter"
                :nombre="$t('menuItems.misPuntos')"
                :descripcion="$t('descripcionMenuItems.misPuntos')" />
        </v-col>

        <v-col v-if="farmacia.rgpdproxdispens" v-bind="accesoCardsProps">
            <home-acceso-card
                to="/dispensaciones"
                icon="mdi-calendar-multiple"
                :nombre="$t('menuItems.fechasDispensacion')"
                :descripcion="$t('descripcionMenuItems.fechasDispensacion')" />
        </v-col>

        <v-col v-bind="accesoCardsProps">
            <home-acceso-card
                to="/encargos"
                icon="mdi-text-box-check-outline"
                :nombre="$t('menuItems.misEncargos')"
                :descripcion="$t('descripcionMenuItems.misEncargos')" />
        </v-col>

        <v-col v-bind="accesoCardsProps">
            <home-acceso-card
                to="/farmacia"
                icon="mdi-mortar-pestle-plus"
                :nombre="$t('menuItems.contacto')"
                :descripcion="$t('descripcionMenuItems.contacto')" />
        </v-col>

        <v-col v-if="isLogged" v-bind="accesoCardsProps">
            <home-acceso-card
                v-if="isLogged"
                to="/cuenta"
                icon="mdi-account-circle"
                :nombre="$t('menuItems.miCuenta')"
                :descripcion="$t('descripcionMenuItems.miCuenta')" />
        </v-col>

        <v-col v-bind="accesoCardsProps">
            <v-dialog v-model="mostrarComparteCard" max-width="500" style="overflow: hidden">
                <home-comparte-card
                    :qr-url="farmacia.share_url.qr"
                    :copy-link-url="farmacia.share_url.copy_link"
                    :social-share-url="farmacia.share_url.social_share"
                    @cerrar="mostrarComparteCard = false" />

                <template #activator="{ props }">
                    <home-acceso-card
                        v-bind="props"
                        id="comparte-card"
                        :icon="platform === 'ios' ? 'mdi-export-variant' : 'mdi-share-variant'"
                        :nombre="$t('menuItems.comparte')"
                        :descripcion="$t('descripcionMenuItems.comparte')" />
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
import HomeAccesoCard from './HomeAccesoCard.vue';
import HomeComparteCard from './HomeComparteCard.vue';
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
export default{
    components: {
        HomeAccesoCard,
        HomeComparteCard,
    },
    data: () => ({
        mostrarComparteCard: false,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },

        isLogged() {
            return store.isLogged;
        },

        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },

        catalogoHabilitado() {
            return store.catalogoHabilitado;
        },

        imagenHome() {
            return store.farmacia.rutaimagenhome;
        },

        esApp() {
            return Capacitor.isNativePlatform();
        },

        platform() {
            return Capacitor.getPlatform();
        },

        accesoCardsProps() {
            return {
                cols: 6,
                sm: 4,
                md: 3,
                xl: 3,
            };
        },
        tienePuntos(){
            return store.tienePuntos;
        },
        mensajesBadgeCount() {
            return store.mensajes.badge;
        },
    },
};
</script>
