<template>
    <v-container>
        <v-row>
            <v-col class="text-center mb-2">
                <h1 class="text-primary text-h4 font-weight-bold mb-2">
                    {{ $t('listaPromocionesView.promociones') }}
                </h1>
                <p class="text-medium-emphasis mx-auto" style="max-width: 75%;">
                    {{ $t('listaPromocionesView.promocionesSubtitulo', { farmacia: farmacia.nombre}) }}
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="error">
                <oops-alert :error="error" mostrar-reintentar @reintentar="cargarPromociones" />
            </v-col>

            <v-col v-else-if="loading" class="text-center">
                <v-progress-circular indeterminate color="primary" size="60" class="ma-8" />
            </v-col>

            <v-col v-else-if="promociones.length === 0" cols="12">
                <v-card class="px-6 py-16 text-center" color="surface" elevation="0" :border="true">
                    <v-icon size="60" icon="mdi-sale" class="text-medium-emphasis " />
                    <div class="text-high-emphasis text-h6 mt-6">
                        {{ $t('listaPromocionesView.noHayPromocionesActualmente') }}
                    </div>
                    <div class="text-medium-emphasis text-body-1 mt-1">
                        {{ $t('listaPromocionesView.vuelveMasTarde') }}
                    </div>
                </v-card>
            </v-col>

            <template v-else>
                <v-col v-for="(promocion, i) in promociones" :key="i" cols="12">
                    <v-card class="mx-auto mb-2" :to="promocion.link">
                        <v-card-title>
                            <v-icon size="20" icon="mdi-star" class="mr-2" />
                            {{ promocion.descripcion }}
                        </v-card-title>
                        <v-img
                            :src="promocion.imagen"
                            :alt="promocion.descripcion"
                            height="auto" />
                        <v-card-text v-if="promocion.descripcionextendida">
                            {{ promocion.descripcionextendida }}
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import genericPromoImageUrl from '../../images/generic-promo.jpg';
import OopsAlert from '../components/OopsAlert.vue';
import StringHelper from '../support/StringHelper';

export default {
    name: 'PromocionesView', // Usado por <keep-alive>

    components: {
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información desde el server.
         *
         * @type {boolean}
         */
        loading: true,

        /**
         * El error que se produjo al cargar la información.
         *
         * @type {Error?}
         */
        error: null,

        /**
         * La lista de promociones.
         *
         * @type {Array}
         */
        promociones: [],
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
    watch: {
        '$route'(to){
            to.path === '/promociones' && this.cargarPromociones();
        },
    },
    created() {
        this.cargarPromociones();
    },
    methods: {
        imagenUrl(imagen) {
            return imagen
                ? `${store.farmacia.endpointUrl}/img/${store.farmacia.numserie}/${imagen.ruta}`
                : genericPromoImageUrl;
        },
        async cargarPromociones() {
            this.loading = true;
            this.error = null;

            try {
                const { data } = await axios.get(store.isLogged ? '/ofertasuser' : '/ofertas');

                this.promociones = data.map((promocion) => ({
                    ...promocion,
                    imagen: this.imagenUrl(promocion.imagen),
                    link: `/promociones/${promocion.idoferta}`,
                    descripcion: StringHelper.sentenceCase(promocion.descripcion),
                }));
            } catch (error) {
                this.error = error;
                console.error(error);
            }

            this.loading = false;
        },
    },
};
</script>
