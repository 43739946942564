import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import axios from 'axios';
import StorageHelper from './StorageHelper';

export default class SubdominioHelper {
    /**
     * Recupera el subdominio de la farmacia vinculada. Si no hay farmacia vinculada, devuelve null.
     * Si el subdominio no se puede recuperar, devuelve null.
     *
     * El subdominio se intenta recuperar de las siguientes fuentes (en este orden):
     * 1. Desde el objeto global window.subdominio (entorno web)
     * 2. Desde el local storage del dispositivo (mobile)
     * 3. Haciendo una petición al servidor a la ruta /getsubdominio (mobile)
     *
     * @returns {Promise<string|null>}
     */
    static async getSubdominio() {
        return (
            await this._getSubdominioDesdeWeb() ||
            await this._getSubdominioDesdeStorage() ||
            await this._getSubdominioDesdeServerQR()
        );
    }

    /**
     * Recupera el subdominio de la farmacia vinculada desde el local storage del dispositivo.
     *
     * @returns {Promise<string|null>}
     */
    static async _getSubdominioDesdeStorage() {
        // Para mobile, recuperamos el subdominio de la farmacia desde
        // el Storage(si existe), o null si el user todavía no vinculó una farmacia
        return (await StorageHelper.getJson('farmacia'))?.subdominio || null;
    }

    /**
     * Recupera el subdominio de la farmacia vinculada desde el entorno web, matcheando la URL contra expresiones regulares
     * provee el subdominio de la farmacia en la variable global window.subdominio (home.blade.php).
     *
     * @returns {Promise<string|null>}
     */
    static async _getSubdominioDesdeWeb() {

        if (Capacitor.isNativePlatform()) {
            return null;
        }

        const currentUrl = window.location.href;
        const mode = import.meta.env.MODE; // Detectar el entorno

        if (['staging', 'production', 'test'].includes(mode)) {
            // Caso: {farmacia} en subdominio -> https://{farmacia}.[dominio].[xx]
            return window.location.hostname.split('.')[0]; // Tomamos la primera parte del hostname
        } else {
            // Caso: {farmacia} en el path -> http://localhost/farmacia/{farmacia}
            const match = currentUrl.match(/\/farmacia\/([^/]+)/);
            return match ? match[1] : null;
        }
    }

    /**
     * Devuelve true si el subdominio de la farmacia se puede obtener desde el entorno web y
     * false en caso contrario (por ejemplo, si se está ejecutando en un dispositivo móvil).
     *
     * @returns {boolean}
     */
    static hasSubdominioDesdeWeb() {
        return Capacitor.isNativePlatform() && !!window.subdominio;
    }

    /**
     * Recupera el subdominio de la farmacia vinculada desde el servidor consultando la ruta /getsubdominio.
     * Esto se utiliza cuando el usuario escanea un QR con el subdominio de la farmacia, y luego abre la app.
     *
     * @returns {Promise<string|null>}
     */
    static async _getSubdominioDesdeServerQR() {
        if (!Capacitor.isNativePlatform()) {
            return null;
        }
        // En mobile, si no hay farmacia vinculada, se intenta obtener el subdominio desde el servidor
        // Esto es un hack ya que actualmente no estamos utilizando DeepLinks (https://capacitorjs.com/docs/guides/deep-links).
        // En un futuro, cuando se implementen los DeepLinks, esto debería ser innecesario.
        const info = await Device.getInfo();
        const { status, data } = await axios.post('/getsubdominio', info);
        return status === 200 ? data : null;
    }
}
