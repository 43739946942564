import englishIcon from '../../icons/idiomas/en_EN.svg';
import spanishIcon from '../../icons/idiomas/es_ES.svg';
import frenchIcon from '../../icons/idiomas/fr_FR.svg';
import catalanIcon from '../../icons/idiomas/ca_CA.svg';
import douchIcon from '../../icons/idiomas/de_DE.svg';
import euskadiIcon from '../../icons/idiomas/eu_EU.svg';
import gallegoIcon from '../../icons/idiomas/ga_GA.svg';
import portuguesIcon from '../../icons/idiomas/pt_PT.svg';
import RussianIcon from '../../icons/idiomas/ru_RU.svg';

export default class IdiomasHelper{

    static getIcon(idioma) {
        switch (idioma) {
        case 'en':
            return englishIcon;
        case 'es':
            return spanishIcon;
        case 'fr':
            return frenchIcon;
        case 'ca':
            return catalanIcon;
        case 'de':
            return douchIcon;
        case 'eu':
            return euskadiIcon;
        case 'gl':
            return gallegoIcon;
        case 'pt':
            return portuguesIcon;
        case 'ru':
            return RussianIcon;
        default:
            return 'mdi-translate';
        }
    }

}
