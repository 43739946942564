import axios from 'axios';
import ActivityWatcher from './ActivityWatcher';
import EventEmitter from './EventEmitter';
import { store } from '../store';
/**
 * Clase que se encarga de realizar el heartbeat y de emitir eventos cuando cambia el estado de la GUI.
 * Un heartbeat es una petición al servidor que se realiza cada cierto tiempo para comprobar que el usuario
 * sigue logueado y para comprobar si hay cambios en la GUI, como por ejemplo el número de notificaciones,
 * mensajes, etc.
 * El servicio debe ser iniciado manualmente llamando al método start() y detenido llamando al método stop().
 *
 * @see https://en.wikipedia.org/wiki/Heartbeat_(computing)
 * @event guiChanged(versionDatosGui: number) Se emite cuando cambia la versión de los datos de la GUI.
 */
export default class HeartbeatService extends EventEmitter {

    static _instance = null;

    /** @returns {HeartbeatService} */
    static get instance() {
        return this._instance ??= new HeartbeatService();
    }

    /** @type {Date} Fecha local del cliente del último heartbeat. Usado para calcular el tiempo transcurrido desde el último heartbeat. */
    lastHeartbeat = null;

    /** @type {number} Versión de los datos de la GUI recibido en el ultimo heartbeat. Si es -1 quiere decir que aún no se recibió ningun dato. */
    versionDatosGui = -1;

    /** @type {Error} Datos de error de no disponibilidad de la APP recibido en el último heartbeat. Se utiliza para pintar cartel informativo */
    error = null;

    /** @type {number} Handle del Timeout de JS para el siguiente heartbeat. */
    _timeout = null;

    /** @type {boolean} */
    _started = false;

    /** @type {boolean} */
    _paused = false;

    /** @type {number} Número de intentos fallidos consecutivos de heartbeat. */
    _failedAttempts = 0;

    /** @type {number} Tiempo en milisegundos entre heartbeat y heartbeat. */
    _refreshTimeoutMs = 30000; // 30 segundos por defecto

    constructor() {
        super();

        this._onIdle = this._onIdle.bind(this);
        this._onActive = this._onActive.bind(this);
    }

    /**
     * Realiza un heartbeat y programa el siguiente.
     *
     * @returns {Promise<void>}
     */
    async heartbeat() {
        this._paused = false;
        clearTimeout(this._timeout);

        try {
            const { data } = await axios.get('/heartbeat');
            this._refreshTimeoutMs = Math.max(new Date(data.nextHeartbeat) - new Date(data.now), 5000); // 5 segundos mínimo

            if (data.versionDatosGui && data.versionDatosGui > this.versionDatosGui) {
                this.versionDatosGui = data.versionDatosGui;
                this.emit('guiChanged', this.versionDatosGui);
            }

            if (data.isLogged && data.isLogged !== store.isLogged && store.isLogged) {
                await store.logoutUser();
            }

            //Actualización si procede, del modo ecommerce
            if(data.ecommerce !== store.farmacia.ecommerce){
                await store.setModoEcommerce(data.ecommerce);
            }

            // Esto actualiza directamente el store, quizas sería mejor un evento
            // para desacoplar la clase del store.
            store.setCestaData(data.cesta);
            store.setNotificacionesBadge(data.notificaciones.badge);
            store.setMensajesBadge(data.mensajes.badge);

            if (data.seccionesHome !== null && data.seccionesHome.trim().length !== 0){
                store.setSeccionesHome(data.seccionesHome);
            }

            this._failedAttempts = 0;
        } catch (e) {
            //Intecepción no disponibilidad
            if (e.response) {
                this.error = e;
                this.emit('noDisponible', e);
                //await this.$router.push({ name: 'Home' });
            }

            console.error(e);
            // Reintentar en 30 segundos. Quizas estamos en mantenimiento. O es un error temporal.
            this._failedAttempts++;
            this._refreshTimeoutMs = 30000 + (Math.min(this._failedAttempts, 10) * 10000); // 30 segundos + 10 segundos por cada intento fallido
            console.warn(`Error al realizar el heartbeat. Reintentando en ${this._refreshTimeoutMs / 1000} segundos...`, e);
        }

        this.lastHeartbeat = new Date(); // Fecha de cliente

        this.scheduleHeartbeat(this._refreshTimeoutMs);
    }

    /**
     * Programa el siguiente heartbeat para dentro de timeoutMs milisegundos.
     *
     * @param {number?} timeoutMs
     * @returns
     */
    scheduleHeartbeat(timeoutMs) {
        if (this._paused) return;

        timeoutMs = Math.max(timeoutMs ?? this._refreshTimeoutMs, 5000); // 30 segundos por defecto, 5 segundos mínimo

        clearTimeout(this._timeout);
        this._timeout = setTimeout(this.heartbeat.bind(this), timeoutMs);
    }

    /**
     * Devuelve el tiempo transcurrido desde el último heartbeat en milisegundos.
     *
     * @returns {number}
     */
    get timeSinceLastHeartbeatMs() {
        return new Date() - this.lastHeartbeat;
    }

    /**
     * Realiza un heartbeat si ha pasado el tiempo suficiente desde el último. En caso contrario
     * programa el siguiente heartbeat para dentro de un tiempo suficiente. Esto previene que el
     * usuario haga spam de peticiones al servidor si por ejemplo tiene el navegador abierto y va
     * cambiando de pestañas muy rápido.
     *
     * @returns {Promise<void>}
     */
    throtledHeartbeat() {
        const minDelay = this._refreshTimeoutMs / 4;
        if (this.timeSinceLastHeartbeatMs < minDelay) {
            this.scheduleHeartbeat(minDelay - this.timeSinceLastHeartbeatMs);
            return Promise.resolve();
        } else {
            return this.heartbeat();
        }
    }

    /**
     * Inicia el servicio de heartbeat y lo mantiene funcionando hasta que se llame a stop().
     * La promesa se resuelve cuando se termina de ejecutar el primer heartbeat.
     *
     * @returns {Promise<void>}
     */
    start() {
        if (this._started) return Promise.resolve();
        this._started = true;

        ActivityWatcher.instance.addListener('idle', this._onIdle);
        ActivityWatcher.instance.addListener('active', this._onActive);

        return this.throtledHeartbeat();
    }

    /**
     * Detiene el servicio de heartbeat de forma permanente.
     */
    stop() {
        if (!this._started) return;
        this._started = false;

        clearTimeout(this._timeout);
        this._timeout = null;

        ActivityWatcher.instance.removeListener('idle', this._onIdle);
        ActivityWatcher.instance.removeListener('active', this._onActive);
    }

    _onIdle() {
        this.pause();
    }

    _onActive() {
        this.resume();
    }

    /**
     * Pausa el servicio de heartbeat.
     */
    pause() {
        if (this._paused) return;
        this._paused = true;

        clearTimeout(this._timeout);
        this._timeout = null;
        console.info('Heartbeat pausado.');
    }

    /**
     * Reanuda el servicio de heartbeat. Devuelve una promesa que se resuelve cuando se termina
     * de ejecutar el primer heartbeat.
     *
     * @returns {Promise<void>}
     */
    resume() {
        if (!this._paused) return;
        this._paused = false;

        console.info('Heartbeat reanudado.');
        return this.throtledHeartbeat();
    }
}
