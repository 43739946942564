<template>
    <v-container>
        <div>
            <v-btn prepend-icon="mdi-arrow-left" color="primary" @click="volver">
                {{ $t('volver') }}
            </v-btn>
        </div>

        <v-alert
            v-if="!$i18n.locale.startsWith('es')"
            type="info"
            class="mt-4">
            {{ $t('avisoIdiomaSoloEspañol') }}
        </v-alert>

        <div class="pb-12 pt-6 texto-legal">
            <h1>TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN</h1>
            <p>
                Estos Términos y Condiciones regulan la descarga, acceso y utilización de la aplicación móvil <strong>Farmatic Approx</strong> (en adelante, la <strong>“APLICACIÓN”</strong>)
            </p>
            <h2>1. Identificación de las partes</h2>

            <p>
                Consoft, S.A.U. (en adelante CONSOFT) es el propietario de la aplicación y actúa a efectos de la normativa de protección de datos vigente como Encargado de Tratamiento de la APLICACIÓN.
            </p>
            <p>
                pone a disposición del USUARIO el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), al Reglamento General de Protección de Datos 2016/679, de 27 de abril (RGPD) y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), así como informar a todos los usuarios de la APLICACIÓN respecto a cuales son las condiciones de uso que regulan la descarga, acceso y utilización de la APLICACIÓN.
            </p>
            <p>
                El USUARIO adquiere esta condición con la descarga y uso de la misma.
            </p>

            <h3 class="mt-4 mb-2">
                Datos del propietario de la aplicación:
            </h3>

            <ul>
                <li>Razón social: Consoft, S.A.U.</li>
                <li>Nombre comercial: CONSOFT</li>
                <li>CIF: A30047955</li>
                <li>Domicilio social: Partida Madrigueres Nord, 12. Apartado de Correos 31. C.P. 03700, Denia (Alicante)</li>
                <li>Web: www.consoft.es </li>
                <li>Teléfono: 966 42 60 30</li>
                <li>Email: consoft@consoft.es</li>
            </ul>

            <h3 class="mt-4 mb-2">
                Datos de la FARMACIA:
            </h3>

            <ul>
                <li>Razón social: {{ farmacia.rgpddenominacionsocial }}.</li>
                <li>Nombre comercial: {{ farmacia.rgpdnombrecomercial }}</li>
                <li>NIF: {{ farmacia.rgpdnif }}</li>
                <li>Domicilio social: {{ farmacia.rgpddomiciliosocial }}</li>
                <li>Teléfono: {{ farmacia.telefono }}</li>
                <li>Email: {{ farmacia.rgpdemail }}</li>
            </ul>
            <p>
                El titular y responsable de la base de datos de la App es la oficina de farmacia (en adelante FARMACIA) con la que el USUARIO decide vincularse y que se selecciona una vez efectúa el registro y validación en la APLICACIÓN.
            </p>
            <p>
                Toda persona que descargue y use la APLICACIÓN adquiere la condición de USUARIO, comprometiéndose a la observancia y cumplimiento riguroso de las disposiciones aquí dispuestas, así como cualquier otra disposición legal que fuera de aplicación.
            </p>

            <h2>2. Objeto de la APLICACIÓN.</h2>

            <p>La APLICACIÓN pone a disposición del USUARIO una herramienta para facilitar la comunicación bidireccional con su FARMACIA, así como para facilitar, en caso de que la farmacia así la configure la compra de productos de parafarmacia a través de la APLICACIÓN. </p>
            <p>Pueden beneficiarse de esta APLICACIÓN y las ventajas que la misma proporciona, aquellos USUARIOS que quieran adscribirse a los servicios de su FARMACIA de referencia a través de Farmatic Approx.</p>
            <p>La APLICACIÓN se pone a disposición de los usuarios (clientes de la oficina de farmacia) para su uso personal (nunca empresarial)</p>

            <h2>3. Licencia de uso de la APLICACIÓN. </h2>

            <p>La FARMACIA es titular de todos los derechos de la base de datos de la APLICACIÓN, y en este sentido, otorga al usuario el derecho a utilizar la APLICACIÓN conforme a lo previsto en estos Términos y Condiciones.</p>
            <p>El USUARIO acepta una licencia de uso gratuito de la APLICACIÓN, personal e intransferible, que estará disponible en Google Play y Apple Store, ya que funciona con teléfonos móviles con sistema operativo Android o IOS. El USUARIO reconoce y acepta cumplir con los Términos y Condiciones aplicables respecto a la obtención, descarga y utilización de la APLICACIÓN que estos stores determinen. Asimismo, una vez descargada la APLICACIÓN, el USUARIO deberá aceptar las condiciones recogidas en este documento para poder acceder a las funcionalidades que FARMACIA ponga a su disposición a través de la APLICACIÓN. </p>
            <p>El acceso a la APLICACIÓN supone que el usuario reconoce haber aceptado y consentido sin reservas las presentes condiciones de uso. En caso contrario, el USUARIO deberá cesar su utilización y proceder a desinstalar la APLICACIÓN de su terminal mediante las opciones de configuración del mismo.</p>
            <p>En caso de que el USUARIO viole alguna de estas condiciones, la FARMACIA podrá cancelar la cuenta. </p>
            <p>La descarga de la APLICACIÓN y/o las funcionalidades previstas o que en el futuro se puedan permitir a través de la misma, tendrán carácter gratuito, sin perjuicio de que la conexión, navegación y llamadas que, en su caso, el USUARIO realice a FARMACIA, sean facturadas por su operador según la tarifa que tenga contratada.</p>
            <p>En ningún caso CONSOFT ni la FARMACIA serán responsables de las pérdidas, daños o perjuicios de cualquier tipo que surjan por acceder y usar la APLICACIÓN, incluyéndose los producidos en los sistemas informáticos por la introducción de virus y/o ataques informáticos. Tampoco serán responsables de los daños que pudieran sufrir los USUARIOS por un uso inadecuado de la APLICACIÓN y, en modo alguno, de las caídas, interrupciones, ausencia o defecto de las telecomunicaciones.</p>
            <p>Tanto CONSOFT como la FARMACIA se reserva el derecho a revisar estos Términos y Condiciones de Uso de la APLICACIÓN, así como las condiciones y funcionalidades de la misma en cualquier momento por motivos legales, técnicos o por cambios en la prestación de la APLICACIÓN o en la normativa, así como modificaciones que pudieran derivarse de códigos tipo aplicables o si procede, por decisiones corporativas estratégicas. Las nuevas condiciones se mostrarán al USUARIO a través de la APLICACIÓN, debiendo éste aceptarlas mediante el botón disponible una vez descargada la actualización. Una vez aceptadas las nuevas condiciones, el USUARIO podrá acceder a las funcionalidades de la APLICACIÓN. </p>
            <p>La FARMACIA se reserva el derecho a actualizar, modificar o eliminar la información contenida en la APLICACIÓN, pudiendo incluso limitar o no permitir el acceso a dicha información, sin previo aviso. Especialmente, la FARMACIA se reserva el derecho a eliminar, limitar o impedir el acceso a su APLICACIÓN cuando surjan dificultades técnicas por hechos o circunstancias ajenos a la FARMACIA que, a su criterio, disminuyan o anulen los niveles de seguridad estándares adoptados para el adecuado funcionamiento de dicha APLICACIÓN.</p>
            <p>Si cualquiera de los Términos y Condiciones aquí expuestos se declara total o parcialmente nula o ineficaz, sólo afectará a aquella disposición o parte de la misma que resulte nula o ineficaz, permaneciendo vigentes todas las otras disposiciones.</p>
            <p>La FARMACIA tendrá la facultad de resolver el contrato en cualquier momento, quedando cancelada la licencia otorgada, mediante comunicación previa al USUARIO.</p>
            <p>El USUARIO podrá resolver el presente contrato en cualquier momento procediendo a desinstalar la APLICACIÓN, sin necesidad de comunicar esta circunstancia a la FARMACIA.</p>

            <h2>4. Registro de Acceso a la APLICACIÓN. </h2>

            <p>Para poder acceder y usar la APLICACIÓN, el USUARIO tiene que descargarse la APLICACIÓN y “crear una cuenta”, a través de un formulario en el que se introduce el Nombre y Apellidos, correo electrónico y una contraseña personal, necesarios para poder acceder a la APLICACIÓN. </p>
            <p>En ningún caso la FARMACIA responderá de las pérdidas y/o daños que, si procede, pudieran ser causados con motivo del acceso y/o uso de la cuenta de la APLICACIÓN por parte de terceros sin autorización. A tal efecto, el USUARIO es el único responsable de custodiar sus claves de acceso, así como de los posibles daños y/o perjuicios que se pudieran ocasionar a la FARMACIA y/o a terceros debido a un uso no autorizado de las mismas. </p>

            <h2>5. Funcionalidades </h2>

            <ul>
                <li>Para usuarios anónimos: información sobre la farmacia (ubicación, teléfono, mail, horarios), promociones activas y categorías de productos a la venta. </li>
                <li>Para usuarios registrados: Además de información sobre la farmacia, promociones y categorías de productos, los usuarios registrados podrán acceder a información sobre sus puntos, consumos, dispensaciones, encargos, mensajes y configuración de su cuenta, y podrá realizar compras de productos de parafarmacia a través de la APLICACIÓN. </li>
            </ul>
            <p>La APLICACIÓN necesita permiso de acceso al almacenamiento del teléfono móvil del usuario, con la finalidad de almacenar los datos estrictamente necesarios para el funcionamiento de la aplicación (datos básicos de la farmacia y datos básicos del usuario) La extensión de los permisos son: acceso, lectura, escritura y modificación. La APLICACIÓN tratará datos únicamente cuando se está ejecutando por acción del usuario en primer plano.  El USUARIO puede gestionar los permisos otorgados de manera que puede decidir en todo momento si otorga o revoca dichos permisos y en qué condiciones.</p>
            <p>
                Para revocar los permisos, el usuario deberá acceder:
                En Android: Ajustes  Aplicaciones   Seleccionar la App que vamos a modificar los permisos  Notificaciones y Permisos.
                En IOS: Ajustes   Seleccionamos la App que vamos a modificar los permisos  Modificación de permisos.
            </p>

            <h2>6. Derechos de propiedad intelectual e industrial.</h2>
            <p>Los derechos de propiedad intelectual e industrial sobre la APLICACIÓN son titularidad de CONSOFT, correspondiéndole el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación. La totalidad de la APLICACIÓN y los elementos que la componen (textos, imágenes, logos, marcas, , archivos de audio, archivos de software, etc.), así como la estructura, selección y orden de sus contenidos, diseño gráfico, arquitectura, código fuente, tecnología, etc. se encuentran protegidos igualmente por la normativa de Propiedad Intelectual e Industrial, por lo que no pueden ser objeto de explotación, reproducción, distribución, modificación, comunicación pública, cesión o transformación o cualquier otra forma de difusión no autorizada expresamente.</p>
            <p>La utilización de la APLICACIÓN queda restringida al uso personal, nunca empresarial, y a los usos expuestos, por lo que su uso para cualquier otro fin queda expresamente prohibido y será considerado como acto de piratería que infringe las leyes de propiedad intelectual e industrial, de tal forma que se podrá exigir responsabilidad por el incumplimiento de esta condición. </p>
            <p>En el diseño y desarrollo de esta APLICACIÓN han intervenido profesionales especialistas, así como un grupo de usuarios que participaron en el periodo de prueba.</p>
            <p>Las fotografías, logotipos o cuales quiera otros símbolos o contenidos facilitados por terceros colaboradores o por la FARMACIA, serán titulares de éstos y acogidos a los derechos de propiedad intelectual e industrial, por lo que igualmente queda prohibida su reproducción, modificación, distribución o manipulación por parte de terceros sin el consentimiento de los titulares. Respecto a estos contenidos incluidos en la APLICACIÓN, los terceros titulares han concedido las correspondientes autorizaciones para su reproducción, distribución y puesta a disposición del público.</p>
            <p>El USUARIO reconoce que la reproducción, modificación, distribución, comercialización descompilación, desensamblado, utilización de técnicas de ingeniería inversa o cualquier otro medio para obtener el código fuente, transformación o publicación de cualquier resultado de pruebas de referencia no autorizadas de cualquiera de los elementos y utilidades integradas dentro del desarrollo constituye una infracción de los derechos de propiedad intelectual de CONSOFT, obligándose, en consecuencia, a no realizar ninguna de las acciones mencionadas.</p>
            <p>El acceso a la APLICACIÓN no otorga al USUARIO derecho ni titularidad alguna sobre los derechos de propiedad intelectual y/o industrial de los contenidos que alberga la APLICACIÓN, por lo que se reserva el derecho de ejercer las acciones legales que correspondan contra el USUARIO que viole o infrinja estos derechos.</p>

            <h2>7. Política de privacidad.</h2>

            <p>En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (RGPD) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), se informa que la FARMACIA que el USUARIO ha solicitado vincularse es la responsable del tratamiento de los datos personales del USUARIO y que CONSOFT es encargado del tratamiento de dichos datos ya que es el titular de la APLICACIÓN que los recopila y procesa. Una vez acceda a la APLICACIÓN y proceda a su registro inicial, se informará de la política de privacidad específica de dicha FARMACIA.</p>
            <p>Ambos suscriben que los datos son tratados de conformidad con el RGPD, la LOPDGDD y cualquier normativa posterior que le sea de aplicación. </p>
            <p>FARMACIA como responsable del tratamiento de datos, será la responsable de cumplir con la normativa vigente, al margen de que CONSOFT cumpla con las obligaciones exigibles como encargado del tratamiento.  </p>
            <h3>¿Por qué tratamos sus datos personales?</h3>
            <p>El tratamiento de los datos a través de la APLICACIÓN, tendrán por objeto cumplir con las funcionalidades descritas en los apartados 2 y 5 de estos Términos y Condiciones de Uso.</p>
            <h3>¿Por qué motivo podemos tratar sus datos personales?</h3>
            <div>
                El tratamiento de sus datos está legitimado con base en:
                <ul>
                    <li>Nuestro interés legítimo en el caso de dar respuesta a sus encargos o solicitudes realizadas a través de cualquiera de los formularios y/o formas de contacto que ponemos a su disposición en la APLICACIÓN (art. 6.1.f. RGPD)</li>
                    <li>Para la ejecución de un contrato o precontrato en la gestión, mantenimiento, mejora o desarrollo de los servicios prestados, así como para gestionar su compra o pedido online, procesar el pago y proceder al envío del mismo (art. 6.1.b. RGPD)</li>
                    <li>Por el consentimiento del USUARIO para la remisión de comunicaciones comerciales objeto de esta APLICACIÓN, a través de medios electrónicos presentes o futuros y la participación en sorteos y/o promociones (art. 6.1.a. RGPD). Se otorga con anterioridad al primer acceso a las funcionalidades de la App y que puede ser revocado en cualquier momento. </li>
                </ul>
            </div>
            <h3>¿Durante cuánto tiempo guardaremos sus datos personales?</h3>
            <p>Conservaremos sus datos personales durante no más tiempo del necesario para mantener el fin del tratamiento, es decir, mientras dure la relación contractual objeto del uso de la APLICACIÓN (incluyendo la obligación de conservarlos durante los plazos de prescripción aplicables), y cuando ya no sean necesarios para tal fin, se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización o la destrucción total de los mismos.</p>
            <h3>¿A quién facilitamos sus datos personales?</h3>
            <div>
                Sus datos personales se comunicarán a:
                <ul>
                    <li>Las Administraciones Públicas y otras entidades privadas para el cumplimiento de las obligaciones legales a las que FARMACIA está sujeta por sus actividades.</li>
                    <li>Los proveedores que precisen acceder a los datos personales del usuario para la prestación de los servicios que FARMACIA los haya contratado, o que el propio funcionamiento de los servicios electrónicos (aplicación, página web y correos electrónicos) puedan tener acceso a determinados datos personales, como es el caso de CONSOFT. FARMACIA tiene suscritos los contratos de confidencialidad y de encargo de tratamiento de datos personales necesarios y exigidos por la normativa para proteger su privacidad (art. 28.3 RGPD)</li>
                </ul>
            </div>
            <h3>¿Cuáles son los derechos que le asisten como usuario?</h3>
            <div>
                <ul>
                    <li>Derecho a retirar el consentimiento en cualquier momento.</li>
                    <li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.</li>
                    <li>Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es ) si considera que el tratamiento no se ajusta a la normativa vigente.</li>
                </ul>
            </div>
            <h3>Carácter obligatorio o facultativo de la información facilitada por el usuario.</h3>
            <p>Los usuarios, mediante la marcación de las casillas correspondientes y entrada de datos en los campos, marcados con un asterisco (*) en los formularios de la APLICACIÓN, aceptan expresamente y de forma libre e inequívoca que sus datos personales son necesarios para atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos restantes. El usuario garantiza que los datos personales facilitados a FARMACIA son veraces y se hace responsable de comunicar cualquier modificación de los mismos.</p>
            <p>FARMACIA informa de que todos los datos solicitados a través de la APLICACIÓN son obligatorios, ya que son necesarios para la prestación de un servicio óptimo al Usuario. En caso de que no se faciliten todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.</p>

            <h3> Medidas de seguridad</h3>
            <p>De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, FARMACIA, como responsable del tratamiento de los datos del USUARIO, está cumpliendo con todas las disposiciones de las normativas RGPD y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.</p>
            <p>FARMACIA garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el RGPD y la LOPDGDD con el fin de proteger los derechos y libertades de los usuarios y les ha comunicado la información adecuada para que puedan ejercerlos.</p>
            <p>Toda transferencia de información que la APLICACIÓN realiza con servidores en la nube (cloud), propios o de terceros, se realiza de manera cifrada y segura a través de un protocolo seguro de transferencia de hipertexto (HTTPS), que además garantiza que la información no pueda ser interceptada.</p>
            <h3> Datos de contacto para ejercer sus derechos o para más información sobre las garantías de su privacidad:</h3>
            <p>Datos de contacto de FARMACIA que se encuentran en el apartado “contacto” de la APLICACIÓN y en la pantalla de inicio. </p>
            <p>Asimismo, se podrá contactar con CONSOFT, como encargado de tratamiento, a través de los medios facilitados en el apartado 1 del presente Aviso Legal. CONSOFT comunicará inmediatamente a FARMACIA cualquier solicitud de derechos ejercitada por cualquier USUARIO de la APLICACIÓN.</p>

            <!-- ****************************************** -->

            <h2>8. Exclusión de Responsabilidad.</h2>

            <p>Tanto CONSOFT como la FARMACIA se eximen de cualquier tipo de responsabilidad derivada de la información publicada en la APLICACIÓN siempre que esta información haya sido manipulada o introducida por un tercero ajeno al mismo.</p>
            <p>La responsabilidad del uso de la APLICACIÓN corresponde sólo al usuario. Salvo lo establecido en estos Términos y Condiciones, CONSOFT y FARMACIA no son responsables de ninguna pérdida o daño que se produzca en relación con la descarga o el uso de la APLICACIÓN, tales como los producidos a consecuencia de fallos, averías o bloqueos en el funcionamiento de la APLICACIÓN (por ejemplo, y sin carácter limitativo: error en las líneas de comunicaciones, defectos en el hardware o software de la APLICACIÓN o fallos en la red de Internet). Igualmente, CONSOFT y FARMACIA tampoco son responsables de los daños producidos a consecuencia de un uso indebido o inadecuado de la APLICACIÓN por parte de los usuarios. </p>
            <p>CONSOFT y FARMACIA no serán responsables del uso de la APLICACIÓN por un menor de edad, siendo la descarga y uso de la APLICACIÓN exclusiva responsabilidad del usuario.</p>
            <p>El usuario responderá de los daños y perjuicios de toda naturaleza que la FARMACIA o CONSOFT puedan sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las cuales queda sometido en virtud del presente documento legal o de la legislación aplicable.</p>
            <p>La APLICACIÓN se presta «tal y como es» y sin ninguna clase de garantía. CONSOFT no se hace responsable de la calidad final de la APLICACIÓN, ni de que esta sirva y cumpla con todos los objetivos de la misma. No obstante lo anterior, CONSOFT se compromete en la medida de sus posibilidades a contribuir en la mejora de la calidad de la APLICACIÓN, pero no puede garantizar la precisión ni la actualidad del contenido de la misma.</p>

            <h3>Modificaciones.</h3>
            <p>FARMACIA Y CONSOFT se reservan el derecho de editar, actualizar, modificar, suspender, eliminar o finalizar los servicios ofrecidos por la APLICACIÓN, incluyendo todo o parte de su contenido, sin necesidad de previo aviso, así como de modificar la forma o tipo de acceso a ésta. </p>
            <p>Las posibles causas de modificación pueden tener lugar por motivos tales como su adaptación a las posibles novedades legislativas y cambios en la propia APLICACIÓN, así como a las que se puedan derivar de los códigos tipo existentes en la materia o por motivos estratégicos o corporativos.</p>
            <h3>Enlaces.</h3>
            <p>La APLICACIÓN puede incluir enlaces o las páginas web de terceros a través de la APLICACIÓN, como links a La Farmacia, dispositivos técnicos de enlace, directorios o incluso instrumentos de búsqueda que permitan al usuario a acceder a otras páginas.</p>
            <p>La Farmacia no se responsabiliza de la exactitud o disponibilidad de la información, el material o el contenido incluido en los enlaces externos. Tampoco se responsabiliza del contenido, funcionamiento, condiciones de uso ni políticas de privacidad de los sitios a los que se redireccione.</p>
            <h3>Promociones.</h3>
            <p>La APLICACIÓN puede presentar diversas promociones generadas por la farmacia. En este caso, La FARMACIA es la única responsable de asegurarse que el material remitido para su inclusión de la APLICACIÓN cumple con la normativa vigente.</p>
            <p>La FARMACIA no será responsable de cualquier error, inexactitud o irregularidad en la que puedan incurrir los contenidos promocionales.</p>

            <h2>9. Obligaciones de los usuarios.</h2>
            <div>
                <ul>
                    <li>El acceso y el uso en la APLICACIÓN está sujeto en todo momento al cumplimiento de los presentes Términos y Condiciones de Uso.</li>
                    <li>El acceso y uso en la APLICACIÓN es única y exclusivamente para las finalidades establecidas en el apartado 2, 5 y 7 del presente documento.</li>
                    <li>Hará un uso responsable y razonable de la APLICACIÓN y de sus contenidos, según las posibilidades y finalidades por las cuales están concebidas respectivamente.</li>
                    <li>Es responsable de mantener la confidencialidad de sus datos de acceso. En caso de tener conocimiento de un uso indebido, el USUARIO tiene que comunicarlo a la FARMACIA.</li>
                </ul>
            </div>

            <h2>10. Normas de uso.</h2>
            <div>
                Queda expresamente prohibido:
                <ul>
                    <li>Utilizar cualquiera de los contenidos y servicios, con finalidades o efectos ilícitos, prohibidos en el presente documento legal y otras condiciones aplicables, lesivos de los derechos e intereses de terceros o, que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de la APLICACIÓN.</li>
                    <li>Utilizar contenidos y servicios que se encuentren protegidos por cualquier derecho de propiedad intelectual o industrial pertenecientes a CONSOFT, la FARMACIA o a terceros, sin que el USUARIO haya obtenido previamente de sus titulares la autorización necesaria para llevar a cabo el uso que efectúa o pretende efectuar.</li>
                    <li>Reproducir, copiar, distribuir, comunicar, transformar o modificar los contenidos de la APLICACIÓN salvo que se cuente con la autorización de la FARMACIA.</li>
                    <li>Incorporar virus u otros elementos físicos o electrónicos que puedan dañar o impedir el normal funcionamiento de la APLICACIÓN, así como de la red, el sistema o dispositivos de la FARMACIA, de CONSOFT o de terceros, o que puedan dañar los documentos electrónicos y archivos almacenados en éstos. </li>
                    <li>Interferir o manipular cualquier sistema de clasificación o sistema de respuesta de los usuarios. </li>
                    <li>Presentar o aportar cualquier información o comentario sobre otra persona sin el permiso de esa persona. </li>
                </ul>
            </div>
            <div>
                El incumplimiento de alguna o todas las normas de uso incluidas en esta cláusula constituye un incumplimiento de los Términos y Condiciones de Uso, dando el derecho a la FARMACIA a tomar todas o algunas de las siguientes acciones (con o sin previo aviso):
                <ul>
                    <li>Retirada inmediata, temporal o permanente del derecho del usuario a utilizar la APLICACIÓN.</li>
                    <li>Retirada inmediata, temporal o permanente de cualquier contenido del USUARIO.</li>
                    <li>Emisión de una advertencia al usuario.</li>
                    <li>Emprender acciones legales contra el usuario, incluyendo procedimientos para el reembolso de todos los costes (incluyente, pero no limitado a, gastos administrativos y legales razonables) resultantes de a infracción</li>
                    <li>La divulgación de esta información a las autoridades policiales y judiciales competentes.</li>
                </ul>
            </div>
            <h2>11. Legislación y fuero.</h2>

            <p>El USUARIO acepta que la legislación aplicable y los Juzgados y Tribunales competentes para conocer de las divergencias derivadas de la interpretación o aplicación de este clausurado son los españoles, y se somete, con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales más cercanos al domicilio de la FARMACIA.</p>
        </div>
    </v-container>
</template>
<script>
import { store } from '@/store';
export default {
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
    methods: {
        volver() {
            this.$router.go(-1);
        },
    },
};
</script>
