<template>
    <!-- El orden de los 3 componentes (navigation drawer, app bar y bottom navigation) cual aparece por arriba del otro -->
    <the-navigation-drawer
        :is-open="drawer || !$vuetify.display.mdAndDown"
        :mensajes-badge-count="mensajesBadgeCount"
        @update:is-open="drawer = $event" />

    <the-app-bar
        v-model:buscador-visible="buscadorVisible"
        :cesta-badge-count="cestaBadgeCount"
        :notificaciones-badge-count="notificacionesBadgeCount"
        @drawer-toggled="drawer = !drawer" />

    <!-- La diferencia entre v-if y :active es que el primero elimina el componente del DOM instantaneamente, mientras que el segundo lo mantiene oculto
        haciendo una animación de transición. En este caso, el bottom navigation se mantiene oculto, pero no se elimina del DOM. -->
    <the-bottom-navigation
        v-if="!tecladoVisible"
        v-model:buscador-visible="buscadorVisible"
        :notificaciones-badge-count="notificacionesBadgeCount"
        :active="bottomNavigationActive"
        :aria-hidden="!bottomNavigationActive" />

    <v-main>
        <cookies-banner v-if="esWeb && $route.name !== 'Install'" />
        <router-view v-slot="{ Component }">
            <!--
            Preservamos un máximo de 10 componentes en el cache LRU.
            Para simplificar, solo incluimos los componentes que queremos mantener en el cache.
            Con qué criterio decidimos qué componentes mantener en el cache? Todos los componentes que tengan
            una lista larga de elementos (estilo infinite loading) y que tenga hipervínculos a otros componentes
            son buenos candidatos para mantener, ya que esto hace que cuando el usuario vuelva atrás
            se conserve el estado de la lista y no se tenga que volver a cargar. Posiblemente tambien se quiera
            combinar con "preserveScrollPosition" en el router. (ver router/routes.js).
            -->
            <keep-alive :max="10" :include="keepAliveComponents">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </v-main>
</template>

<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import CookiesBanner from './CookiesBanner.vue';
import TheNavigationDrawer from './TheNavigationDrawer.vue';
import TheBottomNavigation from './TheBottomNavigation.vue';
import TheAppBar from './TheAppBar.vue';
import PushNotificationsService from '../../support/PushNotificationsService';
import HeartbeatService from '../../support/HeartbeatService';
import { App } from '@capacitor/app';

export default {
    components: {
        CookiesBanner,
        TheBottomNavigation,
        TheNavigationDrawer,
        TheAppBar,
    },
    data: () => ({
        /**
         * Indica si el navigation drawer está abierto.
         *
         * @type {boolean}
         */
        drawer: false,

        /**
         * Indica si el buscador está expandido.
         *
         * @type {boolean}
         */
        buscadorVisible: false,

        /**
         * Indica si el teclado virtual del dispositivo está abierto.
         *
         * @type {boolean}
         */
        tecladoVisible: false,

        /**
         * Indica la versión actual de los datos de la GUI. Este numero es diferente a la version de datos GUI
         * del HeartbeatService, ya que este numero se incrementa cada vez que se cambia la GUI, mientras que
         * el numero del HeartbeatService se incrementa cada vez que se recibe un nuevo valor del servidor.
         *
         * @type {number}
         */
        versionDatosGuiActual: 0,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },
        esWeb() {
            return !Capacitor.isNativePlatform();
        },
        bottomNavigationActive() {
            return this.$vuetify.display.mdAndDown;
        },
        cestaBadgeCount() {
            return store.cesta.badge;
        },
        notificacionesBadgeCount() {
            return store.notificaciones.badge;
        },
        mensajesBadgeCount() {
            return store.mensajes.badge;
        },
        keepAliveComponents() {
            // IMPORTANTE: Si se agrega un componente aquí, debe tener el nombre definido en el atributo "name" del componente de forma explícita
            // ya que vue no puede inferir el nombre del componente automáticamente.
            // Posiblemente se quiera combinar con "preserveScrollPosition" en el router. (ver router/routes.js).
            return store.keepAliveSecctions;
        },
        isLogged() {
            return store.isLogged;
        },
    },

    watch: {
        $route(){
            this.checkGuiVersion();
        },
    },

    _listeners: [], // No reactivos

    mounted() {
        // Si alguno de los servicios ya está inicializado, es un no-op
        PushNotificationsService.instance.initialize(this.$router);
        HeartbeatService.instance.start();

        if (Capacitor.isNativePlatform()) {
            this.$options._listeners.push(
                Keyboard.addListener('keyboardWillShow', () => this.tecladoVisible = true),
            );

            this.$options._listeners.push(
                Keyboard.addListener('keyboardDidHide', () => this.tecladoVisible = false),
            );
        }

        this.$options._listeners.push(
            App.addListener('appStateChange', async({ isActive }) => {
                if (isActive) {
                    await HeartbeatService.instance.start();
                    this.checkGuiVersion(); // Si se activa el evento 'guiChanged', se debe comentar esta linea ya que sino se ejecutaría dos veces.
                } else {
                    HeartbeatService.instance.stop();
                }
            }),
        );

        this.versionDatosGuiActual = store.farmacia.versiondatosgui;

        // jhm.ciberman: A Rafa no le gusta que se recargue la página cuando se cambia la GUI cada 30 segundos.
        // Lo desactivo por ahora, dejo solo el watch de $route. Se puede volver a reactivar en un futuro si se desea.
        //HeartbeatService.instance.addListener('guiChanged', this.checkGuiVersion);

        //Hidratar tienePuntos
        if(this.isLogged){
            store.checkPuntos();
        }
    },

    unmounted() {
        HeartbeatService.instance.stop();

        this.$options._listeners.forEach((listener) => listener.remove());

        //HeartbeatService.instance.removeListener('guiChanged', this.checkGuiVersion);
    },

    methods: {
        checkGuiVersion() {
            // Solo checkeamos la version de la GUI en rutas que no contengan formularios, ya que si el usuario
            // está escribiendo en un formulario y se refresca la página, se pierden los datos.
            if (this.$route.meta?.noGuiReload) return;

            // Además, si el usuario está usando el buscador, no queremos refrescar la página.
            if (this.buscadorVisible) return;

            // Si la version de datos de la GUI cambió, recargamos la página.
            const versionDatosGui = HeartbeatService.instance.versionDatosGui;
            if (versionDatosGui !== -1 && versionDatosGui > this.versionDatosGuiActual) {
                this.versionDatosGuiActual = versionDatosGui;
                window.location.reload();
            }
        },
    },
};


</script>
