<template>
    <v-container>
        <v-card max-width="600" class="mx-auto">
            <div class="bg-info text-on-info pa-8 d-flex align-center">
                <v-icon icon="mdi-power-plug-off" size="62" />
                <h1 class="d-h4 d-inline ml-6">
                    {{ $t('dialogDesvincularFarmacia.desconectarApproxDeMiFarmacia') }}
                </h1>
            </div>
            <div class="pa-8">
                <p>{{ $t('dialogDesvincularFarmacia.estoDesconectaraApprox', [farmaciaNombre]) }}</p>
                <p class="mt-4 font-weight-bold">
                    {{ $t('dialogDesvincularFarmacia.estasSeguro') }}
                </p>

                <v-btn link block color="info" class="mt-8" :loading="loading" @click="desvincular">
                    {{ $t('dialogDesvincularFarmacia.siDesconectar') }}
                </v-btn>
                <v-btn link block variant="text" color="info" class="mt-2" @click="$router.back()">
                    {{ $t('dialogDesvincularFarmacia.cancelar') }}
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import { store } from '@/store';

export default {
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,
    }),
    computed: {
        farmaciaNombre() {
            return store.farmacia?.nombre ?? ''; // Cuando se desvincula, se borra la farmacia del store, por eso es null safe
        },
    },
    methods: {
        async desvincular() {
            this.loading = true;
            try {
                await store.desvincularFarmacia();
            } catch (error) {
                console.error(error); // No hay mucho que se pueda hacer :( aunque nunca debería fallar
            } finally {
                this.loading = false;
                await this.$router.push({ name: 'VincularFarmacia' });
            }
        },
    },
};
</script>
