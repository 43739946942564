<template>
    <div v-if="mensajes === null && loading" class="text-center d-flex h-100 text-center align-center justify-center">
        <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" />
    </div>
    <div v-else-if="mensajes?.length === 0" class="text-medium-emphasis pa-8 d-flex h-100 text-center align-center justify-center">
        {{ $t('mensajesView.noHayMensajesQueMostrar') }}
    </div>
    <div v-for="mensaje in mensajes" v-else :key="`${mensaje.autor}_${mensaje.idmensaje}`">
        <div v-if="mensaje.autor === 'U'" class="mt-2 text-right">
            <div class="text-body-2 text-medium-emphasis" :title="formatFecha(mensaje.fechahora)">
                <b>{{ $t('pedidoCard.tu') }}</b>, el {{ formatFecha(mensaje.fechahora) }}
            </div>
            <v-sheet class="py-2 px-4 d-inline-block rounded-xl rounded-te-0" color="secondary">
                {{ mensaje.mensaje }}
            </v-sheet>
        </div>
        <div v-else class="mt-2">
            <div class="text-body-2 text-medium-emphasis" :title="formatFecha(mensaje.fechahora)">
                <b>{{ farmaciaNombre }}</b>, el {{ formatFecha(mensaje.fechahora) }}
            </div>
            <v-sheet class="py-2 px-4 d-inline-block rounded-xl rounded-ts-0" color="surface" :border="true">
                {{ mensaje.mensaje }}
            </v-sheet>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        /**
         * El nombre de la farmacia con la que se está chateando.
         *
         * @type {String}
         */
        farmaciaNombre: {
            type: String,
            required: true,
        },

        /**
         * Los mensajes que se van a mostrar, o null si no se han cargado aún.
         *
         * @type {{ idmensaje: Number, autor: String, mensaje: String, fechahora: String }[]|null}
         */
        mensajes: {
            type: [Array, null],
            required: true,
        },

        /**
         * Indica si se debe mostrar el indicador de carga.
         *
         * @type {Boolean}
         */
        loading: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        formatFecha(fechahora) {
            return moment(fechahora).format('LLL');
        },
    },
};
</script>
