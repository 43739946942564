<template>
    <v-container>
        <recurso-no-encontrado-card />
    </v-container>
</template>

<script>
import RecursoNoEncontradoCard from '@/components/RecursoNoEncontradoCard.vue';

export default {
    components: {
        RecursoNoEncontradoCard,
    },
};
</script>
