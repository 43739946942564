<template>
    <v-container>
        <v-row justify="center" class="mb-6">
            <v-col cols="12" md="8" lg="6">
                <v-row>
                    <v-col class="text-center mt-4 mb-6">
                        <h1 class="text-primary text-h4 font-weight-bold mb-2">
                            {{ $t('restablecerContrasenaView.restablecerContrasena') }}
                        </h1>
                        <p class="text-medium-emphasis">
                            {{ $t('restablecerContrasenaView.introduceTuDireccionDeEmail') }}
                        </p>
                    </v-col>
                </v-row>

                <v-sheet color="surface" elevation="1" rounded>
                    <oops-alert
                        v-if="error"
                        :error="error"
                        variant="flat"
                        class="rounded-b-0" />
                    <v-alert
                        v-else-if="completado"
                        type="success"
                        class="rounded-b-0"
                        :text="$t('restablecerContrasenaView.teHemosEnviadoUnEmail')" />

                    <v-form ref="reestablecerForm" v-model="valid" lazy-validation class="pa-6">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    :label="$t('restablecerContrasenaView.e-mail')"
                                    required
                                    hide-details="auto"
                                    autocapitalize="off" />
                            </v-col>
                            <v-col v-if="esWeb() && consentimientoCookies" cols="12">
                                <vue-recaptcha
                                    sitekey="6Le-IPkZAAAAABTpYJnhCu-Z2gE2jVESYwnhESz7"
                                    :load-recaptcha-script="consentimientoCookies"
                                    class="g-recaptcha" />
                            </v-col>
                            <v-col v-if="!consentimientoCookies" cols="12">
                                <v-sheet color="error" class="pa-2">
                                    {{ $t('restablecerContrasenaView.atencionDebesActivar') }}
                                    <v-btn @click="aceptarTodas()">
                                        {{ $t('restablecerContrasenaView.aceptarTodas') }}
                                    </v-btn>
                                </v-sheet>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12">
                                <v-btn
                                    size="x-large"
                                    block
                                    :disabled="!valid || !consentimientoCookies"
                                    color="primary"
                                    :loading="loading"
                                    @click="reestablecer">
                                    {{ $t('restablecerContrasenaView.restablecer') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import OopsAlert from '@/components/OopsAlert.vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { Capacitor } from '@capacitor/core';
import { store } from '@/store';

export default {
    components: {
        VueRecaptcha,
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si el formulario es válido.
         *
         * @type {boolean}
         */
        valid: false,

        /**
         * El email del usuario.
         *
         * @type {string}
         */
        email: '',

        /**
         * Indica si el proceso de restablecer la contraseña se ha completado.
         *
         * @type {boolean}
         */
        completado: false,

        /**
         * Indica si el usuario ha aceptado las cookies.
         *
         * @type {boolean}
         */
        consentimientoCookies: false,

        /**
         * La respuesta de recaptcha.
         *
         * @type {string|null}
         */
        recaptchaResponse: null,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },

        emailRules() {
            return [
                (v) => !!v || this.$t('restablecerContrasenaView.requerido'),
                (v) => /.+@.+\..+/.test(v) || this.$t('restablecerContrasenaView.introduceUnEmailValido'),
            ];
        },
    },
    created() {
        let analiticasPublicitarias;
        let activarAnaliticas;
        let activarPublicitarias;
        if (this.$cookies.isKey('cookiescfg')) {
            analiticasPublicitarias = this.$cookies.get('cookiescfg').split(',');
            activarAnaliticas = analiticasPublicitarias[0] === '1';
            activarPublicitarias = analiticasPublicitarias[1] === '1';
        }
        if (!this.esWeb() || (activarAnaliticas && activarPublicitarias)) {
            this.consentimientoCookies = true;
        }
    },
    methods: {
        async reestablecer() {
            const recaptchaResponse = (this.esWeb() && window.grecaptcha.getResponse())
                ? window.grecaptcha.getResponse()
                : null;
            if (!this.$refs.reestablecerForm.validate()) {
                return;
            }

            this.loading = true;
            this.error = null;

            try {
                await axios.post('/forgot-password', {
                    numserie: this.farmacia.numserie, // TODO: Remover cuando el endpoint use `X-Approx-Subdomain`
                    email: this.email,
                    recaptcha: recaptchaResponse,
                });

                this.completado = true;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
                this.$refs.reestablecerForm.reset();
                window.grecaptcha.reset();
            }
        },

        aceptarTodas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '1,1');
            this.consentimientoCookies = true;
        },

        esWeb() {
            return !Capacitor.isNativePlatform();
        },
    },
};
</script>
