<template>
    <v-container>
        <div>
            <v-btn prepend-icon="mdi-arrow-left" @click="$router.back()">
                {{ $t('volver') }}
            </v-btn>
        </div>

        <v-alert
            v-if="!$i18n.locale.startsWith('es')"
            type="info"
            class="mt-4">
            {{ $t('avisoIdiomaSoloEspañol') }}
        </v-alert>

        <div class="pb-12 pt-6 texto-legal">
            <h1>POLÍTICA DE PRIVACIDAD</h1>
            <h2>1. Información al usuario.</h2>
            <h3>¿Quién es el responsable del tratamiento de tus datos personales?</h3>
            <p>En cumplimiento de lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (RGPD) y la Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD), se informa que la FARMACIA ({{ farmacia.rgpddenominacionsocial }}, NIF: {{ farmacia.rgpdnif }}) que el USUARIO ha solicitado vincularse es la responsable del tratamiento de los datos personales del USUARIO y que CONSOFT es encargado del tratamiento de dichos datos ya que es el titular de la APLICACIÓN que los recopila y procesa. Una vez acceda a la APLICACIÓN y proceda a su registro inicial, se informará de la política de privacidad específica de dicha FARMACIA.</p>
            <p>Ambos suscriben que los datos son tratados de conformidad con el RGPD, la LOPDGDD y cualquier normativa posterior que le sea de aplicación. </p>
            <p>La FARMACIA ({{ farmacia.rgpddenominacionsocial }}, NIF: {{ farmacia.rgpdnif }}) como responsable del tratamiento de datos, será la responsable de cumplir con la normativa vigente, al margen de que CONSOFT cumpla con las obligaciones exigibles como encargado del tratamiento.  </p>
            <h3>¿Para qué tratamos tus datos personales y por qué lo hacemos?</h3>
            <div>
                El tratamiento de los datos a través de la APLICACIÓN, tendrán por objeto cumplir con las funcionalidades descritas en los apartados 2 y 5 de los Términos y Condiciones de Uso, con la siguiente base de legitimación:
                <ul>
                    <li>
                        Gestionar, mantener, mejorar o desarrollar los servicios prestados, incluyendo notificaciones relacionadas con la prestación de los mismos. Se le remitirán notificaciones a través de la APLICACIÓN y correo electrónico sobre información y seguimiento del pedido, en su caso, así como otras comunicaciones técnicas o necesarias relacionadas con la APLICACIÓN y su cuenta de usuario.
                        (para la ejecución de un contrato o precontrato, 6.1.b RGPD)
                    </li>
                    <li>
                        Gestionar su compra o pedido online, procesar el pago y proceder al envío del mismo, en base a las Condiciones Generales de Contratación.
                        (para la ejecución de un contrato o precontrato, 6.1.b RGPD)
                    </li>
                    <li>
                        Dar respuesta a las consultas o cualquier tipo de petición que sea realizada por el usuario a través de cualquiera de las formas de contacto que se ponen a su disposición en la APLICACIÓN.
                        (por el interés legítimo del responsable, art. 6.1.f RGPD)
                    </li>
                    <li>
                        Realizar análisis estadísticos y estudios de mercado.
                        (por el interés legítimo del responsable, art. 6.1.f RGPD)
                    </li>
                    <li>
                        Realizar encuestas de satisfacción y calidad.
                        (por el interés legítimo del responsable, art. 6.1.f RGPD)
                    </li>
                    <li>
                        Remitirle comunicaciones comerciales mediante notificaciones push (mensajes instantáneos) en su dispositivo o cualquier otro medio electrónico presente o futuro que posibilite realizar comunicaciones comerciales relacionadas con los productos y servicios del responsable o de sus colaboradores o proveedores, con los que se haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos personales. En caso de no otorgar o revocar consentimiento para el envío de mensajes instantáneos a su dispositivo, podrá acceder cuando lo desee a la información comercial que la Farmacia pone a disposición de los usuarios a través de la APLICACIÓN.
                        (por el consentimiento del interesado, 6.1.a. RGPD)
                    </li>
                    <li>
                        Participar en sorteos, concursos, promociones u otros servicios ofrecidos por el responsable. (por el consentimiento del interesado, 6.1.a RGPD)
                    </li>
                </ul>
            </div>
            <h3>¿Durante cuánto tiempo guardaremos sus datos personales?</h3>
            <p>Conservaremos sus datos personales durante no más tiempo del necesario para mantener el fin del tratamiento, es decir, mientras dure la relación contractual objeto del uso de la APLICACIÓN (incluyendo la obligación de conservarlos durante los plazos de prescripción aplicables), y cuando ya no sean necesarios para tal fin, se suprimirán con medidas de seguridad adecuadas para garantizar la anonimización o la destrucción total de los mismos.</p>
            <h3>¿A quién facilitamos sus datos personales?</h3>
            <div>
                Sus datos personales se comunicarán a:
                <ul>
                    <li>Las Administraciones Públicas y otras entidades privadas para el cumplimiento de las obligaciones legales a las que FARMACIA está sujeta por sus actividades.</li>
                    <li>Los proveedores que precisen acceder a los datos personales del usuario para la prestación de los servicios que FARMACIA los haya contratado, o que el propio funcionamiento de los servicios electrónicos (aplicación, página web y correos electrónicos) puedan tener acceso a determinados datos personales, como es el caso de CONSOFT. FARMACIA tiene suscritos los contratos de confidencialidad y de encargo de tratamiento de datos personales necesarios y exigidos por la normativa para proteger su privacidad (art. 28.3 RGPD)</li>
                </ul>
            </div>
            <h3>¿Cuáles son los derechos que le asisten como usuario?</h3>
            <div>
                <ul>
                    <li>Derecho a retirar el consentimiento en cualquier momento.</li>
                    <li>Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.</li>
                    <li>Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es ) si considera que el tratamiento no se ajusta a la normativa vigente.</li>
                </ul>
            </div>
            <h3>Carácter obligatorio o facultativo de la información facilitada por el usuario.</h3>
            <p>Los usuarios, mediante la marcación de las casillas correspondientes y entrada de datos en los campos, marcados con un asterisco (*) en los formularios de la APLICACIÓN, aceptan expresamente y de forma libre e inequívoca que sus datos personales son necesarios para atender su petición, por parte del prestador, siendo voluntaria la inclusión de datos en los campos restantes. El usuario garantiza que los datos personales facilitados a FARMACIA son veraces y se hace responsable de comunicar cualquier modificación de los mismos.</p>
            <p>FARMACIA informa de que todos los datos solicitados a través de la APLICACIÓN son obligatorios, ya que son necesarios para la prestación de un servicio óptimo al Usuario. En caso de que no se faciliten todos los datos, no se garantiza que la información y servicios facilitados sean completamente ajustados a sus necesidades.</p>

            <h3> Medidas de seguridad</h3>
            <p>De conformidad con lo dispuesto en las normativas vigentes en protección de datos personales, FARMACIA, como responsable del tratamiento de los datos del USUARIO, está cumpliendo con todas las disposiciones de las normativas RGPD y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el artículo 5 del GDPR, por los cuales son tratados de manera lícita, leal y transparente en relación con el interesado y adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados.</p>
            <p>FARMACIA garantiza que ha implementado políticas técnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el RGPD y la LOPDGDD con el fin de proteger los derechos y libertades de los usuarios y les ha comunicado la información adecuada para que puedan ejercerlos.</p>
            <p>Toda transferencia de información que la APLICACIÓN realiza con servidores en la nube (cloud), propios o de terceros, se realiza de manera cifrada y segura a través de un protocolo seguro de transferencia de hipertexto (HTTPS), que además garantiza que la información no pueda ser interceptada.</p>
            <h3> Datos de contacto para ejercer sus derechos o para más información sobre las garantías de su privacidad:</h3>
            <p>Datos de contacto de FARMACIA que se encuentran en el apartado “contacto” de la APLICACIÓN y en la pantalla de inicio. </p>
            <p>Asimismo, se podrá contactar con CONSOFT, como encargado de tratamiento, a través de los medios facilitados en el apartado 1 del presente Aviso Legal. CONSOFT comunicará inmediatamente a FARMACIA cualquier solicitud de derechos ejercitada por cualquier USUARIO de la APLICACIÓN.</p>
        </div>
    </v-container>
</template>
<script>
import { store } from '@/store';
export default {
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
};
</script>
