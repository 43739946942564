<template>
    <v-app id="app" :key="componentKey">
        <div v-if="error" class="d-flex justify-center align-center w-100 h-100">
            <no-disponible-card :error="error" :farmacia-nombre="farmaciaNombreDisplay" @reintentar="load" />
        </div>

        <div v-else-if="loading" class="d-flex align-center justify-center flex-column w-100 h-100">
            <div v-if="farmaciaNombreDisplay" class="text-h4 mb-8 mx-4">
                {{ farmaciaNombreDisplay }}
            </div>
            <v-progress-circular :width="5" :size="50" indeterminate />
        </div>

        <router-view v-else />
    </v-app>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { store } from './store';
import SubdominioHelper from './support/SubdominioHelper';
import NoDisponibleCard from './components/NoDisponibleCard.vue';
import StorageHelper from './support/StorageHelper';
import axios from 'axios';
import { mergeLocaleMessages } from './i18n';
import HeartbeatService from './support/HeartbeatService';
import ApproxStatusCode from './enums/ApproxStatusCode';

class NoSubdominioError extends Error {
    constructor() {
        super('No hay ningun subdominio vinculado');
    }
}

export default {
    components: {
        NoDisponibleCard,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * El nombre de la farmacia que se debe mostrar mientras se muestra el spinner de carga.
         * No se puede usar el nombre de la farmacia del store ya que aún no se ha cargado.
         *
         * @type {string|null}
         */
        farmaciaNombre: null,
        componentKey: 0,
    }),
    computed: {
        farmaciaNombreDisplay() {
            return this.farmaciaNombre || store.farmacia?.nombre || null;
        },
    },
    created() {
        this.load();
    },
    mounted() {
        HeartbeatService.instance.addListener('noDisponible', this.refreshWithError);
    },
    methods: {
        /**
         * Carga la farmacia vinculada. Si no hay subdominio vinculado, se lanza una excepción de tipo NoSubdominioError.
         *
         * @returns {Promise<void>}
         * @throws {NoSubdominioError} Si no hay subdominio vinculado.
         */
        async loadFarmacia() {
            const subdominio = await SubdominioHelper.getSubdominio();
            if (!subdominio) {
                throw new NoSubdominioError();
            }

            await store.vincularFarmacia(subdominio).then(() => {
                this.error = null;
            });
        },

        /**
         * Carga los mensajes de traducción desde el servidor.
         *
         * @returns {Promise<void>}
         */
        async loadTranslations() {

            const { data } = await axios.get('/traducciones');

            await store.setIdiomasSoportados(Object.keys(data));
            mergeLocaleMessages(data);
        },

        /**
         * Carga la farmacia vinculada. Si no hay farmacia vinculada, redirige a la página de vinculación de farmacia.
         *
         * @returns {Promise<void>}
         */
        async load() {
            this.loading = true;
            this.farmaciaNombre = Capacitor.isNativePlatform()
                ? (await StorageHelper.getJson('farmacia'))?.nombre // Queremos mostrar el nombre de la farmacia mientras se carga la app. Usamos los datos cacheados en el storage.
                : null;

            try {
                await this.loadTranslations();

                // Se obtiene la farmacia vinculada.
                await this.loadFarmacia();

                // En mobile, al llegar a este punto, la ruta actual debería ser la de VincularFarmacia ya que
                // los navigation guards impiden que se acceda a otras rutas si no hay farmacia vinculada.
                // Es por eso que al terminar de cargar la farmacia, ahora si podemos redirigir de forma segura
                // a la página de inicio.
                // En web esto no aplica ya que el navigation guard no aplica en web ya que los datos de la
                // farmacia se hidratan desde el backend en el html.
                // Checkeamos explícitamente por $route.name === 'VincularFarmacia' en lugar de $route.name !== 'Home'
                // ya que existe la posibilidad de que la app de Vue se inice con la ruta de "/error-pago", "/pago-ok/:id" o "/pedido-pendiente"
                // luego de volver de la pasarela de RedSys.
                if (Capacitor.isNativePlatform() && store.farmacia && this.$route.name === 'VincularFarmacia') {
                    const redirect = this.$route.query.redirect;
                    if (redirect && this.$router.resolve({ path: redirect }).name !== 'Error404') {
                        await this.$router.push({ path: this.$route.query.redirect });
                    } else {
                        await this.$router.push({ name: 'Home' });
                    }
                }
            } catch (error) {
                console.error(error);
                if (error instanceof NoSubdominioError) {
                    this.$router.push({ name: 'VincularFarmacia' });
                    return;
                }

                if (Capacitor.isNativePlatform() && error.response?.status === 404  && error.response.data.approx_status_code === ApproxStatusCode.NOT_FOUND) {
                    await store.desvincularFarmacia();
                    this.$router.push({ name: 'VincularFarmacia' });
                    return;
                }

                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async refreshWithError() {
            this.componentKey += 1;
            this.error = HeartbeatService.instance.error;
        },
    },
};
</script>
