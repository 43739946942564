<template>
    <v-navigation-drawer
        v-if="farmacia"
        :model-value="isOpen"
        color="shell"
        class="text-on-shell"
        :temporary="$vuetify.display.mdAndDown"
        elevation="2"
        @update:model-value="$emit('update:isOpen', $event)">
        <router-link to="/">
            <v-img
                :src="farmaciaLogo"
                :alt="farmacia.nombre"
                :aria-label="$t('menuItems.inicio')"
                style="max-width: 200px; max-height: 100px;"
                class="mx-auto ma-2" />
        </router-link>
        <Transition mode="out-in" :name="transitionAction === 'enter' ? 'scroll-x-reverse-transition' : 'scroll-x-transition'" color="shell-primary" :duration="150">
            <!-- Menu principal -->
            <v-list v-if="categoriaPadreActual === null" key="index" :selected="[$route.path]" nav rounded="xl" base-color="shell-primary" role="navigation">
                <v-list-item
                    v-if="mostrarCategorias"
                    prepend-icon="mdi-shape"
                    color="shell-primary"
                    rounded="xl"
                    class="px-4"
                    append-icon="mdi-chevron-right"
                    :title="$t('menuItems.categorias')"
                    @click.stop="transitionAction = 'enter'; categoriaPadreActual = categoriaRoot;" />
                <v-list-item
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-home"
                    exact
                    to="/"
                    :title="$t('menuItems.inicio')" />
                <v-list-item
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-mortar-pestle-plus"
                    to="/farmacia"
                    :title="$t('menuItems.contacto')" />
                <v-list-item
                    v-if="isLogged && farmacia.programapuntos && tienePuntos"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-counter"
                    to="/puntos"
                    :title="$t('menuItems.misPuntos')" />
                <v-list-item
                    v-if="isLogged && ecommerceHabilitado"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-cart-check"
                    to="/pedidos"
                    :title="$t('menuItems.misPedidos')" />
                <v-list-item
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-format-list-bulleted-type"
                    to="/consumos"
                    :title="$t('menuItems.misConsumos')" />
                <v-list-item
                    v-if="farmacia.rgpdproxdispens"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-calendar-multiple"
                    to="/dispensaciones"
                    :title="$t('menuItems.fechasDispensacion')" />
                <v-list-item
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-text-box-check-outline"
                    to="/encargos"
                    :title="$t('menuItems.misEncargos')" />
                <v-list-item
                    v-if="catalogoHabilitado"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-sale"
                    to="/promociones"
                    :title="$t('menuItems.promociones')" />
                <v-list-item
                    v-if="farmacia.mensajeria"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-email-multiple-outline"
                    to="/mensajes"
                    :title="$t('menuItems.mensajes')">
                    <template #append>
                        <v-badge v-if="mensajesBadgeCount > 0" :content="mensajesBadgeCount" inline color="secondary" />
                    </template>
                </v-list-item>
                <v-list-item
                    v-if="farmacia.whatsapp"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-whatsapp"
                    :href="`https://api.whatsapp.com/send?phone=${farmacia.whatsapp}`"
                    target="_blank"
                    :title="$t('menuItems.whatsapp')" />
                <v-list-item
                    v-if="isLogged"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-qrcode"
                    to="/tarjeta"
                    :title="$t('menuItems.miTarjeta')" />
                <v-list-item
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-account-circle"
                    to="/cuenta"
                    :title="$t('menuItems.miCuenta')" />
                <v-list-item
                    v-if="farmacia.web"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-web"
                    :href="farmacia.web"
                    target="_blank"
                    :title="$t('menuItems.web')" />
                <v-list-item
                    v-if="mostrarInstalarApp"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-cellphone-arrow-down"
                    to="/install"
                    :title="$t('menuItems.instalaNuestraApp')" />
                <v-list-item
                    v-if="$i18n.availableLocales.length > 1"
                    link
                    role="button"
                    rounded="xl"
                    class="px-4"
                    prepend-icon="mdi-translate"
                    :title="$t('menuItems.idioma')">
                    <idioma-dialog activator="parent" @update:model-value="!$event ? $emit('update:isOpen', false) : null" />
                </v-list-item>
            </v-list>

            <!-- Submenú de categorías -->
            <div v-else-if="mostrarCategorias" :key="'submenu-' + categoriaPadreActual.id">
                <v-list-item
                    class="categoria-header"
                    base-color="shell-primary"
                    prepend-icon="mdi-chevron-left"
                    :title="categoriaPadreActual?.descripcion || $t('menuItems.categorias')"
                    @click.stop="atras()" />
                <v-list nav rounded="xl" base-color="shell-primary" :selected="[$route.path]">
                    <v-list-item
                        v-if="categoriaPadreActual.hijos.length > 0"
                        class="categoria-item"
                        link
                        rounded="xl"
                        base-color="shell-primary"
                        :title="categoriaPadreActual.padre === null
                            ? $t('menuItems.todosLosProductos')
                            : $t('menuItems.todoEn', [categoriaPadreActual.descripcion])"
                        @click.stop="verProductos(categoriaPadreActual)" />
                    <v-list-item
                        v-for="categoria in categoriaPadreActual.hijos"
                        :key="categoria.id"
                        link
                        color="shell-primary"
                        class="categoria-item px-4"
                        rounded="xl"
                        :value="categoria.link"
                        :title="categoria.descripcion"
                        @click.stop="categoriaClicked(categoria)">
                        <template v-if="categoria.hijos.length > 0" #append>
                            <v-icon icon="mdi-chevron-right" />
                        </template>
                    </v-list-item>
                </v-list>
            </div>
        </Transition>

        <template #append>
            <v-divider />
            <div class="pa-2 linksrgpd on-shell text-caption">
                <router-link to="/terminos-condiciones-uso" class="on-shell">
                    {{ $t('linksLegal.terminosYcondicionesDeUso.link') }}
                </router-link>
                <span class="mx-2">∙</span>
                <router-link to="/politica-privacidad" class="on-shell">
                    {{ $t('linksLegal.politicaDePrivacidad.link') }}
                </router-link>
                <span class="mx-2">∙</span>
                <router-link to="/condiciones-contratacion" class="on-shell">
                    {{ $t('linksLegal.condicionesGeneralesDeContratacion.link') }}
                </router-link>
                <template v-if="esWeb">
                    <span class="mx-2">∙</span>
                    <router-link to="/politica-cookies" class="on-shell">
                        {{ $t('linksLegal.politicaDeCookies.link') }}
                    </router-link>
                </template>
                <span class="mx-2">∙</span>
                <a class="on-shell" role="button">
                    {{ $t('linksDrawerFooter.acercaDe') }}
                    <acerca-de-dialog activator="parent" />
                </a>
                <template v-if="!esWeb">
                    <span class="mx-2">∙</span>
                    <router-link to="/desvincular-farmacia" class="on-shell">
                        {{ $t('linksDrawerFooter.desconectar') }}
                    </router-link>
                </template>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { store } from '@/store';
import AcercaDeDialog from './AcercaDeDialog.vue';
import IdiomaDialog from './IdiomaDialog.vue';
import { Device } from '@capacitor/device';

export default {
    components: {
        AcercaDeDialog,
        IdiomaDialog,
    },
    props: {
        /**
         * Indica si el drawer está abierto.
         */
        isOpen: {
            type: Boolean,
            default: false,
        },

        /**
         * El número de mensajes a mostrar en el badge de mensajes. Si es 0, no se muestra el badge.
         */
        mensajesBadgeCount: {
            type: Number,
            default: 0,
        },
    },

    emits: ['update:isOpen'],

    data() {
        return {
            /**
             * Categoría padre actualmente seleccionada. Esta es la categoría padre que se muestra actualmente
             * en el arbol de categorías con todos sus hijos o null si no hay ninguna seleccionada.
             *
             * @type {Categoria?}
             */
            categoriaPadreActual: null,

            /**
             * Categoría actualmente activa. Esta es la categoría que se está mostrando en la
             * web actualmente o null si no hay ninguna activa. Se muestra con un fondo diferente.
             *
             * @type {Categoria?}
             */
            categoriaActual: null,

            /**
             * Indica si se debe mostrar el botón de "Instalar app" en el menú. Solo se muestra en
             * dispositivos móviles en entorno web.
             *
             * @type {boolean}
             */
            mostrarInstalarApp: false,

            /**
             * La acción de transición que se está ejecutando actualmente. 'enter' indica que se está
             * entrando en un submenú y 'leave' indica que se está saliendo de un submenú.
             *
             * @type {'enter'|'leave'}
             */
            transitionAction: 'enter',

            /**
             * Los idiomas disponibles en la app.
             *
             * @type {string[]}
             */
            idiomasDisponibles: ['Inglés', 'Español'],
        };
    },

    computed: {
        farmacia() {
            return store.farmacia;
        },

        isLogged() {
            return store.isLogged;
        },

        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },

        catalogoHabilitado() {
            return store.catalogoHabilitado;
        },

        categoriaRoot() {
            return store.categoriaRoot;
        },
        /**
         * Obtiene la URL del logo de la farmacia.
         *
         * @returns {string}
         */
        farmaciaLogo() {
            return this.farmacia.rutaicono;
        },

        /**
         * Obtiene si se debe mostrar el menú de categorías.
         *
         * @returns {boolean}
         */
        mostrarCategorias() {
            return this.farmacia && this.farmacia.ecommerce !== 0 && this.categoriaRoot.hijos.length > 0;
        },

        /**
         * Obtiene si se está ejecutando la app en la web (móvil o escritorio).
         *
         * @returns {boolean}
         */
        esWeb() {
            return !Capacitor.isNativePlatform();
        },

        /**
         * Obtiene si el usuario actual tiene puntos, de cara a mostrar el acceso.
         *
         * @returns {boolean}
         */
        tienePuntos() {
            return store.tienePuntos;
        },

    },

    watch: {
        $route(to, _from) {
            if (to.name === 'Home') {
                this.categoriaPadreActual = null;
            }

            else if (to.name === 'Categoria') {
                this.onCategoriaRoute(to.params.idcategoria);
            }
        },
    },

    async created() {
        // Settea la categoría actual de acuerdo a la ruta actual
        if (this.$route.name === 'Categoria') {
            this.onCategoriaRoute(this.$route.params.idcategoria);
        }

        if (this.isWeb) {
            const { operatingSystem: os} = await Device.getInfo();
            this.mostrarInstalarApp = os === 'android' || os === 'ios';
        }
    },

    methods: {
        /**
         * Procesa el click sobre una categoría.
         *
         * @param {Categoria} categoria
         */
        categoriaClicked(categoria) {
            if (categoria.hijos.length > 0) {
                this.transitionAction = 'enter';
                this.categoriaPadreActual = categoria;
            } else {
                this.verProductos(categoria);
            }
        },

        /**
         * Navega a la página de productos de la categoría especificada.
         *
         * @param {Categoria} categoria
         */
        verProductos(categoria) {
            this.categoriaActual = categoria;
            this.$router.push(categoria.link);
        },

        /**
         * Navega a la página de productos de la categoría padre actual.
         */
        atras() {
            if (this.categoriaPadreActual) {
                this.transitionAction = 'leave';
                this.categoriaPadreActual = this.categoriaPadreActual.padre;
            }
        },

        /**
         * Maneja el evento de cambio de ruta de la página de productos.
         *
         * @param {number} categoriaId
         */
        onCategoriaRoute(categoriaId) {
            // Si la ruta es /productos/:categoria, se busca la categoría en el menú
            // Solo se muestra el menú de categorías si la categoría tiene subcategorías
            const categoria = this.categoriaRoot.findById(categoriaId);
            if (categoria) {
                this.categoriaActual = categoria;
                this.categoriaPadreActual = categoria.hijos.length > 0 ? categoria : categoria.padre;
            } else {
                this.categoriaActual = null;
                this.categoriaPadreActual = null;
            }
        },
    },
};
</script>

<style scoped>
.linksrgpd a {
    text-decoration: none;
    cursor: pointer;
}
.linksrgpd a:hover {
    text-decoration: underline;
    color: rgba(var(--v-theme-shell-primary), 1) !important;
}

.categoria-item {
    height: 2.9em !important;
    padding-left: 1em !important;
}

.categoria-header {
    height: 3.5em !important;
}

/** Sobreescribe la velocidad de la transición para que sea más rápida. Recuerda cambiarla también en <Transition :duration>. */
.scroll-x-transition-enter-active,
.scroll-x-transition-leave-active,
.scroll-x-reverse-transition-enter-active,
.scroll-x-reverse-transition-leave-active {
    transition: all 150ms ease !important;
}
</style>
