<template>
    <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarOferta" />

    <div v-else-if="loading" class="text-center d-block ma-16">
        <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" size="70" />
    </div>

    <v-container v-else-if="!oferta">
        <recurso-no-encontrado-card
            :titulo="$t('productosView.ofertaNoEncontrada')"
            :descripcion="$t('productosView.ofertaNoEncontradaDescripcion')" />
    </v-container>

    <template v-else>
        <v-sheet v-if="!loading" color="primary-surface">
            <v-container>
                <h1 class="mt-md-6 text-start font-weight-bold mt-1" :class="$vuetify.display.smAndUp ? 'text-h4' : 'text-h5'">
                    <v-icon icon="mdi-sale" class="mr-2" size="1.2em" />
                    {{ titulo }}
                </h1>
            </v-container>
        </v-sheet>

        <v-sheet v-if="!loading" color="surface" elevation="1">
            <v-container class="py-3">
                <v-row class="align-center" dense>
                    <v-col cols="8" md="auto">
                        <app-breadcrumbs :items="breadcrumbs" class="mx-n4" />
                    </v-col>
                    <v-spacer />
                    <v-col md="auto">
                        <articulos-orden-selector v-model="orden" />
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-container class="mt-sm-2">
            <articulos-lista
                :orden="orden"
                :post-data="{ idoferta: +idpromocion}" />
        </v-container>
    </template>
</template>

<script>
import ArticulosLista from '../components/ArticulosLista.vue';
import ArticuloOrden from '../enums/ArticuloOrden';
import ArticulosOrdenSelector from '../components/ArticulosOrdenSelector.vue';
import OopsAlert from '../components/OopsAlert.vue';
import AppBreadcrumbs from '../components/AppBreadcrumbs.vue';
import RecursoNoEncontradoCard from '../components/RecursoNoEncontradoCard.vue';
import { store } from '../store';
import axios from 'axios';
import StringHelper from '../support/StringHelper';

export default {
    name: 'PromocionView', // Usado por <keep-alive>

    components: {
        ArticulosLista,
        OopsAlert,
        AppBreadcrumbs,
        RecursoNoEncontradoCard,
        ArticulosOrdenSelector,
    },
    props: {
        idpromocion: {
            type: [Number, String],
            required: true,
        },
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * La oferta que se está mostrando.
         *
         * @type {object}
         */
        oferta: null,

        /**
         * El orden que se debe mostrar los articulos.
         *
         * @type {number}
         */
        orden: ArticuloOrden.DEFAULT,
    }),
    computed: {
        titulo() {
            return this.oferta?.descripcion || this.$t('productosView.promocion');
        },
        breadcrumbs() {
            return [
                {
                    title: this.$t('productosView.promociones'),
                    to: '/promociones',
                },
                {
                    title: this.titulo,
                    href: 'javascript:void(0)',
                    disabled: true,
                },
            ];
        },
    },
    watch: {
        idpromocion: {
            handler() {
                this.cargarOferta();
            },
            immediate: true,
        },
    },
    methods: {
        async cargarOferta() {
            this.loading = true;
            this.error = null;
            this.oferta = null;
            try {
                // Un problema que existe es que no tenemos un endpoint que nos permita obtener la información de una promoción
                // en particular. Por lo tanto, lo que hacemos es obtener todas las promociones y buscar la que tiene el id
                // que nos pasaron por parámetro.
                const { data } = await axios.get(store.isLogged ? '/ofertasuser' : '/ofertas');

                const oferta = data.find((oferta) => +oferta.idoferta === +this.idpromocion) || null;

                if (oferta) {
                    this.oferta = {
                        ...oferta,
                        descripcion: StringHelper.sentenceCase(oferta.descripcion),
                    };
                }
            } catch (error) {
                this.error = error;
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
