<template>
    <v-card class="ma-4 pa-6 pa-sm-8" max-width="600" elevation="12">
        <h1 class="text-h5 text-sm-h4 d-flex align-center" :class="isOfflineError ? 'text-gray' : 'text-error'">
            <v-icon class="mr-6" :icon="info.icon" :color="isOfflineError ? 'gray' : 'error'" />
            <span>{{ info.titulo }}</span>
        </h1>
        <div class="text-body-1 mt-4">
            <p>{{ info.texto }}</p>
            <p v-if="info.message" class="mt-4">
                <b>{{ $t('noDisponibleView.informacionDelServidor') }}</b>
                {{ serverMessage || info.message }}
            </p>
        </div>
        <div v-if="mostrarReintentar" class="d-flex justify-end mt-4">
            <v-btn
                variant="tonal"
                :color="isOfflineError ? 'on-grey' : 'on-error'"
                @click="$emit('reintentar')">
                {{ $t('noDisponibleView.reintentar') }}
            </v-btn>
        </div>
        <div v-if="mostrarVincularOtraFarmacia" class="d-flex justify-end mt-4">
            <v-btn
                variant="tonal"
                :color="isOfflineError ? 'grey' : 'error'"
                @click="vincularOtraFarmacia">
                {{ $t('noDisponibleView.vincularOtraFarmacia') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import ApproxStatusCode from '../enums/ApproxStatusCode';
import { store } from '../store';

export default {
    props: {
        /**
         * Error al cargar la información del servidor.
         */
        error: {
            type: Error,
            required: true,
        },

        /**
         * Nombre de la farmacia, opcional. Se usa para mostrar un mensaje más amigable.
         */
        farmaciaNombre: {
            type: String,
            default: null,
            required: false,
        },
    },
    emits: ['reintentar'],
    computed: {
        /**
         * El código de error de APProx. Ver FarmaciaHttpException en el backend.
         *
         * @returns {string}
         */
        approxStatusCode() {
            return this.error.response?.data?.approx_status_code || ApproxStatusCode.UNKNOWN;
        },

        /**
         * El mensaje de error proporcionado por el servidor.
         *
         * @returns {string|null}
         */
        serverMessage() {
            return this.error.response?.data?.message || null;
        },

        /**
         * Indica si el error es debido a que no hay conexión a internet.
         *
         * @returns {boolean}
         */
        isOfflineError() {
            return this.error.isAxiosError && !navigator.onLine;
        },

        /**
         * Indica si el error es debido a que el servidor está en mantenimiento.
         * Esto es distinto a que la farmacia esté en mantenimiento.
         *
         * @returns {boolean}
         */
        isServerMantananceError() {
            return this.error.isAxiosError && this.error.response?.status === 503;
        },

        /**
         * Indica si el error es debido a que la farmacia está en mantenimiento.
         * Esto es distinto a que el servidor esté en mantenimiento.
         *
         * @returns {boolean}
         */
        isFarmaciaMantananceError() {
            return this.approxStatusCode === ApproxStatusCode.MAINTANANCE_MODE;
        },

        /**
         * Indica si tiene sentido mostrar el botón de reintentar.
         *
         * @returns {boolean}
         */
        mostrarReintentar() {
            return this.approxStatusCode !== ApproxStatusCode.INVALID_SUBDOMAIN
                && this.approxStatusCode !== ApproxStatusCode.NOT_FOUND
                && this.approxStatusCode !== ApproxStatusCode.DISABLED;
        },

        /**
         * Indica si tiene sentido mostrar el botón de vincular otra farmacia.
         *
         * @returns {boolean}
         */
        mostrarVincularOtraFarmacia() {
            return Capacitor.isNativePlatform() && !this.mostrarReintentar;
        },

        /**
         * Información a mostrar en la tarjeta.
         *
         * @returns {{icon: string, titulo: string, texto: string, message: string|null}}
         */
        info() {
            if (this.isOfflineError) {
                return {
                    icon: 'mdi-wifi-off',
                    titulo: this.$t('noDisponibleView.sinConexion'),
                    texto: this.$t('noDisponibleView.noHayConexionConElServicio'),
                    message: null,
                };
            }

            if (this.isServerMantananceError || this.isFarmaciaMantananceError) {
                return {
                    icon: 'mdi-cog-sync',
                    titulo: this.$t('noDisponibleView.servicioEnMantenimiento'),
                    texto: this.$t('noDisponibleView.debidoATareasDeMantenimiento'),
                    message: this.isFarmaciaMantananceError
                        ? this.$t('noDisponibleView.laFarmaciaNoEstaDisponible', [this.farmaciaNombre, this.approxStatusCode])
                        : null,
                };
            }

            return {
                icon: 'mdi-alert-circle',
                titulo: this.$t('noDisponibleView.servicioNoDisponible'),
                texto: this.$t('noDisponibleView.noSeHaPodidoConectar'),
                message: {
                    [ApproxStatusCode.INVALID_SUBDOMAIN]: this.$t('noDisponibleView.elSubdominioSolicitadoEsInvalido'),
                    [ApproxStatusCode.NOT_FOUND]: this.$t('noDisponibleView.laFarmaciaSolicitadaNoExiste'),
                    [ApproxStatusCode.DISABLED]: this.$t('noDisponibleView.laFarmaciaNoEstaDisponible', [this.farmaciaNombre, this.approxStatusCode]),
                    [ApproxStatusCode.PENDING_LEGAL_CONDITIONS]: this.$t('noDisponibleView.laFarmaciaNoEstaDisponible', [this.farmaciaNombre, this.approxStatusCode]),
                }[this.approxStatusCode] || this.$t('noDisponibleView.laFarmaciaNoEstaDisponible', [this.farmaciaNombre, this.approxStatusCode]),
            };
        },
    },
    methods: {
        /**
         * Desvincula la farmacia actual y redirige a la pantalla de vinculación.
         *
         * @returns {Promise<void>}
         */
        async vincularOtraFarmacia() {
            if (this.isOfflineError) return;

            await store.desvincularFarmacia();
            await this.$router.push({ name: 'VincularFarmacia' });
        },
    },
};
</script>
