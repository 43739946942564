<template>
    <v-dialog v-model="dialog" persistent max-width="600">
        <v-card :title="$t('dialogEditDatosUser.misDatos')">
            <v-card-text>
                <v-text-field
                    v-model="nombre"
                    maxlength="20"
                    :label="$t('dialogEditDatosUser.nombre')"
                    :rules="[rules.required]" />
                <v-text-field
                    v-model="apellidos"
                    maxlength="60"
                    :label="$t('dialogEditDatosUser.apellidos')"
                    :rules="[rules.required]" />
                <v-text-field
                    v-model="telefono"
                    maxlength="20"
                    :label="$t('dialogEditDatosUser.telefono')"
                    :rules="[rules.isTelephone]" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn variant="text" @click="dialog = false">
                    {{ $t('dialogEditDatosUser.cancelar') }}
                </v-btn>
                <v-btn
                    variant="text"
                    :disabled="!isValid"
                    :loading="loading"
                    @click="aceptar()">
                    {{ $t('dialogEditDatosUser.guardar') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { store } from '@/store';
export default {
    data: () => ({
        /**
         * Indica si se está mostrando el dialogo.
         */
        dialog: false,

        /**
         * El nombre del usuario.
         */
        nombre: '',

        /**
         * Los apellidos del usuario.
         */
        apellidos: '',

        /**
         * Telefono del usuario.
         */
        telefono: '',

        /**
         * Indica si se está guardando la información.
         */
        loading: false,
    }),
    computed: {
        isValid() {
            return this.nombre.length > 0 && this.apellidos.length > 0;
        },
        rules() {
            return {
                required: (value) => !!value || 'Obligatorio.',
                isTelephone: (value) => {
                    const pattern = /^\+?\d{1,4}?[-.\s]?(\(?\d{1,3}?\)?[-.\s]?)?\d{6,10}$/
                    ;
                    return pattern.test(value) || 'Teléfono no válido.';
                },
            };
        },
    },
    created() {
        this.nombre = store.user.name;
        this.apellidos = store.user.apellidos;
        this.telefono = store.user.telefono;
    },
    methods: {
        async aceptar() {
            this.loading = true;

            await store.updateOpcionesRgpd({
                name: this.nombre,
                apellidos: this.apellidos,
                telefono: this.telefono,
            });

            this.dialog = false;
            this.loading = false;
        },
    },
};
</script>
