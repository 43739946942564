<template>
    <articulo-agregado-dialog :articulo="articuloAgregado" />
    <v-card
        :loading="disableCardRedirection"
        :variant="mostrarImagen ? 'elevated' : 'tonal'"
        color="on-shell"
        :disabled="disableCardRedirection"
        class="d-flex flex-column fill-height"
        :to="`/productos/${articulo.idarticu}`">
        <!-- 224px es el mismo alto que usa MercadoLibre para las imágenes de sus tarjetas de producto -->
        <v-img
            v-if="mostrarImagen"
            :src="articulo.imagen"
            :alt="articulo.descripcion"
            :lazy-src="imagenDefault"
            height="224"
            :max-height="224">
            <articulo-card-chips :articulo="articulo" :stockcesta="Number(stockcesta)" :no-disponible="disponible" :porcentaje-aplicado="porcentaje" />
        </v-img>
        <v-card-actions v-else>
            <articulo-card-chips :articulo="articulo" :stockcesta="Number(stockcesta)" :no-disponible="disponible" :porcentaje-aplicado="porcentaje" />
        </v-card-actions>
        <v-card-text class="d-flex flex-column">
            <span class="titulo">
                {{ articulo.descripcion }}
            </span>
            <v-col>
                <the-precio v-if="muestraPrecioFinal" :precio="precio" :preciotachado="preciotachado" @porcentaje="calculaPorcentaje" />
            </v-col>
            <v-spacer />
            <v-col class="py-0" style="font-size: 1.5rem;">
                <v-btn
                    v-if="mostrarBoton"
                    color="primary"
                    size="small"
                    block
                    :loading="agregarACestaLoading"
                    :disabled="disponible"
                    variant="flat"
                    prepend-icon="mdi-cart-arrow-down"
                    @click.stop="agregarACesta($event)">
                    {{ $t('articuloView.anadir') }}
                </v-btn>
            </v-col>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from 'axios';
import ArticuloAgregadoDialog from '../views/articulo/ArticuloAgregadoDialog.vue';
import ArticuloCardChips from './ArticuloCardChips.vue';
import imagenDefault from '../../images/producto-default.jpg';
import EurosHelper from '../support/EurosHelper';
import HeartbeatService from '../support/HeartbeatService';
import ThePrecio from './ThePrecio.vue';
import { store } from '@/store';

export default {
    components: {
        ArticuloCardChips,
        ArticuloAgregadoDialog,
        ThePrecio,
    },
    props: {
        articulo: {
            type: Object,
            required: true,
        },
        mostrarImagen: {
            type: Boolean,
            default: true,
        },
        mostrarPrecio: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['error'],
    data() {
        return {
            /**
               * Indica si se estan enviando los datos al servidor para agregar el articulo a la cesta.
               *
               * @type {boolean}
               */
            agregarACestaLoading: false,

            /**
                * Error al agregar el articulo a la cesta.
                *
                * @type {Error|null}
                */

            agregarACestaError: null,
            /**
                * Error al agregar el articulo a la cesta.
                *
                * @type {boolean}
                */
            disableCardRedirection: false,
            /**
                * El ultimo articulo que se ha agregado a la cesta.
                *
                * @type {{ idarticu: string, cantidad: number, descripcion: string, imagen: string, pvp: number }}
                */
            articuloAgregado: null,
            /**
             * Indica el porcentaje del descuento con respecto al precio anterior.
             * @type {number}
             */
            porcentaje: 0,
        };
    },
    computed: {
        imagenDefault() {
            return imagenDefault;
        },
        parts() {
            return EurosHelper.parts(this.articulo.pvp);
        },
        precio() {
            if (this.articulo.importeOferta /*&& this.articulo.importeOferta < this.articulo.pvp*/)
            {
                return EurosHelper.format(this.articulo.importeOferta);
            }
            return EurosHelper.format(this.articulo.pvp);
        },
        /**
             * Indica si se debe mostrar el botón de agregar a la cesta
             * segun la configuración del ecommerce.
             * @returns {boolean}
             */
        mostrarBoton() {
            return store.ecommerceHabilitado;
        },
        /**
             * Indica si se debe mostrar el cartel de "No hay más articulos".
             * @type {boolean}
             */
        disponible() {
            let res = this.articulo.noDisponible;

            if (this.articulo.dispensasinstock && Number(this.articulo.dispensasinstock) === 1)
            {
                return res;
            }

            if (Number(this.stockcesta) >= Number(this.articulo.stockactual)) {
                res = true;
            }
            return res;

        },
        stockcesta(){
            if (!store.isLogged)
                return 0;
            let stockcesta = 0;
            if (!store.cesta.lineas) {
                return 0;
            }
            let articuCesta = store.cesta.lineas.find((linea) => linea.idarticu === this.articulo.idarticu) ?? false;
            if (articuCesta) {
                stockcesta = articuCesta.cantidad;
            } else {
                stockcesta = 0;
            }
            return stockcesta;
        },
        preciotachado() {
            if (this.articulo.importeOferta && this.articulo.importeOferta < this.articulo.pvp)
            {
                return EurosHelper.format(this.articulo.pvp);
            }
            else
                return null;
        },
        muestraPrecioFinal(){
            if(!this.disponible && this.mostrarPrecio)
                return true;
            else
                return false;
        },
    },
    methods: {
        async agregarACesta(e) {
            //Se evita que reaccione al evento del padre
            e.preventDefault();
            if (!store.isLogged) {
                this.$router.push({
                    name: 'Login',
                    query: { redirect: this.$route.fullPath },
                });
                return;
            }
            this.disableCardRedirection = true;
            this.agregarACestaLoading = true;
            this.agregarACestaError = null;
            this.articuloAgregado = null;

            try {
                await axios.post('/cesta', {
                    idarticu: this.articulo.idarticu,
                    cantidad: 1,
                });

                this.articuloAgregado = {
                    ...this.articulo,
                    cantidad: 1,
                };
            } catch (error) {
                console.error(error);
                this.agregarACestaError = error;
                this.$emit('error', this.agregarACestaError);
            } finally {
                this.agregarACestaLoading = false;
                this.disableCardRedirection = false;
            }

            if (this.agregarACestaError === null) {
                HeartbeatService.instance.heartbeat();
            }
        },

        calculaPorcentaje(porcentaje) {
            this.porcentaje = porcentaje;
        },
    },
};
</script>

<style scoped>
.titulo {
    font-weight: normal;
    font-size: 1.1rem;
    word-break: normal;
    line-height: 1.25em;
    display: block;
    overflow: hidden;
    height: 45px;

    text-overflow: ellipsis;
    /* Multiline ellipsis for webkit (Support: https://caniuse.com/mdn-css_properties_-webkit-line-clamp)*/
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* Multiline ellipsis for Opera */
    text-overflow: -o-ellipsis-lastline;
    /* Standard */
    line-clamp: 3;
    /* Max height back up for non-supporting browsers (3 lines * line-height) */
    max-height: calc(1.25em * 3);
}

</style>
