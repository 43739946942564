<template>
    <v-sheet color="primary-surface">
        <v-container>
            <h1 class="mt-md-6 text-start font-weight-bold mt-1" :class="$vuetify.display.smAndUp ? 'text-h4' : 'text-h5'">
                <template v-if="query">
                    <v-icon icon="mdi-magnify" class="mr-2" size="1.2em" />
                    <span v-if="$vuetify.display.smAndUp">
                        {{ $t('productosView.busqueda') }}:
                    </span>
                    <span>
                        {{ query }}
                    </span>
                </template>
                <template v-else>
                    {{ $t('productosView.todosLosProductos') }}
                </template>
            </h1>
        </v-container>
    </v-sheet>

    <v-sheet color="surface" elevation="1">
        <v-container class="py-3">
            <v-row dense>
                <v-spacer />
                <v-col cols="12" md="auto">
                    <articulos-orden-selector v-model="orden" />
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>

    <v-container class="mt-sm-2">
        <articulos-lista
            :orden="orden"
            :post-data="{ cadena: query }" />
    </v-container>
</template>

<script>
import { store } from '@/store';
import ArticulosLista from '@/components/ArticulosLista.vue';
import ArticuloOrden from '@/enums/ArticuloOrden';
import ArticulosOrdenSelector from '@/components/ArticulosOrdenSelector.vue';

export default {
    name: 'ArticulosView', // Usado por <keep-alive>

    components: {
        ArticulosLista,
        ArticulosOrdenSelector,
    },
    data: () => ({
        /**
         * El orden que se debe mostrar los articulos.
         *
         * @type {number}
         */
        orden: ArticuloOrden.DEFAULT,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        query() {
            return this.$route.query.query; // El query string de la URL se llama "?query=blabla"
        },
    },
};
</script>
