import FarmaciaEcommerce from '../enums/FarmaciaEcommerce';
import StringHelper from './StringHelper';
import productoDefaultImageUrl from '../../images/producto-default.jpg';

/**
| ------------------------------------------------------------------------------
| Normalización de datos
| ------------------------------------------------------------------------------
|
| Aquí se normalizan los datos provenientes del servidor para que tengan la forma
| que espera el cliente. Esto es necesario ya que en muchos casos el servidor
| devuelve los datos tal cual están en la base de datos sin castearlos al tipo
| que corresponde (por ejemplo, devuelve un string en vez de un booleano),
| o sin trimmearlos (por almacenarce en CHAR en vez de VARCHAR).
|
| Al normalizarlos en un solo lado, evitamos tener que hacer trims, ifs y
| casteos en cada uno de los componentes, simplificando el código y evitando
| errores.
|
| Cuando se pase esta lógica al servidor, se podrán eliminar estas funciones.
|
*/

// Helpers
const trim = str => StringHelper.trimToNull(str);
const title = str => StringHelper.titleCase(str);
const text = str => StringHelper.sentenceCase(str);
const bool = str => +str > 0;
const int = str => +str;

/**
 * Normaliza los datos del usuario para que tengan la forma que espera el cliente.
 *
 * @param {object} user
 * @returns {object}
 */
export function normalizeUser(user) {
    return {
        ...user,
        numserie: int(user.numserie),
        esCliente: bool(user.idcliente),
        notificacionescomerciales: bool(user.notificacionescomerciales),
        facturaporemail: bool(user.facturaporemail),
    };
}


/**
 * Normaliza los datos de la farmacia para que tengan la forma que espera el cliente.
 *
 * @param {object} farmacia
 * @returns {object}
 */
export function normalizeFarmacia(farmacia) {
    const endpointUrl = farmacia.endpointUrl ?? import.meta.env.VITE_ENDPOINT_URL;

    return {
        ...farmacia,
        endpointUrl,
        numserie: int(farmacia.numserie),
        subdominio: farmacia.subdominio,
        theme: farmacia.theme,

        gmaps: trim(farmacia.gmaps),
        nombre: title(farmacia.nombre),
        telefono: trim(farmacia.telefono),
        movil: trim(farmacia.movil),
        whatsapp: trim(farmacia.whatsapp),
        email: trim(farmacia.email),
        web: trim(farmacia.web),
        direccion: title(farmacia.direccion),

        versiondatosgui: int(farmacia.versiondatosgui),
        mostrarImagenes: bool(farmacia.apariencia),
        mensajeria: bool(farmacia.mensajeria),
        programapuntos: bool(farmacia.programapuntos),
        etiquetapromo: int(farmacia.etiquetapromo),
        ecommerce: int(farmacia.ecommerce), // ver enum FarmaciaEcommerce

        rgpdnombrecomercial: trim(farmacia.rgpdnombrecomercial),
        rgpddenominacionsocial: trim(farmacia.rgpddenominacionsocial),
        rgpddomiciliosocial: trim(farmacia.rgpddomiciliosocial),
        rgpdnif: trim(farmacia.rgpdnif),
        rgpdemail: trim(farmacia.rgpdemail),
        rgpdproxdispens: bool(farmacia.rgpdproxdispens),

        enlaces: farmacia.enlaces?.map(enlace => ({
            ...enlace,
            texto: title(enlace.texto),
        })) || [],

        rutaimagen: farmacia.rutaimagen
            ? `${endpointUrl}/img/${farmacia.numserie}/${farmacia.rutaimagen}`
            : null,

        rutaicono: farmacia.rutaicono
            ? `${endpointUrl}/img/${farmacia.numserie}/${farmacia.rutaicono}`
            : `${endpointUrl}/img/generic/farmatic-approx-258x66.png`,

        rutaimagenhome: farmacia.rutaimagenhome
            ? `${endpointUrl}/img/${farmacia.numserie}/${farmacia.rutaimagenhome}`
            : null,
        ocultapvpconsumos: bool(farmacia.ocultapvpconsumos),
    };
}

/**
 * Normaliza el artículo para que tenga la forma que espera el cliente.
 *
 * @param {object} articulo
 * @returns {object}
 */
export function normalizeArticulo(articulo) {
    return {
        ...articulo,
        imagen: articulo.imagen?.ruta || productoDefaultImageUrl,
        cantidadMin: 1,
        cantidad: articulo.cantidad,
        cantidadMax: bool(articulo.dispensasinstock) ? null : int(articulo.stockactual),
        noDisponible: !bool(articulo.dispensasinstock) && int(articulo.stockactual) === 0,
    };
}

/**
 * Normaliza la notificación para que tenga la forma que espera el cliente.
 *
 * @param {object} notificacion
 * @returns {object}
 */
export function normalizeNotificacion(notificacion) {
    return {
        ...notificacion,
        leido: bool(notificacion.leido),
        titulo: text(notificacion.titulo),
        descripcion: text(notificacion.descripcion),
        idnotificacion: int(notificacion.idnotificacion),
        tiponotificacion: int(notificacion.tiponotificacion), // TODO: Documentar enum
        url: {
            'url': notificacion.url,
            'oferta': `/promociones/${notificacion.identidad}`,
            'pedido': `/pedidos/${notificacion.identidad}`,
            'producto': `/productos/${notificacion.identidad}`,
            'categoria': `/categorias/${notificacion.identidad}`,
            'encargo': '/encargos',
        }[notificacion.tipoentidad?.toLowerCase()] || notificacion.url,
    };
}

/**
 * Transforma el modo de ecommerce de acuerdo a si el usuario es cliente o no.
 *
 * @param {number} modo
 * @param {boolean} esCliente
 * @returns {number}
 */
export function getModoEcommerce(modo, esCliente) {
    if (modo === FarmaciaEcommerce.COMPRA_PRECIOS_LOGIN) {
        return esCliente
            ? FarmaciaEcommerce.COMPRA_PRECIOS_VISIBLES
            : FarmaciaEcommerce.CATALOGO_PRECIOS_OCULTOS;
    }

    if (modo === FarmaciaEcommerce.CATALOGO_PRECIOS_LOGIN) {
        return esCliente
            ? FarmaciaEcommerce.CATALOGO_PRECIOS_VISIBLES
            : FarmaciaEcommerce.CATALOGO_PRECIOS_OCULTOS;
    }

    return modo;
}

/**
 * Obtiene el icono de la oferta según el tipo de oferta.
 *
 * @param {string} tipo
 * @returns {string}
 */
export function getOfertaIcon(tipo) {
    return {
        'V': 'mdi-cash',
        'U': 'mdi-cash',
        'T': 'mdi-ticket-percent',
        'S': 'mdi-ticket-account',
        'B': 'mdi-gift',
        'P': 'mdi-counter',
    }[tipo] || 'mdi-currency-eur';
}


/**
 * Normaliza la cesta para que tenga la forma que espera el cliente.
 *
 * @param {object} cesta
 * @returns {object}
 */
export function normalizeCesta(cesta) {
    return {
        ...cesta,
        total: cesta.total,
        lineas: cesta.lineas?.map(linea => {
            const importeOriginal = linea.importe || null; // Precio original antes de aplicar la oferta (normalmente se muestra tachado) o null si no hay oferta.
            const importeFinal = linea.importeOferta || linea.importe; // Precio final luego de aplicar la oferta si la hubiese. Nunca es null.
            return {
                idarticu: linea.idarticu,
                imagen: linea.imagen?.ruta || productoDefaultImageUrl,
                descripcion: StringHelper.sentenceCase(linea.descripcion),
                to: `/productos/${linea.idarticu}`,
                cantidadMin: 1,
                cantidad: linea.cantidad,
                cantidadMax: bool(linea.dispensasinstock) ? null : int(linea.stockactual),
                importeOriginal: importeOriginal > importeFinal ? importeOriginal : null, // HACK: El server a veces envía el mismo importe en importe y en importeOferta. En ese caso, no queremos mostrar el mismo valor 2 veces
                importeFinal: importeFinal,
                promociones: linea.promociones?.map(promocion => ({
                    descripcion: StringHelper.sentenceCase(promocion.descripcion),
                    icon: getOfertaIcon(promocion.tipo),
                })) || [],
            };
        }) || [],
        vales: cesta.vales?.map(vale => ({
            contenido: vale.contenido,
            descripcion: StringHelper.sentenceCase(vale.descripcion),
            icon: getOfertaIcon(vale.tipo),
        })) || [],
        lineasBonificadas: cesta.lineasBonificadas?.map(linea => ({
            idarticu: linea.idarticu,
            imagen: linea.imagen?.ruta || productoDefaultImageUrl,
            descripcion: StringHelper.sentenceCase(linea.descripcion),
            cantidad: linea.cantidad,
            pvp: linea.pvp,
            to: `/productos/${linea.idarticu}`,
        })) || [],
        puntos: cesta.puntos,
    };
}

/**
 * Devuelve true si la URL es una URL externa (que no pertenece a la app).
 *
 * @param {string} url
 * @returns {boolean}
 */
export function isExternalUrl(url) {
    return url.startsWith('http://') || url.startsWith('https://');
}

/**
 * Resuelve la URL (interna o externa) a la que debe dirigirse la app al pulsar sobre una notificación.
 * Si la URL es una URL interna, se devuelve el path relativo a la app. (Ej: /productos/123)
 * Si la URL es una URL externa, se devuelve la URL tal cual. (Ej: https://www.google.com)
 * Si la URL dada es null, se devuelve null.
 * Se puede utilizar {@link isExternalUrl} para saber si la URL es interna o externa y saber si abrir la URL en el navegador o en la app.
 *
 * @param {string?} url
 * @param {string} farmaciaSubdominio
 * @returns {string?}
 */
export function normalizeNotificationUrl(url, farmaciaSubdominio) {
    if (!url) return null;

    // URLs externas se devuelven tal cual
    if (isExternalUrl(url)) {
        // Si la URL es una URL interna de la farmacia (o sea, un link dentro de la web de la farmacia), se devuelve la URL interna
        const fullDomain = import.meta.env.VITE_APP_FARMACIA_URL.replace('{farmacia}', farmaciaSubdominio);
        const fullDomainUnsecure = fullDomain.replace('https://', 'http://');
        if (url.startsWith(fullDomain) || url.startsWith(fullDomainUnsecure)) {
            const url = url.replace(fullDomain, '').replace(fullDomainUnsecure, '');
            return _normalizeInternalNotificationUrl(url);
        } else {
            return url; // Es realmente una URL externa que no tiene nada que ver con APProx.
        }
    }

    // URLs internas se normalizan
    return _normalizeInternalNotificationUrl(url);
}


/**
 * Normaliza una URL interna. Si la URL es una URL interna conocida, se devuelve la URL.
 * En caso contrario, se devuelve null.
 *
 * @param {string} url
 * @returns {string?}
 */
function _normalizeInternalNotificationUrl(url) {
    url = url
        .toLowerCase() // BUG: El server a veces devuelve mayúsculas
        .replace(/^\/+#?\/?/, '') // Quita slashes y hash al principio (Ej: '/#/login/' -> 'login/')
        .replace(/\/+$/, ''); // Quita slashes al final (Ej: 'login/' -> 'login')

    const parts = url.split('/'); // Separa por slashes (Ej: 'productos/foo/bar/1234' -> ['productos', 'foo', 'bar', '1234'])

    // Se remapean los recursos conocidos a sus respectivas URLs
    const recurso = {
        'mensaje': 'mensajes',
        'notificacion': 'notificaciones',
        'categoria': 'categorias',
        'encargo': 'encargos',
        'oferta': 'promociones',
        'promocion': 'promociones',
        'pedido': 'pedidos',
        'producto': 'productos',
    }[parts[0]] ?? null;

    url = recurso
        ? `/${recurso}/${parts.slice(1).join('/')}`
        : `/${url}`; // Si no es un recurso conocido, se devuelve tal cual, por si es una URL interna implementada en el futuro.

    return url;
}



/**
 * Normaliza la cesta del Heartbeat para que tenga la forma que espera el cliente.
 *
 * @param {object} cesta
 * @returns {object}
 */
export function normalizeCestaHB(cesta) {
    return {
        ...cesta,
        badge: cesta.lineas?.length || 0,
        lineas: cesta.lineas?.map(linea => {
            return {
                idarticu: linea.idarticu,
                cantidad: Number(linea.cantidad),
            };
        }) || [],
    };
}
