import CategoriaView from '../views/categoria/CategoriaView.vue';
import CestaView from '../views/CestaView.vue';
import ConfiguracionView from '../views/ConfiguracionView.vue';
import ConsumosView from '../views/ConsumosView.vue';
import DesvincularFarmaciaView from '../views/DesvincularFarmaciaView.vue';
import EncargosView from '../views/EncargosView.vue';
import Error404View from '../views/Error404View.vue';
import InstallView from '../views/InstallView.vue';
import LectorQrView from '../views/LectorQrView.vue';
import MensajesView from '../views/mensajes/MensajesView.vue';
import NotificacionesView from '../views/NotificacionesView.vue';
import PedidoSingleView from '../views/PedidoSingleView.vue';
import PromocionView from '../views/PromocionView.vue';
import PromocionesView from '../views/PromocionesView.vue';
import ProxDispensacionesView from '../views/ProxDispensacionesView.vue';
import PuntosView from '../views/PuntosView.vue';
import TarjetaView from '../views/TarjetaView.vue';
import VincularFarmaciaView from '../views/VincularFarmaciaView.vue';
import ArticuloView from '../views/articulo/ArticuloView.vue';
import LoginView from '../views/auth/LoginView.vue';
import ReestablecerContrasenaView from '../views/auth/ReestablecerContrasenaView.vue';
import RegistroView from '../views/auth/RegistroView.vue';
import CompraView from '../views/compra/CompraView.vue';
import PagoErrorView from '../views/compra/PagoErrorView.vue';
import PagoOkView from '../views/compra/PagoOkView.vue';
import PagoPendienteView from '../views/compra/PagoPendienteView.vue';
import ContactoView from '../views/contacto/ContactoView.vue';
import CuentaView from '../views/cuenta/CuentaView.vue';
import HomeView from '../views/home/HomeView.vue';
import CondicionesContratacionView from '../views/legal/CondicionesContratacionView.vue';
import DerechoDesistimientoView from '../views/legal/DerechoDesistimientoView.vue';
import PoliticaCookiesView from '../views/legal/PoliticaCookiesView.vue';
import PoliticaPrivacidadView from '../views/legal/PoliticaPrivacidadView.vue';
import TerminosCondicionesUsoView from '../views/legal/TerminosCondicionesUsoView.vue';
import AppShell from '../views/shell/AppShell.vue';
import ArticulosView from '../views/articulo/ArticulosView.vue';

import {
    autenticate,
    ensureEcommerce,
    ensureCatalogo,
    ensureFarmacia,
    onlyMobile,
    redirectIfAutenticated,
    withoutFarmacia,
} from './guards';

import { store } from '../store';
import { Style } from '@capacitor/status-bar';
import PedidosView from '../views/PedidosView.vue';

/*
| ----------------------------------------------------------------------
| Convencion de nombres para las rutas
| ----------------------------------------------------------------------
|
| Para nombrar URLs que correspondan a un recurso, usar el nombre del recurso en plural.
| Ej bien: /productos, /productos/:idarticu, /productos/:idarticu/editar
| Ej mal: /producto/:idarticu, /producto/:idarticu/editar
| Para URLs que no correspondan a un recurso, usar el nombre del recurso en singular.
| Ej: Perfil del usuario, datos de la farmacia, etc.
|
*/

/*
| ----------------------------------------------------------------------
| Parametros meta propios usados en APProx
| ----------------------------------------------------------------------
|
| statusBarStyle:
| Estilo del status bar del dispositivo.Solo se usa en iOS y android para establecer el color del status bar.
| Si es undefined, se usa el color del shell para calcular el color del status bar.
| Valores: Style.Light, Style.Dark, undefined.
|
| preserveScrollPosition:
| Si es true, cuando el usuario regrese a esta ruta, (porque pulsó el botón de atrás del navegador o el botón atrás de android)
| se mantendrá la posición del scroll donde estaba el usuario. Si es false, el scroll se reiniciará a la parte superior de la página, similar a como funciona
| la navegación clásica de un sitio web con SSR. El valor por defecto (undefined) es igual a false.
| Valores: true, false, undefined.
|
| pageOverflowHidden:
| Si es false, el HTML y body de la página no scrollean (overflow: hidden). Si no está definido o si es true, la página
| scrollea normalmente.
| Valores: true, false, undefined.
|
| noGuiReload:
| Este flag se usa para indicar al sistema de sincrogui que si el usuario está en esta ruta y se produce un cambio de "version de datos gui"
| no se debe recargar la página. Se deben marcar con este flag todas las rutas que contengan formularios o algun tipo de interacción con el usuario
| que pueda perderse si se recarga la página. Por defecto, si no se define este flag, se recarga la página.
| Valores: true, false, undefined.
|
*/

/** @type {import('vue-router').RouteRecord[]} */
export default [
    {
        path: '/vincular-farmacia',
        component: VincularFarmaciaView,
        name: 'VincularFarmacia',
        beforeEnter: [onlyMobile, withoutFarmacia],
        meta: {
            statusBarStyle: Style.Light,
        },
    },
    {
        path: '/lectorqr',
        component: LectorQrView,
        name: 'LectorQr',
        beforeEnter: [onlyMobile, withoutFarmacia],
        meta: {
            statusBarStyle: Style.Dark,
        },
    },
    {
        path: '/lectorqr-art',
        component: LectorQrView,
        name: 'LectorQrArticu',
        beforeEnter: [onlyMobile],
        meta: {
            isArticuloFind: true,
            statusBarStyle: Style.Dark,
        },
    },
    {
        path: '/',
        component: AppShell,
        beforeEnter: [ensureFarmacia],
        children: [
            {
                path: '/',
                component: HomeView,
                name: 'Home',
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/install',
                component: InstallView,
                name: 'Install',
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/login',
                component: LoginView,
                name: 'Login',
                beforeEnter: [redirectIfAutenticated],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/reestablecer-contrasena',
                component: ReestablecerContrasenaView,
                name: 'ReestablecerContrasena',
                beforeEnter: [redirectIfAutenticated],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/registro',
                component: RegistroView,
                name: 'Registro',
                beforeEnter: [redirectIfAutenticated],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/cuenta',
                component: CuentaView,
                name: 'Cuenta',
                beforeEnter: [autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/categorias/:idcategoria',
                component: CategoriaView,
                name: 'Categoria',
                beforeEnter: [ensureCatalogo],
                props: true,
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/productos',
                component: ArticulosView,
                beforeEnter: [ensureCatalogo],
                name: 'Productos',
                props: true,
            },
            {
                path: '/productos/:idarticu',
                component: ArticuloView,
                beforeEnter: [ensureCatalogo],
                name: 'Articulo',
                props: true,
            },
            {
                path: '/promociones/:idpromocion',
                component: PromocionView,
                beforeEnter: [ensureCatalogo],
                name: 'Promocion',
                props: true,
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/notificaciones',
                component: NotificacionesView,
                name: 'Notificaciones',
                meta: {
                    preserveScrollPosition: true,
                    noGuiReload: true,
                },
            },
            {
                path: '/promociones',
                component: PromocionesView,
                name: 'Promociones',
                beforeEnter: [ensureCatalogo],
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/farmacia',
                component: ContactoView,
                name: 'Farmacia',
            },
            {
                path: '/puntos',
                component: PuntosView,
                name: 'Puntos',
                beforeEnter: [autenticate],
            },
            {
                path: '/pedidos',
                component: PedidosView,
                name: 'Pedidos',
                beforeEnter: [autenticate, ensureEcommerce],
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/consumos',
                component: ConsumosView,
                name: 'Consumos',
                beforeEnter: [autenticate],
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/dispensaciones',
                component: ProxDispensacionesView,
                name: 'ProxDispensaciones',
                beforeEnter: [
                    () => store.farmacia?.rgpdproxdispens,
                    autenticate,
                ],
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/encargos',
                component: EncargosView,
                name: 'Encargos',
                beforeEnter: [autenticate],
                meta: {
                    preserveScrollPosition: true,
                },
            },
            {
                path: '/politica-privacidad',
                component: PoliticaPrivacidadView,
                name: 'PoliticaPrivacidad',
            },
            {
                path: '/politica-cookies/:configuracion?',
                component: PoliticaCookiesView,
                name: 'PoliticaCookies',
                props: true,
            },
            {
                path: '/terminos-condiciones-uso',
                component: TerminosCondicionesUsoView,
                name: 'TerminosCondicionesUso',
            },
            {
                path: '/condiciones-contratacion',
                component: CondicionesContratacionView,
                name: 'CondicionesContratacion',
            },
            {
                path: '/derecho-desistimiento',
                component: DerechoDesistimientoView,
                name: 'DerechoDesistimiento',
            },
            {
                path: '/cesta',
                component: CestaView,
                name: 'Cesta',
                beforeEnter: [ensureEcommerce, autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/compra',
                component: CompraView,
                name: 'Compra',
                beforeEnter: [ensureEcommerce, autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/error-pago/:message?', // Usada por el backend para redirigir aquí en caso de pago fallido con redsys
                component: PagoErrorView,
                name: 'ErrorPago',
                meta: {
                    noGuiReload: true,
                },
                props: true,
            },
            {
                path: '/pago-ok/:id', // Usada por el backend para redirigir aquí en caso de pago correcto con redsys
                component: PagoOkView,
                name: 'PagoOk',
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/pedido-pendiente', // Usada para "Pago en tienda"
                component: PagoPendienteView,
                name: 'PedidoPendiente',
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/pedidos/:idpedido',
                component: PedidoSingleView,
                name: 'PedidoSingle',
                props: true,
                beforeEnter: [ensureEcommerce, autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/configuracion',
                component: ConfiguracionView,
                name: 'Configuracion',
                beforeEnter: [autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/mensajes',
                component: MensajesView,
                name: 'Mensajes',
                beforeEnter: [
                    () => store.farmacia?.mensajeria,
                    autenticate,
                ],
                meta: {
                    pageOverflowHidden: false,
                    noGuiReload: true,
                },
            },
            {
                path: '/tarjeta',
                component: TarjetaView,
                name: 'Tarjeta',
                beforeEnter: [autenticate],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/desvincular-farmacia',
                component: DesvincularFarmaciaView,
                name: 'DesvincularFarmacia',
                beforeEnter: [onlyMobile],
                meta: {
                    noGuiReload: true,
                },
            },
            {
                path: '/:url(.*)*',
                component: Error404View,
                name: 'Error404',
            },
        ],
    },
];
