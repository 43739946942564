<template>
    <div class="parent" :style="transformStyle">
        <!-- Marker icon -->
        <svg class="marker-icon" width="150px" height="150px" viewBox="-3.6 -3.6 43.20 43.20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#262626" stroke="#262626">
            <g id="SVGRepo_bgCarrier" stroke-width="0" /><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.72" /><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>map-marker</title> <desc>Created with Sketch.</desc> <defs /> <g id="Vivid.JS" stroke-width="0.00036" fill="none" fill-rule="evenodd"> <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)"> <g id="Icons" transform="translate(37.000000, 169.000000)"> <g id="map-marker" transform="translate(78.000000, 468.000000)"> <g transform="translate(10.000000, 6.000000)"> <path id="Shape" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" fill="#FF6E6E" /> <circle id="Oval" fill="#8b4b4b" fill-rule="nonzero" cx="14" cy="14" r="7" /> </g> </g> </g> </g> </g> </g>
        </svg>

        <!-- marker floor shadow  -->
        <svg>
            <ellipse cx="60" cy="138" rx="30px" ry="8px" fill="#00000040" />
        </svg>

        <!-- error icon -->
        <svg v-if="isError" class="icon" width="60px" height="60px" viewBox="0 0 512.00 512.00" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="" stroke="">
            <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(176.64,176.64), scale(0.31)"><rect x="0" y="0" width="512.00" height="512.00" rx="256" fill="#fafafa" strokewidth="0" /></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="5.12" /><g id="SVGRepo_iconCarrier"> <title>error-filled</title> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="add" fill="#de4f4f" transform="translate(42.666667, 42.666667)"> <path id="Combined-Shape" d="M213.333333,3.55271368e-14 C331.136,3.55271368e-14 426.666667,95.5306667 426.666667,213.333333 C426.666667,331.136 331.136,426.666667 213.333333,426.666667 C95.5306667,426.666667 3.55271368e-14,331.136 3.55271368e-14,213.333333 C3.55271368e-14,95.5306667 95.5306667,3.55271368e-14 213.333333,3.55271368e-14 Z M262.250667,134.250667 L213.333333,183.168 L164.416,134.250667 L134.250667,164.416 L183.168,213.333333 L134.250667,262.250667 L164.416,292.416 L213.333333,243.498667 L262.250667,292.416 L292.416,262.250667 L243.498667,213.333333 L292.416,164.416 L262.250667,134.250667 Z" /> </g> </g> </g>
        </svg>

        <!-- check icon -->
        <svg v-else-if="isValid" class="icon" width="60px" height="60px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1792 1792" xml:space="preserve" fill="#85d364">
            <g id="SVGRepo_bgCarrier" stroke-width="0" /><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" /><g id="SVGRepo_iconCarrier"> <circle cx="896" cy="896" r="782.9" /> <path class="st0" fill="#fafafa" d="M1322.7,592.9c-40.6-40.6-106.3-40.6-146.9,0l-455.7,456L549.4,878.6c-40.6-40.6-106.3-40.6-146.9,0 c-40.6,40.6-40.6,106.3,0,146.9l233.6,232.4c3.1,4.1,6.5,8.1,10.2,11.8c20.5,20.5,47.5,30.6,74.4,30.4c26.8,0.2,53.7-9.9,74.2-30.4 c3.8-3.8,7.3-7.9,10.4-12.1l517.4-517.8C1363.3,699.3,1363.3,633.5,1322.7,592.9z" /> </g>
        </svg>

        <!-- question icon -->
        <svg v-else class="icon" width="60px" height="60px" fill="#499fca" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#499fca" stroke-width="0.00024000000000000003">
            <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(5.52,5.52), scale(0.54)"><rect x="0" y="0" width="24.00" height="24.00" rx="0" fill="#fafafa" strokewidth="0" /></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" /><g id="SVGRepo_iconCarrier"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,16a1,1,0,1,1,1-1A1,1,0,0,1,12,18Zm1.71-4.61c-.71.34-.71.39-.71.61a1,1,0,0,1-2,0,2.6,2.6,0,0,1,1.84-2.42C13.61,11.21,14,11,14,10a1,1,0,0,0-.49-.86A2.82,2.82,0,0,0,12,8.75c-.69,0-2,.26-2,1.25a1,1,0,0,1-2,0c0-1.88,1.68-3.25,4-3.25S16,8.12,16,10A3.41,3.41,0,0,1,13.71,13.39Z" /></g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        /**
         * Indica si el icono debe ser un check.
         *
         * @type {Boolean}
         */
        isValid: {
            type: Boolean,
            default: false,
        },

        /**
         * Indica si el icono debe ser un error.
         *
         * @type {Boolean}
         */
        isError: {
            type: Boolean,
            default: false,
        },

        /**
         * Indica el tamaño del componente en pixeles.
         */
        size: {
            type: [Number, String],
            default: 150,
        },
    },
    computed: {
        transformStyle() {
            return `scale: ${this.size / 150}`;
        },
    },
};
</script>

<style scoped>
@keyframes marker-anim {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.parent {
    position: relative;
    width: 150px;
    height: 150px;
}

.icon {
    position: absolute;
    bottom: 15px;
    right: 10px;
}

.marker-icon {
    position: absolute;
    animation: marker-anim 2s ease-in-out infinite;
}
</style>
