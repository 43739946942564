<template>
    <div v-if="opcionEnvio.datosenvio">
        <v-row class="d-flex mx-4 mx-sm-8" dense no-gutters>
            <v-col>
                <p class="ml-2 mb-4">
                    {{ $t('compraEnvioFacturacion.introduceTuCodigoPostal') }}
                </p>
                <v-text-field
                    v-model="codpostalValue"
                    autocomplete="postal-code"
                    :rules="[rules.required, rules.codPostal]"
                    maxlength="5"
                    style="max-width: 350px;"
                    class="justify-self-center"
                    :label="$t('compraEnvioFacturacion.codigoPostal')"
                    :loading="loading"
                    required />
            </v-col>
            <v-col class="d-flex mb-4 align-center justify-center ml-sm-10 ml-md-8 ml-lg-16">
                <compra-metodo-envio-marker-icon :size="markerSize" :is-valid="metodoEnvio !== null" :is-error="envioNoDisponible" />
            </v-col>
        </v-row>

        <v-alert v-if="metodoEnvio" type="info" variant="tonal" class="rounded-t-0">
            <dl>
                <div>
                    <dt>{{ $t('compraEnvioFacturacion.zonaDeEnvio') }}</dt>
                    <dd>
                        {{ metodoEnvio.descripcion }}
                    </dd>
                </div>
                <div>
                    <dt>{{ $t('compraEnvioFacturacion.gastosDeEnvio') }}</dt>
                    <dd v-if="metodoEnvio.importe > 0">
                        {{ precioFormateado(metodoEnvio.importe) }}
                    </dd>
                    <dd v-else>
                        <v-chip color="success" rounded="lg" size="md" class="px-2 py-1 my-n1 mx-n1">
                            {{ $t('compraEnvioFacturacion.gratis') }}
                        </v-chip>
                    </dd>
                </div>
            </dl>
        </v-alert>

        <oops-alert v-if="error" :error="error" variant="flat" :border="'start'" class="rounded-t-0" :mostrar-reintentar="mostrarReintentar" @reintentar="obtenerMetodoEnvio" />
    </div>
    <div v-else>
        <v-row class="d-flex my-4 mb-8" dense no-gutters>
            <v-col class="flex-grow-1 mx-8 mr-4 mr-md-16s">
                <div v-if="loading" class="d-flex h-100 align-center justify-center">
                    <v-progress-circular indeterminate color="primary" size="large" />
                </div>
                <p v-else>
                    <i18n-t v-if="!tiendaDireccionEntrega" scope="global" keypath="compraEnvioFacturacion.deberasIrARecogerTuPedidoPor">
                        <template #farmacia>
                            <strong>{{ tiendaNombre }}</strong>
                        </template>
                        <template #direccion>
                            <strong>{{ tiendaDireccion }}</strong>
                        </template>
                    </i18n-t>
                    <i18n-t v-else scope="global" keypath="compraEnvioFacturacion.deberasIrARecogerTuPedidoSinNombre">
                        <template #direccion>
                            <strong>{{ tiendaDireccionEntrega }}</strong>
                        </template>
                    </i18n-t>
                </p>
            </v-col>
            <v-col class="d-flex mb-4 align-center justify-center ml-sm-10 ml-md-8 ml-lg-16">
                <compra-metodo-envio-marker-icon :size="markerSize" :is-valid="!loading" :is-error="envioNoDisponible" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import CompraMetodoEnvioMarkerIcon from './CompraMetodoEnvioMarkerIcon.vue';
import EurosHelper from '../../support/EurosHelper';
import OopsAlert, { OopsError } from '../../components/OopsAlert.vue';

export default {
    components: {
        CompraMetodoEnvioMarkerIcon,
        OopsAlert,
    },
    props: {
        /**
         * El código postal introducido por el usuario.
         *
         * @type {string}
         */
        codpostal: {
            type: String,
            default: '',
        },

        /**
         * La opción de envío seleccionada.
         *
         * @type {{idopcion: number, descripcion: string, datosenvio: boolean}?}
         */
        opcionEnvio: {
            type: Object,
            default: null,
        },

        /**
         * El total de la compra. Este valor se utiliza para determinar el método de envío
         * en el servidor.
         *
         * @type {number}
         */
        cestaTotal: {
            type: Number,
            required: true,
        },

        /**
         * La dirección de recogida de la tienda. Esta dirección se muestra al usuario
         * cuando la opción de envío seleccionada es "Recoger en tienda".
         *
         * @type {string}
         */
        tiendaDireccion: {
            type: String,
            required: true,
        },
        /**
         * La dirección de recogida de la tienda. Esta dirección se muestra al usuario
         * cuando la opción de envío seleccionada es "Recoger en tienda".
         *
         * @type {string}
         */
        tiendaDireccionEntrega: {
            type: String,
            default: null,
        },
        /**
         * El nombre de la tienda. Este nombre se muestra al usuario
         * cuando la opción de envío seleccionada es "Recoger en tienda".
         *
         * @type {string}
         */
        tiendaNombre: {
            type: String,
            required: true,
        },
    },
    emits: [
        'update:codpostal',
        'update:metodoEnvio',
    ],
    data() {
        return {
            /**
             * Error al cargar las notificaciones.
             *
             * @type {Error|null}
             */
            error: null,

            /**
             * Indica si se está cargando contenido.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * El método de envío que se utilizará. El método lo determina el servidor
             * en base a la opcion de envío seleccionada, monto de la compra y el código postal.
             * El valor es null si no se ha seleccionado una opcion de envio, o si se está cargando.
             *
             * @type {{importe: number, descripcion: string, idregla: number}?}
             */
            metodoEnvio: null,

            /**
             * Indica si el envio no está disponible para el ZIP code introducido.
             *
             * @type {boolean}
             */
            envioNoDisponible: false,
        };
    },
    computed: {
        codpostalValue: {
            get() { return this.codpostal; },
            set(value) { this.$emit('update:codpostal', value); },
        },
        rules() {
            return {
                required: (value) => !!value || this.$t('compraEnvioFacturacion.obligatorio'),
                codPostal: (value) => value.length >= 5 || this.$t('compraEnvioFacturacion.5caracteres'),
            };
        },
        direccionDeRecogida() {
            return this.opcionEnvio.datosenvio;
        },
        markerSize() {
            return {
                'xs': 100,
                'sm': 150,
                'md': 110,
                'lg': 150,
            }[this.$vuetify.display.name] || 150;
        },
        mostrarReintentar() {
            return this.error?.isAxiosError;
        },
    },
    watch: {
        codpostal() {
            this.envioNoDisponible = false;
            this.error = '';
            this.metodoEnvio = null;

            if (this.opcionEnvio.datosenvio && this.codpostal.length === 5) {
                this.obtenerMetodoEnvio();
            }
        },
        opcionEnvio() {
            this.obtenerMetodoEnvio();
        },
        metodoEnvio(value) {
            this.$emit('update:metodoEnvio', value);
        },
    },
    created() {
        this.obtenerMetodoEnvio();
    },
    methods: {
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },

        async obtenerMetodoEnvio() {

            if (!this.codpostal && this.opcionEnvio.datosenvio) {
                return;
            }

            const idopcion = this.opcionEnvio.idopcion;
            const codpostal = this.opcionEnvio.datosenvio ? this.codpostal : 0; // cero se usa cuando no se pide el código postal

            this.loading = true;
            this.error = null;
            this.metodoEnvio = null;
            this.envioNoDisponible = false;

            try {
                const { data, status } = await axios.get(`/metodoenvio/${idopcion}/${this.cestaTotal}/${codpostal}`);

                if (status !== 200) {
                    this.envioNoDisponible = true;
                    throw new OopsError(this.$t('compraEnvioFacturacion.noHayEnviosATuZona'));
                }

                this.metodoEnvio = {
                    importe: parseFloat(data.importe),
                    descripcion: data.descripcion,
                    idregla: data.idregla,
                };
            } catch (error) {
                console.error(error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
 dl > div {
    display: grid;
    padding-top: .25em;
    padding-bottom: .25em;
    grid-template-columns: minmax(10em, 20%) auto;
}

dd {
    margin: 0;
    grid-column: 2;
    font-weight: bold;
}

</style>
