<template>
    <v-container>
        <v-card
            v-if="user !== null"
            class="mt-n4 mt-sm-4 mx-n4 mx-sm-0"
            :rounded="$vuetify.display.smAndUp ? 'lg' : 0"
            :elevation="$vuetify.display.smAndUp ? 2 : 0"
            :border="!$vuetify.display.smAndUp">
            <v-card-title>{{ $t('cuentaView.detallesDeLaCuenta') }}</v-card-title>
            <v-list>
                <v-list-item prepend-icon="mdi-account" :title="nombreApellidos">
                    <template #append>
                        <v-btn v-if="$vuetify.display.smAndUp" variant="plain" prepend-icon="mdi-pencil">
                            <span>{{ $t('cuentaView.cambiarNombre') }}</span>
                            <cuenta-user-edit-datos-dialog activator="parent" />
                        </v-btn>
                        <v-btn v-else icon variant="plain" :aria-label="$t('cuentaView.cambiarNombre')">
                            <v-icon icon="mdi-pencil" />
                            <cuenta-user-edit-datos-dialog activator="parent" />
                        </v-btn>
                    </template>
                </v-list-item>
                <v-list-item prepend-icon="mdi-email" :title="user.email" />
                <v-list-item prepend-icon="mdi-calendar" :title="$t('cuentaView.miembroDesde') + ' ' + userCreatedAt" />
                <v-list-item v-if="telefono" prepend-icon="mdi-phone" :title="telefono" />
            </v-list>
        </v-card>

        <v-row dense class="mt-4 mt-sm-6">
            <v-col cols="12" sm="auto">
                <v-btn prepend-icon="mdi-qrcode" to="/tarjeta" class="w-100" :block="isXsOnly">
                    {{ $t('cuentaView.miTarjeta') }}
                </v-btn>
            </v-col>
            <v-col cols="12" sm="auto">
                <v-btn prepend-icon="mdi-cog" to="/configuracion" class="w-100" :block="isXsOnly">
                    {{ $t('cuentaView.opciones') }}
                </v-btn>
            </v-col>
            <v-col cols="12" sm="auto">
                <v-btn prepend-icon="mdi-exit-to-app" class="w-100" :block="isXsOnly" @click="logout()">
                    {{ $t('cuentaView.cerrarSesion') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-footer app>
            <v-col>
                <v-divider class="my-6 mx-n4 mx-sm-0" />
                <p class="text-medium-emphasis body-2">
                    <i18n-t scope="global" keypath="pedidoCard.trataremosSusDatos.text">
                        <template #url>
                            <a href="#/politica-privacidad">{{ $t('pedidoCard.trataremosSusDatos.link') }}</a>
                        </template>
                    </i18n-t>
                </p>
            </v-col>
        </v-footer>
    </v-container>
</template>

<script>
import { store } from '@/store';
import CuentaUserEditDatosDialog from './CuentaUserEditDatosDialog.vue';
import moment from 'moment';

export default {
    components: {
        CuentaUserEditDatosDialog,
    },
    data: () => ({
        //
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        isLogged() {
            return store.isLogged;
        },
        user() {
            return store.user;
        },
        nombreApellidos() {
            return (store.user?.apellidos)
                ? `${store.user.name} ${store.user.apellidos}`
                : store.user?.name;
        },
        userCreatedAt() {
            return moment(store.user.created_at).format('LL');
        },
        isXsOnly() {
            return this.$vuetify.display.xsOnly;
        },
        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },
        telefono() {
            return store.user?.telefono;
        },
    },
    async mounted() {
        try {
            await store.getDatosUsuario();
        } catch (error) {
            this.error = error;
        }
    },
    methods: {
        async logout() {
            await store.logoutUser();

            await this.$router.push('/');
        },
    },
};
</script>
<style scoped>
a {
    text-decoration: none;
}
</style>
