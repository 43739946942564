<template>
    <v-container>
        <h1 class="text-primary mb-3">
            {{ $t('compraEnvioFacturacion.datosParaLaCompra') }}
        </h1>
        <oops-alert v-if="errorCesta" :error="errorCesta" variant="tonal" mostrar-reintentar @reintentar="cargarCesta" />

        <div v-else-if="loadingCesta" class="d-flex align-center justify-center py-5 px-8">
            <v-progress-circular indeterminate color="primary" />
        </div>

        <v-row v-else>
            <v-col cols="12" lg="8" md="7">
                <!-- El margin negativo y padding positivo (ma-n4 pa-4) es un hack para que si mostramos
                        elementos con drop shadow adentro del v-window, no queden clipeados por el overflow -->
                <v-window :model-value="stepNumber" class="ma-n4 pa-4" :touch="false">
                    <v-window-item :value="1">
                        <compra-step-1
                            ref="step1"
                            v-model:metodo-envio="metodoEnvio"
                            v-model:codpostal="codpostal"
                            v-model:opcion-envio="opcionEnvio"
                            :cesta="cesta"
                            :tienda-nombre="farmacia.nombre"
                            :tienda-direccion="farmacia.direccion"
                            :readonly="loadingSubmit"
                            @update:can-continue="canContinueStep1 = $event"
                            @submit="siguiente" />
                    </v-window-item>

                    <v-window-item v-if="solicitarDatosEnvio" :value="2">
                        <compra-step-2
                            ref="step2"
                            v-model:nombre="datosEnvio.nombre"
                            v-model:apellidos="datosEnvio.apellidos"
                            v-model:telefono="datosEnvio.telefono"
                            v-model:direccion1="datosEnvio.direccion1"
                            v-model:direccion2="datosEnvio.direccion2"
                            v-model:poblacion="datosEnvio.poblacion"
                            v-model:provincia="datosEnvio.provincia"
                            :codpostal="codpostal"
                            :readonly="loadingSubmit"
                            @update:can-continue="canContinueStep2 = $event"
                            @submit="siguiente" />
                    </v-window-item>

                    <v-window-item :value="3">
                        <compra-step-3
                            v-if="metodoEnvio"
                            ref="step3"
                            v-model:formapago="formapago"
                            v-model:observaciones="observaciones"
                            v-model:tyc="tyc"
                            :cesta="cesta"
                            :metodo-envio="metodoEnvio"
                            :opcion-envio="opcionEnvio"
                            :es-retiro-en-tienda="solicitarDatosEnvio === false"
                            :readonly="loadingSubmit"
                            @update:can-continue="canContinueStep3 = $event"
                            @submit="siguiente" />
                    </v-window-item>
                </v-window>

                <div v-if="errorSubmit" class="mt-4 d-block">
                    <oops-alert :error="errorSubmit" variant="tonal" />
                </div>

                <compra-navigation
                    :step-number="stepNumber"
                    :step-count="3"
                    :can-continue="canContinue"
                    :finish-button-label="confirmarPedidoButtonText"
                    :loading="loadingSubmit"
                    :disabled="loadingSubmit"
                    @previous="anterior"
                    @next="siguiente" />
            </v-col>

            <v-col v-if="$vuetify.display.mdAndUp" cols="12" lg="4" md="5">
                <!-- resumen de la cesta (Barra lateral solo desktop)-->
                <compra-resumen-card
                    :cesta="cesta"
                    :metodo-envio="metodoEnvio"
                    :opcion-envio="opcionEnvio"
                    :total="total" />
            </v-col>
        </v-row>

        <v-divider class="mt-8 mb-4" />
        <v-col cols="12" class="mb-8">
            <compra-texto-legal :rgpddenominacionsocial="farmacia.rgpddenominacionsocial || farmacia.nombre" />
        </v-col>
    </v-container>
</template>
<script>
import { store } from '@/store';
import axios from 'axios';
import CompraTextoLegal from './CompraTextoLegal.vue';
import { Device } from '@capacitor/device';
import EurosHelper from '../../support/EurosHelper';
import CompraStep1 from './CompraStep1.vue';
import CompraStep2 from './CompraStep2.vue';
import CompraStep3 from './CompraStep3.vue';
import CompraNavigation from './CompraNavigation.vue';
import CompraResumenCard from './CompraResumenCard.vue';
import OopsAlert from '../../components/OopsAlert.vue';
import { normalizeCesta } from '../../support/Normalization';
import HeartbeatService from '../../support/HeartbeatService';

export default {
    components: {
        CompraStep1,
        CompraStep2,
        CompraStep3,
        CompraTextoLegal,
        CompraNavigation,
        CompraResumenCard,
        OopsAlert,
    },
    data() {
        return {
            /**
             * El número de paso actual.
             *
             * @type {number}
             */
            stepNumber: 1,

            /**
             * Indica si se puede continuar al paso 2 desde el paso 1.
             *
             * @type {boolean}
             */
            canContinueStep1: false,

            /**
             * Indica si se puede continuar al paso 3 desde el paso 2.
             *
             * @type {boolean}
             */
            canContinueStep2: false,

            /**
             * Indica si se puede enviar el formulario de compra desde el paso 3.
             *
             * @type {boolean}
             */
            canContinueStep3: false,

            /**
             * Indica si se está cargando la cesta de la compra desde el servidor.
             *
             * @type {boolean}
             */
            loadingCesta: false,

            /**
             * El error que se ha producido al cargar la cesta.
             *
             * @type {Error|null}
             */
            errorCesta: null,

            /**
             * Indica si se está enviando el formulario final de compra.
             *
             * @type {boolean}
             */
            loadingSubmit: false,

            /**
             * El error que se ha producido al enviar el formulario de compra.
             *
             * @type {Error|null}
             */
            errorSubmit: null,

            /**
             * La opcion de envío seleccionada, o null si no se ha seleccionado ninguna.
             *
             * @type {{idopcion: number, descripcion: string, datosenvio: boolean}?}
             */
            opcionEnvio: null,

            /**
             * El método de envío que se utilizará. El método lo determina el servidor
             * en base a la opcion de envío seleccionada, monto de la compra y el código postal.
             * El valor es null si no aún no se ha determinado el método de envío.
             *
             * @type {{importe: number, descripcion: string, idregla: number}?}
             */
            metodoEnvio: null,

            /**
             * Los datos de envío del usuario.
             *
             * @type {object}
             */
            datosEnvio: {
                nombre: '',
                apellidos: '',
                telefono: '',
                direccion1: '',
                direccion2: '',
                poblacion: '',
                provincia: '',
            },

            /**
             * Las observaciones del usuario sobre el pedido.
             *
             * @type {string}
             */
            observaciones: '',

            /**
             * Indica si se han aceptado los términos y condiciones.
             *
             * @type {boolean}
             */
            tyc: false,

            /**
             * Cesta del usuario. Null si la cesta aún no se ha cargado.
             *
             * @type {{lineas: [], lineasBonificadas: [], vales: [], puntos: [], total: number}?}}
             */
            cesta: null,

            /**
             * El código postal introducido por el usuario.
             *
             * @type {string}
             */
            codpostal: '',

            /**
             * La forma de pago seleccionada por el usuario.
             *
             * @type {object}
             */
            formapago: null,
        };
    },

    computed: {
        farmacia() {
            return store.farmacia;
        },
        solicitarDatosEnvio() {
            return this.metodoEnvio && this.opcionEnvio?.datosenvio;
        },
        rules() {
            return ({
                required: (value) => !!value || this.$t('compraEnvioFacturacion.obligatorio'),
                max255: [(value) => value.length <= 255 || this.$t('compraEnvioFacturacion.maximo255caracteres')],
            });
        },
        total() {
            if (this.cesta === null) return 0;
            if (this.metodoEnvio === null) return this.cesta.total;
            return this.cesta.total + this.metodoEnvio.importe;
        },
        confirmarPedidoButtonText() {
            return this.formapago?.paymethod === 'C' || this.formapago?.paymethod === 'z'
                ? this.$t('compraEnvioFacturacion.continuarConElPago')
                : this.$t('compraEnvioFacturacion.confirmarPedido');
        },
        canContinue() {
            if (this.cesta === null) return false;

            return [
                this.canContinueStep1,
                this.canContinueStep2,
                this.canContinueStep3,
            ][this.stepNumber - 1] || false;
        },
    },

    watch: {
        stepNumber() {
            // Normalmente, vue router se encarga de hacer scroll al top de la página cuando
            // se produce un cambio de ruta. Sin embargo, en este caso, es todo la misma ruta,
            // por lo que hay que hacer scroll manualmente.
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },

    created() {
        this.cargarCesta();
        this.cargarDatosPorDefecto();
    },

    methods: {
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },

        async cargarCesta() {
            this.loadingCesta = true;
            this.errorCesta = null;

            try {
                const { data } = await axios.get('/cesta');
                this.cesta = normalizeCesta(data);

                if (this.cesta.lineas.length === 0) { // Si la cesta está vacía, redirigimos a la página de la cesta
                    this.$router.push({ name: 'Cesta' });
                }
            } catch (error) {
                this.errorCesta = error;
                console.error(error);
            } finally {
                this.loadingCesta = false;
            }
        },

        async cargarDatosPorDefecto() {
            try {
                const response = await axios.get('/datosenvio', {
                    validateStatus: (status) => status === 200, // 204 No Content significa que no hay datos de envío
                });

                const data = response.data;
                this.codpostal = data.codpostal;
                const trim = (str) => str?.trim() || '';
                this.datosEnvio = {
                    nombre: trim(data.nombre),
                    apellidos: trim(data.apellidos),
                    telefono: trim(data.telefono),
                    direccion1: trim(data.direccion1),
                    direccion2: trim(data.direccion2),
                    poblacion: trim(data.poblacion),
                    provincia: trim(data.provincia),
                };
            } catch (error) {
                // No mostramos ningun error ya que es normal que no haya datos de envío, no es algo crítico
                // Usamos datos de envío por defecto sacados del store
                console.error(error);
                this.datosEnvio = {
                    ...this.datosEnvio,
                    nombre: store.user.name,
                    apellidos: store.user.apellidos,
                };
            }
        },

        getDatosPedido() {
            const datosEnvio = this.solicitarDatosEnvio ? this.datosEnvio : null;

            return {
                ...datosEnvio,
                email: store.user.email,
                nombre: datosEnvio?.nombre || store.user.name,
                apellidos: datosEnvio?.apellidos || store.user.apellidos,
                telefono: datosEnvio?.telefono || null,
                direccion1: datosEnvio?.direccion1 || null,
                direccion2: datosEnvio?.direccion2 || null,
                poblacion: datosEnvio?.poblacion || null,
                provincia: datosEnvio?.provincia || null,
                codpostal: this.codpostal,
                observaciones: this.observaciones,
                idreglaenvio: this.metodoEnvio.idregla,
                total: this.total,
                envio: {
                    idopcion: this.opcionEnvio.idopcion,
                    descripcion: this.opcionEnvio.descripcion, // no usado para pago redsys, solo en pago pendiente
                },
            };
        },

        handleErrorSubmit(error) {
            if (error?.response?.status === 422) {
                this.$router.push({
                    name: 'ErrorPago',
                    params: {
                        message: error.response.data.message,
                    },
                });
            } else {
                this.errorSubmit = error;
            }
        },

        async procesarPagoEnTienda(formapago) {
            this.loadingSubmit = true;
            this.errorSubmit = null;

            try {
                await axios.post('/pago/pendiente', {
                    datospedido: {
                        idformapago: formapago.idformapago,
                        ...this.getDatosPedido(),
                    },
                });
                this.$router.push('/pedido-pendiente');
            } catch (error) {
                this.handleErrorSubmit(error);
                console.error(error);
            }
            this.loadingSubmit = false;
        },

        async procesarPagoRedsys(formapago) {
            this.loadingSubmit = true;
            this.errorSubmit = null;

            try {
                const info = await Device.getInfo();
                const { data } = await axios.post('/pago/getdata', {
                    idformapago: formapago.idformapago,
                    so: info.operatingSystem,
                    ...this.getDatosPedido(),
                });

                const form = document.createElement('form');
                form.method = 'POST';
                form.action = data.urlconexion;
                form.target = '_self';
                const createInput = (name, value) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = name;
                    input.value = value;
                    return input;
                };

                form.appendChild(createInput('Ds_SignatureVersion', data.Ds_SignatureVersion));
                form.appendChild(createInput('Ds_MerchantParameters', data.Ds_MerchantParameters));
                form.appendChild(createInput('Ds_Signature', data.Ds_Signature));
                document.body.appendChild(form);
                form.submit();
            } catch (error) {
                this.handleErrorSubmit(error);
                this.loadingSubmit = false;
                console.error(error);
            }

            // No hacemos loadingSubmit = false porque queremos que siga "cargando" mientras se abre la ventana de pago de redsys
        },

        async confirmarPedido() {
            store.ActualizaKeepAliveSecctions();
            if (this.formapago.paymethod === ' ') {
                await this.procesarPagoEnTienda(this.formapago);
                await HeartbeatService.instance.heartbeat();
            } else {
                this.procesarPagoRedsys(this.formapago);
            }
        },

        anterior() {
            if (this.stepNumber === 3 && !this.solicitarDatosEnvio) {
                this.stepNumber -= 2; // Saltearse el paso 2
            } else {
                this.stepNumber--;
            }
        },

        async siguiente() {
            const steps = [
                this.$refs.step1,
                this.$refs.step2,
                this.$refs.step3,
            ];

            if (! await steps[this.stepNumber - 1].validate()) {
                return;
            }

            if (this.stepNumber === 3) {
                this.confirmarPedido();
                return;
            }

            if (this.stepNumber === 1 && !this.solicitarDatosEnvio) {
                this.stepNumber += 2; // Saltearse el paso 2
            } else {
                this.stepNumber++;
            }
        },
    },
};
</script>


