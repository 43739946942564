<template>
    <!--
    INFO: Si bien este componente es funcionalmente muy similar a la vista de la cesta, tiene algunas diferencias:
    - El espaciado entre los elementos y el tamaño de fuente es menor, esto permite mostrarlo como una columna lateral.
    - No se muestra el botón de eliminar ni el de editar cantidad.
    - Se muestra siempre como lista, no como tabla.
    - Los productos no tienen links a sus páginas de detalle. Esto es porque si el usuario llegó a este punto, ya no tiene
    sentido que pueda navegar a la página de detalle. Queremos que se quede en la página de compra y no se distraiga.
    -->
    <div>
        <v-card>
            <v-card-title class="text-h6 pb-0 mb-n2">
                {{ $t('compraEnvioFacturacion.resumenDeLaCompra') }}
            </v-card-title>

            <v-card-text>
                <template v-for="(item, i) in cesta.lineas" :key="i">
                    <div class="py-6">
                        <div class="d-flex">
                            <v-avatar
                                :image="item.imagen"
                                width="50"
                                height="50"
                                :alt="item.descripcion"
                                class="d-inline-block rounded-lg mr-2" />

                            <div>
                                {{ item.cantidad }} × {{ item.descripcion }}
                            </div>
                        </div>
                        <v-row class="mt-1" dense>
                            <v-col>
                                <v-row v-if="item.promociones?.length > 0" dense class="text-body-2 mt-1">
                                    <v-col v-for="promocion in item.promociones" :key="promocion.id">
                                        <v-chip color="success" density="comfortable" :prepend-icon="promocion.icon" :text="promocion.descripcion" />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-spacer />


                            <v-col class="d-flex-block ml-4 align-self-end text-right ma-n1">
                                <span v-if="item.importeOriginal" class="text-decoration-line-through ma-1 text-disabled">
                                    {{ precioFormateado(item.importeOriginal) }}
                                </span>
                                <span class="text-body-2 ma-1">
                                    {{ precioFormateado(item.importeFinal) }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider class="mx-n4" />
                </template>

                <v-row class="mt-3">
                    <v-col cols="6" class="text-body-2">
                        {{ $t('compraEnvioFacturacion.subtotal') }}
                    </v-col>
                    <v-col cols="6" class="text-right text-body-2">
                        {{ precioFormateado(cesta.total) }}
                    </v-col>
                </v-row>

                <v-row v-if="metodoEnvio && opcionEnvio" class="mt-2">
                    <v-col cols="6" class="text-body-2">
                        <div v-if="!opcionEnvio.esRetiroEnTienda">
                            {{ opcionEnvio.descripcion }}
                        </div>

                        <td v-else>
                            {{ $t('compraEnvioFacturacion.retiroEnTienda') }}
                        </td>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <span v-if="metodoEnvio.importe > 0">
                            {{ precioFormateado(metodoEnvio.importe) }}
                        </span>
                        <span v-else>
                            {{ $t('compraEnvioFacturacion.gratis') }}
                        </span>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="6" class="text-body-2">
                        {{ $t('compraEnvioFacturacion.total') }}
                    </v-col>
                    <v-col cols="6" class="text-right text-body-1 font-weight-bold">
                        {{ precioFormateado(total) }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card v-if="cesta.lineasBonificadas.length > 0" class="mt-4">
            <v-card-title class="text-h6 mb-n2">
                <v-icon class="mr-4" icon="mdi-gift" size="small" />
                <span>{{ $t('compraCesta.deRegaloConTuCompra') }}</span>
            </v-card-title>

            <v-list>
                <v-list-item v-for="(item, i) in cesta.lineasBonificadas" :key="i" class="py-4">
                    <v-avatar
                        :image="item.imagen"
                        width="50"
                        height="50"
                        :alt="item.descripcion"
                        class="d-inline-block mr-4 float-left rounded-lg mr-4" />

                    <div :to="item.to" class="text-body-2 flex-grow-1">
                        {{ item.cantidad }} × {{ item.descripcion }}
                    </div>

                    <div class="text-end mt-2">
                        <v-chip color="success" density="comfortable" :text="$t('compraCesta.gratis')" />
                    </div>
                </v-list-item>
            </v-list>
        </v-card>


        <v-card v-if="cesta.vales.length > 0" class="mt-4 pa-4 pt-0">
            <v-card-title class="px-0">
                {{ $t('compraCesta.valesPromocionalesPorTuCompra') }}
            </v-card-title>
            <v-row dense>
                <template v-for="(item, i) in cesta.vales" :key="i">
                    <v-alert
                        type="success"
                        variant="tonal"
                        class="ma-1 text-body-2"
                        density="compact"
                        :icon="item.icon"
                        rounded="lg">
                        <div class="mr-2 font-weight-bold">
                            {{ item.descripcion }}
                        </div>
                        <div>
                            {{ item.contenido }}
                        </div>
                    </v-alert>
                </template>
            </v-row>
            <div class="text-body-2 text-medium-emphasis text-justify mt-4" variant="tonal" type="info">
                {{ $t('compraCesta.losValesPromocionales') }}
            </div>
        </v-card>

        <v-alert v-if="cesta.puntos.totalPuntos" type="success" class="mt-4" icon="mdi-counter">
            <span class="mr-4">{{ $t('compraCesta.puntosPorTuCompra') }}</span>
            <strong>{{ cesta.puntos.totalPuntos }}</strong>
        </v-alert>
    </div>
</template>

<script>
import EurosHelper from '../../support/EurosHelper';

export default {
    props: {
        /**
         * La cesta de la compra.
         */
        cesta: {
            type: Object,
            required: true,
        },

        /**
         * El monto total de la compra.
         */
        total: {
            type: Number,
            required: true,
        },

        /**
         * El método de envío seleccionado.
         */
        metodoEnvio: {
            type: Object,
            default: null,
        },

        /**
         * La opción de envío seleccionada.
         */
        opcionEnvio: {
            type: Object,
            default: null,
        },
    },

    methods: {
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },
    },
};
</script>
