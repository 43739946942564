<template>
    <v-chip
        :prepend-icon="config.icon"
        :color="config.color"
        variant="flat"
        class="pa-4"
        rounded
        :text="label" />
</template>

<script>
export default {
    props: {
        estado: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            //
        };
    },
    computed: {
        config() {
            return {
                'C': {
                    color: 'green',
                    icon: 'mdi-checkbox-marked-circle-outline',
                },
                'A': {
                    color: 'black',
                    icon: 'mdi-close-thick',
                },
                'P': {
                    color: 'yellow',
                    icon: 'mdi-package-up',
                },
                'E': {
                    color: 'grey',
                    icon: 'mdi-truck-check-outline',
                },
                'D': {
                    color: 'grey',
                    icon: 'mdi-arrow-right-bold-box-outline',
                },
            }[this.estado.trim()] || {
                color: 'grey',
                icon: 'mdi-arrow-right-bold-box-outline',
            };
        },
    },
};
</script>
