export default {
    /**
     * Status code for when the requested farmacia is not found.
     */
    NOT_FOUND: 'NOT_FOUND',

    /**
     * Status code for when the requested farmacia is disabled.
     */
    DISABLED: 'INA',

    /**
     * Status code for when the requested farmacia has not accepted the legal TOS.
     */
    PENDING_LEGAL_CONDITIONS: 'LEG',

    /**
     * Status code for when the requested farmacia is in maintenance mode.
     */
    MAINTANANCE_MODE: 'MAN',

    /**
     * Status code for when the request does not contain a valid subdomain header.
     */
    INVALID_SUBDOMAIN: 'INVALID_SUBDOMAIN',

    /**
     * Unknown status code.
     */
    UNKNOWN: 'UNKNOWN',
};
