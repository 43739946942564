<template>
    <v-card class="mx-auto" variant="elevated" max-width="800">
        <div class="bg-grey text-on-grey pa-8 d-flex flex-column align-center text-center">
            <v-icon icon="mdi-emoticon-confused" size="60" style="opacity: 0.8" />
            <h1 class="d-h4 d-inline mt-4">
                {{ titulo || $t('recursoNoEncontradoCard.titulo') }}
            </h1>
            <div v-if="subtitulo" class="text-body-1 w-50 text-on-grey" style="opacity: 0.8">
                {{ subtitulo }}
            </div>
        </div>
        <v-card-text class="px-10">
            <div class="my-8 text-center text-body-1 mx-auto" style="max-width: 500px;">
                <p>{{ descripcion || $t('recursoNoEncontradoCard.descripcion') }}</p>
                <div class="text-center mt-16">
                    <v-btn v-if="canGoBack" color="grey" prepend-icon="mdi-arrow-left" size="large" variant="flat" class="mx-auto" @click="$router.back()">
                        {{ $t('volver') }}
                    </v-btn>
                    <v-btn v-else color="grey" prepend-icon="mdi-home" size="large" variant="flat" class="mx-auto" to="/">
                        {{ $t('volverAlInicio') }}
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>


<script>
/**
 * Tarjeta genérica de error 404 para mostrar cuando no se encuentra un recurso.
 */
export default {
    props: {
        titulo: {
            type: String,
            default: '',
        },
        subtitulo: {
            type: String,
            default: '',
        },
        descripcion: {
            type: String,
            default: '',
        },
    },
    computed: {
        canGoBack() {
            return window.history.state.back !== null;
        },
    },
};
</script>
