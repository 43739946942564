<template>
    <div class="d-flex flex-column wrapper-full-screen">
        <!-- Desktop: Se muestra una tarjeta centrada -->
        <v-container v-if="$vuetify.display.smAndUp" class="w-100 h-100 d-flex overflow-y-hidden">
            <v-card class="pa-4 w-100 d-flex flex-column" :loading="mensajes !== null && loading">
                <v-sheet
                    ref="container"
                    color="background"
                    class="pa-4 flex-grow-1"
                    rounded="lg"
                    :border="true"
                    style="overflow-x: hidden; overflow-y: auto;">
                    <mensajes-content
                        :farmacia-nombre="farmaciaNombre"
                        :mensajes="mensajes"
                        :loading="loading" />
                </v-sheet>
                <v-form class="d-flex justify-space-between mx-4 mt-3 mx-sm-0" @submit.prevent="enviarMensaje">
                    <v-textarea
                        v-model="mensajeTexto"
                        counter="255"
                        maxlength="255"
                        variant="outlined"
                        :placeholder="$t('pedidoCard.mensaje')"
                        append-outer-icon="mdi-send"
                        :persistent-hint="false"
                        density="default"
                        :rows="1"
                        auto-grow
                        :disabled="enviandoMensaje"
                        :single-line="true"
                        :hide-details="true"
                        enterkeyhint="send"
                        @keydown.enter.prevent="enviarMensaje" />
                    <v-btn
                        class="ml-3"
                        type="submit"
                        rounded="lg"
                        color="primary"
                        append-icon="mdi-send"
                        elevation="1"
                        height="55px"
                        :loading="enviandoMensaje"
                        :disabled="mensajeTexto.length < 1 || enviandoMensaje">
                        {{ $t('pedidoCard.enviar') }}
                    </v-btn>
                </v-form>
            </v-card>
        </v-container>

        <!-- Mobile: Se muestra el contenido directamente -->
        <v-container v-else class="w-100 h-100 pa-0 d-flex flex-column overflow-y-hidden">
            <v-sheet
                ref="container"
                color="background"
                class="pa-4 flex-grow-1"
                style="overflow-x: hidden; overflow-y: auto;">
                <mensajes-content
                    :farmacia-nombre="farmaciaNombre"
                    :mensajes="mensajes"
                    :loading="loading" />
            </v-sheet>
            <v-form v-click-outside="onClickOutside" class="w-100" @submit.prevent="enviarMensaje">
                <v-sheet class="d-flex justify-space-between align-center px-4 py-3" elevation="3">
                    <v-textarea
                        v-model="mensajeTexto"
                        counter="255"
                        maxlength="255"
                        variant="outlined"
                        :placeholder="$t('pedidoCard.mensaje')"
                        append-outer-icon="mdi-send"
                        :persistent-hint="false"
                        density="compact"
                        :rows="1"
                        auto-grow
                        :single-line="true"
                        :hide-details="true"
                        :disabled="enviandoMensaje"
                        enterkeyhint="send"
                        @keydown.enter.prevent="enviarMensaje" />
                    <v-btn
                        class="ml-2 mr-n1"
                        type="submit"
                        variant="elevated"
                        color="primary"
                        icon="mdi-send"
                        height="40px"
                        width="40px"
                        :aria-label="$t('pedidoCard.enviar')"
                        :loading="enviandoMensaje"
                        :disabled="mensajeTexto.length < 1 || enviandoMensaje" />
                </v-sheet>
            </v-form>
        </v-container>
    </div>
</template>
<script>
import { store } from '@/store';
import { Capacitor } from '@capacitor/core';
import { clickOutside } from '@/support/Directives.js';
import { Keyboard } from '@capacitor/keyboard';
import axios from 'axios';
import MensajesContent from './MensajesContent.vue';

export default {
    components: {
        MensajesContent,
    },

    directives: {
        clickOutside,
    },

    data: () => ({
        /**
         * Indica si se está cargando información desde el server.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Mensajes del chat. Si es null, significa que no se ha cargado aún.
         *
         * @type {object[]|null}
         */
        mensajes: null,

        /**
         * Texto del mensaje que se está escribiendo.
         *
         * @type {string}
         */
        mensajeTexto: '',

        /**
         * Indica si se está enviando un mensaje.
         *
         * @type {boolean}
         */
        enviandoMensaje: false,
    }),
    computed: {
        userNombre() {
            return store.user.name;
        },

        farmaciaNombre() {
            return store.farmacia.nombre;
        },

        mensajesBadgeCount() {
            return store.mensajes.badge;
        },
    },

    watch: {
        mensajesBadgeCount() {
            // El HeartbeatService se encarga de cambiar el valor del badge
            // De esta forma podemos tener feedback inmediato
            // cuando se recibe un mensaje nuevo. Esto permite que esten sincronizados el badge y el componente.
            // No es el mejor diseño para saber si hay mensajes nuevos, pero sirve para nuestro caso de uso y
            // las restricciones de servidor.
            if (this.mensajesBadgeCount > 0) {
                this.obtenerMensajes();
            }
        },
    },

    _listeners: [], // No reactivo

    mounted() {
        this.obtenerMensajes();

        if (Capacitor.isNativePlatform()) {
            this.$options._listeners.push(
                Keyboard.addListener('keyboardDidShow', info => this.onKeyboardToggle(info.keyboardHeight)),
            );

            this.$options._listeners.push(
                Keyboard.addListener('keyboardDidHide', () => this.onKeyboardToggle(0)),
            );
        }
    },

    unmounted() {
        clearInterval(this.$options._interval);

        this.$options._listeners.forEach(listener => listener.remove());
    },

    methods: {
        async notificarMensajesLeidos() {
            await axios.post('/mensajesleidos'); // Si falla, no importa

            store.mensajes.badge = 0; // No hace falta hacer un heartbeat, simplemente ponemos en cero y listo.
        },

        validate() {
            return this.mensajeTexto.length > 0 && this.mensajeTexto.length < 255;
        },

        async enviarMensaje() {
            if (!this.validate()) {
                return;
            }

            this.enviandoMensaje = true;
            try {
                const response = await axios.post('/usermsg', {
                    mensaje: this.mensajeTexto,
                });

                this.mensajeTexto = '';
                this.mensajes.push(response.data);

                this.$nextTick(() => this.scrollToBottom());
            } catch (e) {
                console.error(e);
            } finally {
                this.enviandoMensaje = false;
            }
        },

        async obtenerMensajes() {
            if (this.loading) return;

            this.loading = true;
            const oldMensajes = this.mensajes;
            const { data } = await axios.get('usermsg');

            if (oldMensajes?.length !== data.length) {
                this.notificarMensajesLeidos();
            }

            const isFirstLoad = this.mensajes === null;
            this.mensajes = data;
            this.loading = false;

            this.$nextTick(() => this.scrollToBottom(isFirstLoad ? 'auto' : 'smooth'));
        },

        scrollToBottom(behavior) {
            const container = this.$refs.container; // No cachear $el directamente o no funciona (ni idea por que)
            container.$el.scrollTo({ // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
                top: container.$el.scrollHeight,
                behavior: behavior ?? 'smooth',
            });
        },

        onKeyboardToggle(_keyboardHeight) {
            // Scrolleamos hasta el final cuando se abre el teclado solo si ya estamos cerca del final
            const container = this.$refs.container; // No cachear $el directamente o no funciona (ni idea por que)
            if (container.$el.scrollHeight - container.$el.scrollTop - container.$el.clientHeight < 200) {
                setTimeout(() => this.scrollToBottom('auto'), 100);
            }
        },

        onClickOutside() {
            // En iOS, por alguna extraña razón, el teclado no se oculta cuando se hace click fuera del input.
            // la solución es tener un listener en el body del evento click. Esto hace que iOS permita perder
            // el foco del input. La directiva custom v-click-outside sirve para esto.
            // Este callback puede quedar vacío.
        },
    },
};
</script>

<style scoped>
.wrapper-full-screen {
    /* Queremos que el componente ocupe toda la pantalla, asi que le ponemos un height del 100dvh menos el top y el bottom del layout */
    /* En dispositivos con notch ademas necesitamos restar el safe area inset */
    height: calc(100dvh
        - var(--v-layout-top) - var(--v-layout-bottom)
        - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
</style>
