<template>
    <v-form ref="form" @submit.prevent>
        <compra-resumen-card
            v-if="!$vuetify.display.mdAndUp"
            class="mb-6"
            :cesta="cesta"
            :metodo-envio="metodoEnvio"
            :opcion-envio="opcionEnvio"
            :total="total" />

        <compra-formas-pago
            v-if="metodoEnvio"
            :idregla="metodoEnvio.idregla"
            :model-value="formapago"
            @update:model-value="$emit('update:formapago', $event)" />

        <compra-observaciones
            class="mt-8"
            :model-value="observaciones"
            @update:model-value="$emit('update:observaciones', $event)" />

        <v-row class="mt-2">
            <v-col cols="12">
                <compra-checkbox-tyc
                    :model-value="tyc"
                    @update:model-value="$emit('update:tyc', $event)" />

                <v-alert
                    v-if="hasBeenValidated && !tyc"
                    type="error"
                    variant="tonal"
                    :border="'start'"
                    class="mt-4"
                    :text="$t('compraEnvioFacturacion.debesAceptarLosTycdu')" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import CompraFormasPago from './CompraFormasPago.vue';
import CompraResumenCard from './CompraResumenCard.vue';
import CompraObservaciones from './CompraObservaciones.vue';
import CompraCheckboxTyc from './CompraCheckboxTyc.vue';
import EurosHelper from '../../support/EurosHelper';

export default {
    expose: ['validate'],

    components: {
        CompraFormasPago,
        CompraObservaciones,
        CompraResumenCard,
        CompraCheckboxTyc,
    },

    props: {
        /**
         * El método de envío seleccionado.
         */
        metodoEnvio: {
            type: Object,
            required: true,
        },

        /**
         * La opción de envío seleccionada.
         */
        opcionEnvio: {
            type: Object,
            required: true,
        },

        /**
         * Indica si el método de envío es retiro en tienda.
         */
        esRetiroEnTienda: {
            type: Boolean,
            required: true,
        },

        /**
         * La cesta de la compra.
         */
        cesta: {
            type: Object,
            required: true,
        },

        /**
         * La forma de pago seleccionada.
         */
        formapago: {
            type: Object,
            default: null,
        },

        /**
         * Las observaciones del pedido.
         */
        observaciones: {
            type: String,
            required: true,
        },

        /**
         * Indica si el usuario ha aceptado los términos y condiciones.
         */
        tyc: {
            type: Boolean,
            required: true,
        },
    },

    emits: [
        'submit',
        'update:canContinue',
        'update:formapago',
        'update:observaciones',
        'update:tyc',
    ],

    data() {
        return {
            hasBeenValidated: false, // Esto es para no mostrar el error de TyC hasta que el usuario haya intentado enviar el formulario al menos una vez
        };
    },

    computed: {
        total() {
            return this.cesta.total + this.metodoEnvio.importe;
        },
        canContinue() {
            return this.formapago !== null;
        },
    },

    watch: {
        canContinue: {
            immediate: true,
            handler(value) { this.$emit('update:canContinue', value); },
        },
    },

    methods: {
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },

        async validate() {
            const { valid } = await this.$refs.form.validate();

            this.hasBeenValidated = true;

            return this.canContinue && valid && this.tyc;
        },
    },
};
</script>
