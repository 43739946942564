export default {
    // Seccion que incluye el estado de la farmacia.
    ESTADO: 0,

    // Seccion que incluye la imagen de la farmacia.
    IMAGEN: 1,

    // Seccion que incluye el carousel de la home
    CAROUSEL: 2,

    // Seccion que incluye los accesos directos
    ACCESOS: 3,

    // Seccion que incluye los Productos destacados
    DESTACADOS: 4,
};
