<template>
    <v-container v-if="categoria === null">
        <recurso-no-encontrado-card />
    </v-container>

    <template v-else>
        <v-sheet color="primary-surface">
            <v-container>
                <h1
                    class="mt-md-6 text-start font-weight-bold mt-1"
                    :class="$vuetify.display.smAndUp ? 'text-h4' : 'text-h5'">
                    {{ categoria.descripcion ?? $t('menuItems.todosLosProductos') }}
                </h1>
            </v-container>
        </v-sheet>

        <v-sheet color="surface" elevation="1">
            <v-container class="py-3">
                <v-row no-gutters class="align-center" dense>
                    <v-col cols="8">
                        <app-breadcrumbs :items="breadcrumbs" class="mx-n4" />
                    </v-col>
                    <v-col :class="$vuetify.display.mdAndUp ? '' : 'd-flex justify-end'">
                        <articulos-orden-selector v-model="orden" />
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

        <v-sheet v-if="hayCategorias" color="background">
            <v-container class="py-0">
                <categorias-chips />
            </v-container>
        </v-sheet>

        <v-container class="pt-0">
            <articulos-lista
                :orden="orden"
                :post-data="{ idcategoria: +idcategoria } " />
        </v-container>
    </template>
</template>

<script>
import { store } from '@/store';
import ArticulosLista from '@/components/ArticulosLista.vue';
import AppBreadcrumbs from '@/components/AppBreadcrumbs.vue';
import ArticuloOrden from '@/enums/ArticuloOrden';
import ArticulosOrdenSelector from '@/components/ArticulosOrdenSelector.vue';
import RecursoNoEncontradoCard from '@/components/RecursoNoEncontradoCard.vue';
import CategoriasChips from '@/components/CategoriasChips.vue';
export default {
    name: 'CategoriaView', // Usado por <keep-alive>

    components: {
        ArticulosLista,
        ArticulosOrdenSelector,
        AppBreadcrumbs,
        RecursoNoEncontradoCard,
        CategoriasChips,
    },
    props: {
        idcategoria: {
            type: [String, Number],
            required: true,
        },
    },
    data: () => ({
        /**
         * El orden que se debe mostrar los articulos.
         *
         * @type {number}
         */
        orden: ArticuloOrden.DEFAULT,
    }),
    computed: {
        /**
         * devuelve si hay categorias o no. Si no hay categorias, Simplemente no mostrara el Selector
         */
        hayCategorias() {
            if (store.categoriaRoot && store.categoriaRoot.hijos.length > 0) {
                return true;
            }
            return false;
        },
        farmacia() {
            return store.farmacia;
        },
        categoria() {
            return store.categoriaRoot.findById(+this.idcategoria);
        },
        breadcrumbs() {
            if (this.categoria === null) {
                return [];
            }

            const breadcrumbs = this.categoria.getBreadcrumbs();

            return breadcrumbs.map(categoria => ({
                title: categoria.descripcion,
                icon: categoria.padre === null ? 'mdi-home' : null,
                iconLabel: categoria.padre === null ? this.$t('productosView.todosLosProductos') : null,
                to: categoria.link,
                idcategoria: categoria.id,
            }));
        },
    },
    watch: {
        categoria: {
            handler() {
                store.setDocumentTitle(this.categoria?.descripcion);
            },
            immediate: true,
        },
    },
};
</script>
