import { store } from '../store';
import SeccionesHome from '../enums/SeccionesHome';

/**
 * Clase de ayuda para las secciones.
 */
export default class SeccionesHelper{

    /**
     * Genera un array de objetos de secciones con el id y el orden.
     * @param {number} numerosSecciones - El numero de secciones a generar.
     * @returns {Array} - El array de secciones.
     **/
    getSecciones(numerosSecciones){
        let secciones = [];
        for(let i = 0; i < String(numerosSecciones).length; i++){
            secciones.push({
                id: Number(String(numerosSecciones).at(i)),
                orden: i,
            });
        }
        return secciones;
    }

    /**
     * Procesa las secciones de la home, asignandoles un orden y las clases necesarias para su visualizacion.
     * @param {Function} callback
     * @param {Array} secciones - Secciones a procesar.
     * @param {boolean} deviceMdAndUp - tamanyo del dispositivo.
     * @returns {Array} - El array de secciones procesadas.
     */
    procesaSecciones(secciones, deviceMdAndUp){

        if(store.seccionesHome){
            store.seccionesHome.forEach(seccion => {
                secciones.find(s => s.id === seccion.id).orden = seccion.orden;
            });
        }

        secciones.forEach(seccion => {
            if(store.seccionesHome.find(s => s.id === seccion.id) && seccion.id !== ''){
                seccion = store.seccionesHome.find(s => s.id === seccion.id);
            }else{
                if (seccion.id !== SeccionesHome.ACCESOS)
                {
                    seccion.orden = -1;
                }
            }

        });

        secciones = secciones.filter(seccion => {
            if (seccion.orden < 0)
                return false;
            return true;
        });

        secciones.sort((a, b) => a.orden - b.orden);
        secciones.forEach((seccion, index) => {

            // Ajusta el margen de la imagen si la seccion anterior es el estado.
            if(seccion.id === SeccionesHome.ESTADO)
            {
                if(secciones[index+1] && secciones[index+1].id === SeccionesHome.IMAGEN && deviceMdAndUp){
                    secciones[index+1].class += ' mt-n6';
                }
            }

            if(index === 0 && !deviceMdAndUp){
                seccion.class += ' mt-n4';
            }
            seccion.orden = index;
        });

        return secciones;
    }
}
