import EventEmitter from './EventEmitter';

/**
 * Clase que detecta si el usuario está activo o inactivo.
 *
 * Adaptado de https://thisinterestsme.com/javascript-detect-user-activity/
 *
 * @event active Se emite cuando el usuario pasa de inactivo a activo.
 * @event idle Se emite cuando el usuario pasa de activo a inactivo.
 */
export default class ActivityWatcher extends EventEmitter {

    static _instance = null;

    /**
     * Obtiene la instancia de la clase.
     *
     * @type {ActivityWatcher}
     */
    static get instance() {
        return this._instance ??= new ActivityWatcher();
    }

    /**
     * El número de segundos que han pasado desde la última actividad del usuario.
     *
     * @type {number}
     */
    secondsSinceLastActivity = 0;

    /**
     * El número máximo de segundos que pueden pasar antes de que el usuario se considere inactivo.
     *
     * @type {number}
     */
    maxInactivity = 300; // 5 minutes

    /**
     * Un array de eventos DOM que se consideran actividad.
     *
     * @type {string[]}
     */
    activityEvents = [
        'mousedown',
        'mousemove',
        'keydown',
        'scroll',
        'touchstart',
    ];

    /**
     * Inicializa una nueva instancia de la clase.
     *
     * @private Singleton, no instanciable.
     */
    constructor(){
        super();

        this._onActivity = this._onActivity.bind(this);

        this.start();
    }

    _onActivity() {
        if (! this.isActive) {
            this.emit('active');
        }

        this.secondsSinceLastActivity = 0;
    }

    /**
     * Obtiene un valor que indica si el usuario está activo.
     *
     * @type {boolean}
     */
    get isActive() {
        return this.secondsSinceLastActivity < this.maxInactivity;
    }

    /**
     * Obtiene un valor que indica si el usuario está inactivo.
     *
     * @type {boolean}
     */
    get isIdle() {
        return !this.isActive;
    }

    /**
     * Inicia el watcher.
     */
    start() {
        if (this._interval) {
            return;
        }

        for (const event of this.activityEvents) {
            window.addEventListener(event, this._onActivity, {
                passive: true, // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
            });
        }

        this._interval = setInterval(() => {
            var wasActive = this.isActive;
            this.secondsSinceLastActivity++;

            if (wasActive && this.isIdle) {
                this.emit('idle');
            }
        }, 1000);
    }

    /**
     * Detiene el watcher.
     */
    stop() {
        if (! this._interval) {
            return;
        }

        for (const event of this.activityEvents) {
            window.removeEventListener(event, this._onActivity);
        }

        clearInterval(this._interval);
        this._interval = null;
    }

    /**
     * Ejecuta una función cada cierto tiempo mientras el usuario esté activo.
     *
     * @param {Function} callback
     * @param {number} seconds
     * @returns {number} El ID del interval.
     */
    periodicallyWhileActive(callback, seconds) {
        if (this.isActive) {
            callback();
        }

        return setInterval(() => {
            if (this.isActive) {
                callback();
            }
        }, seconds * 1000);
    }
}
