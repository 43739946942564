<template>
    <v-container>
        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="load" />
        <div v-else-if="loading" class="d-flex justify-center align-center h-100">
            <v-progress-circular :width="3" :size="80" indeterminate />
        </div>

        <v-row v-if="!esMobile">
            <v-card class="mx-auto mt-6" variant="elevated">
                <div class="bg-primary-surface pa-8 d-flex align-center">
                    <v-icon icon="mdi-cellphone-arrow-down" size="62" />
                    <h1 class="d-h4 d-inline ml-6">
                        {{ $t('installView.instalaApprox') }}
                    </h1>
                </div>
                <div class="d-flex">
                    <p class="pa-8 text-h6 font-weight-regular d-flex flex-column align-center justify-center">
                        {{ $t('installView.paraInstalarLaAplicacion') }}
                    </p>
                    <div class="pa-12 d-flex align-center justify-center">
                        <qrcode-vue :value="linkQr" :size="200" level="M" />
                    </div>
                </div>
            </v-card>
        </v-row>

        <v-row v-else>
            <v-card class="mx-auto mt-6" max-width="700" variant="elevated">
                <div class="bg-primary-surface pa-8 d-flex align-center">
                    <v-icon icon="mdi-cellphone-arrow-down" size="62" />
                    <h1 class="d-h4 d-inline ml-6">
                        {{ $t('installView.accedeALaAppDeTuFarmacia') }}
                    </h1>
                </div>
                <div class="pa-8">
                    <p class="text-h6 font-weight-regular d-flex flex-column align-center justify-center">
                        {{ $t('installView.yaPuedesAcceder') }}
                    </p>
                    <div class="mt-6 mx-auto">
                        <div style="font-size: 1.2rem;" class="d-flex align-center justify-stretch">
                            <span class="mr-6 font-weight-bold text-medium-emphasis">
                                {{ $t('installView.codigoDeFarmacia') }}
                            </span>
                            <v-text-field
                                :model-value="farmacia.subdominio"
                                variant="outlined"
                                style="font-family: monospace; background-color: rgba(var(--v-theme-on-surface), 0.04); width: auto;"
                                height="55"
                                :hide-details="true"
                                readonly />
                            <v-btn color="primary" class="ml-4" rounded="false" height="55" size="large" prepend-icon="mdi-content-copy" @click="copiarCodigo">
                                {{ $t('installView.copiar') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { store } from '@/store';
import { Device } from '@capacitor/device';
import axios from 'axios';
import QrcodeVue from 'qrcode.vue';
import OopsAlert from '../components/OopsAlert.vue';

export default {
    components: {
        QrcodeVue,
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si el dispositivo es móvil.
         *
         * @type {boolean}
         */
        esMobile: false,

        /**
         * Link para generar el QR que se verá en desktop.
         *
         * @type {string}
         */
        linkQr: window.location.href, // La misma url de la página actual sirve como link para el QR
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
    },
    created() {
        this.load();
    },
    methods: {
        async load() {
            this.error = false;
            this.loading = true;

            try {
                const info = await Device.getInfo();
                this.esMobile = info.operatingSystem === 'android' || info.operatingSystem === 'ios';

                if (!this.esMobile) return;

                const response = await axios.post('/subdominio', info);

                window.location.replace(response.data);
                //window.open(response.data, '_blank');
            } catch (error) {
                this.error = error;
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        copiarCodigo() {
            navigator.clipboard.writeText(this.farmacia.subdominio);
        },
    },

};
</script>
