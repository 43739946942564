<template>
    <v-container>
        <articulo-agregado-dialog :articulo="articuloAgregado" />

        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />

        <div v-else-if="loading" class="text-center d-block ma-16">
            <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" size="70" />
        </div>

        <recurso-no-encontrado-card
            v-else-if="!articulo"
            :titulo="$t('articuloView.productoNoEncontrado')"
            :descripcion="$t('articuloView.productoNoEncontradoDescripcion')" />

        <div v-else class="mx-n4 mx-md-0">
            <v-card class="pa-6 mt-n4 mt-md-2">
                <v-row>
                    <v-col cols="12" md="6" lg="7">
                        <v-sheet color="surface" rounded="lg" :border="true">
                            <v-chip
                                v-if="porcentaje > 0"
                                class="ma-4"
                                color="error"
                                variant="elevated"
                                elevation="0"
                                text-color="white"
                                prepend-icon="mdi-brightness-percent">
                                {{ porcentaje.toFixed(0) }}%
                            </v-chip>
                            <imagen-ampliable
                                v-if="articulo.imagen"
                                :src="articulo.imagen"
                                :zoom-scale="2"
                                style="cursor: zoom-in;">
                                <v-chip
                                    v-if="rotulo"
                                    class="ma-4"
                                    color="pink"
                                    variant="elevated"
                                    elevation="0"
                                    text-color="white"
                                    prepend-icon="mdi-label">
                                    {{ rotulo }}
                                </v-chip>
                            </imagen-ampliable>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="6" lg="5" class="d-flex flex-column">
                        <div>
                            <h1 class="d-flex align-center text-h5 font-weight-bold">
                                {{ articulo.descripcion }}
                            </h1>
                            <div
                                v-if="muestraPrecioFinal"
                                class="text-primary font-weight-bold mt-4">
                                <the-precio :precio="precio" :justify="'center'" :preciotachado="preciotachado" show-big @porcentaje="calculaPorcentaje" />
                            </div>
                        </div>
                        <div v-if="ecommerceHabilitado" class="mt-6 card-group">
                            <v-alert
                                v-if="stockcesta > 0"
                                type="info"
                                variant="flat"
                                class="mb-2"
                                :text="(Number(stockcesta) === 1)
                                    ? (stockcesta + ' ' + $t('articuloView.unidad'))
                                    : (stockcesta + ' ' + $t('articuloView.unidades'))" />

                            <v-alert
                                v-if="noDisponible"
                                type="error"
                                :text="$t('articuloView.articuloTemporalmenteNoDisponible')" />
                            <template v-else>
                                <div class="d-flex">
                                    <quantity-selector
                                        v-model.number="cantidad"
                                        :min="articulo.cantidadMin"
                                        :max="cantidadMax" />

                                    <v-btn
                                        class="ml-6 px-6 flex-grow-1"
                                        size="large"
                                        :loading="agregarACestaLoading"
                                        :disabled="noDisponible"
                                        prepend-icon="mdi-cart-arrow-down"
                                        @click="agregarACesta">
                                        {{ $t('articuloView.anadir') }}
                                    </v-btn>
                                </div>
                                <oops-alert v-if="agregarACestaError" :error="agregarACestaError" class="mt-4" />
                            </template>
                        </div>
                        <v-spacer />
                        <div v-if="promociones.length" class="mt-4">
                            <div class="text-medium-emphasis">
                                {{ promociones.length === 1
                                    ? $t('articuloView.promocionAplicableAEsteArticulo')
                                    : $t('articuloView.promocionesAplicablesAEsteArticulo')
                                }}
                            </div>
                            <div color="primary">
                                <v-alert
                                    v-for="( promocion, i ) in promociones "
                                    :key="i"
                                    type="success"
                                    variant="tonal"
                                    class="mt-2"
                                    :icon="promocion.icon"
                                    :text="promocion.descripcion"
                                    rounded="lg" />
                            </div>
                        </div>
                        <div v-if="articuslote && articuslote.length" class="red mx-6 rounded-lg">
                            <v-list color="black" class="yellow lighten-2 mt-2">
                                <v-list-subheader class="yellow rounded">
                                    {{ $t('articuloView.loteCompuestoPor') }}
                                </v-list-subheader>
                                <v-list-item
                                    v-for="( articulote, i ) in articuslote "
                                    :key="i"
                                    prepend-icon="mdi-cube"
                                    :title="articulote.descripcion"
                                    :to="`/productos/${articulote.idarticu}`" />
                            </v-list>
                        </div>
                        <div class="text-medium-emphasis text-right text-caption my-2">
                            <span>REF: {{ idarticu }}</span>
                        </div>
                    </v-col>
                </v-row>

                <!-- El font-face que se usa en la descripción se sobre-escribe en los estilos globales. -->
                <!-- eslint-disable-next-line vue/no-v-html TODO: Revisar en backend que este dato no pueda generar un ataque XSS -->
                <div v-if="articulo.descripcionext" class="mt-6" v-html="articulo.descripcionext" />
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import OopsAlert from '@/components/OopsAlert.vue';
import QuantitySelector from '@/components/QuantitySelector.vue';
import ArticuloAgregadoDialog from './ArticuloAgregadoDialog.vue';
import RecursoNoEncontradoCard from '@/components/RecursoNoEncontradoCard.vue';
import imagenDefault from '@/../images/producto-default.jpg';
import { getOfertaIcon } from '@/support/Normalization';
import StringHelper from '@/support/StringHelper';
import EurosHelper from '../../support/EurosHelper';
import { normalizeArticulo } from '../../support/Normalization';
import HeartbeatService from '../../support/HeartbeatService';
import ImagenAmpliable from '../../components/ImagenAmpliable.vue';
import ThePrecio from '@/components/ThePrecio.vue';

export default {
    components: {
        OopsAlert,
        QuantitySelector,
        ArticuloAgregadoDialog,
        RecursoNoEncontradoCard,
        ThePrecio,
        ImagenAmpliable,
    },
    props: {
        idarticu: {
            type: [String],
            required: true,
        },
    },
    data() {
        return {
            /**
                 * Indica si se está cargando información del servidor.
                 *
                 * @type {boolean}
                 */
            loading: false,

            /**
                 * Error al cargar la información del servidor.
                 *
                 * @type {Error|null}
                 */
            error: null,

            /**
                 * El articulo que se esta mostrando.
                 *
                 * @type {object|null}
                 */
            articulo: null,

            /**
                 * La cantidad de articulos que se van a agregar a la cesta.
                 *
                 * @type {number}
                 */
            cantidad: 1,

            /**
                 * Indica si se estan enviando los datos al servidor para agregar el articulo a la cesta.
                 *
                 * @type {boolean}
                 */
            agregarACestaLoading: false,

            /**
                 * Error al agregar el articulo a la cesta.
                 *
                 * @type {Error|null}
                 */
            agregarACestaError: null,

            /**
                 * El ultimo articulo que se ha agregado a la cesta.
                 *
                 * @type {{ idarticu: string, cantidad: number, descripcion: string, imagen: string, pvp: number }}
                 */
            articuloAgregado: null,

            /**
             * Indica el porcentaje del descuento con respecto al precio anterior.
             * @type {number}
             */
            porcentaje: 0,
        };
    },
    computed: {
        ecommerceHabilitado() {
            return store.ecommerceHabilitado;
        },

        farmacia() {
            return store.farmacia;
        },

        imagenDefault() {
            return imagenDefault;
        },

        rotulo() {
            return this.articulo.rotuloarticulos;
        },

        promociones() {
            return this.articulo.ofertas?.map((oferta) => ({
                ...oferta,
                descripcion: StringHelper.titleCase(oferta.descripcion),
                icon: getOfertaIcon(oferta.tipooferta),
            })) || [];
        },

        articuslote() {
            return this.articulo.articuslote;
        },

        stock() {
            return this.articulo.stockactual;
        },

        precio() {
            if (this.articulo.importeOferta /*&& this.articulo.importeOferta < this.articulo.pvp*/)
            {
                return EurosHelper.format(this.articulo.importeOferta);
            }
            return EurosHelper.format(this.articulo.pvp);
        },

        canGoBack() {
            return window.history.state.back !== null;
        },
        mostrarPrecio() {
            return store.mostrarPrecios;
        },
        noDisponible() {
            let res = this.articulo.noDisponible;
            if (this.articulo.dispensasinstock && Number(this.articulo.dispensasinstock) === 1)
            {
                return res;
            }
            if (Number(this.stockcesta) >= Number(this.articulo.stockactual)){
                res = true;
            }
            return res;
        },
        stockcesta(){
            let stockcesta = 0;
            if (!store.cesta.lineas) {
                return 0;
            }
            let articuCesta = store.cesta.lineas.find((linea) => linea.idarticu === this.articulo.idarticu) ?? false;
            if (articuCesta) {
                stockcesta = articuCesta.cantidad;
            } else {
                stockcesta = 0;
            }
            return stockcesta;
        },
        cantidadMax(){
            let cMax = this.articulo.cantidadMax;
            if (this.articulo.dispensasinstock && Number(this.articulo.dispensasinstock) === 1)
            {
                return 999;
            }
            if(store.isLogged){
                cMax = this.articulo.stockactual - this.stockcesta;
            }

            return cMax;
        },
        preciotachado() {
            if (this.articulo.importeOferta && this.articulo.importeOferta < this.articulo.pvp)
            {
                return EurosHelper.format(this.articulo.pvp);
            }
            else
                return null;
        },
        muestraPrecioFinal(){
            if(!this.noDisponible && this.mostrarPrecio)
                return true;
            else
                return false;
        },
    },
    watch: {
        idarticu() {
            this.cargarDatos();
        },
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.articulo = null;
            this.error = null;
            store.setDocumentTitle(null);
            try {
                const endpoint = '/articulos';
                const { data } = await axios.post(endpoint, {
                    idarticu: this.idarticu,
                    idfarmacia: store.farmacia.numserie, // TODO: Remover este parámetro cuando se corrija el backend ya que es redundante con X-Approx-Subdomain.
                });

                if (data.data.length === 0) {
                    this.loading = false;
                    return;
                }
                this.articulo = normalizeArticulo(data.data[0]);
                store.setDocumentTitle(this.articulo.descripcion);

                if(store.isLogged){
                    this.articulo.cantidadMax = this.articulo.stockactual - this.stockcesta;
                }

            } catch (error) {
                console.error(error);
                this.error = error;
            }
            this.loading = false;
        },
        async agregarACesta() {
            if (!store.isLogged) {
                this.$router.push({
                    name: 'Login',
                    query: { redirect: this.$route.fullPath },
                });
                return;
            }

            this.agregarACestaLoading = true;
            this.agregarACestaError = null;
            this.articuloAgregado = null;

            try {
                await axios.post('/cesta', {
                    idarticu: this.idarticu,
                    cantidad: this.cantidad,
                });

                this.articuloAgregado = {
                    ...this.articulo,
                    cantidad: this.cantidad,
                };


            } catch (error) {
                console.error(error);
                this.agregarACestaError = error;
            } finally {
                this.agregarACestaLoading = false;
                //Se reinicia el contador de unidades
                this.cantidad = 1;

                if (this.agregarACestaError === null) {
                    this.cargarDatos();
                    await HeartbeatService.instance.heartbeat();
                }
            }

        },
        calculaPorcentaje(porcentaje) {
            this.porcentaje = porcentaje;
        },
    },
};
</script>

<style scoped>
.zoom{
    margin:auto;
    display: flex;
    max-height: 70vh;
    max-width: 30vw;
}
.zoom div {
    display:  none !important;
}
</style>
