<template>
    <v-container>
        <v-row justify="center" class="mb-16">
            <v-col cols="12" md="8" lg="6">
                <registro-completado-card v-if="registroCompletado" />

                <template v-else>
                    <v-row>
                        <v-col class="text-center mt-4 mb-6">
                            <h1 class="text-primary text-h4 font-weight-bold mb-2">
                                {{ $t('registroView.crearUnaCuenta') }}
                            </h1>
                            <p class="text-medium-emphasis">
                                {{ subtitulo }}
                            </p>
                        </v-col>
                    </v-row>

                    <v-sheet color="surface" elevation="1" rounded>
                        <oops-alert
                            v-if="error"
                            :error="error"
                            variant="flat"
                            class="rounded-b-0"
                            :titulo="$t('registroView.errorEnElRegistro')" />

                        <v-alert v-if="mostrarRecaptcha && !consentimientoCookies" type="error" icon="mdi-cookie-alert" class="rounded-b-0">
                            {{ $t('registroView.atencionDebesActivar') }}
                            <template #append>
                                <v-btn variant="tonal" color="on-error" @click="aceptarTodas()">
                                    {{ $t('registroView.aceptarTodas') }}
                                </v-btn>
                            </template>
                        </v-alert>

                        <v-form ref="registerForm" v-model="valid" :disabled="mostrarRecaptcha && !consentimientoCookies" validate-on="input" class="pa-6" @submit.prevent="register">
                            <v-row dense>
                                <v-col cols="12" sm="6" lg="6">
                                    <v-text-field
                                        v-model="firstName"
                                        autocomplete="given-name"
                                        :rules="[rules.required]"
                                        :label="$t('registroView.nombre')"
                                        maxlength="20"
                                        autocapitalize="on"
                                        required />
                                </v-col>
                                <v-col cols="12" sm="6" lg="6">
                                    <v-text-field
                                        v-model="lastName"
                                        autocomplete="family-name"
                                        :rules="[rules.required]"
                                        :label="$t('registroView.apellidos')"
                                        maxlength="60"
                                        autocapitalize="on"
                                        required />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="email"
                                        autocomplete="email"
                                        :rules="emailRules"
                                        :label="$t('registroView.email')"
                                        required
                                        autocapitalize="off" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="telefono"
                                        autocomplete="telephone"
                                        :rules="telephoneRules"
                                        :label="$t('registroView.telefono')"
                                        autocapitalize="off" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="password"
                                        autocomplete="new-password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPassword ? 'text' : 'password'"
                                        autocapitalize="off"
                                        :label="$t('registroView.contrasena')"
                                        :hint="$t('registroView.alMenos8caracteres')"
                                        counter
                                        @click:append="showPassword = !showPassword" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="passwordConfirmation"
                                        autocomplete="new-password"
                                        block
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, passwordMatch]"
                                        :type="showPassword ? 'text' : 'password'"
                                        autocapitalize="off"
                                        :label="$t('registroView.confirmarContrasena')"
                                        counter
                                        @click:append="showPassword = !showPassword" />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col v-if="mostrarRecaptcha && consentimientoCookies" cols="12">
                                    <vue-recaptcha
                                        id="respregistro"
                                        sitekey="6Le-IPkZAAAAABTpYJnhCu-Z2gE2jVESYwnhESz7"
                                        :load-recaptcha-script="consentimientoCookies"
                                        class="g-recaptcha"
                                        style="height: 78px"
                                        :language="$i18n.locale" />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" class="text-body-2 font-weight-light">
                                    <v-checkbox
                                        v-model="checkboxPrivacidad"
                                        :rules="[rules.required]"
                                        required
                                        hide-details="auto">
                                        <template #label>
                                            <div>
                                                <i18n-t scope="global" keypath="linksLegal.heLeidoYaceptoLosTycduYla.text">
                                                    <template #url>
                                                        <a href="#/terminos-condiciones-uso" @click.stop>{{ $t('compraEnvioFacturacion.heLeidoYaceptoLosTycdu.link') }}</a>
                                                    </template>
                                                </i18n-t>
                                                <i18n-t scope="global" keypath="linksLegal.politicaDePrivacidad.text">
                                                    <template #url>
                                                        <a href="#/politica-privacidad" @click.stop>{{ $t('compraEnvioFacturacion.laPoliticaDePrivacidad.link') }}</a>
                                                    </template>
                                                </i18n-t>
                                            </div>
                                        </template>
                                    </v-checkbox>

                                    <v-checkbox
                                        v-model="notificacionescomerciales"
                                        :label="$t('registroView.aceptoElEnvioDe')"
                                        hide-details="auto" />
                                    <v-checkbox
                                        v-model="facturaporemail"
                                        :label="$t('registroView.quieroRecibirLaFactura')"
                                        hide-details="auto" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        block
                                        type="submit"
                                        size="x-large"
                                        :disabled="!canSubmit"
                                        color="primary"
                                        variant="elevated"
                                        :loading="loading">
                                        {{ $t('registroView.registrarse') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-divider />
                        <v-sheet color="secondary-surface" class="pa-6">
                            <v-row>
                                <v-col align-self="center">
                                    <v-icon icon="mdi-chevron-double-right" />
                                    {{ $t('registroView.yaTienesUnaCuenta') }}
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn to="/login" variant="outlined" color="on-secondary-surface" append-icon="mdi-chevron-right">
                                        {{ $t('registroView.iniciarSesion') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-sheet>

                    <v-row class="text-medium-emphasis text-justify text-body-2 font-weight-light mt-4 mx-0">
                        <v-col>
                            <p class="pb-0">
                                {{ textoLegal }}
                                <router-link to="/politica-privacidad">
                                    {{ $t('registroView.politicaDePrivacidad') }}
                                </router-link>
                            </p>
                        </v-col>
                    </v-row>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
import RegistroCompletadoCard from './RegistroCompletadoCard.vue';
import OopsAlert from '@/components/OopsAlert.vue';
import { store } from '@/store';
import { VueRecaptcha } from 'vue-recaptcha';
import { Capacitor } from '@capacitor/core';

export default {
    components: {
        RegistroCompletadoCard,
        VueRecaptcha,
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si el formulario es válido.
         *
         * @type {boolean}
         */
        valid: false,

        /**
         * Indica si se debe mostrar el mensaje de registro completado.
         *
         * @type {boolean}
         */
        registroCompletado: false,

        /**
         * Nombre del usuario.
         *
         * @type {string}
         */
        firstName: '',

        /**
         * Apellido del usuario.
         *
         * @type {string}
         */
        lastName: '',

        /**
         * Email del usuario.
         *
         * @type {string}
         */
        email: '',

        /**
         * Contraseña del usuario.
         *
         * @type {string}
         */
        password: '',

        /**
         * Confirmación de la contraseña del usuario.
         *
         * @type {string}
         */
        passwordConfirmation: '',

        /**
         * Indica si el usuario quiere recibir la factura por email.
         *
         * @type {boolean}
         */
        facturaporemail: true,

        /**
         * Indica si el usuario quiere recibir notificaciones comerciales.
         *
         * @type {boolean}
         */
        notificacionescomerciales: true,

        /**
         * Indica si se debe mostrar el password del usuario.
         *
         * @type {boolean}
         */
        showPassword: false,

        /**
         * Teléfono del usuario.
         *
         * @type {string}
         */
        telefono: '',

        /**
         * Indica si el usuario acepta la política de privacidad.
         *
         * @type {boolean}
         */
        checkboxPrivacidad: false,

        /**
         * Indica si el usuario ha dado su consentimiento para el uso de cookies.
         *
         * @type {boolean}
         */
        consentimientoCookies: false, // TODO: Esto mejor sería centralizarlo en el store global
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        canSubmit() {
            return this.valid !== false && this.checkboxPrivacidad;
        },
        subtitulo() {
            return store.ecommerceHabilitado
                ? this.$t('registroView.registrateEnParaEmpezarAComprar', [this.farmacia.nombre])
                : this.$t('registroView.registrateEnParaAccederATodosLosBeneficios', [this.farmacia.nombre]);
        },
        passwordMatch() {
            return () => this.password === this.passwordConfirmation || this.$t('registroView.laContrasenaDebeCoincidir');
        },
        textoLegal() {
            return this.$t('registroView.responsableFinesDelTratamiento', [this.farmacia.rgpddenominacionsocial || this.farmacia.nombre]);
        },
        rules() {
            return {
                required: (value) => !!value || this.$t('registroView.requerido'),
                min: (v) => (v && v.length >= 8) || this.$t('registroView.minimo8caracteres'),
            };
        },
        emailRules() {
            return [
                (v) => !!v || this.$t('registroView.requerido'),
                (v) => /.+@.+\..+/.test(v) || this.$t('registroView.introduceUnEmailValido'),
            ];
        },
        mostrarRecaptcha() {
            return !Capacitor.isNativePlatform();
        },
        telephoneRules() {
            return [
                (v) => !v || /^[0-9]+$/.test(v) || this.$t('registroView.introduceUnTelefonoValido'),
            ];
        },
    },
    created() {
        let analiticasPublicitarias;
        let activarAnaliticas;
        let activarPublicitarias;
        if (this.$cookies.isKey('cookiescfg')) {
            analiticasPublicitarias = window.$cookies.get('cookiescfg').split(',');
            activarAnaliticas = analiticasPublicitarias[0] === '1';
            activarPublicitarias = analiticasPublicitarias[1] === '1';
        }
        if ((activarAnaliticas && activarPublicitarias) || !this.mostrarRecaptcha) {
            this.consentimientoCookies = true;
        }
    },
    methods: {
        validate() {
            return this.$refs.registerForm.validate()
                && (!this.mostrarRecaptcha || window.grecaptcha.getResponse());
        },
        async register() {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            this.error = null;
            this.registroCompletado = false;

            try {
                await axios.post('/register', {
                    idfarmacia: this.farmacia.numserie, // TODO: Remover cuando el endpoint use `X-Approx-Subdomain`
                    name: this.firstName,
                    apellidos: this.lastName,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    facturaporemail: this.facturaporemail,
                    notificacionescomerciales: this.notificacionescomerciales,
                    telefono: this.telefono,
                    recaptcha: window.grecaptcha?.getResponse(),
                });

                this.registroCompletado = true;
                window.scrollTo(0, 0); // Scrollear al top de la página para que se vea el mensaje de registro completado

            } catch (error) {
                console.error(error);
                this.error = error;
                window.scrollTo(0, 0); // Scrollear al top de la página para que se vea el error
            } finally {
                this.loading = false;
            }
        },

        aceptarTodas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '1,1');
            this.consentimientoCookies = true;
        },
    },
};
</script>
