export default {
    /**
     * El modo ecommerce está desactivado. Sin catálogo de productos ni compra online.
     */
    DESHABILITADO: 0,

    /**
     * El modo ecommerce está habilitado para compra. Los precios son siempre visibles.
     */
    COMPRA_PRECIOS_VISIBLES: 1,

    /**
     * El modo ecommerce solo muestra catálogo de productos, no se puede comprar. Los precios son siempre visibles.
     */
    CATALOGO_PRECIOS_VISIBLES: 2,

    /**
     * El modo ecommerce solo muestra catálogo de productos, no se puede comprar. Los precios son siempre ocultos.
     */
    CATALOGO_PRECIOS_OCULTOS: 3,

};
