/**
 * Directiva para detectar cuando se hace click fuera de un elemento.
 *
 * Sintaxis:
 * <div v-click-outside="myFunction"></div>
 *
 * { myFunction } es la función que se ejecutará cuando se haga click fuera del elemento.
 *
 */
export const clickOutside = {
    mounted(el, binding) {
        el._clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el._clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el._clickOutsideEvent);
    },
};
