<template>
    <v-card class="mx-auto" max-width="600" variant="elevated">
        <div class="bg-success text-on-success pa-8 d-flex align-center">
            <v-icon icon="mdi-checkbox-marked-circle-outline" size="62" />
            <h1 class="d-h4 d-inline ml-6">
                {{ $t('registroView.soloUnPasoMas') }}
            </h1>
        </div>
        <v-card-text class="pa-16 py-16 text-center text-body-1">
            <p>{{ $t('registroView.paraCompletarElRegistro') }}</p>

            <!-- https://lordicon.com/icons/wired/flat/177-envelope-mail-send -->
            <v-img src="../../../images/mail-send.gif" alt="" class="mb-n10 w-50 mx-auto" />
        </v-card-text>
    </v-card>
</template>
