<template>
    <div>
        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="load" />

        <div v-else-if="loading" class="d-flex align-center justify-center py-8 px-8">
            <v-progress-circular size="large" indeterminate color="primary" />
        </div>

        <!--
            Si hay una sola opción de envio, no la mostramos, directamente la seleccionamos
            y el componente solo mostrará el método de envío
        -->
        <template v-else-if="opcionesEnvio.length === 1">
            <v-card class="pa-6">
                <div class="d-flex">
                    <div class="font-weight-bold text-h6 mb-5">
                        {{ opcionesEnvio[0].descripcion }}
                    </div>
                    <v-chip v-if="opcionesEnvio[0].esEnvioGratis" color="success" class="ml-4" rounded="lg">
                        {{ $t('compraEnvioFacturacion.gratis') }}
                    </v-chip>
                </div>
                <slot name="default" :opcion="opcion" />
            </v-card>
        </template>

        <!-- Si hay más de una opción de envío, mostramos el componente de selección de opción de envío -->
        <div v-else class="mb-6">
            <div class="d-flex align-center mb-5 text-h6">
                <v-icon icon="mdi-truck-fast" class="mr-4" />
                {{ $t('compraEnvioFacturacion.seleccionaUnMetodoDeEnvio') }}
            </div>

            <v-item-group v-model="opcionEnvioValue" mandatory class="mb-6">
                <v-row class="d-flex align-stretch" dense>
                    <v-col v-for="opcion in opcionesEnvio" :key="opcion.idopcion" cols="12">
                        <v-item v-slot="{ isSelected, toggle }" :value="opcion">
                            <v-card
                                elevation="1"
                                variant="elevated"
                                class="align-center h-100 bg-surface"
                                style="min-width: 300px"
                                :ripple="false"
                                @click="toggle">
                                <v-radio :model-value="isSelected" color="primary" class="mx-4 mx-sm-8 py-5">
                                    <template #label>
                                        <div class="font-weight-bold ml-3">
                                            {{ opcion.descripcion }}
                                        </div>
                                        <v-chip v-if="opcion.esEnvioGratis" color="success" class="ml-4" rounded="lg">
                                            {{ $t('compraEnvioFacturacion.gratis') }}
                                        </v-chip>
                                    </template>
                                </v-radio>
                                <div class="d-block">
                                    <slot v-if="isSelected" name="default" :opcion="opcion" />
                                </div>
                            </v-card>
                        </v-item>
                    </v-col>
                </v-row>
            </v-item-group>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import OopsAlert from '../../components/OopsAlert.vue';
import StringHelper from '../../support/StringHelper';

export default {
    components: {
        OopsAlert,
    },
    props: {
        /**
         * La opción de envío seleccionada.
         *
         * @type {{idopcion: number, descripcion: string, datosenvio: boolean}?}
         */
        opcionEnvio: {
            type: Object,
            default: null,
        },
    },
    emits: [
        'update:opcionEnvio',
    ],
    data() {
        return {
            /**
             * Indica si se está cargando información del servidor.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Error al cargar información del servidor.
             *
             * @type {Error?}
             */
            error: null,

            /**
             * Las opciones de envío disponibles.
             *
             * @type {{idopcion: number, descripcion: string, datosenvio: boolean}[]}
             */
            opcionesEnvio: [],
        };
    },
    computed: {
        opcionEnvioValue: {
            get() { return this.opcionEnvio; },
            set(value) { this.$emit('update:opcionEnvio', value); },
        },
    },
    async created() {
        this.load();
    },
    methods: {
        async load() {
            this.opcionesEnvio = [];
            this.opcionEnvioValue = null;
            this.loading = true;
            this.error = null;
            try {
                const response = await axios.get('/opcionesenvio');

                this.opcionesEnvio = response.data.map((opcion) => ({
                    ...opcion,
                    esEnvioGratis: !opcion.datosenvio,
                    esRetiroEnTienda: !opcion.datosenvio,
                    descripcion: StringHelper.sentenceCase(opcion.descripcion),
                }));

                if (this.opcionesEnvio.length === 1) {
                    this.opcionEnvioValue = this.opcionesEnvio[0];
                }
            } catch (error) {
                this.error = error;
            }
            this.loading = false;
        },
    },
};
</script>
