<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <v-row>
                    <v-col v-if="puntosMostrar" cols="12" sm="6">
                        <v-card class="pb-6">
                            <v-card-title class="justify-center">
                                {{ $t('puntosView.misPuntosAcumulados') }}
                            </v-card-title>
                            <v-card-text class="pt-8 text-right font-weight-regular" style="font-size: 4rem;">
                                {{ puntosMostrar }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="eurosMostrar" cols="12" sm="6">
                        <v-card class="pb-6">
                            <v-card-title class="justify-center">
                                {{ $t('puntosView.misEurosAcumulados') }}
                            </v-card-title>
                            <v-card-text class="pt-8 text-right font-weight-regular" style="font-size: 4rem;">
                                {{ precioFormateado(eurosMostrar) }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="12">
                <v-card>
                    <v-card-title>
                        {{ $t('puntosView.detalleDePuntosEuros') }}
                    </v-card-title>

                    <div v-if="!lineaspuntos">
                        <v-card-text class="py-16 valor">
                            <v-progress-circular indeterminate color="primary" size="large" />
                        </v-card-text>
                    </div>

                    <v-card-text v-else-if="lineaspuntos.length === 0" class="py-16 valor">
                        <v-icon size="60" icon="mdi-counter" class="text-medium-emphasis " />
                        <div class="text-high-emphasis text-h6 mt-6">
                            {{ $t('puntosView.noTienesPuntos') }}
                        </div>
                        <div class="text-medium-emphasis text-body-1 mt-1">
                            {{ $t('puntosView.noTienesPuntosSubtitulo') }}
                        </div>
                    </v-card-text>

                    <!-- Vista de lista: esta implementación se descartó por mmoran ya que el requerimiento es que el usuario no lea los detalles de puntos.
                        Se reemplaza por la vista de tabla. -->
                    <!--<v-list lines="one">
                        <template v-for="(item, i) in lineaspuntos" :key="item.idhistorico">
                            <v-list-item :title="item.concepto" class="py-4">
                                <template #prepend>
                                    <v-avatar :color="item.color" size="40">
                                        <v-icon :icon="item.icon" />
                                    </v-avatar>
                                </template>
                                <template #append>
                                    <div>
                                        <div class="text-right text-no-wrap font-weight-bold" :class="item.clase">
                                            {{ item.cantidad }}
                                        </div>
                                        <div class="text-right text-caption">
                                            {{ item.tipo }}
                                        </div>
                                    </div>
                                </template>
                                <v-list-item-subtitle>
                                    <span>{{ item.fecha }}</span>
                                </v-list-item-subtitle>
                                <v-tooltip activator="parent" location="right" :text="item.fechaTitle" />
                            </v-list-item>
                            <v-divider v-if="i < lineaspuntos.length - 1" />
                        </template>
                    </v-list>-->

                    <!-- Vista de tabla -->
                    <v-table v-else>
                        <thead>
                            <tr>
                                <th class="text-caption">
                                    {{ $t('puntosView.fecha') }}
                                </th>
                                <th class="text-caption">
                                    {{ $t('puntosView.concepto') }}
                                </th>
                                <th class="text-caption px-0">
                                    {{ $t('puntosView.tipo') }}
                                </th>
                                <th class="text-caption text-right">
                                    {{ $t('puntosView.cantidad') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lineaspuntos" :key="item.idhistorico">
                                <td class="text-caption">
                                    {{ item.fecha }}
                                </td>
                                <td class="text-caption">
                                    {{ item.concepto }}
                                </td>
                                <td class="text-caption px-0">
                                    {{ item.tipo }}
                                </td>
                                <td :class="item.clase" class="text-no-wrap text-caption text-right">
                                    {{ item.cantidad }}
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import EurosHelper from '../support/EurosHelper';
import StringHelper from '../support/StringHelper';

export default {
    data: () => ({
        puntos: 0,
        puntosMostrar: 0,
        euros: 0,
        eurosMostrar: 0,
        interval: false,
        interval2: false,
        lineaspuntos: null, // Array
        isIos: false,
    }),
    watch: {
        puntos() {
            if (!this.isIos) {
                clearInterval(this.interval);
                if (this.puntos === this.puntosMostrar) {
                    return;
                }
                this.interval = window.setInterval(() => {
                    if (this.puntosMostrar < this.puntos) {
                        let change = (this.puntos - this.puntosMostrar) / 10;
                        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
                        this.puntosMostrar = this.puntosMostrar + change;
                    } else {
                        this.puntosMostrar = this.puntos;
                    }
                }, 20);
            } else {
                this.puntosMostrar = this.puntos;
            }
        },
        euros() {
            if (!this.isIos) {
                clearInterval(this.interval2);
                if (this.euros === this.eurosMostrar) {
                    return;
                }
                this.interval2 = window.setInterval(() => {
                    if (this.eurosMostrar < this.euros) {
                        let change = (this.euros - this.eurosMostrar) / 10;
                        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
                        this.eurosMostrar = this.eurosMostrar + change;
                    } else {
                        this.eurosMostrar = this.euros;
                    }
                }, 20);
            } else {
                this.eurosMostrar = this.euros;
            }
        },
    },
    created() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        this.isIos = /iphone|ipad|ipod/.test(userAgent);

        this.cargarPuntos();
        this.cargarHistorialPuntos();
    },
    methods: {
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },
        cantidadFormateada(cantidad) {
            return parseFloat(cantidad).toFixed(2);
        },

        async cargarPuntos() {
            const { data } = await axios.get('/puntos');

            this.puntos = data.puntos ? parseFloat(data.puntos) : 0;
            this.euros = data.euros ? parseFloat(data.euros) : 0;
        },

        async cargarHistorialPuntos() {
            const { data } = await axios.get('/histopuntos');

            this.lineaspuntos = data
                .filter((linea) => linea.tipolinea !== 'T' && linea.tipolinea !== 'A')
                .map(linea => ({
                    ...linea,
                    concepto: StringHelper.sentenceCase(linea.descripcion),
                    //fecha: moment(linea.fechahora).calendar({ sameElse: 'LL' }), // Vista de lista
                    fecha: moment(linea.fechahora).calendar({ sameElse: 'L LT' }),
                    fechaTitle: moment(linea.fechahora).format('L LT'),
                    puntosdebe: parseFloat(linea.puntosdebe),
                    puntoshaber: parseFloat(linea.puntoshaber),
                    eurosdebe: parseFloat(linea.eurosdebe),
                    euroshaber: parseFloat(linea.euroshaber),
                }))
                .map((linea) => {
                    if (linea.puntosdebe > 0) {
                        return {
                            fecha: linea.fecha,
                            fechaTitle: linea.fechaTitle,
                            concepto: linea.concepto,
                            cantidad: '+ ' + this.cantidadFormateada(linea.puntosdebe),
                            tipo: this.$t('puntosView.puntos'),
                            clase: 'text-success',
                            icon: 'mdi-plus',
                            color: 'success',
                        };
                    } else if (linea.puntoshaber > 0) {
                        return {
                            fecha: linea.fecha,
                            fechaTitle: linea.fechaTitle,
                            concepto: linea.concepto,
                            cantidad: '- ' + this.cantidadFormateada(linea.puntoshaber),
                            tipo: this.$t('puntosView.puntos'),
                            clase: 'text-error',
                            icon: 'mdi-minus',
                            color: 'error',
                        };
                    } else if (linea.eurosdebe > 0) {
                        return {
                            fecha: linea.fecha,
                            fechaTitle: linea.fechaTitle,
                            concepto: linea.concepto,
                            cantidad: '+ ' + this.cantidadFormateada(linea.eurosdebe),
                            tipo: this.$t('puntosView.euros'),
                            clase: 'text-success',
                            icon: 'mdi-plus',
                            color: 'success',
                        };
                    } else if (linea.euroshaber > 0) {
                        return {
                            fecha: linea.fecha,
                            fechaTitle: linea.fechaTitle,
                            concepto: linea.concepto,
                            cantidad: '- ' + this.cantidadFormateada(linea.euroshaber),
                            tipo: this.$t('puntosView.euros'),
                            clase: 'text-error',
                            icon: 'mdi-minus',
                            color: 'error',
                        };
                    } else {
                        return {
                            fecha: linea.fecha,
                            fechaTitle: linea.fechaTitle,
                            concepto: linea.concepto,
                            tipo: null,
                            cantidad: null,
                            clase: '',
                            icon: 'mdi-star',
                            color: 'grey',
                        };
                    }
                });
        },
    },
};
</script>
<style scoped>
.valor {
    font-size: 62px !important;
    text-align: center;
}
</style>
