<template>
    <v-banner
        v-if="visible"
        elevation="5"
        position="fixed"
        stacked
        class="banner mx-auto"
        :max-width="$vuetify.display.lgAndUp ? '800px' : '100%'"
        :class="$vuetify.display.lgAndUp ? 'rounded-lg banner-detached text-body-1 pa-6' : 'rounded-none mb-0 d-flex flex-column'">
        <p class="max-width">
            {{ $t('cookiesBanner.utilizamosCookies') }}
        </p>
        <template #prepend>
            <v-icon
                icon="mdi-cookie-lock"
                class="mb-4"
                :size="$vuetify.display.lgAndUp ? '64px' : '42px'"
                color="primary" />
        </template>
        <template #actions>
            <v-row dense class="text-end mb-2 mb-sm-0 justify-end">
                <v-col cols="12" sm="auto">
                    <v-btn variant="text" color="primary" class="px-4" @click="configurarCookies">
                        {{ $t('cookiesBanner.configurarCookies') }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="auto">
                    <v-btn variant="flat" color="primary" class="px-4" @click="RechazarCookies">
                        {{ $t('cookiesBanner.rechazarCookies') }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="auto">
                    <v-btn variant="flat" color="primary" class="px-4" @click="aceptarTodas">
                        {{ $t('cookiesBanner.aceptarTodas') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-banner>
</template>
<script>
export default {
    data() {
        return {
            /**
             * Indica si el banner de cookies es visible.
             *
             * @type {boolean}
             */
            visible: true,
        };
    },
    created() {
        this.visible = (!this.$cookies.get('cookiescfg') && this.$route.name !== 'Install');
    },
    methods: {
        aceptarTodas() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '1,1');
            this.visible = false;
        },
        configurarCookies() {
            this.$cookies.config('365d');
            this.$cookies.set('cookiescfg', '0,0');
            this.visible = false;
            this.$router.push({ name: 'PoliticaCookies', params: { configuracion: 'true' } });
        },
        RechazarCookies() {
            this.$cookies.keys().forEach((cookie) => {
                if (cookie !== 'XSRF-TOKEN' && (!cookie.endsWith('_session')))
                {
                    this.$cookies.remove(cookie);
                }
            });

            this.visible = false;
        },
    },
};
</script>

<style scoped>
.banner {
    bottom: var(--v-layout-bottom);
    left: var(--v-layout-left);
    right: var(--v-layout-right);
    z-index: 9;
}

.banner-detached {
    left: 256px; /* Este valor es el ancho del drawer */
    bottom: calc(var(--v-layout-bottom) + 1rem);
}
</style>
