<template>
    <v-container>
        <h1 class="d-flex align-center py-5 px-4 text-h5 text-primary font-weight-bold">
            {{ $t('compraCesta.cesta') }}
        </h1>

        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />

        <div v-else-if="cesta === null && loading" class="text-center">
            <v-card class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
                <v-progress-circular indeterminate color="primary" size="large" />
            </v-card>
        </div>

        <div v-else-if="cesta.lineas.length === 0">
            <v-card class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
                <v-icon size="60" icon="mdi-heart-circle-outline" class="text-medium-emphasis " />
                <div class="text-high-emphasis text-h6 mt-6">
                    {{ $t('compraCesta.sinProductosEnTuCesta') }}
                </div>
                <div class="text-medium-emphasis text-body-1 mt-1">
                    {{ $t('compraCesta.sinProductosEnTuCestaSubtitulo') }}
                </div>
                <v-btn to="/productos" class="mt-6 px-6" size="large" append-icon="mdi-chevron-right">
                    {{ $t('compraCesta.explorarProductos') }}
                </v-btn>
            </v-card>
        </div>

        <template v-else>
            <!-- Cesta: Vista desktop. Se muestra en formato de tabla ya que es más cómodo para pantallas grandes. -->

            <v-card v-if="$vuetify.display.mdAndUp" class="pb-4 mb-6" elevation="1" :loading="loading">
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                {{ $t('compraCesta.descripcion') }}
                            </th>
                            <th class="text-center">
                                {{ $t('compraCesta.cantidad') }}
                            </th>
                            <th class="text-right">
                                {{ $t('compraCesta.importe') }}
                            </th>
                            <th style="width: 2rem">
                                <!-- Botón de eliminar item -->
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in cesta.lineas" :key="i">
                            <td>
                                <div class="d-flex align-center ma-4">
                                    <router-link :to="item.to" class="my-4 mr-4 text-decoration-none">
                                        <v-avatar
                                            :image="item.imagen"
                                            width="50"
                                            height="50"
                                            :alt="item.descripcion"
                                            class="d-inline-block rounded-lg" />
                                    </router-link>
                                    <div>
                                        <router-link :to="item.to" class="text-decoration-none text-on-surface">
                                            {{ item.descripcion }}
                                        </router-link>
                                        <v-row v-if="item.promociones?.length > 0" dense class="mt-1">
                                            <v-col v-for="promocion in item.promociones" :key="promocion.id" cols="auto" class="text-body-2">
                                                <v-chip color="success" density="comfortable" :prepend-icon="promocion.icon" :text="promocion.descripcion" />
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-center justify-center" style="min-width: 200px;">
                                    <quantity-selector
                                        v-model="item.cantidad"
                                        :min="item.cantidadMin"
                                        :max="item.cantidadMax"
                                        :show-unit="false"
                                        @update:model-value="enviarCesta" />
                                </div>
                            </td>
                            <td class="text-right">
                                <span v-if="item.importeOriginal" class="text-decoration-line-through mr-2 text-disabled ">
                                    {{ precioFormateado(item.importeOriginal) }}
                                </span>
                                <span class="text-body-1 font-weight-bold">
                                    {{ precioFormateado(item.importeFinal) }}
                                </span>
                            </td>
                            <td>
                                <v-btn
                                    icon="true"
                                    variant="plain"
                                    color="error"
                                    density="compact"
                                    class="mt-1 mx-auto"
                                    :aria-label="$t('compraCesta.eliminarProducto')"
                                    @click="eliminar(item)">
                                    <v-tooltip activator="parent" location="top" :text="$t('compraCesta.eliminarProducto')" />
                                    <v-icon icon="mdi-delete" />
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>

                <v-divider />

                <div class="text-right mr-12 mt-5">
                    <span class="px-4 pt-8 text-h6">Total:</span>
                    <span class="px-4 pt-8 text-h5 font-weight-black">
                        {{ precioFormateado(cesta.total) }}
                    </span>
                </div>
            </v-card>

            <!-- Cesta: Vista mobile. Se muestra en formato de lista ya que es más cómodo para pantallas pequeñas. -->
            <v-card v-else :loading="loading" class="px-4 pb-4 mb-6 mx-n4 mx-sm-0">
                <template v-for="(item, i) in cesta.lineas" :key="i">
                    <div class="align-top py-4 w-100">
                        <div>
                            <router-link :to="item.to" class="d-inline-block float-left rounded-lg mr-4 text-decoration-none">
                                <v-avatar
                                    :image="item.imagen"
                                    width="50"
                                    height="50"
                                    :alt="item.descripcion" />
                            </router-link>

                            <div class="d-flex">
                                <router-link class="flex-grow-1 text-body-1 text-decoration-none text-on-surface" :to="item.to">
                                    {{ item.descripcion }}
                                </router-link>

                                <v-btn
                                    variant="plain"
                                    color="error"
                                    size="small"
                                    class="mr-n3 ml-1 mb-n2"
                                    append-icon="mdi-delete"
                                    :aria-label="$t('compraCesta.eliminarProducto') + ' ' + item.descripcion"
                                    @click="eliminar(item)">
                                    {{ $t('compraCesta.eliminar') }}
                                </v-btn>
                            </div>

                            <v-row v-if="item.promociones?.length > 0" dense class="mt-1">
                                <v-col v-for="promocion in item.promociones" :key="promocion.id" cols="auto" class="text-body-2">
                                    <v-chip color="success" density="comfortable" :prepend-icon="promocion.icon" :text="promocion.descripcion" />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="d-flex mt-4 w-100" style="min-width: 200px;">
                            <quantity-selector
                                v-model="item.cantidad"
                                :min="item.cantidadMin"
                                :max="item.cantidadMax"
                                :show-unit="true"
                                @update:model-value="enviarCesta" />

                            <v-spacer />

                            <div class="align-top ml-4">
                                <span v-if="item.importeOriginal" class="text-decoration-line-through mr-2 text-body-1 text-disabled ">
                                    {{ precioFormateado(item.importeOriginal) }}
                                </span>
                                <span class="font-weight-bold text-h6">
                                    {{ precioFormateado(item.importeFinal) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-divider class="mx-n4" />
                </template>

                <div class="text-right mt-5">
                    <span class="mr-4 pt-8 text-h6">Total:</span>
                    <span class="pt-8 text-h5 font-weight-black">
                        {{ precioFormateado(cesta.total) }}
                    </span>
                </div>
            </v-card>


            <v-card v-if="cesta.lineasBonificadas.length > 0" class="mb-6 mx-n4 mx-sm-0">
                <v-card-title class="text-h6">
                    <v-icon class="mr-4" icon="mdi-gift" size="small" />
                    <span>{{ $t('compraCesta.deRegaloConTuCompra') }}</span>
                </v-card-title>

                <v-list>
                    <v-list-item v-for="(item, i) in cesta.lineasBonificadas" :key="i" class="py-4">
                        <router-link :to="item.to" class="d-inline-block mr-4 float-left rounded-lg mr-4text-decoration-none">
                            <v-avatar
                                :image="item.imagen"
                                width="50"
                                height="50"
                                :alt="item.descripcion" />
                        </router-link>

                        <router-link :to="item.to" class="flex-grow-1 text-decoration-none text-on-surface">
                            {{ item.cantidad }} × {{ item.descripcion }}
                        </router-link>

                        <template #append>
                            <v-chip color="success" density="comfortable" :text="$t('compraCesta.gratis')" />
                        </template>
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card v-if="cesta.vales.length > 0" class="mb-6 pa-2 mx-n4 mx-sm-0">
                <v-card-title class="text-h6">
                    {{ $t('compraCesta.valesPromocionalesPorTuCompra') }}
                </v-card-title>
                <v-card-text>
                    <v-row dense class="mx-n4">
                        <template v-for="(item, i) in cesta.vales" :key="i">
                            <v-col cols="12" md="6" lg="4">
                                <v-alert
                                    type="success"
                                    variant="tonal"
                                    class="ma-1"
                                    :icon="item.icon"
                                    rounded="lg">
                                    <div class="mr-2 font-weight-bold">
                                        {{ item.descripcion }}
                                    </div>
                                    <div>
                                        {{ item.contenido }}
                                    </div>
                                </v-alert>
                            </v-col>
                        </template>
                    </v-row>

                    <v-alert class="text-body-1 px-6 mx-n6 mb-n6 mt-2 rounded-t-0" variant="tonal" type="info">
                        {{ $t('compraCesta.losValesPromocionales') }}
                    </v-alert>
                </v-card-text>
            </v-card>

            <v-alert v-if="cesta.puntos.totalPuntos" type="success" class="mb-6" icon="mdi-counter">
                <span class="mr-4">{{ $t('compraCesta.puntosPorTuCompra') }}</span>
                <strong>{{ cesta.puntos.totalPuntos }}</strong>
            </v-alert>

            <v-row dense justify="end">
                <v-col cols="12" md="auto">
                    <v-btn :disabled="cesta.lineas.length === 0 || loading" size="large" class="w-100" append-icon="mdi-chevron-right" to="/compra">
                        {{ $t('compraCesta.continuarCompra') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import axios from 'axios';
import { store } from '@/store';
import QuantitySelector from '../components/QuantitySelector.vue';
import OopsAlert from '../components/OopsAlert.vue';
import EurosHelper from '../support/EurosHelper';
import { normalizeCesta } from '../support/Normalization';
import imagenProductoDefault from '../../images/producto-default.jpg';
import HeartbeatService from '../support/HeartbeatService';

export default {
    components: {
        QuantitySelector,
        OopsAlert,
    },
    emits: [
        /**
         * Emitido cuando el usuario pulsa el botón de siguiente paso.
         */
        'siguiente',
    ],
    data() {
        return {
            /**
             * Indica si se está cargando información del servidor.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Error al cargar la información del servidor.
             *
             * @type {Error|null}
             */
            error: null,

            /**
             * Cesta del usuario. Null si la cesta aún no se ha cargado.
             *
             * @type {{lineas: object[], lineasBonificadas: object[], vales: object[], puntos: object[], total: number}?}}
             */
            cesta: null,
        };
    },
    computed: {
        imagenProductoDefault() {
            return imagenProductoDefault;
        },
        cestaBadgeCount() {
            return store.cesta.badge;
        },
    },
    watch: {
        cestaBadgeCount() {
            // El HeartbeatService se encarga de cambiar el valor del badge. Si cambia,
            // entonces recargamos la cesta. Esto permite que siempre
            // esten sincronizados el badge y la cesta. En un futuro sería ideal que se cambie
            // por un hash de la cesta, para manejar edge cases donde el numero de items no cambia
            // pero el contenido de la cesta sí.
            this.cargarDatos();
        },
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.error = null;

            try {
                const { data: cesta } = await axios.get('/cesta');
                this.cesta = normalizeCesta(cesta);
                this.loading = false;
            } catch (error) {
                this.error = error;
                console.error(error);
            } finally {
                this.loading = false;

                HeartbeatService.instance.heartbeat(); // No hace falta esperar a que termine
            }
        },

        eliminar(item) {
            this.cesta.lineas = this.cesta.lineas.filter((articulo) => articulo.idarticu !== item.idarticu);
            this.enviarCesta();
        },

        async enviarCesta() {
            this.loading = true;

            try {
                await axios.put('/cesta', {
                    cesta: this.cesta.lineas.map((articulo) => ({
                        idarticu: articulo.idarticu,
                        cantidad: articulo.cantidad,
                    })),
                });
                this.cargarDatos();
            } catch (error) {
                // Si falla nos comemos el error porque no queremos asustar al usuario con un mensaje de error.
                // En este caso, el usuario va a ver la cesta con los datos anteriores sin actualizar.
                // En un futuro se podría implementar un rollback de la cesta al estado anterior.
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },
    },
};
</script>
