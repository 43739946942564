<template>
    <v-container>
        <v-row class="pb-12">
            <v-col cols="12">
                <h1 class="mb-4 text-h5 text-primary font-weight-bold">
                    {{ $t('proxDispensacionesView.proximasDispensaciones') }}
                </h1>

                <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />

                <div v-else-if="loading" class="text-center d-block">
                    <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" />
                </div>

                <v-card v-else-if="ventas.length === 0" class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
                    <v-icon size="60" icon="mdi-calendar-multiple" class="text-medium-emphasis " />
                    <div class="text-high-emphasis text-h6 mt-6">
                        {{ $t('proxDispensacionesView.noHayProximasDispensaciones') }}
                    </div>
                </v-card>

                <v-card v-else class="mx-n4 mx-sm-0">
                    <v-list>
                        <template v-for="(venta, i) in ventas" :key="venta.idproxdispens">
                            <v-list-item class="py-4" :variant="vencida(venta.fechaini)">
                                <template #prepend>
                                    <v-img
                                        :src="venta.imagen"
                                        :alt="venta.descripcion"
                                        class="mr-6 rounded-lg"
                                        :width="imageSize"
                                        :height="imageSize" />
                                </template>
                                <div class="d-flex flex-column flex-md-row justify-sm-space-between ">
                                    <div>
                                        <v-list-item-title class="font-weight-bold text-wrap">
                                            {{ venta.cantidad }} × {{ venta.descripcion }}
                                        </v-list-item-title>
                                        <div v-if="venta.observaciones" class="pt-2 text-body-2 text-medium-emphasis">
                                            {{ venta.observaciones }}
                                        </div>
                                        <div class="pt-2 text-body-2 text-medium-emphasis">
                                            {{ $t('proxDispensacionesView.fecha') }}
                                            <strong>{{ venta.fecha }}</strong>
                                        </div>
                                    </div>
                                    <div class="mt-4 mt-md-0 text-start text-md-end text-body-2 text-medium-emphasis justify-start justify-md-end">
                                        <v-btn
                                            v-if="venta.prospecto"
                                            class="text-body-2 px-0"
                                            color="grey-darken-2"
                                            density="compact"
                                            variant="plain"
                                            :href="venta.prospecto"
                                            target="_blank">
                                            <v-icon icon="mdi-file-pdf-box" size="large" class="me-2 order-md-last ms-md-2 me-md-0" />
                                            {{ $t('articuloView.prospecto') }}
                                        </v-btn>
                                        <div v-if="venta.fucrecetaelectronica" class="mt-2 d-flex align-center justify-md-end">
                                            <v-icon icon="mdi-information-outline" class="me-2 order-md-last ms-md-2 me-md-0" />
                                            {{ $t('proxDispensacionesView.informacionConsultada') }}
                                            <br v-if="$vuetify.display.mdAndUp">
                                            {{ venta.fucrecetaelectronica }}
                                        </div>
                                        <div v-if="venta.paciente" class="mt-2 d-flex align-center justify-sm-end">
                                            <v-icon icon="mdi-account" class="me-2 order-sm-last ms-sm-2 me-sm-0" />
                                            {{ $t('proxDispensacionesView.paciente') }} {{ venta.paciente }}
                                        </div>
                                        <div class="text-medium-emphasis text-right text-caption my-2">
                                            <span>REF: {{ venta.idarticu }}</span>
                                        </div>
                                    </div>
                                </div>
                            </v-list-item>
                            <v-divider v-if="i < ventas.length - 1" />
                        </template>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import imagenProductoDefault from '../../images/producto-default.jpg';
import OopsAlert from '../components/OopsAlert.vue';

export default {
    name: 'ProxDispensacionesView', // Usado por <keep-alive>

    components: {
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Listado de ventas.
         *
         * @type {object[]}
         */
        ventas: [],
    }),
    computed: {
        imageSize() {
            return this.$vuetify.display.smAndUp ? 120 : 80;
        },
        
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.error = null;
            try {
                const { data } = await axios.get('/proxdispensaciones');

                // Set<paciente> para saber si mostrar el nro de paciente solo si hay mas de 1 paciente
                const pacientes = Object.keys(data.reduce((pacientes, linea) => (
                    {...pacientes, [linea.paciente]: true}
                ), {}));

                const mostrarNroPaciente = pacientes.length > 1;

                this.ventas = data.map((linea) => ({
                    idproxdispens: linea.idproxdispens,
                    idarticu: linea.idarticu,
                    fechaini: linea.fechaini,
                    fecha: moment(linea.fechaini).format('L'),
                    fucrecetaelectronica: linea.fucrecetaelectronica ? moment(linea.fucrecetaelectronica).format('LLL') : null,
                    descripcion: linea.descripcion,
                    cantidad: linea.cantidad,
                    observaciones: linea.observaciones || null,
                    imagen: linea.imagen || imagenProductoDefault,
                    prospecto: linea.prospecto,
                    paciente: mostrarNroPaciente ? linea.paciente : null,
                }));
            } catch (error) {
                this.error = error;
                console.error(error);
            }
            this.loading = false;
        },
        vencida(fecha) {
            console.log(fecha);
            let valor = moment(fecha).isSameOrBefore(moment());
            if(valor === true){
                return 'tonal';
            }
            return '';
        },
    },
};
</script>
