<template>
    <div class="pb-16">
        <oops-alert v-if="errorAddCarrito" :titulo="$t(errorAddCarrito.message)" />
        <oops-alert
            v-if="error"
            :error="error"
            :titulo="$t('articulosLista.mensajeError')"
            mostrar-reintentar
            @reintentar="reset" />

        <v-card v-else-if="sinArticulos" class="px-6 py-16 text-center mt-3" color="surface" elevation="0" :border="true">
            <v-icon size="60" icon="mdi-alert-circle-outline" class="text-medium-emphasis " />
            <div class="text-high-emphasis text-h6 mt-6">
                {{ $t('articulosLista.sinArticulos') }}
            </div>
            <div class="text-medium-emphasis text-body-1 mt-1">
                {{ $t('articulosLista.sinArticulosDescripcion') }}
            </div>
            <v-btn color="primary" class="mt-8" to="/">
                {{ $t('volverAlInicio') }}
            </v-btn>
        </v-card>

        <div v-else>
            <v-infinite-scroll
                :key="idcomponente"
                :items="articulos"
                direction="vertical"
                class="overflow-hidden"
                @load="load">
                <v-row dense>
                    <template v-for="articulo in articulos" :key="articulo.idarticu">
                        <v-col cols="6" sm="4" md="3">
                            <articulo-card
                                :articulo="articulo"
                                :mostrar-imagen="mostrarImagenes"
                                :mostrar-precio="mostrarPrecios"
                                @error="mostrarErrorArticulo" />
                        </v-col>
                    </template>
                </v-row>
                <template #loading>
                    <div class="text-center d-block ma-16">
                        <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" size="70" />
                    </div>
                </template>
                <template #empty>
                    <div
                        v-if="mostrarFinLista"
                        class="text-medium-emphasis text-center d-flex align-center justify-center d-block ma-16">
                        <v-icon icon="mdi-check" class="mr-2" />
                        {{ $t('articulosLista.noHayMasProductos') }}
                    </div>
                </template>
                <template #error="{ retry }">
                    <oops-alert
                        :error="error"
                        :titulo="$t('articulosLista.mensajeError')"
                        mostrar-reintentar
                        class="mt-4"
                        @reintentar="retry" />
                </template>
            </v-infinite-scroll>
        </div>
    </div>
</template>
<script>
import { store } from '@/store';
import axios from 'axios';
import ArticuloCard from './ArticuloCard.vue';
import OopsAlert from './OopsAlert.vue';
import ArticuloOrden from '../enums/ArticuloOrden';
import { normalizeArticulo } from '../support/Normalization';

/**
     * Este componente muestra una lista de articulos que carga de forma infinita. Se puede pasar un objeto postData
     * que se envía como parámetros POST al endpoint de articulos.
     */
export default {
    components: {
        ArticuloCard,
        OopsAlert,
    },
    props: {
        /**
             * Indica si se debe mostrar el cartel de "No hay más articulos".
             */
        mostrarFinLista: {
            type: Boolean,
            default: true,
        },

        /**
             * Indica los parámetros POST que se deben enviar para obtener los articulos.
             *
             * @type {{portada: number?, idcategoria: number?, idoferta: number?, cadena: string?}}
             */
        postData: {
            type: Object,
            default: () => ({}),
        },

        /**
             * Indica el orden que se deben mostrar los articulos.
             *
             * @see ArticuloOrden
             * @type {number}
             */
        orden: {
            type: Number,
            default: ArticuloOrden.DEFAULT,
        },
    },
    data() {
        return {
            /**
                 * Error ocurrido al cargar los articulos.
                 *
                 * @type {Error?}
                 */
            error: null,
            /**
                 * Error ocurrido al cargar los articulos.
                 *
                 * @type {Error?}
                 */
            errorAddCarrito: null,

            /**
                 * Lista de artículos.
                 *
                 * @type {object[]}
                 */
            articulos: [],

            /**
                 * URL de la siguiente página de articulos, o null si no hay más páginas.
                 *
                 * @type {string?}
                 */
            nextPageUrl: '/articulos',

            /**
                 * Indica si no hay ningun articulo para mostrar. Esto es distinto de que no haya más páginas.
                 *
                 * @type {boolean}
                 */
            sinArticulos: false,

            /**
                 * Indica si se está cargando la siguiente página de articulos.
                 *
                 * @type {boolean}
                 */
            loading: false,

            /**
                 * Id para refresco del componente
                 *
                 * @type {number}
                 */
            idcomponente: 0,

            /**
                 * Cesta actual.
                 *
                 * @type {object}
                 */
            cesta: null,

        };
    },
    computed: {
        mostrarImagenes() {
            return store.farmacia.mostrarImagenes;
        },

        mostrarPrecios() {
            return store.mostrarPrecios;
        },
    },
    watch: {

        postData() {
            // Siempre que cambien los parámetros post, reseteamos el componente.
            this.reset();
        },
        mostrarPrecios() {
            //Siempre que cambie mostrarPrecios, reseteamos el componente.
            this.reset();
        },
    },
    methods: {

        /**
             * Resetea el estado del componente y vuelve a cargar los articulos.
             *
             * @returns {Promise<void>}
             */
        async reset() {
            this.idcomponente += 1; //Esto hace un reset completo del componente
            this.articulos = [];
            this.nextPageUrl = '/articulos';
            this.sinArticulos = false;
            this.error = null;
        },
        /**
             * Carga la siguiente página de articulos.
             * Es absolutamente autogestionado por el plugin. El scroll se encargará de llamarlo cuando sea necesario a través del handler load()
             *
             * @returns {Promise<void>}
             */
        async loadNextPage() {
            if (this.loading) return;
            if (!this.nextPageUrl) return;
            this.loading = true;
            this.error = null;

            try {
                var dataraw;
                //Si existe cadena, ademas es mayor que 5 y es un número, se envía como idarticu
                if(this.postData.cadena?.length > 5 && !isNaN(this.postData.cadena)){
                    dataraw = await axios.post(this.nextPageUrl, {
                        idarticu : this.postData.cadena,
                        orden: this.orden,
                        idfarmacia: store.farmacia.numserie, // TODO: Remover este parámetro cuando se corrija el backend ya que es redundante con X-Approx-Subdomain.
                    });
                }else{
                    dataraw = await axios.post(this.nextPageUrl, {
                        ...this.postData,
                        orden: this.orden,
                        idfarmacia: store.farmacia.numserie, // TODO: Remover este parámetro cuando se corrija el backend ya que es redundante con X-Approx-Subdomain.
                    });
                }
                const data = dataraw.data;

                // https://laravel.com/docs/10.x/pagination#converting-results-to-json
                // Todos estos null checks es porque actualmente hay un bug en el backend en donde
                // se devuelve 204 No Content cuando no hay articulos en lugar de un 200 OK con un JSON vacío.
                this.nextPageUrl = data.next_page_url ?? null;
                this.total = data.total ?? 0;
                const articulos = data.data ?? [];
                this.articulos.push(...articulos.map(articulo => normalizeArticulo(articulo)));

            } catch (err) {
                this.error = err;
                console.error(err);
            } finally {
                this.sinArticulos = (this.nextPageUrl === null && this.error === null && this.articulos.length === 0);
                this.loading = false;
            }

        },

        /**
             * Handler de la carga infinita de articulos.
             *
             * @param {object} $state
             * @returns {Promise<void>}
             */
        async load({ done }) {
            this.loading = false;
            await this.loadNextPage();
            // Solo se llama a done después de haber cargado los artículos
            if (this.nextPageUrl) {
                done();
            } else {
                done('empty');
            }
        },
        /**
             * Muestra el error ocurrido al cargar un articulo en el carro de la compra.
             * @param {Error} event
             */
        mostrarErrorArticulo(error) {
            console.error(error);
            this.errorAddCarrito = error;
        },
    },
};
</script>
