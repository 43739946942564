<template>
    <v-container>
        <h1 class="text-h5 text-primary font-weight-bold text-center my-4">
            {{ $t('inicioView.vinculaLaAppAtuEstablecimiento') }}
        </h1>
        <v-card class="pa-4 pa-sm-8">
            <oops-alert v-if="error" :error="error" variant="flat" class="mt-n4 mx-n4 rounded-b-0 mb-4" />
            <v-row>
                <v-col cols="12" sm="6" class="pr-sm-8">
                    <p class="text-body-1 mb-4">
                        {{ $t('inicioView.paraIniciarLaAplicacion') }}
                    </p>
                    <v-form ref="loginForm" v-model="valid" @submit.prevent="vincularPorSubdominio">
                        <v-text-field
                            v-model="subdominio"
                            :disabled="loading"
                            variant="outlined"
                            color="primary"
                            :rules="reglasSubdominio"
                            hide-details="auto"
                            :placeholder="$t('inicioView.placeholder')"
                            autocapitalize="off"
                            :style="$vuetify.display.smAndUp ? 'max-width: 400px; margin-left: auto; margin-right: auto': null" />
                        <v-btn block :disabled="!canSubmit" type="submit" color="primary" class="mt-4" :loading="loadingSubdominio">
                            {{ $t('inicioView.irAMiFarmacia') }}
                        </v-btn>
                    </v-form>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    class="d-flex flex-column pl-sm-8"
                    :style="$vuetify.display.smAndUp ? 'border-left: 1px solid rgba(var(--v-border-color), var(--v-border-opacity))': null">
                    <v-divider v-if="! $vuetify.display.smAndUp" class="my-4 mx-n4" />
                    <p class="text-body-1 mb-4">
                        {{ $t('inicioView.tambienPuedesHacerlo') }}
                    </p>
                    <v-spacer />
                    <v-btn to="/lectorqr" prepend-icon="mdi-qrcode" color="primary">
                        {{ $t('inicioView.escanearQr') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <template v-if="recientes.length > 0">
            <h1 class="text-h6 mt-6">
                {{ $t('inicioView.farmaciasRecientes') }}
            </h1>

            <v-card>
                <v-list :disabled="loading">
                    <template v-for="(farmacia, i) in recientes" :key="farmacia.numserie">
                        <v-list-item :title="farmacia.nombre" :subtitle="farmacia.direccion" class="py-4" @click="vincularPorReciente(farmacia)">
                            <template #append>
                                <v-icon v-if="!farmacia.loading" icon="mdi-chevron-right" />
                                <v-progress-circular v-else :size="20" indeterminate color="primary" />
                            </template>
                        </v-list-item>

                        <v-divider v-if="i < recientes.length - 1" />
                    </template>
                </v-list>
            </v-card>
        </template>
    </v-container>
</template>

<script>
import OopsAlert from '../components/OopsAlert.vue';
import { store } from '../store';

export default {
    components: {
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Indica si se está cargando información del servidor para la vinculación por subdominio.
         *
         * @type {boolean}
         */
        loadingSubdominio: false,

        /**
         * Indica si el formulario de vinculación es válido.
         *
         * @type {boolean}
         */
        valid: false,

        /**
         * Lista de farmacias recientes.
         *
         * @type {object[]}
         */
        recientes: [],

        /**
         * Subdominio tipeado por el usuario.
         *
         * @type {string}
         */
        subdominio: '',
    }),
    computed: {
        reglasSubdominio() {
            return [(v) => /^[A-Za-z0-9_-]+$/.test(this.normalizar(v)) || this.$t('inicioView.formatoInvalido')];
        },
        canSubmit() {
            return this.subdominio.length > 0 && this.valid;
        },
    },
    async mounted() {
        this.recientes = await store.getFarmaciasRecientes();
        console.info('farmacias recientes', this.recientes);
    },
    methods: {
        normalizar(subdominio) {
            // Esto permite que los usuarios puedan escribir el subdominio con espacios y en mayúsculas
            // e igual la UI los interpreta como un subdominio válido
            // EJ: "  Mi Farmacia " -> "mifarmacia", "DON ORIONE" -> "donorione"
            return subdominio.replace(' ', '').toLowerCase();
        },

        async vincularFarmacia(subdominio) {
            if (this.loading) return;
            this.error = null;
            this.loading = true;
            try {
                // Descargamos primero los datos de la farmacia para verificar que el subdominio exista
                await store.vincularFarmacia(this.normalizar(subdominio));

                // Si todo sale bien, redirigimos a la página principal
                await this.$router.push('/');
            } catch (e) {
                console.error(e);
                this.error = e;
            }
            this.loading = false;
        },

        async vincularPorSubdominio() {
            this.loadingSubdominio = true;
            await this.vincularFarmacia(this.subdominio);
            this.loadingSubdominio = false;
        },

        async vincularPorReciente(farmaciaReciente) {
            farmaciaReciente.loading = true;

            await this.vincularFarmacia(farmaciaReciente.subdominio);

            if (this.error) {
                farmaciaReciente.loading = false;
                window.scrollTo(0, 0); // Scroll to top para que se vea el error
                return;
            }

            const { user, token } = farmaciaReciente;
            if (user && token) {
                await store.setUser(user, token);
            }

            farmaciaReciente.loading = false;
        },
    },
};
</script>
