<template>
    <v-container>
        <h1 class="mb-4 text-h5 text-primary font-weight-bold">
            {{ $t('PedidosView.misPedidos') }}
        </h1>
        <pedidos-lista />
    </v-container>
</template>

<script>
import PedidosLista from '@/views/cuenta/PedidosLista.vue';

export default {
    name: 'PedidosView',

    components: {
        PedidosLista,
    },
    data: () => ({
        //
    }),
};
</script>
