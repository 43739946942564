<template>
    <v-card class="pb-4 mb-12 pa-4 pa-sm-6">
        <v-row class="d-flex align-center justify-space-between px-0 pt-0" dense>
            <v-col class="text-h6" cols="auto">
                {{ formatFecha(pedido.created_at) }}
            </v-col>
            <v-spacer v-if="$vuetify.display.smAndUp" />
            <v-col cols="auto" class="text-end">
                <pedido-card-chip :estado="pedido.estado" :label="pedido.estadonombre" />
            </v-col>
        </v-row>
        <v-row :dense="!$vuetify.display.smAndUp">
            <v-col cols="12" md="5">
                <dl class="mb-6">
                    <div>
                        <dt>{{ $t('pedidoCard.nPedido') }}</dt>
                        <dd>#{{ nroPedido }}</dd>
                    </div>
                    <div>
                        <dt>{{ $t('pedidoCard.subtotal') }}</dt>
                        <dd>{{ subtotal }}</dd>
                    </div>
                    <div>
                        <dt>{{ $t('pedidoCard.gastosDeEnvio') }}</dt>
                        <dd>{{ envio }}</dd>
                    </div>
                    <div>
                        <dt>{{ $t('pedidoCard.total') }}</dt>
                        <dd>{{ total }}</dd>
                    </div>
                    <div>
                        <dt>{{ $t('pedidoCard.formaDePago') }}</dt>
                        <dd>{{ descripcionformapago }}</dd>
                    </div>
                    <div>
                        <dt>{{ $t('pedidoCard.envio') }}</dt>
                        <dd>{{ descripcionenvio }}</dd>
                    </div>
                </dl>
            </v-col>
            <v-col cols="12" md="7">
                <div class="mx-n4 mx-sm-0">
                    <div
                        v-for="(item, i) in lineas"
                        :key="item.idnlinea"
                        class="d-flex py-4 px-4 px-sm-0"
                        :class="{'border-top': i > 0}">
                        <div class="pr-2">
                            <v-img
                                width="64"
                                height="64"
                                :src="item.imagen"
                                :alt="item.descripcion"
                                :lazy-src="imageProductoDefault"
                                :title="item.descripcion" />
                        </div>
                        <div class="text-left flex-grow-1">
                            <div class="font-weight-bold">
                                {{ item.descripcion }}
                            </div>
                            <div class="text-medium-emphasis">
                                {{ item.cantidad }} × {{ precioFormateado(item.pvp) }}
                            </div>
                        </div>
                        <div class="text-right font-weight-bold ml-4 d-flex align-center" style="margin-left: 0; padding-left: 0">
                            {{ precioFormateado(item.importeoferta) }}
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-divider class="mx-n6 my-6" />
        <div>
            <div class="mb-2 font-weight-bold">
                {{ $t('pedidoCard.seguimiento') }}
            </div>
            <pedido-card-mensajes :pedido="pedido" />
        </div>
    </v-card>
</template>
<script>
import moment from 'moment';
import EurosHelper from '@/support/EurosHelper';
import PedidoCardChip from './PedidoCardChip.vue';
import PedidoCardMensajes from './PedidoCardMensajes.vue';
import imageProductoDefault from '@/../images/producto-default.jpg';
import StringHelper from '@/support/StringHelper';

export default {
    components: {
        PedidoCardChip,
        PedidoCardMensajes,
    },
    props: {
        pedido: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        subtotal() {
            return EurosHelper.format(this.pedido.importe);
        },
        envio() {
            return parseFloat(this.pedido.gastosenvio) === 0
                ? this.$t('pedidoCard.gratis')
                : EurosHelper.format(this.pedido.gastosenvio);
        },
        total() {
            return EurosHelper.format(parseFloat(this.pedido.importe) + parseFloat(this.pedido.gastosenvio));
        },
        nroPedido() {
            return this.pedido.idpedido.toString().padStart(5, '0');
        },
        lineas() {
            return this.pedido.lineas.map((linea) => {
                return {
                    ...linea,
                    imagen: linea.imagen || imageProductoDefault,
                };
            });
        },
        imageProductoDefault() {
            return imageProductoDefault;
        },
        descripcionformapago() {
            return StringHelper.sentenceCase(this.pedido.descripcionformapago);
        },
        descripcionenvio() {
            return StringHelper.sentenceCase(this.pedido.descripcionenvio);
        },
    },
    methods: {
        formatFecha(fechahora) {
            return moment(fechahora).format('LL');
        },
        precioFormateado(precio) {
            return EurosHelper.format(precio);
        },
    },
};
</script>
<style scoped>
dl > div {
  display: grid;
  padding-top: .25em;
  padding-bottom: .25em;
  grid-gap: 2em;
  grid-template-columns: minmax(150px, 30%) auto;
}

dd {
  margin: 0;
  grid-column: 2;
  font-weight: bold;
}

.border-top {
    border-top: rgba(var(--v-border-color), var(--v-border-opacity)) 1px solid;
}
</style>
