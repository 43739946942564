<template>
    <div class="h-100 mx-4 my-2">
        <v-card class="text-center d-flex align-center justify-center" v-bind="linkAttrs" color="surface" rounded="xl" elevation="3" hover style="min-width: 120px; height: 100px;">
            <v-badge v-if="badge > 0" :content="badge" color="secondary" class="d-flex align-self-start mt-6" />
            <v-icon :icon="icon" color="primary" :size="50" />
        </v-card>
        <div class="mx-3 mt-2 text-center d-block">
            <router-link class="text-h6 font-weight-bold text-primary d-block" v-bind="linkAttrs">
                {{ nombre }}
            </router-link>
            <router-link class="text-body-2 d-block text-on-background text-high-emphasis text-wrap-balance" v-bind="linkAttrs">
                {{ descripcion }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: { // Todos los props de <v-card> también están disponibles (to, href, target, etc)

        /**
         * El icono del acceso.
         */
        icon: {
            type: String,
            required: true,
        },

        /**
         * El nombre del acceso.
         */
        nombre: {
            type: String,
            required: true,
        },

        /**
         * La descripción del acceso.
         */
        descripcion: {
            type: String,
            required: true,
        },

        to: {
            type: [String, null],
            default: null,
        },

        href: {
            type: [String, null],
            default: null,
        },

        target: {
            type: [String, null],
            default: null,
        },
        badge: {
            type: [Number, null],
            default: null,
        },
    },
    data: () => ({}),
    computed: {
        linkAttrs() {
            const isLink = this.to || this.href || this.$attrs.onClick;
            return {
                to: this.to ?? '',
                href: this.href ?? undefined,
                target: this.target ?? undefined,
                class: {
                    'cursor-pointer text-decoration-none': isLink,
                },
            };
        },
    },
};
</script>
