<template>
    <v-dialog v-model="dialog" max-width="600px" transition="dialog-bottom-transition">
        <v-card class="pa-4">
            <v-card-title>
                <v-icon icon="mdi-translate" class="pr-3" />
                {{ $t("dialogIdioma.seleccionarIdioma") }}
            </v-card-title>
            <v-list color="primary">
                <v-list-item v-for="locale in idiomas" :key="locale" :prepend-avatar="getIcon(locale)" :title="labels[locale] || locale" :append-icon=" locale === $i18n.locale ? 'mdi-check' : undefined" @click="setIdioma(locale)" />
            </v-list>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    variant="tonal"
                    class="px-4"
                    @click="dialog = false">
                    {{ $t("dialogIdioma.cancelar") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { store } from '@/store';
import IdiomasHelper from '@/support/IdiomasHelper';
export default {
    emits: ['update:modelValue'],
    data: () => ({
        /**
         * Indica si el dialogo es visible.
         *
         * @type {boolean}
         */
        dialog: false,
    }),
    computed: {
        labels() {
            return {
                es: 'Español',
                en: 'English',
                pt: 'Português',
                fr: 'Français',
                de: 'Deutsch',
                it: 'Italiano',
                ca: 'Català',
                eu: 'Euskara',
                gl: 'Galego',
                ru: 'Russian',
            };
        },
        idiomas(){
            return store.idiomasSoportados;
        },
    },
    watch: {
        dialog(value) {
            this.$emit('update:modelValue', value);
        },
    },
    methods: {
        async setIdioma(codigo) {
            await store.setIdiomaUser(codigo);
            this.dialog = false;
        },
        cerrar() {
            this.dialog = false;
        },
        getIcon(icon){
            return IdiomasHelper.getIcon(icon);
        },
    },
};
</script>
