<template>
    <v-alert
        type="error"
        :variant="variant"
        :color="esErrorOffline ? 'grey' : color"
        :icon="esErrorOffline ? 'mdi-wifi-off' : icon">
        <div>{{ tituloMostrar }}</div>
        <div v-if="detallesMostrar" class="text-medium-emphasis mt-1" :class="variant === 'outlined' ? 'text-error' : 'on-error'">
            <span class="font-weight-bold mr-2">{{ $t('oops.detalles') }}: </span>
            <code v-if="esErrorDeServerSinMensaje">{{ detallesMostrar }}</code>
            <span v-else>{{ detallesMostrar }}</span>
        </div>
        <div class="d-flex justify-end mt-2">
            <v-btn
                v-if="mostrarReintentar && !esErrorValidacion"
                variant="tonal"
                :color="esErrorOffline ? 'on-grey' : 'on-error'"
                @click="$emit('reintentar')">
                {{ $t('oops.reintentar') }}
            </v-btn>
        </div>
    </v-alert>
</template>

<script>

/**
 * Un error de tipo OopsError se puede usar para mostrar un mensaje de error personalizado.
 */
export class OopsError extends Error {
    /**
     * Inicializa una instancia de OopsError.
     *
     * @param {string} message  El mensaje de error a mostrar al usuario.
     * @param {string?} details Los detalles del error a mostrar al usuario.
     * @param {Error?} error El error original.
     */
    constructor(message, details = null, error = null) {
        super(message);
        this.details = details;
        this.error = error;
    }
}

/**
 * El componente OopsAlert muestra un mensaje de error al usuario.
 * Recibe un objeto de tipo Error y muestra un mensaje de error personalizado en caso de que sea un error de tipo OopsError.
 * Si el error es un error de axios, se mostrará el mensaje de error del server.
 */
export default {
    props: {
        /**
         * El título del error. Por defecto, se mostrará un título genérico de acuerdo al tipo de error.
         * Este titulo sólo se usa como fallback en caso de que no se pueda determinar un título apropiado.
         * En caso de que se pueda determinar un título apropiado, se ignorará este parámetro.
         */
        titulo: {
            type: String,
            default: null,
        },

        /**
         * Los detalles del error a mostrar. Por defecto, se mostrará el mensaje de error del server.
         */
        detalles: {
            type: String,
            default: null,
        },

        /**
         * El tipo de alerta a mostrar. (ver documentación de Vuetify)
         */
        variant: {
            type: String,
            default: 'outlined',
        },

        /**
         * El error a mostrar. Debe ser un objeto de tipo Error.
         * En el caso de que sea un error de Axios, se mostrará el mensaje de error del server.
         * En el caso de que sea un error de tipo OopsError, se mostrará el mensaje de error que se haya pasado
         * como parámetro al constructor. Esto se puede usar para mostrar un mensaje de error personalizado.
         * En cualquier otro caso (error genérico de JS), no se mostrará ningun detalle (para no asustar al usuario).
         *
         * @type {Error | null}
         */
        error: {
            type: Object,
            default: null,
        },

        /**
         * Indica si se debe mostrar el botón de reintentar.
         * Si el error es un error de validación (status 422), no se mostrará el botón de reintentar
         * sin importar el valor de este parámetro. Esto es porque el usuario debe corregir los datos que introdujo
         * antes de volver a intentar.
         *
         * @type {boolean}
         */
        mostrarReintentar: {
            type: Boolean,
            default: false,
        },

        /**
         * El color de la alerta. (ver documentación de Vuetify)
         */
        color: {
            type: String,
            default: null,
        },

        /**
         * El icono a mostrar. (ver documentación de Vuetify)
         */
        icon: {
            type: String,
            default: null,
        },

        /**
         * El tipo de error. (ver documentación de Vuetify)
         */
        type: {
            type: String,
            default: 'error',
        },
    },
    emits: [
        'reintentar',
    ],
    computed: {
        /**
         * Indica si el error es un error de validación (status 422). Esto significa que el usuario
         * introdujo datos incorrectos y debe corregirlos antes de volver a intentar.
         *
         * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
         * @see https://laravel.com/docs/8.x/validation#conditional-validation
         * @returns {boolean}
         */
        esErrorValidacion() {
            return this.error?.isAxiosError && this.error?.response?.status === 422;
        },

        /**
         * Indica si el error es un error de tipo FarmaciaHttpException. Esto se produce cuando la farmacia
         * solicitada no existe o no está disponible.
         *
         * @returns {boolean}
         */
        esErrorFarmacia() { // Ver FarmaciaHttpException en server.
            return this.error?.isAxiosError && this.error?.response?.data?.approx_status_code;
        },

        /**
         * Indica si el error es un error causado al enviar un formulario.
         *
         * @returns {boolean}
         */
        esEnvioDeFormulario() {
            return this.error?.config?.method === 'post';
        },

        /**
         * Indica si el error es un error de tipo Axios que no tiene mensaje del server. Normalmente esto indica
         * que algo salió mal en el server y no se pudo determinar el mensaje de error.
         *
         * @returns {boolean}
         */
        esErrorDeServerSinMensaje() {
            if (this.error && this.error.isAxiosError) {
                const message = this.error.response?.data?.message;
                return !message || message?.toLowerCase() === 'server error';
            }

            return false;
        },

        /**
         * Indica si el error es un error de tipo Axios que se produjo cuando el usuario no tiene conexión a internet.
         *
         * @returns {boolean}
         */
        esErrorOffline() {
            return this.error?.isAxiosError && !this.error?.response && window.navigator.onLine === false;
        },

        /**
         * Indica si el error es un error de tipo Axios que se produjo cuando el server está en modo mantenimiento.
         *
         * @returns {boolean}
         */
        esModoMantenimiento() {
            return this.error?.isAxiosError && this.error?.response?.status === 503;
        },

        /**
         * El título a mostrar.
         *
         * @returns {string}
         */
        tituloMostrar() {
            if (this.error instanceof OopsError) {
                return this.error.message;
            }

            if (this.esErrorOffline) {
                return this.$t('oops.errorOffline');
            }

            if (this.esErrorFarmacia) {
                return this.error.response?.data?.message ?? this.$t('oops.errorValidacion');
            }

            if (this.esErrorValidacion) {
                return this.$t('oops.errorValidacion');
            }

            if (this.esEnvioDeFormulario) {
                return this.$t('oops.errorPost');
            }

            return this.titulo ?? this.$t('oops.error');
        },

        /**
         * Los detalles a mostrar, o null si no hay detalles.
         *
         * @returns {string|null}
         */
        detallesMostrar() {
            if (this.detalles) return this.detalles;
            if (!this.error) return null;
            if (this.esErrorFarmacia) return null;
            if (this.esErrorOffline) return null;
            if (this.esModoMantenimiento) return null;

            if (this.error instanceof OopsError) {
                return this.error.details;
            }

            if (this.error.isAxiosError) {
                if (this.esErrorDeServerSinMensaje) {
                    return null; // No queremos mostrar errores genéricos de backend al usuario.
                }

                // Si el error del server viene con mensaje, lo mostramos.
                const response = this.error.response;
                const message = response?.data?.message;
                // El server tiene actualmente muchas rutas que incorrectamente devuelven
                // 'message' como un array de un único elemento en vez de un string.
                // Cuando se solucione, se puede eliminar este if.
                if (Array.isArray(message)) {
                    return message.join(', ');
                }

                // Si el error viene del server, priorizamos mostrar el campo "message" que mande el server.
                // en caso de que no exista, mostramos el HTTP status code y el mensaje de error.
                return message ?? `${response?.status} ${response?.statusText}`;
            }

            return null; // No queremos mostrar errores genéricos de JS al usuario.
        },
    },
};
</script>
