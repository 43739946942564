<template>
    <v-container class="text-center">
        <v-card class="mx-auto" max-width="600" variant="elevated">
            <div class="bg-success text-on-success pa-8 d-flex align-center flex-column">
                <v-icon icon="mdi-checkbox-marked-circle" size="62" />
                <h1 class="d-h4 d-inline ml-sm-6 mt-2">
                    {{ $t('pagoOkView.pedidoConfirmado') }}
                </h1>
            </div>
            <v-card-text class="pa-8 text-center text-body-1">
                <p>{{ $t('pagoOkView.tuPagoHaSidoProcesado') }}</p>
                <div class="text-center mt-12">
                    <v-btn color="success" prepend-icon="mdi-home" size="large" variant="flat" class="mx-auto" to="/">
                        {{ $t('volverAlInicio') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import HeartbeatService from '../../support/HeartbeatService';

export default {
    name: 'PagoOkView',
    created(){
        HeartbeatService.instance.heartbeat();
    },
};
</script>
