<template>
    <v-container>
        <h1 class="mb-4 text-h5 text-primary font-weight-bold">
            {{ $t('consumosView.misConsumos') }}
        </h1>

        <oops-alert v-if="error" :error="error" mostrar-reintentar @reintentar="cargarDatos" />

        <div v-else-if="loading" class="text-center">
            <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" />
        </div>

        <v-card v-else-if="ventas.length === 0" class="px-6 py-16 text-center mx-n4 mx-sm-0" color="surface" elevation="0" :border="true">
            <v-icon size="60" icon="mdi-format-list-bulleted-type" class="text-medium-emphasis " />
            <div class="text-high-emphasis text-h6 mt-6">
                {{ $t('consumosView.noTienesConsumos') }}
            </div>
            <div class="text-medium-emphasis text-body-1 mt-1">
                {{ $t('consumosView.noTienesConsumosDescripcion') }}
            </div>
        </v-card>

        <v-card v-else class="mx-n4 mx-sm-0">
            <v-list>
                <template v-for="(venta, i) in ventas" :key="venta.id">
                    <v-list-item class="py-4" :to="venta.to">
                        <template #prepend>
                            <v-img
                                contain
                                :src="venta.imagen"
                                :alt="venta.descripcion"
                                class="mr-4 mr-sm-6 rounded-lg"
                                :width="imageSize"
                                :height="imageSize" />
                        </template>
                        <div class="d-flex flex-column flex-sm-row justify-sm-space-between ">
                            <div>
                                <v-list-item-title class="font-weight-bold text-wrap">
                                    {{ venta.cantidad }} × {{ venta.descripcion }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="pt-2">
                                    {{ $t('consumosView.fecha') }}:
                                    <strong>{{ venta.fecha }}</strong>
                                </v-list-item-subtitle>
                                <v-btn
                                    v-if="venta.prospecto"
                                    class="text-body-2 px-0 mt-4"
                                    color="grey-darken-2"
                                    density="compact"
                                    variant="plain"
                                    :href="venta.prospecto"
                                    target="_blank"
                                    @click.stop>
                                    <v-icon icon="mdi-file-pdf-box" size="large" class="me-2" />
                                    {{ $t('articuloView.prospecto') }}
                                </v-btn>
                            </div>
                            
                            <div :class="$vuetify.display.smAndUp ? 'd-flex flex-column mx-4':''">
                                <div class="mt-4 mt-sm-0 align-self-end align-self-sm-start pl-0 pl-sm-4 text-right d-flex flex-row flex-sm-column justify-space-between align-sm-end w-100 align-end h-100">
                                    <div v-if="mostrarpvpconsumos()" class="text-body-1 font-weight-bold order-last order-sm-first">
                                        {{ venta.importe }}
                                    </div>
                                    <div class="text-body-2 font-medium-emphasis mt-0 mt-sm-2 d-flex align-center">
                                        <v-icon icon="mdi-account-tie" class="mr-2" :aria-label="$t('consumosView.vendedor')" />
                                        <span>{{ venta.vendedor }}</span>
                                        <v-tooltip activator="parent" location="right" :text="$t('consumosView.vendedor')" />
                                    </div>
                                </div>
                                <div class="text-medium-emphasis text-right text-caption my-2">
                                    <span>REF: {{ venta.idarticu }}</span>
                                </div>
                            </div>
                        </div>
                    </v-list-item>
                    <v-divider v-if="i < ventas.length - 1" />
                </template>
            </v-list>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import productoDefaultImageUrl from '../../images/producto-default.jpg';
import EurosHelper from '../support/EurosHelper';
import StringHelper from '../support/StringHelper';
import OopsAlert from '@/components/OopsAlert.vue';
import { store } from '../store';
export default {
    name: 'ConsumosView', // Usado por <keep-alive>

    components: {
        OopsAlert,
    },
    data: () => ({
        /**
         * Indica si se está cargando información del servidor.
         *
         * @type {boolean}
         */
        loading: false,

        /**
         * Error al cargar la información del servidor.
         *
         * @type {Error|null}
         */
        error: null,

        /**
         * Las ventas del cliente.
         *
         * @type {object[]}
         */
        ventas: [],
    }),
    computed: {
        imageSize() {
            return this.$vuetify.display.smAndUp ? 120 : 80;
        },
        store() {
            return store;
        },
    },
    created() {
        this.cargarDatos();
    },
    methods: {
        async cargarDatos() {
            this.loading = true;
            this.error = null;
            try {
                const { data } = await axios.get('/histoconsumo');

                this.ventas = data.flatMap((venta) => {
                    return venta.lineas.map((linea) => ({
                        id: `${linea.idventa}-${linea.idnlinea}`,
                        fecha: this.fechaHora(venta.fechahora),
                        vendedor: StringHelper.titleCase(venta.vendedor),
                        cantidad: linea.cantidad,
                        descripcion: linea.descripcion,
                        idarticu: linea.codigo,
                        imagen: linea.imagen ?? productoDefaultImageUrl,
                        prospecto: linea.prospecto,
                        importe: EurosHelper.format(linea.importeneto),
                        to: linea.articuexiste ? `/productos/${linea.codigo}` : null,
                    }));
                });
            } catch (error) {
                this.error = error;
                console.error(error);
            }
            this.loading = false;
        },
        fechaHora(fechahora){
            return moment(fechahora).format('L')+' '+moment(fechahora).format('LT');
        },
        mostrarpvpconsumos(){
            return this.store.farmacia.ocultapvpconsumos === false;
        },
    },
};
</script>
