<template>
    <v-container>
        <v-card class="mx-auto" max-width="600" variant="elevated">
            <div class="bg-error text-on-error pa-8 d-flex flex-column align-center">
                <v-icon icon="mdi-close-circle" size="62" />
                <h1 class="d-h4 d-inline mt-4">
                    {{ $t('errorPagoView.errorEnElPago') }}
                </h1>
            </div>
            <v-card-text class="pa-8 text-center text-body-1">
                <div v-if="message">
                    <p>{{ message }}</p>
                </div>
                <div v-else>
                    <p>{{ $t('errorPagoView.loSentimosNoHemos') }}</p>
                    <p class="mt-6">
                        {{ $t('errorPagoView.intentaloDeNuevo') }}
                    </p>
                </div>
                <div class="text-center mt-12">
                    <v-btn color="error" prepend-icon="mdi-home" size="large" variant="flat" class="mx-auto" to="/">
                        {{ $t('volverAlInicio') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import HeartbeatService from '../../support/HeartbeatService';

export default {
    name: 'PagoErrorView',
    props: {
        message: {
            type: String,
            required: false,
            default: null,
        },
    },
    created(){
        HeartbeatService.instance.heartbeat();
    },
};
</script>
