<template>
    <v-dialog v-model="visible" width="600px" transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar color="primary" class="d-flex justify-start align-center pa-3 px-4">
                <v-img
                    src="../../../images/icon.png"
                    max-height="42px"
                    max-width="42px"
                    class="rounded-lg bg-white" />
                <h1 class="headline ml-6 text-h6">
                    Farmatic APProx
                </h1>
            </v-toolbar>
            <v-card-text>
                <p>Mi Farmacia</p>
                <p v-if="textoVersion" class="mt-2">
                    {{ textoVersion }}
                </p>
                <p v-if="copyright" class="mt-2">
                    {{ copyright }}
                </p>
            </v-card-text>
            <v-card-actions class="mt-4">
                <v-spacer />
                <v-btn color="primary" @click="visible = false">
                    {{ $t('acercaDe.cerrar') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

export default {
    data() {
        return {
            /**
             * Indica si el dialogo es visible.
             *
             * @type {boolean}
             */
            visible: false,

            /**
             * El texto de la versión de la app.
             *
             * @type {string|null}
             */
            textoVersion: null,

            /**
             * El texto de la copyrigth de la app.
             *
             * @type {string|null}
             */
            copyright: null,
        };
    },
    created() {
        this.inicializar();
    },
    methods: {
        async inicializar() {
            if (Capacitor.isNativePlatform()) {
                const { version, build } = await App.getInfo();
                this.textoVersion = `v${version}(${build})`;
            }
            this.copyright = `® ${ new Date().getFullYear() } ${this.$t('acercaDe.consoftSau')}`;
        },
    },
};
</script>
