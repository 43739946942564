<template>
    <v-container style="max-width: 750px !important;" :class="{'px-0': $vuetify.display.mdAndDown}">
        <h1 class="d-flex align-center py-4 px-4 text-h5 text-primary font-weight-bold">
            {{ $t('notificacionesView.misNotificaciones') }}
        </h1>

        <div v-if="loading && notificaciones.length === 0" class="text-center d-block">
            <v-card class="px-6 py-16 ext-centert" color="surface" elevation="0" :border="true">
                <v-progress-circular indeterminate color="primary" class="ma-8 mt-16" size="large" />
            </v-card>
        </div>

        <div v-else-if="notificaciones.length === 0">
            <v-card class="px-6 py-16 text-center" color="surface" elevation="0" :border="true">
                <v-icon size="60" icon="mdi-bell" class="text-medium-emphasis " />
                <div class="text-high-emphasis text-h6 mt-6">
                    {{ $t('notificacionesView.noHayNotificaciones') }}
                </div>
                <div class="text-medium-emphasis text-body-1 mt-1">
                    {{ $t('notificacionesView.noHayNotificacionesSubtitulo') }}
                </div>
            </v-card>
        </div>

        <v-card v-else class="mx-auto mb-16">
            <!--****-->
            <v-infinite-scroll :items="notificaciones" direction="vertical" class="overflow-hidden" empty-text="" @load="load">
                <v-list class="pb-0 pt-0 mt-0">
                    <template v-for="item in notificaciones" :key="item.idnotificacion">
                        <v-list-item
                            :aria-description="!item.leido ? $t('notificacionesView.notificacionNoLeida') : null"
                            class="pa-4"
                            @click.stop="mostrarNotificacion(item)">
                            <template #prepend>
                                <v-img v-if="item.imagen" :alt="item.titulo" width="42" height="42" :src="item.imagen" class="rounded-lg mr-4 bg-grey" />
                                <v-avatar v-else icon="mdi-information-outline" size="42" color="secondary" />
                            </template>
                            <div class="d-flex w-100 justify-space-between align-stretch">
                                <div class="font-weight-bold">
                                    {{ item.titulo }}
                                </div>
                                <div class="ms-2 d-flex align-center">
                                    <div class="text-medium-emphasis text-caption text-no-wrap">
                                        <v-badge v-if="isLogged && !item.leido" dot inline color="primary" class="d-inline" />
                                        {{ item.fecha }}
                                    </div>
                                </div>
                            </diV>
                            <p class="mt-1">
                                {{ item.descripcion }}
                            </p>
                        </v-list-item>
                    </template>
                </v-list>
                <template #loading>
                    <div class="text-center d-block">
                        <v-progress-circular indeterminate class="ma-8 mt-16" />
                    </div>
                </template>
                <template #error="{ retry }">
                    <oops-alert :error="error" mostrar-reintentar @retry="retry" />
                </template>
            </v-infinite-scroll>
        </v-card>
        <notificacion-dialog v-model="mostrarNotificacionDialog" :notificacion="notificacionSeleccionada" />
    </v-container>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import moment from 'moment';
import OopsAlert from '../components/OopsAlert.vue';
import NotificacionDialog from './NotificacionDialog.vue';
import { normalizeNotificacion } from '../support/Normalization';
import HeartbeatService from '../support/HeartbeatService';

export default {
    components: {
        OopsAlert,
        NotificacionDialog,
    },
    data() {
        return {
            /**
             * Error al cargar las notificaciones.
             *
             * @type {Error|null}
             */
            error: null,

            /**
             * Indica si se está cargando contenido.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Lista de notificaciones.
             *
             * @type {object[]}
             */
            notificaciones: [],

            /**
             * URL de la próxima página, o null si no hay más páginas.
             *
             * @type {string|null}
             */
            nextPageUrl: this.getFirstPageUrl(),

            /**
             * Indica si se debe mostrar el diálogo de notificación.
             *
             * @type {boolean}
             */
            mostrarNotificacionDialog: false,

            /**
             * Notificación seleccionada.
             *
             * @type {object|null}
             */
            notificacionSeleccionada: null,

            /**
             * Indica si se debe ignorar el siguiente cambio de badge.
             * Esto es un "hack" para evitar que se refresque la lista de notificaciones cuando se marca una notificación como leída
             * y por consiguiente el HeartbeatService cambia el valor del badge y eso produce que se triggeree el watcher de notificacionesBadgeCount
             * y se recargue la lista de notificaciones. Esto produce un efecto visual no deseado porque se ve como se recarga la lista de notificaciones
             * de forma innecesaria.
             *
             * @type {boolean}
             */
            ignoreNextBadgeChange: false,
        };
    },
    computed: {
        isLogged() {
            return store.isLogged;
        },

        notificacionesBadgeCount() {
            return store.notificaciones.badge;
        },
    },
    watch: {
        notificacionesBadgeCount() {
            // El HeartbeatService se encarga de cambiar el valor del badge
            // Si cambia el valor, recargamos desde cero las notificaciones. No es muy elegante recargar la lista desde cero,
            // pero como está paginada, y no tenemos un endpoint para pedir solo las notificaciones no leídas, es la única forma.

            if (this.ignoreNextBadgeChange) {
                this.ignoreNextBadgeChange = false;
                return;
            }

            this.reset();
        },
    },

    created() {
        this.reset();
    },

    methods: {
        getFirstPageUrl() {
            // TODO: La URL debería ser siempre la misma. El backend debería cambiar la respuesta segun si el user está logueado o no.
            // Además la ruta tiene un bug (bug? o feature?) que hace que no se pagine si no se pasa el parámetro 'page'.
            return store.isLogged ? '/notificacionesuser?page=1' : '/notificaciones?page=1';
        },

        async reset() {
            this.nextPageUrl = this.getFirstPageUrl();
            this.notificaciones = [];
            this.loading = false;
            await this.loadNextPage();
        },

        async loadNextPage() {
            if (this.loading) return;
            if (!this.nextPageUrl) return;

            this.loading = true;
            this.error = null;

            try {
                const { data } = await axios.get(this.nextPageUrl);

                // https://laravel.com/docs/10.x/pagination#converting-results-to-json
                this.nextPageUrl = data.next_page_url;
                this.notificaciones.push(
                    ...data.data
                        .map(item => normalizeNotificacion(item))
                        .map(item => ({
                            ...item,
                            fecha: moment(item.fecha).format('L'),
                        })),
                );
            } catch (error) {
                this.error = error;
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        /**
         * Handler de la carga infinita de articulos.
         *
         * @param {object} $state
         * @returns {Promise<void>}
         */
        async load({ done }) {
            this.loading = false;
            await this.loadNextPage();
            
            if (this.nextPageUrl) {
                done('ok');
            } else {
                done('empty');
            }
        },

        mostrarNotificacion(notificacion) {
            this.notificacionSeleccionada = notificacion;
            this.mostrarNotificacionDialog = true;

            this.marcarLeida(notificacion);
        },

        async marcarLeida(notificacion) {
            if (!store.isLogged) return;
            if (notificacion.leido) return;

            await axios.post('/notificaciones/markasread', { // Si falla, no hay nada que hacer. 🙁
                idfarmacia: store.farmacia.numserie,
                idnotificacion: notificacion.idnotificacion,
                tiponotificacion: notificacion.tiponotificacion,
                iduser: store.user.id,
            });

            this.ignoreNextBadgeChange = true; // Evitamos que se recargue la lista de notificaciones cuando se cambie el valor del badge.
            HeartbeatService.instance.heartbeat(); // Actualizamos el valor del badge. No esperamos a que termine.

            notificacion.leido = true;
        },
    },
};
</script>
