<template>
    <v-sheet v-if="visible" color="primary-surface" class="pa-4 d-flex font-weight-bold">
        <div class="d-inline-flex mr-4 align-center justify-center rounded-circle bg-primary" style="width: 24px; height: 24px;">
            <div class="rounded-circle d-inline" style="width: 22px; height: 22px; background: black;">
                <div class="icon-blink rounded-circle" :class="estado === 'ABIERTO' ? 'bg-success' : 'bg-error'" style="width: 22px; height: 22px;" />
            </div>
        </div>
        <div class="text-on-primary-surface">
            {{ mensaje }}
        </div>
    </v-sheet>
</template>
<script>
import { store } from '@/store';
import axios from 'axios';

export default {
    data: () => ({
        /**
         * El mensaje a mostrar.
         */
        mensaje: '',

        /**
         * El estado de la farmacia, o null si se está cargando.
         *
         * @type {'ABIERTO'|'CERRADO'|'DESCONOCIDO'|null}
         */
        estado: null,
    }),
    computed: {
        farmacia() {
            return store.farmacia;
        },
        visible() {
            return this.estado === 'ABIERTO' || this.estado === 'CERRADO';
        },
        imagenHome() {
            return store.farmacia.rutaimagenhome;
        },

    },
    watch: {
        '$i18n.locale'() {
            this.refrescar();
        },
    },

    /**
     * El intervalo para refrescar el estado de la farmacia.
     *
     * @type {NodeJS.Timer|null}
     */
    interval: null,

    created() {
        this.refrescar();
        this.$options.interval = setInterval(() => {
            this.refrescar();
        }, 5 * 60 * 1000); // 5 minutos
    },

    beforeUnmount() {
        clearInterval(this.$options.interval);
    },

    methods: {
        /**
         * Devuelve el timezone offset como un string ISO 8601 (ej +01:00)
         *
         * @returns {string}
         */
        getTimezoneOffset() {
            const offset = new Date().getTimezoneOffset();
            const sign = offset > 0 ? '-' : '+';
            const absOffset = Math.abs(offset);
            const hours = Math.floor(absOffset / 60);
            const minutes = absOffset % 60;
            const hoursStr = hours.toString().padStart(2, '0');
            const minutesStr = minutes.toString().padStart(2, '0');
            return `${sign}${hoursStr}:${minutesStr}`;
        },

        /**
         * Refresca el estado de la farmacia desde el servidor.
         *
         * @returns {Promise<void>}
         */
        async refrescar() {
            try {
                const tz = window.encodeURIComponent(this.getTimezoneOffset());
                const { data } = await axios.get(`/estado-farmacia?timezone=${tz}`);
                this.mensaje = data.mensaje;
                this.estado = data.estado;
            } catch (e) {
                console.error(e); // Si falla nos comemos el error ya que no es crítico
            }
        },
    },


};
</script>

<style scoped>
@keyframes blinker {
    from { opacity: 1; }
    to { opacity: 0.5; }
}
.icon-blink {
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
</style>

