export default {
    ENCARGADO: 'Encargado',
    PEDIDO: 'Pedido',
    RECIBIDO: 'Recibido',
    DISPENSADO: 'Dispensado',
    ANULADO: 'Anulado',
    NO_RECOGIDO: 'No Recogido',
    NO_ENVIAN: 'No Envían',
    N_A: 'N/A',
};
