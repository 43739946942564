<template>
    <div class="d-block mt-2">
        <v-chip
            v-if="categoriaPadreActual.padre !== null"
            prepend-icon="mdi-chevron-left"
            variant="tonal"
            color="secondary"
            :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
            class="ma-2"
            rounded="lg"
            @click.stop="atras()">
            {{ $t('atras') }}
        </v-chip>
        <v-chip
            v-if="categoriaPadreActual.hijos.length > 0"
            color="secondary"
            :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
            class="ma-2"
            rounded="lg"
            variant="outlined">
            {{ categoriaPadreActual.padre === null
                ? $t('menuItems.categorias')+':'
                : categoriaPadreActual.descripcion+':' }}
        </v-chip>
    </div>
    <v-expand-transition>
        <v-container v-if="show" :class="$vuetify.display.mdAndUp ? 'pa-0': 'd-inline-flex overflow-x-auto pa-0 py-1'">
            <v-chip
                v-if="categoriaPadreActual.hijos.length > 0"
                color="secondary"
                :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
                :class="$vuetify.display.mdAndUp ? 'mt-2 ma-2' : 'mt-1 mr-1 ml-0 custom-chip'"
                rounded="lg"
                :variant="categoriaVariant(categoriaPadreActual)"
                @click.stop="verProductos(categoriaPadreActual)">
                {{ categoriaPadreActual.padre === null
                    ? $t('menuItems.todosLosProductos')
                    : $t('menuItems.todoEn', [categoriaPadreActual.descripcion]) }}
            </v-chip>
            <v-chip
                v-for="categoria in categoriaPadreActual.hijos"
                :key="categoria.id"
                color="secondary"
                :variant="categoriaVariant(categoria)"
                :size="$vuetify.display.mdAndUp ? 'x-large' : 'default'"
                :class="$vuetify.display.mdAndUp ? 'mt-2 ma-2' : 'mt-1 mr-1 custom-chip'"
                rounded="lg"
                @click.stop="categoriaClicked(categoria)">
                {{ categoria.descripcion }}
            </v-chip>
        </v-container>
    </v-expand-transition>
</template>
<script>

import { store } from '@/store';

export default {
    name: 'CategoriasChips',

    data: () => ({

        /**
         * Categoría padre actualmente seleccionada. Esta es la categoría padre que se muestra actualmente
         * en el arbol de categorías con todos sus hijos o null si no hay ninguna seleccionada.
         *
         * @type {Categoria?}
         */
        categoriaPadreActual: null,

        /**
         * Categoría actualmente activa. Esta es la categoría que se está mostrando en la
         * web actualmente o null si no hay ninguna activa. Se muestra con un fondo diferente.
         *
         * @type {Categoria?}
         */
        categoriaActual: null,

        /**
         * La acción de transición que se está ejecutando actualmente. 'enter' indica que se está
         * entrando en un submenú y 'leave' indica que se está saliendo de un submenú.
         *
         * @type {'enter'|'leave'}
         */
        transitionAction: 'enter',

        /**
         * Los idiomas disponibles en la app.
         *
         * @type {string[]}
         */
        idiomasDisponibles: ['Inglés', 'Español'],
        /**
         * Indica si mostrar o no las categorias hijas
         */
        show: true,
    }),

    computed: {
        farmacia() {
            return store.farmacia;
        },

        categoriaRoot() {
            return store.categoriaRoot;
        },

        /**
         * Obtiene si se debe mostrar el menú de categorías.
         *
         * @returns {boolean}
         */
        mostrarCategorias() {
            return this.farmacia && this.farmacia.ecommerce !== 0 && this.categoriaRoot.hijos.length > 0;
        },

    },
    watch: {
        $route(to) {
            if (to.name === 'Categoria') {
                this.onCategoriaRoute(to.params.idcategoria);
            }
        },
    },

    async created() {
        // Settea la categoría actual de acuerdo a la ruta actual
        if (this.$route.name === 'Categoria') {
            this.onCategoriaRoute(this.$route.params.idcategoria);
        }
    },
    methods: {
        iconoActiva(categoria) {
            if(categoria.activa){
                return 'mdi-check';
            }
            return false;
        },
        estiloActiva(categoria) {
            if(categoria.activa){
                console.log('activa');
                return 'elevated';
            }
            return 'flat';
        },
        /**
         * Procesa el click sobre una categoría.
         *
         * @param {Categoria} categoria
         */
        categoriaClicked(categoria) {
            if (categoria.hijos.length > 0) {
                this.transitionAction = 'enter';
                this.categoriaPadreActual = categoria;
                this.verProductos(categoria);
            } else {
                this.verProductos(categoria);
            }
        },

        /**
         * Navega a la página de productos de la categoría especificada.
         *
         * @param {Categoria} categoria
         */
        verProductos(categoria) {
            this.categoriaActual = categoria;
            this.$router.push(categoria.link);
        },

        /**
         * Navega a la página de productos de la categoría padre actual.
         */
        atras() {
            if (this.categoriaPadreActual) {
                this.transitionAction = 'leave';
                this.categoriaPadreActual = this.categoriaPadreActual.padre;
            }
        },

        /**
         * Maneja el evento de cambio de ruta de la página de productos.
         *
         * @param {number} categoriaId
         */
        onCategoriaRoute(categoriaId) {
            // Si la ruta es /productos/:categoria, se busca la categoría en el menú
            // Solo se muestra el menú de categorías si la categoría tiene subcategorías
            const categoria = this.categoriaRoot.findById(categoriaId);
            if (categoria) {
                this.categoriaActual = categoria;
                this.categoriaPadreActual = categoria.hijos.length > 0 ? categoria : categoria.padre;
            } else {
                this.categoriaActual = null;
                this.categoriaPadreActual = null;
            }
        },

        /**
         * Variante activa/flat para chip de categorías según si está activa o no
         *
         * @returns {boolean}
         */
        categoriaVariant(categoria) {
            if(categoria.id === +this.$route.params.idcategoria){
                return 'elevated';
            }
            return 'tonal';
        },
    },
};
</script>

<style scoped>

.custom-chip {
    max-width: 100%;
    min-width: fit-content;
    font-size: 0.9rem !important;
}

.v-container{
    overflow-x: auto !important;
}


</style>
