<template>
    <div v-if="!initialized" v-intersect="{ handler: onIntersect }">
        <div class="text-center d-block ma-16">
            <v-progress-circular indeterminate color="secondary" class="ma-8 mt-16" size="70" />
        </div>
    </div>
    <v-sheet v-else-if="articulos.length > 0" color="secondary-surface" class="py-2 px-0">
        <v-container fluid class="pa-0">
            <v-carousel
                v-model="itemPosition"
                cycle
                continuous
                :interval="$vuetify.display.mdAndUp ? '3000' : '2500'"
                hide-delimiters
                height="auto"
                :show-arrows="$vuetify.display.mdAndUp ? 'hover' : false">
                <template
                    v-for="(articulo, index) in articulos"
                    :key="articulo.idarticu">
                    <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index" cover>
                        <v-row style="height:100%;" :class="$vuetify.display.mdAndUp ? 'ma-3' : 'ma-0'">
                            <template v-for="(n,i) in columns">
                                <template v-if="(+index + i) < articulos.length">
                                    <v-col :key="i">
                                        <v-row v-if="(+index + i) < articulos.length" class="fill-height" justify="center">
                                            <articulo-carousel-flip :articulo="articulos[index + i]" :mostrar-imagen="mostrarImagenes" :mostrar-precio="mostrarPrecios" />
                                        </v-row>
                                    </v-col>
                                </template>
                            </template>
                        </v-row>
                    </v-carousel-item>
                </template>
            </v-carousel>
        </v-container>
    </v-sheet>
    <oops-alert v-else-if="error" :error="error" :titulo="$t('articulosLista.mensajeError')" mostrar-reintentar class="ma-5 mt-4" @reintentar="reset()" />
</template>

<script>
import { store } from '../../store';
import axios from 'axios';
import { normalizeArticulo } from '../../support/Normalization';
import OopsAlert from '../../components/OopsAlert.vue';
import ArticuloCarouselFlip from '../../components/ArticuloCarouselFlip.vue';

export default {
    components: {
        OopsAlert,
        ArticuloCarouselFlip,
    },
    data() {
        return {
            /**
             * Indica si se está cargando la primera página.
             *
             * @type {boolean}
             */
            loading: false,

            /**
             * Error al cargar los articulos.
             *
             * @type {Error|null}
             */
            error: null,

            /**
             * Lista de articulos.
             *
             * @type {object[]}
             */
            articulos: [],

            /**
             * URL de la siguiente página de articulos, o null si no hay más páginas.
             *
             * @type {string|null}
             */
            nextPageUrl: '/articulos',

            /**
             * Indica si se cargó la primera página.
             *
             * @type {boolean}
             */
            initialized: false,
            /**
             * Indica la posicion en el carrusel para saber cuando debe cargar más articulos.
             */
            itemPosition: 0,
            /**
             * Total de diapositivas en el carrusel.
             */
            totalDiapositivas: 0,

        };
    },
    computed: {
        mostrarImagenes() {
            return store.farmacia.mostrarImagenes;
        },
        mostrarPrecios() {
            return store.mostrarPrecios;
        },
        columns() {
            if (this.$vuetify.display.xl) {
                return 4;
            }

            if (this.$vuetify.display.lg) {
                return 3;
            }

            if (this.$vuetify.display.md) {
                return 3;
            }

            if (this.$vuetify.display.sm) {
                return 2;
            }
            if (this.$vuetify.display.xs) {
                return 1;
            }
            return 1;
        },
    },
    watch: {
        mostrarPrecios(){
            this.reset();
        },
        itemPosition(newPosition) {
            this.totalDiapositivas = parseInt(this.articulos.length/this.columns);
            if (newPosition === this.totalDiapositivas-1) {
                this.loadNextPage();
            }
        },
    },
    methods: {

        /**
         * Handler de la directiva v-intersect.
         *
         * @param {boolean} isIntersecting
        */
        async onIntersect(isIntersecting) {
            if (!isIntersecting) return;
            if (this.initialized) return;
            if (this.loading) return;

            await this.loadNextPage();

            this.initialized = true;
        },

        /**
         * Carga la siguiente página de articulos.
         *
         * @returns {Promise<void>}
         */
        async loadNextPage() {
            if (this.loading) return;
            if (!this.nextPageUrl) return;
            this.loading = true;
            this.error = null;
            try {
                const { data } = await axios.post(this.nextPageUrl, {
                    portada: true,
                });


                // https://laravel.com/docs/10.x/pagination#converting-results-to-json
                this.nextPageUrl = data.next_page_url;

                if(! data.data?.length > 0){
                    this.sinArticulos = true;
                    return;
                }
                this.articulos.push(...data.data.map(articulo => normalizeArticulo(articulo)));

            } catch (err) {
                this.error = err;
                console.error(err);
            } finally {
                this.loading = false;
            }
        },

        /**
         * Handler de la carga infinita de articulos.
         *
         * @param {object} $state
         * @returns {Promise<void>}
         */
        async load({ done }) {
            this.loading = false;
            await this.loadNextPage();
            done('empty');
        },

        /**
         * Resetea el estado del componente y vuelve a cargar los articulos.
         *
         * @returns {Promise<void>}
         */
        async reset() {
            this.articulos = [];
            this.nextPageUrl = '/articulos';
            this.sinArticulos = false;
            this.error = null;

            await this.loadNextPage();

            this.sinArticulos = (this.nextPageUrl === null && this.error === null && this.articulos.length === 0);
        },
    },
};
</script>

<style scoped>

/*ScrollBar custom. para DESTACADOS - Compatible con: Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #777777;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #383838;
}
</style>

